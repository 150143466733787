/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/prop-types */

import { useState, useEffect, useCallback, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import moment from "moment";
import UseHandleModal from "../Modal/UseHandleModal";
import http from "../../../services/httpService";
import auth from "../../../services/authService";
import { useSelector } from "react-redux";

const DisputeUploadTablePage = ({ refresh }) => {
  const email = auth.getUser().UserLogin.email;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [uploadedData, setUploadedData] = useState([]);
  const [dataFetched, setDataFetched] = useState(false);
  const [showDeletionModal, setShowDeletionModal] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [deletionInProgress, setDeletionInProgress] = useState(false);
  const [deletionSuccess, setDeletionSuccess] = useState(false);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  const theme = useSelector((state) => state.theme.theme);
  const {
    handleModal3,
    darkhandleModal3,
    handleModal2,
    darkhandleModal2,
    OtpModal,
    darkOtpModal,
    showLoadingModalOnly,
    darkshowLoadingModalOnly,
  } = UseHandleModal();
  const hasFetchedData = useRef(false);

  const fetchUploadedData = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await http.post2("/getAllUpload", {
        page,
        perPage,
        email,
      });
      // console.log(data, "data");
      setUploadedData(data?.data);
      setDataFetched(true);
      if (!hasFetchedData.current) {
        theme
          ? handleModal3("Data has been fetched successfully.")
          : darkhandleModal3("Data has been fetched successfully.");
        hasFetchedData.current = true;
      }
    } catch (error) {
      // console.error("Error fetching uploaded data:", error);
      setError(error);
      theme
        ? handleModal2("Failed to fetch uploaded data.")
        : darkhandleModal2("Failed to fetch uploaded data.");
      setDataFetched(true);
    } finally {
      setLoading(false);
    }
  }, [page, perPage, refresh]);

  useEffect(() => {
    fetchUploadedData();
  }, [fetchUploadedData, refresh]);

  const handleDeleteRequest = async () => {
    if (!selectedRecord) return;
    setShowDeletionModal(false);
    theme
      ? showLoadingModalOnly(
          "Sending the OTP (One Time Paasword) to your email"
        )
      : darkshowLoadingModalOnly(
          "Sending the OTP (One Time Paasword) to your email"
        );

    try {
      const response = await http.post2("/verifyAndSendOTP", {
        email: selectedRecord.email,
        timeStamp: selectedRecord.timeStamp,
        documentID: selectedRecord?.documentID || "",
      });
      // console.log(selectedRecord, "selectedRecord");

      if (response.status === 200) {
        theme
          ? OtpModal(selectedRecord.email, handleOtpSubmit, handleResendOtp)
          : darkOtpModal(
              selectedRecord.email,
              handleOtpSubmit,
              handleResendOtp
            );
      } else {
        theme
          ? handleModal2("Failed to send OTP. Please try again.")
          : darkhandleModal2("Failed to send OTP. Please try again.");
      }
    } catch (error) {
      // console.error("Error sending OTP:", error);
      theme
        ? handleModal2("Error sending OTP. Please try again.")
        : darkhandleModal2("Error sending OTP. Please try again.");
    }
  };

  const handleOtpSubmit = async (inputOtp) => {
    try {
      const response = await http.post2("/verifyOtp", {
        otp: inputOtp,
        email: selectedRecord.email,
        timeStamp: selectedRecord.timeStamp,
      });

      if (response.data.success) {
        // console.log("performDeletion");
        await performDeletion();
      } else {
        theme
          ? handleModal2(
              `${response.data.message || "Invalid OTP. Please try again."}`
            )
          : darkhandleModal2(
              `${response.data.message || "Invalid OTP. Please try again."}`
            );

        setTimeout(() => {
          OtpModal(selectedRecord.email, handleOtpSubmit, handleResendOtp);
        }, 2000);
      }
    } catch (error) {
      // console.error("Error verifying OTP:", error);
      theme
        ? handleModal2("Error verifying OTP. Please try again.")
        : darkhandleModal2("Error verifying OTP. Please try again.");
      setTimeout(() => {
        OtpModal(selectedRecord.email, handleOtpSubmit, handleResendOtp);
      }, 2000);
    }
  };

  const handleResendOtp = async () => {
    try {
      Swal.fire({
        title: "Resending OTP...",
        didOpen: () => {
          Swal.showLoading();
        },
      });

      const response = await http.post2("/verifyAndSendOTP", {
        email: selectedRecord.email,
        timeStamp: selectedRecord.timeStamp,
        documentID: selectedRecord?.documentID || "",
      });

      if (response.status === 200) {
        Swal.fire({
          icon: "success",
          title: "OTP resent successfully",
          showConfirmButton: false,
          timer: 1500,
        });
        setTimeout(() => {
          theme
            ? OtpModal(selectedRecord.email, handleOtpSubmit, handleResendOtp)
            : OtpModal(selectedRecord.email, handleOtpSubmit, handleResendOtp);
        }, 1500);
      } else {
        theme
          ? handleModal2("Failed to resend OTP. Please try again.")
          : darkhandleModal2("Failed to resend OTP. Please try again.");
      }
    } catch (error) {
      theme
        ? handleModal2("Error resending OTP. Please try again.")
        : handleModal2("Error resending OTP. Please try again.");
    }
  };

  const performDeletion = async () => {
    setDeletionInProgress(true);

    const handleDeletion = async () => {
      // console.log(selectedRecord);
      try {
        const { data } = await http.deleteApi2("/deleteUpload", {
          data: {
            email: selectedRecord.email,
            timeStamp: selectedRecord.timeStamp,
          },
        });

        if (data === "Record deleted successfully") {
          theme
            ? handleModal3("Data has been deleted successfully.")
            : darkhandleModal3("Data has been deleted successfully.");
          await fetchUploadedData();
          setDeletionSuccess(true);
        } else {
          theme
            ? handleModal2("Unexpected response from server.")
            : darkhandleModal2("Unexpected response from server.");
        }
      } catch (error) {
        // console.error("Error deleting record:", error);
        theme
          ? handleModal2("Failed to delete record. Please try again.")
          : darkhandleModal2("Failed to delete record. Please try again.");
      } finally {
        setDeletionInProgress(false);
        setShowDeletionModal(false);
      }
    };
    handleDeletion();
    // theme ? handleLoadingModal("Processing your request, please wait...", handleDeletion) : darkhandleLoadingModal("Processing your request, please wait...", handleDeletion);
  };

  const handleCloseModal = () => {
    setShowDeletionModal(false);
    setSelectedRecord(null);
    if (deletionSuccess) {
      setDeletionSuccess(false);
    }
  };

  const confirmDelete = (record) => {
    setSelectedRecord(record);
    setShowDeletionModal(true);
    setDeletionSuccess(false);
    setDeletionInProgress(false);
  };

  const getFileName = (record) => {
    if (!record) return "";
    const doc = record?.documentId;
    const type = record.noticeType || "unknown";
    return `${doc} & Type: ${type}`;
  };

  const filteredData = uploadedData.filter(
    (record) => record.status.toLowerCase() === "pending"
  );

  const totalPages = Math.ceil(filteredData.length / perPage);

  useEffect(() => {
    if (page > totalPages && totalPages > 0) {
      setPage(totalPages);
    }
  }, []);

  const paginatedData = filteredData.slice(
    (page - 1) * perPage,
    page * perPage
  );

  return (
    <>
      <div className="main-wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-12">
              <div className="conciliation-application-form">
                <div>
                  {loading ? (
                    <div
                      className="d-flex flex-column align-items-center justify-content-center"
                      style={{ minHeight: "200px" }}
                    >
                      <div
                        className="spinner-border text-primary"
                        role="status"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </div>
                      <p className="mt-3">Loading Data...</p>
                    </div>
                  ) : error ? (
                    <p style={{ color: "red" }}>
                      Error loading data: {error.message}
                    </p>
                  ) : (
                    <>
                      <div>
                        <div className="col-md-12 d-flex justify-content-between align-items-center mb-3">
                          <h2 className="mb-0">NOTICE INFO</h2>
                          <i
                            className="bi bi-view-list fs-3"
                            onClick={() => navigate("/DisputeReporting")}
                            style={{ cursor: "pointer" }}
                          ></i>
                        </div>
                      </div>
                      <div className="table-responsive position-relative">
                        {filteredData.length > 0 ? (
                          <table className="table table-bordered">
                            <thead style={{ textAlign: "center" }}>
                              <tr>
                                <th>#</th>
                                <th>Document ID</th>
                                <th>Email</th>
                                <th>Date & Time</th>
                                <th>Company Name</th>
                                <th>Type</th>
                                <th>Status</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {dataFetched && filteredData.length > 0 ? (
                                paginatedData.map((record, index) => (
                                  <tr key={index}>
                                    <td style={{ textAlign: "center" }}>
                                      {index + 1 + (page - 1) * perPage}
                                    </td>
                                    <td
                                      style={{
                                        textAlign: "center",
                                        cursor: "pointer",
                                      }}
                                    >
                                      <span className="text-primary">
                                        {record?.documentId || "-"}
                                      </span>
                                    </td>
                                    <td>{record.email}</td>
                                    <td>
                                      {moment(record.timeStamp).format(
                                        "DD/MM/YYYY - HH:mm:ss"
                                      )}
                                    </td>
                                    <td>{record.companyName}</td>
                                    <td style={{ textAlign: "center" }}>
                                      {record.noticeType}
                                    </td>
                                    <td style={{ textAlign: "center" }}>
                                      {record?.status ?? "-"}
                                    </td>
                                    <td style={{ textAlign: "center" }}>
                                      <button
                                        style={{
                                          background: "none",
                                          border: "none",
                                          color: "blue",
                                          cursor: "pointer",
                                          textDecoration: "none",
                                        }}
                                        onMouseOver={(e) => {
                                          e.target.style.textDecoration =
                                            "underline";
                                          e.target.style.color = "red";
                                        }}
                                        onMouseOut={(e) => {
                                          e.target.style.textDecoration =
                                            "none";
                                          e.target.style.color = "blue";
                                        }}
                                        onClick={() => confirmDelete(record)}
                                      >
                                        Delete
                                      </button>
                                    </td>
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td
                                    colSpan="8"
                                    style={{ textAlign: "center" }}
                                  >
                                    No pending records available.
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        ) : (
                          <p style={{ textAlign: "center", marginTop: "20px" }}>
                            No pending records to display.
                          </p>
                        )}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* Deletion Modal */}
          <div
            className={`modal fade ${showDeletionModal ? "show d-block" : ""}`}
            tabIndex="-1"
            role="dialog"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">
                    {deletionSuccess
                      ? "Deletion Successful"
                      : deletionInProgress
                      ? "Deleting..."
                      : "Confirm Deletion"}
                  </h5>
                  {!deletionInProgress && (
                    <button
                      type="button"
                      className="btn-close"
                      onClick={handleCloseModal}
                    ></button>
                  )}
                </div>
                <div className="modal-body">
                  {deletionInProgress ? (
                    <div className="d-flex justify-content-center align-items-center">
                      <div
                        className="spinner-border spinner-border-sm"
                        role="status"
                      >
                        <span className="visually-hidden">Deleting...</span>
                      </div>
                      <span className="ms-2">
                        Your file is being deleted...
                      </span>
                    </div>
                  ) : deletionSuccess ? (
                    <p>Data has been deleted successfully!</p>
                  ) : (
                    <p>
                      Are you sure you want to delete Document ID:{" "}
                      <strong>{getFileName(selectedRecord)}</strong>?
                    </p>
                  )}
                </div>
                <div className="modal-footer">
                  {!deletionInProgress && (
                    <>
                      {deletionSuccess ? (
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={handleCloseModal}
                        >
                          Close
                        </button>
                      ) : (
                        <>
                          <button
                            type="button"
                            className="btn btn-secondary"
                            onClick={handleCloseModal}
                          >
                            No
                          </button>
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={handleDeleteRequest}
                          >
                            {" "}
                            Yes{" "}
                          </button>{" "}
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* Pagination Part */}
          <div className="col-lg-6">
            <div className="data-pagi float-lg-end mt-lg-0 mt-2">
              <select
                className="form-control"
                id="floatingSelect"
                value={perPage}
                onChange={(e) => setPerPage(e.target.value)}
                aria-label="Floating label select example"
                disabled={filteredData.length === 0}
              >
                <option value={10}>10</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
              </select>
              <div
                className="btn-toolbar"
                role="toolbar"
                aria-label="Toolbar with button groups"
              >
                <div
                  className="btn-group me-1"
                  role="group"
                  aria-label="First group"
                >
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => setPage((prev) => Math.max(prev - 1, 1))}
                    disabled={page <= 1 || filteredData.length === 0}
                  >
                    <i className="bi bi-chevron-left text-white"></i>
                  </button>
                </div>
                <div
                  className="btn-group me-1"
                  role="group"
                  aria-label="Second group"
                >
                  <button type="button" className="btn text-primary">
                    {page}
                  </button>
                </div>
                <div
                  className="btn-group"
                  role="group"
                  aria-label="Third group"
                >
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => setPage(page + 1)}
                    disabled={
                      filteredData.length === 0 ||
                      paginatedData.length < perPage
                    }
                  >
                    <i className="bi bi-chevron-right text-white"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DisputeUploadTablePage;
