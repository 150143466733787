import React, { useState, useEffect, useRef, useCallback } from "react";
import auth from "../services/authService";
import http from "../services/httpService";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import getCurrentDate from "../utils/getCurrentDate";

export const useDispute = () => {
  const formikRef = useRef(null);
  const modalRef = useRef();
  const user = auth.getUser().UserLogin;
  // const email = auth.getUser().UserLogin.email;
  const [fee, setFee] = useState(2000);
  const [size, setSize] = useState(2);
  const [dol, setDol] = useState(1);
  const [selectedCountry, setSelectedCountry] = useState({});
  const [selectedState, setSelectedState] = useState({});
  const [selectedCity, setSelectedCity] = useState({});
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [pansearch, setPanSearch] = useState(Array(size).fill(""));
  const [directors, setDirectors] = useState([]);
  const [preview, setPreview] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [call, setCall] = useState(false);
  const [showotp, setShowOtp] = useState(false);
  const [disputeID, setDisputeID] = useState("");
  const [otp, setOtp] = useState(null);
  const [panFetch, setPanFetch] = useState(true);
  const [panArray, setPanArray] = useState([]);
  const [cardIndex, setCardIndex] = useState(0);
  const [showForm, setShowForm] = useState(Array(size).fill(false));
  const theme = useSelector((state) => state.theme);
  const [firstUser, setFirstUser] = useState("");

  const [initialValues, setInitialValues] = useState({
    mid:"csc",
    secondParties: [
      {
        slectionOption: "",
        name: "",
        organization: "",
        designation: "",
        address: "",
        govtId: "",
        phone: "",
        email: "",
      },
      {
        slectionOption: "",
        name: "",
        organization: "",
        designation: "",
        address: "",
        govtId: "",
        phone: "",
        email: "",
      },
    ],

    Agreement: {
      valueOfAgreement: "",
      title: "",
      referenceAgreementNo: "",
      DateOfAgreement: getCurrentDate(),
      dateOfCommencement: "",
      Fee: fee,
      phone: "",
      seatofArbitration: {
        country: selectedCountry.name,
        state: selectedState.name,
        city: selectedCity.name,
      },
    },
  });
  const handleEmailChange = useCallback((newEmail) => {
    setFirstUser(newEmail);
  }, []);
  const toggleModal = () => {
    setShowModal((prevShowModal) => !prevShowModal);
  };
  const toggleOtp = () => {
    setShowOtp((prevShowModal) => !prevShowModal);
  };

  const currencyEx = async () => {
    await http
      .post2("/currsave")
      .then((res) => {
        setDol(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const calculateAndDisplayFee = (formikValues) => {
    const valueOfAgreement = formikValues.Agreement.valueOfAgreement;
    let calculatedFee = 0;
    if (valueOfAgreement === "") {
      calculatedFee =
        selectedCountry.name !== "India" ? (dol * 2000).toFixed(2) : 2000;
    } else if (valueOfAgreement === 0) {
      calculatedFee =
        selectedCountry.name !== "India" ? (dol * 2000).toFixed(2) : 2000;
    } else if (valueOfAgreement <= 100000) {
      calculatedFee =
        selectedCountry.name !== "India" ? (dol * 500).toFixed(2) : 500;
    } else if (valueOfAgreement <= 1000000) {
      calculatedFee =
        selectedCountry.name !== "India" ? (dol * 1000).toFixed(2) : 1000;
    } else if (valueOfAgreement <= 5000000) {
      calculatedFee =
        selectedCountry.name !== "India" ? (dol * 2000).toFixed(2) : 2000;
    } else if (valueOfAgreement <= 10000000) {
      calculatedFee =
        selectedCountry.name !== "India" ? (dol * 5000).toFixed(2) : 5000;
    } else {
      calculatedFee = Math.ceil(valueOfAgreement * 0.001).toFixed(2);
    }
    setFee(calculatedFee);
  };

  const Alert = (val) => {
    Swal.fire({
      icon: val === "pan" ? "error" : "warning",
      title: val === "pan" ? "PAN VERIFICATION FAILED" : "Oops...",
      text:
        val === "pan"
          ? "The entered PAN number does not exist."
          : "Incomplete form fill the required field!",
    });
  };
  //Country city and state selection form and dynamic change the value
  // const handleSelectChange = (country, state, city) => {
  //   setSelectedCountry(country);
  //   setSelectedState(state);
  //   setSelectedCity(city);
  //   const updatedInitialValues = { ...initialValues };
  //   updatedInitialValues.Agreement.seatofArbitration.city = city.name;
  //   updatedInitialValues.Agreement.seatofArbitration.country = country.name;
  //   updatedInitialValues.Agreement.seatofArbitration.state = state.name;
  //   setInitialValues(updatedInitialValues);
  // };

  const generateCustomObjectId = () => {
    const timestamp = Math.floor(new Date().getTime() / 1000).toString(16);
    const random = Math.random().toString(16).substr(2, 10);
    let counter = Math.floor(Math.random() * 16777216).toString(16);
    counter = counter.padStart(6, "0");
    const customObjectId = timestamp + random + counter;
    return customObjectId;
  };

  const titleCase = (input) => {
    if (typeof input == "string") {
      return input
        .toLowerCase()
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
    } else if (typeof input == "number") {
      return input;
    } else {
      throw new Error("Input must be either a string or a number");
    }
  };

  const downloadPDF = async (timestamp, loanID) => {
    try {
      const response = await fetch("http://localhost:3004/api/downloadPDF", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          timeStamp: timestamp,
          loanId: loanID,
        }),
      });
  
      if (!response.ok) {
        throw new Error("Failed to fetch the PDF");
      }
  
      // Create a Blob from the response stream
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
  
      // Create a temporary link and trigger the download
      const link = document.createElement("a");
      link.href = url;
      link.download = `${loanID}.pdf`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      // Revoke the object URL after the download
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading PDF:", error);
      alert("Failed to download the PDF. Please try again later.");
    }
  };

  const getUrlParameter = (name) => {
    name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    const regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
    const results = regex.exec(window.location.search);
    return results === null
      ? ""
      : decodeURIComponent(results[1].replace(/\+/g, " "));
  };

  const months = [
    { number: 1, name: "January" },
    { number: 2, name: "February" },
    { number: 3, name: "March" },
    { number: 4, name: "April" },
    { number: 5, name: "May" },
    { number: 6, name: "June" },
    { number: 7, name: "July" },
    { number: 8, name: "August" },
    { number: 9, name: "September" },
    { number: 10, name: "October" },
    { number: 11, name: "November" },
    { number: 12, name: "December" },
  ];
  
  function getMonthNumber(monthName) { 

    console.log(monthName)
    const month = months.find(
      (m) => m.name.toLowerCase() === monthName.toLowerCase()
    );
    return month ? month.number : null;
  }

  function getTimestampFromDate(dateString) {
    const [day, month, year] = dateString.split("/").map(Number);
    const dateObj = new Date(year, month - 1, day); // Adjust for 0-indexed month
    return dateObj.getTime();
  } 
  function convertToTimestamp(dateString) {
    let [day, month, year]=[];
    // Split the date string into day, month, and year
    if(dateString.includes('-')){
       [day, month, year] = dateString.split("-").map(Number);
    }
    else if(dateString.includes('/')){
       [day, month, year] = dateString.split("/").map(Number);
    }
  
    // Create a Date object using the parsed values
    const date = new Date(year, month - 1, day); // Month is 0-indexed in JavaScript
  
    // Return the timestamp (milliseconds since UNIX epoch)
    return date.getTime();
  }
  return {
    handleEmailChange,
    toggleModal,
    toggleOtp,
    generateCustomObjectId,
    // handleSelectChange,
    Alert,
    currencyEx,
    calculateAndDisplayFee,
    downloadPDF,
    getUrlParameter,
    convertToTimestamp,
    getMonthNumber,
    getTimestampFromDate,
    formikRef,
    modalRef,
    size,
    // email,
    user,
    fee,
    dol,
    selectedCountry,
    selectedState,
    selectedCity,
    data,
    isLoading,
    pansearch,
    directors,
    preview,
    showModal,
    call,
    showotp,
    disputeID,
    otp,
    // ipAddress,
    panFetch,
    panArray,
    cardIndex,
    showForm,
    theme,
    firstUser,
    setDirectors,
    setPanFetch,
    setOtp,
    setCall,
    setShowModal,
    setCardIndex,
    setSelectedCity,
    setSelectedCountry,
    setSelectedState,
    setFee,
    setDol,
    setIsLoading,
    setSize,
    setData,
    setPreview,
    setDisputeID,
    setPanSearch,
    setShowOtp,
    setPanArray,
    setFirstUser,
    // setIpAddress,
    setShowForm,
    titleCase
  };
};
