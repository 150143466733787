/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import auth from '../services/authService';
import Loading from './loading';

const AuthRoute = ({ children }) => {
    // const [isLoading, setIsLoading] = useState(true); // state to handle delay
    const user = auth.getUser();

    // useEffect(() => {
    //     const timer = setTimeout(() => {
    //         setIsLoading(false); // delay is over, continue with logic
    //     }, 10999); // 1 second delay

    //     return () => clearTimeout(timer); // clean up the timer when component unmounts
    // }, []);

    // if (isLoading) {
    //     return <Loading></Loading>; // Show some loading state
    // }

    if (!user) {
        window.location.href = 'https://login.pvtcourt.com';
        return null; // Prevent rendering during redirect
    }
    return children;
};

export default AuthRoute;

