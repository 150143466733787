/* eslint-disable no-unused-vars */
import { useRef } from "react";
import Swal from "sweetalert2";

const UseHandleModal = () => {
  const flagRef = useRef(false);
  let timerInterval;

  const handleModal = (flag) => {
    // Update flagRef whenever flag changes
    flagRef.current = flag;

    Swal.fire({
      title: "",
      html: "",
      timerProgressBar: false,
      allowOutsideClick: false,
      backdrop: true,
      didOpen: () => {
        Swal.showLoading();
        timerInterval = setInterval(() => {
          if (flagRef.current) {
            clearInterval(timerInterval);
            Swal.close();
          }
        }, 100);
      },
      willClose: () => {
        clearInterval(timerInterval);
      },
    });
  };

  const handleModal2 = (str) => {
    let timerInterval;
    Swal.fire({
      icon: "error",
      title: "",
      showConfirmButton: false,
      timer: 1500,
      footer: str,
      didOpen: () => {
        timerInterval = setInterval(() => {}, 300);
      },
      willClose: () => {
        clearInterval(timerInterval);
      },
      customClass: {
        popup: "invisible-modal",
      },
    }).then((result) => {
      //   if (result.dismiss === Swal.DismissReason.timer) {
      //     console.log("");
      //   }
      console.log("Modal closed by user interaction");
    });
  };

  const darkhandleModal2 = (str) => {
    let timerInterval;
    Swal.fire({
      icon: "error",
      title: "",
      showConfirmButton: false,
      timer: 1500,
      footer: str,
      background: "#343a40", // Set dark background
      color: "#ffc107", // Set text color
      timerProgressBar: true, // Optional: Add progress bar for timer
      customClass: {
        popup: "invisible-modal",
        title: "text-title", // Custom class for title if needed
      },
      didOpen: () => {
        timerInterval = setInterval(() => {}, 300);
      },
      willClose: () => {
        clearInterval(timerInterval);
      },
    }).then((result) => {
      console.log("Modal closed by user interaction");
    });
  };

  const handleModal3 = (str) => {
    let timerInterval;
    Swal.fire({
      //   position: "top-end",
      icon: "success",
      title: str,
      showConfirmButton: false,
      timer: 1500,
      didOpen: () => {
        timerInterval = setInterval(() => {}, 300);
      },
      willClose: () => {
        clearInterval(timerInterval);
      },
      customClass: {
        popup: "invisible-modal",
      },
    }).then((result) => {
      //   if (result.dismiss === Swal.DismissReason.timer) {
      //     console.log("");
      //   }
      console.log("Modal closed by user interaction");
    });
  };

  const darkhandleModal3 = (str) => {
    let timerInterval;
    Swal.fire({
      icon: "success",
      title: str,
      showConfirmButton: false,
      timer: 1500,
      background: "#343a40", // Set dark background
      color: "#ffc107", // Set text color
      timerProgressBar: true, // Optional: Add progress bar for timer
      customClass: {
        popup: "invisible-modal",
        title: "text-title", // Custom class for title if needed
      },
      didOpen: () => {
        timerInterval = setInterval(() => {}, 300);
      },
      willClose: () => {
        clearInterval(timerInterval);
      },
    }).then((result) => {
      console.log("Modal closed by user interaction");
    });
  };

  const handleLoadingModal = async (str, apiCall) => {
    let timerInterval;

    // Show the loading modal
    Swal.fire({
      title: str,
      html: "Please wait...",
      icon: "info",
      allowOutsideClick: false,
      allowEscapeKey: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading(); // Show the loading spinner
        timerInterval = setInterval(() => {}, 300);
      },
      willClose: () => {
        clearInterval(timerInterval);
      },
      customClass: {
        popup: "invisible-modal",
      },
    });

    try {
      const response = await apiCall();

      Swal.fire({
        icon: "success",
        title: "Success!",
        text: "Data loaded successfully!",
        showConfirmButton: false,
        timer: 1500,
      });

      return response;
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "Failed to load data. Please try again.",
        showConfirmButton: true,
      });

      throw error;
    }
  };

  const darkhandleLoadingModal = async (str, apiCall) => {
    let timerInterval;

    // Show the loading modal
    Swal.fire({
      title: str,
      html: "Please wait...",
      icon: "info",
      allowOutsideClick: false,
      allowEscapeKey: false,
      showConfirmButton: false,
      background: "#343a40", // Set dark background
      color: "#ffc107", // Set text color
      didOpen: () => {
        Swal.showLoading(); // Show the loading spinner
        timerInterval = setInterval(() => {}, 300);
      },
      willClose: () => {
        clearInterval(timerInterval);
      },
      customClass: {
        popup: "invisible-modal",
      },
    });

    try {
      const response = await apiCall();

      Swal.fire({
        icon: "success",
        title: "Success!",
        text: "Data loaded successfully!",
        showConfirmButton: false,
        timer: 1500,
        background: "#343a40", // Set dark background
        color: "#ffc107", // Set text color
      });

      console.log(response);
      return response;
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "Failed to load data. Please try again.",
        showConfirmButton: true,
        background: "#343a40", // Set dark background
        color: "#ffc107", // Set text color
      });

      throw error;
    }
  };

  const showLoadingModalOnly = (str) => {
    let timerInterval;

    // Show the loading modal
    Swal.fire({
      title: str,
      html: "Please wait...",
      icon: "info",
      allowOutsideClick: false,
      allowEscapeKey: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading(); // Show the loading spinner
        timerInterval = setInterval(() => {}, 300);
      },
      willClose: () => {
        clearInterval(timerInterval);
      },
      customClass: {
        popup: "invisible-modal",
      },
    });
  };

  const darkshowLoadingModalOnly = (str) => {
    let timerInterval;

    // Show the loading modal
    Swal.fire({
      title: str,
      html: "Please wait...",
      icon: "info",
      allowOutsideClick: false,
      allowEscapeKey: false,
      showConfirmButton: false,
      background: "#343a40", // Set dark background
      color: "#ffc107", // Set text color
      didOpen: () => {
        Swal.showLoading(); // Show the loading spinner
        timerInterval = setInterval(() => {}, 300);
      },
      willClose: () => {
        clearInterval(timerInterval);
      },
      customClass: {
        popup: "invisible-modal",
      },
    });
  };

  const OtpModal = (email, onSubmit, onResendOtp) => {
    Swal.fire({
      title: `Enter the OTP sent to ${email}`,
      html: `<input type="text" id="otpInput" class="swal2-input" placeholder="Enter your OTP here">`,
      showCancelButton: true,
      confirmButtonText: "Submit",
      cancelButtonText: "Cancel",
      focusConfirm: false,
      customClass: {
        confirmButton: "custom-confirm-button-light",
        cancelButton: "custom-cancel-button-light",
      },
      preConfirm: () => {
        const otp = Swal.getPopup().querySelector("#otpInput").value;
        if (!otp) {
          Swal.showValidationMessage("Please enter the OTP");
        }
        return otp;
      },
      allowOutsideClick: () => !Swal.isLoading(),
      footer: `<span type="button" id="resendOtpButton">Resend OTP</span>`,
      didOpen: () => {
        const resendButton = Swal.getFooter().querySelector("#resendOtpButton");
        resendButton.addEventListener("click", onResendOtp);
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        const otp = result.value;
        Swal.fire({
          title: "Verifying...",
          didOpen: () => {
            Swal.showLoading();
          },
        });
        try {
          await onSubmit(otp);
        } catch (error) {
          console.log("Error in OTP modal:", error);
        }
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        console.log("OTP submission cancelled");
      }
    });
  };

  const darkOtpModal = (email, onSubmit, onResendOtp) => {
    Swal.fire({
      title: `<h2 class="text-title">Enter the OTP sent to ${email}</h2>`,
      html: `<input type="text" id="otpInput" class="swal2-input" placeholder="Enter your OTP here">`,
      showCancelButton: true,
      confirmButtonText: "Submit",
      cancelButtonText: "Cancel",
      focusConfirm: false,
      background: "#343a40",
      color: "#ffc107",
      confirmButtonColor: "#ffe665",
      cancelButtonColor: "#343a40",
      customClass: {
        confirmButton: "custom-confirm-button-dark",
        cancelButton: "custom-cancel-button-dark",
        text: "text-title",
      },
      preConfirm: () => {
        const otp = Swal.getPopup().querySelector("#otpInput").value;
        if (!otp) {
          Swal.showValidationMessage("Please enter the OTP");
        }
        return otp;
      },
      allowOutsideClick: () => !Swal.isLoading(),
      footer: `<button type="button" id="resendOtpButton" style="background: none; border: none; color: #007bff; cursor: pointer;">Resend OTP</button>`,
      didOpen: () => {
        const resendButton = Swal.getFooter().querySelector("#resendOtpButton");
        resendButton.addEventListener("click", onResendOtp);
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        const otp = result.value;
        Swal.fire({
          title: "Verifying...",
          background: "#2c2c2c",
          color: "#2c2c2c",
          didOpen: () => {
            Swal.showLoading();
          },
        });
        try {
          await onSubmit(otp);
        } catch (error) {
          console.log("Error in OTP modal:", error);
        }
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        console.log("OTP submission cancelled");
      }
    });
  };

  const handleWarningModalBeforeReload = (str) => {
    Swal.fire({
      title: str,
      html: "Are you sure you want to proceed?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, leave",
      cancelButtonText: "Cancel",
      allowOutsideClick: false,
      allowEscapeKey: false,
      customClass: {
        popup: "visible-modal",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        return true;
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire({
          icon: "info",
          title: "Cancelled",
          text: "You stayed on the page.",
          showConfirmButton: true,
        });
        return false;
      }
    });
  };

  const handleWarningModal = (str) => {
    Swal.fire({
      title: str,
      html: "Are you sure you want to proceed?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Delete",
      cancelButtonText: "Cancel",
      allowOutsideClick: false,
      allowEscapeKey: false,
      customClass: {
        popup: "visible-modal",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          icon: "success",
          title: "Deleted!",
          text: "Your process was successful.",
          showConfirmButton: false,
          timer: 1500,
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire({
          icon: "info",
          title: "Cancelled",
          text: "The deletion has been cancelled.",
          showConfirmButton: true,
        });
      }
    });
  };

  const handleWarningModalWithAPICall = async (str, apiCall) => {
    let timerInterval;

    // Show the warning modal with Update and Cancel options
    Swal.fire({
      title: str,
      html: "Are you sure you want to update?",
      icon: "warning", // Warning icon
      showCancelButton: true,
      confirmButtonText: "Update",
      cancelButtonText: "Cancel",
      allowOutsideClick: false,
      allowEscapeKey: false,
      customClass: {
        popup: "visible-modal",
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        // User clicked 'Update', show loading modal
        Swal.fire({
          title: "Updating...",
          html: "Please wait...",
          icon: "info",
          allowOutsideClick: false,
          allowEscapeKey: false,
          showConfirmButton: false,
          didOpen: () => {
            Swal.showLoading(); // Show the loading spinner
            timerInterval = setInterval(() => {}, 300);
          },
          willClose: () => {
            clearInterval(timerInterval);
          },
          customClass: {
            popup: "invisible-modal",
          },
        });

        try {
          const response = await apiCall(); // Call the API function

          // Show success modal
          Swal.fire({
            icon: "success",
            title: "Success!",
            text: "Update completed successfully!",
            showConfirmButton: false,
            timer: 1500,
          });

          return response;
        } catch (error) {
          // Show error modal if the API call fails
          Swal.fire({
            icon: "error",
            title: "Error!",
            text: "Failed to update. Please try again.",
            showConfirmButton: true,
          });

          throw error;
        }
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        // User clicked 'Cancel'
        Swal.fire({
          icon: "info",
          title: "Cancelled",
          text: "The update has been cancelled.",
          showConfirmButton: true,
        });
      }
    });
  };

  return {
    handleModal,
    handleModal2,
    darkhandleModal2,
    handleModal3,
    darkhandleModal3,
    handleLoadingModal,
    darkhandleLoadingModal,
    showLoadingModalOnly,
    darkshowLoadingModalOnly,
    OtpModal,
    darkOtpModal,
    handleWarningModal,
    handleWarningModalWithAPICall,
    handleWarningModalBeforeReload,
  };
};

export default UseHandleModal;
