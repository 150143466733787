/* eslint-disable no-unused-vars */
import Cookies from 'js-cookie';
const keyName1 = "user3";
const keyName2 = 'attempts';

function login(obj) {
  let expTime = 1 * 24 * 60 * 60 * 1000;
  const expirationDate = new Date(Date.now() + expTime);
  Cookies.set(keyName1, obj, { expires: expirationDate, path: '/' });
}

function logout() {
  Cookies.remove(keyName1);
}

function getUser() {
  let token = Cookies.get(keyName1);
  if (!token) return false;
  try {
    const [header, payload, signature] = token.split(".");
    if (!payload) throw new Error("Invalid token format");

    const base64Url = payload.replace(/-/g, "+").replace(/_/g, "/");
    const decodedPayload = JSON.parse(atob(base64Url));
    return decodedPayload;
  } catch (err) {
    console.error("Failed to decode user token:", err);
    return false;
  }
}

export default {
  login,
  logout,
  getUser,
};