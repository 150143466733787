import React, { useEffect, useState } from "react";
import useLanguage from "../../local/saveLanguage";
import axios from "axios";
import useHandleModal from "../../modal/useModal1";
import LoginHeader from "../../components/loginHeader";
import { useDispute } from "../../hooks/useDispute";
import EmailContent from "./htmlString";
import MapLocation from "./mapLocation";
import { useSelector } from "react-redux";
import http from "../../services/httpService";
import HeaderLeft from "../../components/headerLeft";
import LoadingNew from "../../components/loadingNew";
import CommonOtpUse from "../../components/commonOtpUse";
import bgimg from '../../images/logo-bg.png';
import logoloader from '../../images/logo-loader.svg';
export const TranslationNotice = () => {


  const { handleModal2,handleModal3 } = useHandleModal();
  const { getUrlParameter, convertToTimestamp } = useDispute();
  const theme = useSelector((state) => state.theme.theme);
  const [otp, setOtp] = useState(["", "", "", ""]);
  const [data, setData] = useState({});
  const [templateData, setTemplateData] = useState([]);
  const [link1, setLink] = useState("");
  const translate = useLanguage.getLang();
  const loanID = getUrlParameter("ID");
  const timeStamp = getUrlParameter("ts");
  const path = getUrlParameter("path").toLowerCase();
  const [openmodal,setOpenModal] = useState(false);
 
   const handleOTPmodal = (value) => {
    setOpenModal(value);
  }

  const printFunction = () => {
      document.querySelector('.print-conciliation-notice').addEventListener('click', function () {
      window.print();
  });
  }

  console.log(openmodal)
  console.log(data);
  const fetchHandler = async () => {
    try {
      
      const res = await http.post2("/multiLangInfo", {
        loanId: loanID,
        timeStamp: timeStamp,
        path:path
      });

      console.log(res.data);
      setData(res.data.getDoc);
      setTemplateData(res.data.data);
      setLink(
        `https://dispute.pvtcourt.com/dispute?q1=${res?.data?.accessToken}&path=${path}`
      );
    } catch (err) {
      console.error("Fetch error", err);
    }
  };

  const handleJoin = () => {
    window.open(link1, "_blank");
  };

  const sendOTP = async () => {
     try{
       const res = await http.post2('/sendotpconciliationaccept',{
        mode:path,
        emailornumber:path == "whatsapp" ? data?.borrowerPhone : data?.borrowerEmail,
        borrowerName:data?.borrowerName
       });
       if(res.data == 'Email Sent' || res.data == 'whatsapp sent'){
        handleModal3(`Otp has send to your ${path}`);
        setOpenModal(true);
       }
       else{
        handleModal2('Something wrong.try again later');
       }
    }
     catch(err){
      handleModal2('Something wrong.try again later');
      console.log(err);
     }
  }

  const VerifyOTP = async () => {
    try{
      const res = await http.post2('/verifyotpconciliationaccept',{
       mode:path,
       otp:Number(otp.join("")),
       emailornumber:path == "whatsapp" ? data?.borrowerPhone : data?.borrowerEmail,
       timeStamp:Number(timeStamp)
      });

      if(res.data.message == 'Verification successfully.'){
       data.conciliationAcceptanceStatus = "Yes";
       setOpenModal(false);
       handleModal3("Successfully added");
       window.location.href = link1;
      //  handleMeetingJoin("Successfully added");
      //  handleJoin();
      }
      else{
       handleModal2('Something wrong.try again later');
      }
   }
    catch(err){
     handleModal2('Something wrong.try again later');
     console.log(err);
    }
  }

  const handleReject = async () =>{
     try{
      const res = await http.post2('/saveRejectResponseForConciliationAccept',{
        timeStamp:Number(timeStamp),
        mode:path,
        emailornumber:path == "whatsapp" ? data?.borrowerPhone : data?.borrowerEmail
      });
      if(res.data.message == "Reject added successfully."){
        handleModal3("Thanks for your response");
      }
      else if(res.data.message == "Already updated or no matching record found."){
        handleModal3("Already updated or no matching record found.");
      }
      else{
        handleModal2("Something is wrong try again later.")
      }
     }
     catch(err){
      console.log(err);
     }
  }

  // Join meeting after meeting Link activated
  const handleMeetingJoin = () => {
    const checkTime = convertToTimestamp(data?.meetingDate);
    const currentDate = Date.now();
    console.log(currentDate,checkTime)
    if (currentDate < checkTime) {
      handleModal2("Meeting is not Started Yet");
    } else {
      window.open(data?.meetingAddress, "_blank");
    }
  };

  useEffect(() => {
    fetchHandler();
  }, []);
  return (
    <>
    <div class="bg-logo-bottom bg-cover">
        <img id="bg-img-logo" src={bgimg} alt=""/>
    </div>
    <div className="main-wrapper">
      <LoadingNew />
      <div className="container">
      {/* <LoginHeader /> */}
      <LoginHeader>
        <HeaderLeft/>
      </LoginHeader>
      <div className="login-mid">

      {/* Accept and reject div for consent */}
      <div class="alert alert-info accept-reject" role="alert">
        {data?.conciliationAcceptanceStatus != "Yes" ?
         <div class="row">
         <div class="col-lg-12">
             <h1>{translate?.['To proceed further with the resolution process, kindly accept the conciliation notice.'] ?? 'To proceed further with the resolution process, kindly accept the conciliation notice.'}</h1>
             <div class="d-flex">
                 <div class="form-check">
                     <input class="form-check-input" value="accept"onChange={() => {
                       // handleOTPmodal(true);
                       sendOTP();
                       }} 
                     type="radio"
                     name="flexRadioDefault" 
                     id="flexRadioDefault1" />
                     <label class="form-check-label" for="flexRadioDefault1">
                         {translate?.['Accept'] ?? 'Accept'}
                     </label>
                 </div>
                 <div class="form-check ms-3">
                     <input class="form-check-input" value="accept" onClick={() => handleReject()} type="radio" name="flexRadioDefault" id="flexRadioDefault2" />
                     <label class="form-check-label" for="flexRadioDefault2">
                         {translate?.['Reject'] ?? 'Reject'}
                     </label>
                 </div>
             </div>
         </div>                    
     </div> 
        :
        <div class="row">
         <div class="col-lg-12">
          <p>Thank you for accepting the invitation to participate in the conciliation proceedings under <strong>Section 62(2) of the Arbitration and Conciliation Act, 1996.</strong></p>
          </div>
          </div>
        }
                   

                </div>
        <div class="container">
          <div class="row">
            {/* Left Side Content Information*/}
            
            <div class="col-lg-7 mb-3">
              {Object.keys(data)?.length > 0
               ?
               <div class="card pb-normal letter-card a4-container" id="letter-card">
                <div class="print-area">
                <img class="print-logo" src={logoloader} alt=""/>
                <button class="btn btn-primary print-conciliation-notice" onClick={() => printFunction()}>Print <i class="bi bi-printer text-white"></i></button>     
                <div class="print-divider"></div>
                  
                <EmailContent data={data} templateData={templateData} />
                </div>
              </div>
               : 
               <div class="card pb-normal letter-card">
               <p>Please wait, processing...</p>
             </div>
             }
            </div>

            {/* Right Side Join Section*/}
            <div class="col-lg-5">
               {data?.webPageStage != "verified"
                ?
              <div class="card pb-normal letter-card">
                <i
                  class="bi bi-person-check text-center fz-40"
                  style={{ fontSize: "2.5rem" }}
                ></i>
                <h4 class="blue text-center">
                  {translate?.["Join anytime here and submit your response"] ??
                    "Join anytime here and submit your response"}
                </h4>
                <p class="text-center">
                  {translate?.[
                    "Please explain the reasons for your loan repayment difficulties below. Include any relevant circumstances such as financial hardship, medical issues, or disputes over loan terms. Your detailed response will help us assess and resolve your case effectively."
                  ] ??
                    "Please explain the reasons for your loan repayment difficulties below. Include any relevant circumstances such as financial hardship, medical issues, or disputes over loan terms. Your detailed response will help us assess and resolve your case effectively."}
                </p>
                <button
                  type="button"
                  onClick={handleJoin}
                  disabled={data?.conciliationAcceptanceStatus == "No" || !data?.conciliationAcceptanceStatus}
                  class="btn btn-primary join-video w-100 mt-2"
                >
                  <i class={`bi bi-cursor-fill ${theme ? "text-white" : "text-dark"}`}></i>
                  {translate?.["Join"] ?? "Join"}
                </button>
              </div> 
                : 
                 <div className="card pb-normal letter-card">
                   <h5><strong>{translate["Thank you for submitting the form!"] ?? "Thank you for submitting the form!"}</strong></h5>
                   <p>{translate["Your details have been successfully received and are currently under review. Should any additional information be required, our team will reach out to you directly. Please be informed that, as the form submission is complete, the \"Join Video Call\" option has been disabled, as your presence in the meeting is no longer necessary. We will keep you updated on the next steps in due course."] ?? `Your details have been successfully received and are currently under review. Should any additional information be required, our team will reach out to you directly. Please be informed that, as the form submission is complete, the "Join Video Call" option has been disabled, as your presence in the meeting is no longer necessary. We will keep you updated on the next steps in due course.`}</p>
                 </div>
                }
              {data?.hearingType == "Physical" ? (
                <div class="card pb-normal letter-card mt-3">

                  {/* Location Component for displaying map */}
                  <MapLocation />
                  <address class="blue mt-3 address-right mb-0">
                    <strong>Place : </strong>{data?.seat || "N/A"}
                    {/* <strong>Company:</strong> PRIVATECOURT
                    <br></br>
                    <strong>Phone Number:</strong> 9702747856 
                    <br></br>
                    <strong>Address:</strong> 208 - Globe Business Park MIDC, Kalyan Badlapur Road Ambernath, West, Thane, Maharashtra 421505 
                    */}
                  </address>
                </div>
              ) : (
                <div class="card pb-normal letter-card mt-3">
                  <i
                    class="bi bi-camera-video text-center fz-40"
                    style={{ fontSize: "2.5rem" }}
                  ></i>
                  <h4 class="blue text-center">
                    {translate?.["It's time to join the video call."] ??
                      "It's time to join the video call."}
                  </h4>
                  <p class="text-center">
                    {translate?.[
                      "It’s time for your scheduled online hearing. Join the session by clicking the button below, and ensure all necessary materials are ready for discussion."
                    ] ??
                      "It’s time for your scheduled online hearing. Join the session by clicking the button below, and ensure all necessary materials are ready for discussion."}
                  </p>
                  <button
                    type="button"
                    onClick={handleMeetingJoin}
                    class="btn btn-primary join-video w-100 mt-2"
                    disabled={data?.webPageStage == "verified" || data?.conciliationAcceptanceStatus == "No" || !data?.conciliationAcceptanceStatus}
                  >
                    <i class="bi bi-camera-reels text-white"></i>{" "}
                    {translate?.["Join Video Call"] ?? "Join Video Call"}
                  </button>
                </div>
              )}

                  <div class="card pb-normal letter-card mt-3 site-info">
                            <img class="img-fluid" id="logo-loader" src={logoloader} alt=""/>
                            <h4 class="blue text-center">{translate?.['Redefining Dispute Resolution'] ?? "Redefining Dispute Resolution"}</h4>
                            <p class="text-center">{translate?.['With PrivateCourt, experience faster, more effective dispute resolution powered by technology and guided by industry experts.'] ?? 'With PrivateCourt, experience faster, more effective dispute resolution powered by technology and guided by industry experts.'}
                            </p>
                            <a href="https://www.pvtcourt.com/" class="btn btn-primary join-video w-100 mt-2">
                                <i class="bi bi-box-arrow-up-right text-white"></i> {translate?.['Visit Website'] ?? 'Visit Website'}                                                                 
                            </a>   
                        </div>  
            </div>
          </div>
        </div>
        {
          openmodal ? <CommonOtpUse title="Accept Conciliation OTP :" subtitle={`An OTP has been sent to you ${path} for conciliation acceptance.`} handleOTPmodal={handleOTPmodal} VerifyOTP={VerifyOTP} otp={otp} setOtp={setOtp} /> : ""
        }
      </div>
      </div>
    </div>
    </>
  );
};
