/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from "react";
import http from "../../../services/httpService.js";
import Papa from "papaparse";
import moment from "moment";
import HeaderMapping from "./components/HeaderMapping.jsx";
import UseHandleModal from "../Modal/UseHandleModal.jsx";
import InfoModal from "./components/InfoModal.jsx";
import auth from "../../../services/authService.js";
import { useSelector } from "react-redux";
import { handleDownload } from "./components/InfoModal.jsx";

// const CsvUploadPage = () => {
const CsvUploadPage = ({ onSuccess }) => {
  const [file, setFile] = useState(null);
  const [data, setData] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [mappedData, setMappedData] = useState([]);
  const [isUploaded, setIsUploaded] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [automaticMapping, setAutomaticMapping] = useState({});
  const [type, setType] = useState("");
  const [arbitrationOption, setArbitrationOption] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [docStatus, setDocStatus] = useState("Pending");
  const [showInfoModal, setShowInfoModal] = useState(false);
  const fileInputRef = useRef(null);
  const theme = useSelector((state) => state.theme.theme);

  const {
    handleModal3,
    darkhandleModal3,
    handleModal2,
    darkhandleModal2,
    handleLoadingModal,
    darkhandleLoadingModal,
  } = UseHandleModal();

  const requiredHeaders = [
    "loanId",
    "Name of Borrower",
    "Borrower Address",
    "Borrower Phone",
    // "Borrower Email",
    // "Borrower PAN Number",
    // "Name of CoBorrower",
    // "CoBorrower Address",
    // "CoBorrower Email",
    // "CoBorrower Phone",
    // "CoBorrower PAN Number",
    // "Last EMI Paid Date",
    // "CoBorrower Phone",
    // "CoBorrower PAN Number",
    // "Last EMI Paid Date",
    "Claim Amount",
    "Claimant Company",
    "Claimant Company Address",
    "Meeting Date",
    "Notice Date",
  ];

  const tooltipTexts = {
    loanId: "Unique identifier for the loan. Example: 'A123'.",
    "Name of Borrower": "Full name of the borrower. Example: 'Ayush Tiwari'.",
    "Borrower Address":
      "Complete address of the borrower. Example: 'NetaJi Nagar, North Delhi, 330211'.",
    "Borrower Email":
      "Email address of the borrower. Example: 'ayush@example.com'.",
    "Borrower Phone":
      "Contact phone number of the borrower. Example: '6245789685'.",
    "Borrower PAN Number":
      "Permanent Account Number of the borrower. Example: 'CEPPT7676M'.",
    "Name of CoBorrower":
      "Full name of the co-borrower, if applicable. Example: 'Manoj Tiwari'.",
    "CoBorrower Address":
      "Complete address of the co-borrower. Example: 'NetaJi Nagar, North Delhi, 330211'.",
    "CoBorrower Email":
      "Email address of the co-borrower. Example: 'manoj@example.com'.",
    "CoBorrower Phone":
      "Contact phone number of the co-borrower. Example: '6245789685'.",
    "CoBorrower PAN Number":
      "Permanent Account Number of the co-borrower. Example: 'CEPPT7676M'.",
    "Claim Amount": "Amount claimed in the dispute. Example: '21000'.",
    "Claimant Company":
      "Name of the company making the claim. Example: 'ClaimantPVT'.",
    "Claimant Company Address":
      "Address of the claimant company. Example: 'North Street Park Chennai, 989803, TamilNadu'.",
    "Meeting Date":
      "Date of the meeting regarding the dispute. Example: '08/08/2024'.",
    "Notice Date": "Date when the notice was issued. Example: '23/12/2024'.",
    "Last EMI Paid Date":
      "Date when the last EMI was paid. Example: '05/05/2024'.",
    "Name of Second CoBorrower":
      "Full name of the second co-borrower, if applicable. Example: 'Seena Sharma'.",
    "EMI Start Date":
      "Date when the EMI payments started. Example: '05/12/2023'.",
    "EMI End Date": "Date when the EMI payments end. Example: '05/12/2026'.",
    Interest: "Interest rate applicable to the loan. Example: '6%'.",
    "RC Number":
      "Registration Certificate number of the vehicle. Example: 'DL12AG8956'.",
    "CIN Number":
      "Company Identification Number. Example: 'L17110MH1973PLC019786'.",
    "GST Number": "Goods and Services Tax number. Example: '22AAAAA0000A1Z5'.",
    "Vehicle Number": "Number plate of the vehicle. Example: 'DL12AM2112'.",
    "Driving Licence Number":
      "Driving license number of the borrower or co-borrower. Example: 'MH03 200800222135'.",
    "Loan Type": "Type of the loan. Example: 'Personal'.",
    EMI: "Amount of each EMI payment. Example: '4545'.",
    "Total Loan": "Total amount of the loan. Example: '1000000'.",
    "EMI Start": "Start date of EMI payments. Example: '05/12/2021'.",
    "EMI End": "End date of EMI payments. Example: '05/12/2206'.",
    Seat: "Seat of the vehicle, if applicable. Example: 'First'.",
    "Bank Account Number":
      "Bank account number related to the loan. Example: '8080000008854'.",
    "IFSC Code": "IFSC code of the bank branch. Example: 'SBIN0006245'.",
    "Dispute Resolution Paper ID":
      "Identifier for the dispute resolution paper. Example: 'DRP12456'.",
    "Associate Name":
      "Name of the associate handling the case. Example: 'Lalit Singh'.",
    "Ref Number": "Reference number for the dispute. Example: '54789654'.",
    "Case ID": "Unique identifier for the case. Example: 'A78954A'.",
    "Principle Outstanding": "Outstanding principal amount. Example: '124578'.",
    "Maximum Settlement Amount":
      "Maximum amount allowed for settlement. Example: '12457'.",
    "Minimum Settlement Amount":
      "Minimum amount allowed for settlement. Example: '1235'.",
    "ClaimantSide For Coordination":
      "Contact person for coordination from the claimant's side. Example: 'Nitesh Singh'.",
    "ClaimantSide For Coordination Contact":
      "Contact details for the coordination person. Example: '6598785421'.",
    Zone: "Geographical zone relevant to the dispute. Example: 'zone'.",
    DPD: "Days Past Due, indicating how overdue the payment is. Example: '120'.",
    "Notice Stage":
      "Current stage of the notice in the dispute resolution process. Example: 'Conciliation'.",
  };

  const parseDate = (dateString) => {
    const formats = ["DD/MM/YYYY", "DD-MM-YYYY"];
    for (const format of formats) {
      const parsedDate = moment(dateString, format, true);
      if (parsedDate.isValid()) return parsedDate;
    }
    return null;
  };

  const calculateDaysDifference = (dateStr) => {
    const date = parseDate(dateStr);
    if (!date) return null;
    return Math.floor(moment().diff(date, "days"));
  };

  const getDaysCategory = (days) => {
    if (!days) return "N/A";
    if (days < 30) return "30";
    if (days < 60) return "60";
    if (days < 90) return "90";
    if (days < 180) return "180";
    if (days < 270) return "270";
    if (days < 360) return "360";
    return "360+";
  };

  //its only insert the final into the input field
  const handleFileChange = (event) => {
    const uploadedFile = event.target.files[0];
    // console.log(uploadedFile, "uploadedFile");
    setFile(uploadedFile);
    setLoading(false);
  };

  //its extracting the csv file data and parsing them to match with mine and user headers and set the headers in the dropdown field automatically
  const handleFileRead = (event) => {
    const csvData = event.target.result;
    const parsedData = Papa.parse(csvData, { header: true });

    const filteredData = parsedData.data.filter((row) =>
      Object.values(row).some((value) => value !== null && value !== "")
    );

    const fileHeaders = parsedData.meta.fields;
    setHeaders(fileHeaders);
    setData(filteredData);
    setIsUploaded(true);

    const automaticMapping = {};
    requiredHeaders.forEach((header) => {
      const matchedHeader = fileHeaders.find(
        (fHeader) => fHeader.toLowerCase() === header.toLowerCase()
      );
      if (matchedHeader) {
        automaticMapping[header] = matchedHeader;
      }
    });
    setAutomaticMapping(automaticMapping);
    setShowModal(true);
    setLoading(false); //true to disable after the mapping
  };

  //it only read the the files that are uploaded to map after i click on the upload
  const handleFileUpload = (event) => {
    event.preventDefault();
    if (!file || file.type !== "text/csv") {
      handleModal2("Please select a valid CSV file.");
      return;
    }
    setLoading(true);
    const reader = new FileReader();
    reader.onloadend = handleFileRead;
    reader.readAsText(file);

    setLoading(false);
  };

  //its mapped the data and display in the table below
  const handleMappingComplete = (mapping) => {
    const finalMapping = { ...automaticMapping, ...mapping };

    // console.log(data, "data");
    const mapped = data.map((row) => {
      const mappedRow = {};

      for (const [requiredHeader, csvHeader] of Object.entries(finalMapping)) {
        mappedRow[requiredHeader] = row[csvHeader];
      }

      if (type === "Notice" && finalMapping["Last EMI Paid Date"]) {
        const daysDifference = calculateDaysDifference(
          mappedRow["Last EMI Paid Date"]
        );

        mappedRow["In Days"] = daysDifference
          ? getDaysCategory(daysDifference)
          : "N/A";
      }

      return mappedRow;
    });

    if (mapped.length > 0 && mapped[0]["Claimant Company"]) {
      setCompanyName(mapped[0]["Claimant Company"]);
    }

    setMappedData(mapped);
    setShowTable(true);
  };

  //it create a new set in which it store the new header called [in days]
  const getNonEmptyHeaders = () => {
    const nonEmptyHeaders = new Set();
    // console.log(type, "inside the non empty haeders")

    mappedData.forEach((row) => {
      Object.keys(row).forEach((header) => {
        if (row[header]) {
          nonEmptyHeaders.add(header);
        }
      });
    });
    if (type === "Notice") nonEmptyHeaders.add("In Days");
    return Array.from(nonEmptyHeaders);
  };

  //store the final headers to display in the table i.e [in days]
  const nonEmptyHeaders = getNonEmptyHeaders();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    const submitControl = async () => {
      if (!file || file.type !== "text/csv") {
        theme
          ? handleModal2("Please select a valid CSV file.")
          : darkhandleModal2("Please select a valid CSV file.");
        return;
      }

      const generateUniqueId = () => {
        const company = companyName || "";
        let date = Date.now().toString();
        let companyPart = company.slice(0, 5).replace(/\s+/g, "").trim();
        let remainingLength = 5 - companyPart.length;
        let datePart = date.slice(-4 - remainingLength);
        const id = `${companyPart}${datePart}`;
        const code = id.toUpperCase().slice(0, 10);
        console.log(code);
        return code;
      };

      const generateUniqueName = (name, time) => {
        const company = name ? name.split(" ")[0] : "default";
        const date = new Date(time);

        const day = String(date.getDate()).padStart(2, "0");
        const month = date
          .toLocaleString("default", { month: "short" })
          .toLowerCase();
        const year = date.getFullYear();

        const uniqueName = `${company}-${day}-${month}-${year}`;
        console.log(uniqueName);
        return uniqueName;
      };

      let email = auth.getUser().UserLogin.email;
      let timeStamp = Date.now();
      const documentID = generateUniqueId();
      const docName = generateUniqueName(companyName, timeStamp);

      const BATCH_SIZE = 75;

      try {
        const batches = [];
        for (let i = 0; i < mappedData.length; i += BATCH_SIZE) {
          batches.push(mappedData.slice(i, i + BATCH_SIZE));
        }

        await http.post2("/setNoticeInfos", {
          data: {
            email,
            timeStamp,
            documentId: documentID,
            noticeType: type || "",
            noticeTypeOption: arbitrationOption || "",
            status: docStatus,
            companyName,
            documentName: docName || "",
          },
        });
        for (let [batchIndex, batch] of batches.entries()) {
          const batchPayload = {
            email: email,
            documentId: `${documentID}`,
            data: { csvData: batch },
            timeStamp: timeStamp,
            type: type,
            ...(type === "Arbitration" && {
              arbitartionType: arbitrationOption,
            }),
          };

          const response = await http.post2("/csvUploadBulk", batchPayload, {
            headers: {
              "Content-Type": "application/json",
            },
          });

          await new Promise((resolve) => setTimeout(resolve, 500));
        }

        onSuccess();
        theme
          ? handleModal3("CSV file uploaded successfully!")
          : darkhandleModal3("CSV file uploaded successfully!");
        resetForm();
      } catch (error) {
        console.error("Error uploading CSV file:", error);
        let message = "Failed to upload CSV file. Please try again.";
        if (error.response && error.response.data) {
          message += `\nServer responded with: ${error.response.data.message}`;
        }
        theme ? handleModal2(message) : darkhandleModal2(message);
        resetForm();
      } finally {
        resetForm();
      }
    };

    theme
      ? handleLoadingModal(
          "Processing your request, please wait...",
          submitControl
        )
      : darkhandleLoadingModal(
          "Processing your request, please wait...",
          submitControl
        );
  };

  const resetForm = () => {
    setFile(null);
    setData([]);
    setHeaders([]);
    setMappedData([]);
    setIsUploaded(false);
    setShowTable(false);
    setType("");
    setArbitrationOption("");
    setError(null);
    setShowModal(false);
    setLoading(false);

    const fileInput = document.querySelector('input[type="file"]');
    if (fileInput) {
      fileInput.value = "";
    }
  };

  const handleInfoClick = () => {
    setShowInfoModal(true);
  };

  const handleInfoModalClose = () => {
    setShowInfoModal(false);
  };

  return (
    <>
      <InfoModal
        show={showInfoModal}
        onHide={handleInfoModalClose}
        tooltipTexts={tooltipTexts}
      />
      <div className="main-wrapper">
        <div className="container-fluid">
          <HeaderMapping
            show={showModal}
            onHide={() => setShowModal(false)}
            csvHeaders={headers}
            requiredHeaders={requiredHeaders}
            onMappingComplete={handleMappingComplete}
            tooltipTexts={tooltipTexts}
            initialMapping={automaticMapping}
          />
          <div className="row">
            <div className="col-xl-12">
              <div className="row">
                <div className="conciliation-application-form">
                  <form>
                    <div className="d-flex align-items-center mb-2">
                      {/* Title Text */}
                      <h2 className="mb-0 me-2">NBFC DISPUTE</h2>

                      {/* Info Icon */}
                      <i
                        className="bi bi-info-circle"
                        onClick={handleInfoClick}
                        style={{ fontSize: "1.25rem", cursor: "pointer" }}
                      ></i>
                    </div>

                    {/* upload section */}
                    <div className="row mb-4">
                      {/* Data Type Selection Dropdown*/}
                      <div className="col-md-2 mt-0">
                        <div style={{ marginLeft: "5px" }}>
                          <b className="form-label form-label-new">
                            Select Type
                          </b>
                        </div>
                        <select
                          className="form-select"
                          value={type}
                          onChange={(e) => {
                            setType(e.target.value);
                            setArbitrationOption("");
                            setFile(null);
                            setError(null);
                            setMappedData([]);
                            setHeaders([]);
                            setIsUploaded(false);
                            setShowTable(false);
                            if (fileInputRef.current) {
                              fileInputRef.current.value = "";
                            }
                          }}
                        >
                          <option value="">Select Type</option>
                          <option value="Notice">Notice</option>
                          <option value="Conciliation">Conciliation</option>
                          <option value="Arbitration">Arbitration</option>
                        </select>
                      </div>

                      {type === "Arbitration" && (
                        <div className="col-md-2 mt-0">
                          <div style={{ marginLeft: "5px" }}>
                            <b className="form-label form-label-new">
                              Arbitration Option*
                            </b>
                          </div>
                          <select
                            className="form-select"
                            value={arbitrationOption}
                            onChange={(e) => {
                              setArbitrationOption(e.target.value);
                              setFile(null);
                              setMappedData([]); // Reset table data
                              setHeaders([]); // Reset headers
                              setIsUploaded(false);
                              setError(null);
                              setShowTable(false); // Hide table initially
                              if (fileInputRef.current) {
                                fileInputRef.current.value = ""; // Clear the file input field
                              }
                            }}
                          >
                            <option value="">Select Arbitration Option</option>
                            <option value="LRN to Award">LRN to Award</option>
                            <option value="Invocation to Award">
                              Invocation to Award
                            </option>
                            <option value="Acceptance to Award">
                              Acceptance to Award
                            </option>
                          </select>
                        </div>
                      )}

                      {/* Csv Input Field */}
                      <div className="col-md-4">
                        <label className="form-label form-label-new d-flex align-items-center">
                          Upload
                          <i
                            className="bi bi-cloud-arrow-down-fill ms-2"
                            onClick={handleDownload}
                            title="Download CSV Template"
                          ></i>
                          <span
                            className="ms-1"
                            style={{ cursor: "pointer" }}
                            onClick={handleDownload}
                          >
                            Format
                          </span>
                        </label>
                        <input
                          className="form-control mt-2"
                          type="file"
                          accept=".csv"
                          onChange={handleFileChange}
                          disabled={
                            !type ||
                            (type === "Arbitration" && !arbitrationOption)
                          }
                          ref={fileInputRef}
                        />
                      </div>

                      {/* Upload Button */}
                      <div className="col-md-2 mt-4">
                        <button
                          className="btn btn-primary"
                          disabled={
                            !file ||
                            (type === "Arbitration" && !arbitrationOption) ||
                            loading
                          }
                          onClick={handleFileUpload}
                        >
                          Upload
                        </button>
                      </div>

                      {/* Submit Button */}
                      <div className="col-md-2 mt-4">
                        <button
                          className="btn btn-primary"
                          disabled={
                            !file ||
                            !isUploaded ||
                            !type ||
                            (type === "Arbitration" && !arbitrationOption) ||
                            loading
                          }
                          onClick={handleSubmit}
                        >
                          Submit
                        </button>
                      </div>
                    </div>

                    {/*uploded data table card */}
                    <div className="mb-2">
                      {showTable && (
                        <div style={{ overflowX: "auto", maxHeight: "500px" }}>
                          <table
                            className="table table-bordered mt-8"
                            style={{ whiteSpace: "nowrap" }}
                          >
                            <thead>
                              <tr>
                                <th
                                  style={{
                                    textAlign: "center",
                                    whiteSpace: "normal",
                                  }}
                                >
                                  #
                                </th>
                                {nonEmptyHeaders.map((header) => (
                                  <th
                                    key={header}
                                    style={{
                                      textAlign: "center",
                                      whiteSpace: "normal",
                                      overflowWrap: "break-word",
                                    }}
                                  >
                                    {header}
                                  </th>
                                ))}
                              </tr>
                            </thead>
                            <tbody>
                              {mappedData.length > 0 ? (
                                mappedData.map((row, index) => (
                                  <tr key={index}>
                                    <td>{index + 1}</td>
                                    {nonEmptyHeaders.map((header) => (
                                      <td key={header}>{row[header]}</td>
                                    ))}
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td
                                    colSpan="6"
                                    style={{ textAlign: "center" }}
                                  >
                                    No data available.
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      )}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CsvUploadPage;
