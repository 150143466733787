import LoginHeader from "../../components/loginHeader";
import LoginLang from "../../components/loginLang";
import HeaderLeft from "../../components/headerLeft";
import React, { Suspense } from "react";
import SingleDisputeForm from "./components/singleDisputeForm";

const SingleDispute = () => {
  // const [Loading,setLoading]=useState(false);
  // const singleStage = useSelector((state) => state.singleStage.singleStage);
  const singleStage = "singledisputeStage";
  console.log(singleStage);
  // const fetchDetails1 = useCallback(async () => {
  //   try {
  //     const res = await http.post2("/disputeDataNew", { accessToken: getID });
  //     if (res.data === "Not Found Token") {
  //       dispatch(changeStage("server"));
  //     } else {
  //       console.log(res.data)
  //       dispatch(getData(res.data));
  //       dispatch(insertToken(getID));
  //       dispatch(insertTypeOfCommunication(pathName))
  //     }
  //     setLoading(false);
  //   } catch (err) {
  //     dispatch(changeStage("overload")); //error will check in our end
  //   }
  // }, [dispatch, getID]); 
  // useEffect(() => {
  //   fetchDetails1();
  // }, [fetchDetails1]);
  return (
    <>
      <div className="main-wrapper">
        <div className="container">
          <LoginHeader>
            <HeaderLeft/>
          </LoginHeader>
          <SingleDisputeForm/>
          <LoginLang />
        </div>
      </div>
    </>
  );
};
export default SingleDispute;
