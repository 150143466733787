/* eslint-disable react/prop-types */
import React from "react";
import Field from "./Field";

const ThirdCard = ({ loan }) => {
  return (
    <>
      <div>
        {" "}
        {[
          loan?.claimantCompany,
          loan?.claimantCompanyAddress,
          loan?.claimantEmail,
        ].some((field) => field && field !== "N/A") ? (
          <div className="card card-new">
            <>
              <h2 className="mb-3 mt-3">Claiment Details</h2>
              <div className="row">
                  <Field key1="Company:" value={loan?.claimantCompany || ""}/>
                  <Field key1="Address:" value={loan?.claimantCompanyAddress || ""}/>
                  <Field key1="Email: " value={loan?.claimantEmail || ""}/>
              </div>
            </>
          </div>
        ) : null}
      </div>
      <div>
        {[loan?.bankAccountNo, loan?.ifscCode].some(
          (field) => field && field !== "N/A"
        ) ? (
          <div className="card card-new">
              <h2 className="mb-3 mt-3">Bank Details</h2>
              <div className="row">
              <Field key1="Account Number:" value={loan?.bankAccountNo || ""}/>
              <Field key1="IFSC Code: " value={loan?.ifscCode || ""}/>  
              </div>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default ThirdCard;
