/* eslint-disable react/prop-types */
import React from "react";
// import '../../../style.css'

const ProfileImage = ({ imageUrl }) => {
  const profileImgStyle = {
    backgroundImage: `url(${imageUrl})`,
    // width: '100px',
    // height: '100px',
    // backgroundSize: 'cover',
    // backgroundPosition: 'center',
    // borderRadius: '50%',
  };

  return <div className="profile-img" style={profileImgStyle}></div>;
};

const ProfileDetails = ({ name, gender, age, address, pan, aadhar }) => {
  return (
    <div className="col-auto col-xl-3 col-lg-9 profile-details-col card-body py-0 align-self-center">
      <h5 className="card-title">{name}</h5>
      <ul className="list-unstyled mb-0">
        <li>
          <strong>Gender:</strong> {gender}
        </li>
        <li>
          <strong>Age:</strong> {age} Years
        </li>
        <li>
          <strong>Address:</strong> {address?.house} {address?.loc} ,
          {address?.dist} {address?.state}
        </li>
        <li>
          <strong>PAN:</strong> {pan}
        </li>
        <li>
          <strong>Aadhar:</strong> {aadhar ? aadhar : "N/A"}
        </li>
      </ul>
    </div>
  );
};

const VerificationDetails = ({ verifications }) => {
  return (
    <ul className="list-unstyled">
      {verifications.map((verification, index) => (
        <li key={index} className="list-inline-item">
          <i
            className={`bi ${
              verification.verified
                ? "bi-patch-check-fill"
                : "bi-patch-check text-muted"
            }`}
          ></i>{" "}
          {verification.label}
        </li>
      ))}
    </ul>
  );
};

const ProfileUser = (props) => {
  console.log(props.user);
  const verifications = [
    {
      label: "PAN",
      verified:
        props.user.pan_number === "N/A" || props.user.pan_number === ""
          ? false
          : true,
    },
    {
      label: "Aadhar",
      verified:
        props.user.aadhaar === "N/A" || props.user.aadhaar === "" ? false : true,
    },
    {
      label: "Address",
      verified:
        props.user.address === "N/A" || props.user.address === "" ? false : true,
    },
    {
      label: "Bank",
      verified:
        props.user.bank === "N/A" || props.user.bank === "" ? false : true,
    },
    {
      label: "Phone",
      verified:
        props.user.phone === "N/A" || props.user.phone === "" ? false : true,
    },
    {
      label: "Email",
      verified:
        props.user.email === "N/A" || props.user.email === "" ? false : true,
    },
    {
      label: `CIBIL - ${props.user?.CIBILDetails?.credit_score}`,
      verified:
        props.user?.CIBILDetails?.credit_score === "N/A" ||
        props.user?.CIBILDetails?.credit_score === ""
          ? false
          : true,
    },
    {
      label: "Court Case - No issue / 150",
      verified:
        props.user.courtCase?.count === "0" ||
        props.user.courtCase?.status === "No cases"
          ? true
          : false,
    },
  ];

  return (
    <div className="card personal-long-card card-new">
      <div className="row">
        <div className="col-auto profile-col">
          <ProfileImage
            imageUrl={`https://privatecourtdocs.s3.ap-south-1.amazonaws.com/UserProfileImage/${props.user.aadhaar}`}
          />
        </div>
        {/* <div className="col-auto"> */}
        <ProfileDetails
          name={props.user.fullName}
          gender={props.user.gender}
          age={26}
          address={props.user.address}
          pan={props.user.pan_number}
          aadhar={props.user.aadhaar}
        />
        {/* </div> */}
        <div className="col-xl-6 right-list">
          <VerificationDetails verifications={verifications} />
        </div>
      </div>
    </div>
  );
};

export default ProfileUser;
