/* eslint-disable react/prop-types */
import React from "react";
import CustomField from "../custom/CustomField.jsx";

const FirstCard = ({ loan }) => {
  return (
    <div className="card card-new mb-3 mt-3">
      <div className="row justify-content-center align-items-center">
        <div className="col-lg-4 col-lg-4 d-flex justify-content-center align-items-center">
          <div className="claim-amount text-center">
            <h3>
              <strong>Claim Amout</strong>
            </h3>
            <p className="ca-2">{loan?.claimAmount || " "}</p>
          </div>
        </div>
        <div className="col-lg-4 before-divide">
          <div
            className="claim-emi-box text-center"
            style={{ padding: "0rem" }}
          >
            {/* EMI Section */}
            <div style={{ marginBottom: "1rem" }}>
              <h3 style={{ marginBottom: "0.5rem" }}>EMI</h3>
              <h3 className="mb-0">{loan?.emi || " "}</h3>
            </div>
            <hr className="mt-0" style={{ margin: "1rem 0" }} />

            {/* Start and End EMI Section */}
            <div className="row text-center">
              <div
                className="col-lg-6 start-emi"
                style={{ marginBottom: "1rem" }}
              >
                <p style={{ marginBottom: "0.25rem" }} className="mb-0">
                  {loan?.emiStart || " "}
                </p>
                <p style={{ marginBottom: "0" }}>
                  <span>Start EMI</span>
                </p>
              </div>
              <div
                className="col-lg-6 end-emi"
                style={{ marginBottom: "1rem" }}
              >
                <p style={{ marginBottom: "0.25rem" }} className="mb-0">
                  {loan?.emiEnd || " "}
                </p>
                <p style={{ marginBottom: "0" }}>
                  <span>End EMI</span>
                </p>
              </div>
            </div>
            <p className="mb-0 claim-emi-box-note"></p>
          </div>
        </div>

        <div className="col-lg-4 before-divide s-a-c">
          <CustomField label="Seat" value={loan?.seat} defaultValue="" />
          <hr className="mt-0" />
          <CustomField label="Type" value={loan?.type} defaultValue="" />
          <hr className="mt-0" />
          <CustomField label="Stage" value={loan?.stage} defaultValue="" />
        </div>
      </div>
    </div>
  );
};

export default FirstCard;
