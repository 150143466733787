import React, { useEffect, useState } from 'react';
import logoImage from '../images/logo-loader.svg';
const LoadingNew = () => {
    const [randomItem,setRandomItem] = useState({});
  const ContentObject = [
    {
     title:"Take the First Step to Resolve Your Dispute",
     content:"Disputes can be challenging, but resolving them doesn’t have to be. Collaborate with us to settle your pending issues quickly and amicably. A clear path to resolution awaits—start now for a hassle-free experience."
    },
    {
     title:"Resolve, Rebuild, and Move Forward",
     content:"Unresolved disputes can hold you back. Take this opportunity to address your concerns, rebuild trust, and safeguard your financial standing. Begin the process today for a future free of conflict."
    },
    {
     title:"Settle Disputes, Simplify Life",
     content:"Disputes don’t need to disrupt your peace of mind. Act now to resolve them through our efficient and fair process. Protect your financial health—because every step forward counts."
    },
    {
     title:"A Step Toward Resolution",
     content:"Start resolving your disputes with ease and confidence. Don’t let unresolved issues linger—take the proactive step to find common ground and achieve closure. Your resolution journey begins here."
    }
];
  function getRandomItem(array) {
    const randomIndex = Math.floor(Math.random() * array.length);
    return array[randomIndex];
  }
// Display a random content
useEffect(()=>{
    const res = getRandomItem(ContentObject);
    setRandomItem(res);
    const timer = setTimeout(() => {
        const loader = document.querySelector(".page-loader");
        if (loader) {
          loader.style.transition = "opacity 0.5s";
          loader.style.opacity = "0";
          setTimeout(() => {
            loader.style.display = "none";
          }, 500); 
        }
        }, 1500);
 
      // Cleanup timeout
      return () => clearTimeout(timer);
},[]);
  return (
    <div class="page-loader">
      <div class="loader-container">
        <img class="logo-loader" src={logoImage} alt=""/>
        <div class="loader-content">
          <h2><strong>{randomItem.title}</strong></h2>
          <p>{randomItem.content}</p>
        </div>
        <div class="line">
          <div class="inner"></div>
        </div>
      </div>
    </div>
  )
}

export default LoadingNew;