/* eslint-disable react/prop-types */
import React from "react";
import DOMPurify from "dompurify";
import { useSelector } from "react-redux";

const EmailContent = (props) => {
  let language = useSelector((state) => state.lang.lang);
  console.log(language);
  language = language == "English(UK)" ? "English (UK)" : language;

  let templateInfo = props?.templateData || [];

  //Filter according to the language
  let filterHTML = templateInfo
    .filter((item) => item?.tempLang?.language == language)
    .map((item) => item?.htmlInitialContent);
  filterHTML = `\`${filterHTML[0]}\``;

  let borrowerDetails = "";
  if (props?.data?.borrowerName) {
    borrowerDetails = `<p>1. <strong>${props.data.borrowerName?.toUpperCase()}</strong> (Borrower)<br>`;
  }

  if (props?.data?.borrowerAddress) {
    borrowerDetails += `Having address at:<strong>${props.data.borrowerAddress}</strong><br>`;
  }

  if (props?.data?.borrowerEmail) {
    borrowerDetails += `E-mail: <strong>${props.data.borrowerEmail}</strong><br>`;
  }

  if (props?.data?.borrowerPhone) {
    borrowerDetails += `Contact: <strong>${props.data.borrowerPhone}</strong><br>`;
  }

  if (props?.data?.coBorrowerName) {
    borrowerDetails += `2. <strong>${props.data.coBorrowerName.toUpperCase()}</strong> (Co-Borrower)<br>`;

    if (props?.data?.coBorrowerAddress) {
      borrowerDetails += `Having address at: <strong>${props.data.coBorrowerAddress}</strong><br>`;
    } else if (props?.data?.borrowerAddress) {
      borrowerDetails += `Having address at: <strong>${props.data.borrowerAddress}</strong><br>`;
    }

    if (props?.data?.coBorrowerEmail) {
      borrowerDetails += `E-mail: <strong>${props.data.coBorrowerEmail}</strong><br>`;
    }

    if (props?.data?.phone) {
      borrowerDetails += `Contact: <strong>${props.data.phone}</strong><br>`;
    }
  }

  borrowerDetails += "</p>";
  console.log(props.data);
  const placeholders = {
    "{{borrowerName}}": `<strong>${props?.data?.borrowerName.toUpperCase()}</strong>`,
    "{{coBorrowerName}}": `<strong>${props?.data?.coBorrowerName.toUpperCase()}</strong>`,
    "{{borrowerAddress}}": `<strong>${props?.data?.borrowerAddress}</strong>`,
    "{{borrowerEmail}}": `<strong>${props?.data?.borrowerEmail}</strong>`,
    "{{borrowerPhone}}": `<strong>${props?.data?.borrowerPhone}</strong>`,
    "{{claimantCompany}}": `<strong>${props?.data?.claimantCompany.toUpperCase()}</strong>`,
    "{{loanId}}": `<strong>${props?.data?.loanId}</strong>`,
    "{{claimantCompanyAddress}}": `<strong>${props?.data?.claimantCompanyAddress}</strong>`,
    "{{claimAmount}}": `<strong>${props?.data?.claimAmount}</strong>`, // Replaced globally
    "{{privateNumber}}": `<strong>${props?.data?.privateNumber}</strong>`,
    "{{meetingDate}}": `<strong>${props?.data?.meetingDate}</strong>`,
    "{{asOnDate}}": `<strong>${props?.data?.asOnDate}</strong>`,
    "{{meetingTime}}": `<strong>${props?.data?.meetingTime}</strong>`,
    "{{noticeDate}}": `<strong>${props?.data?.noticeDate}</strong>`,
    "{{totalLoan}}": `<strong>${props?.data?.totalLoan}</strong>`,
    "{{contactPerson}}": `<strong>${props?.data?.contactPerson?.toUpperCase()}</strong>`,
    "{{mobileNumber}}": `<strong>${props?.data?.mobileNumber}</strong>`,
    "{{caseId}}": `<strong>${props?.data?.caseId}</strong>`,
    "{{sectionAct}}": `Section 62 of the Arbitration and Conciliation Act, 1996`,
    "{{privateCourt}}": `<strong>PRIVATECOURT</strong>`,
    "{{conciliation}}": `conciliation`,
    "{{arbitration}}": `arbitration`,
    "{{meetingAddress}}": `<strong style="cursor: pointer;"> ${props?.data?.meetingAddress}</strong>`,
    "{{paymentLink}}": `<strong style="cursor: pointer;">${props?.data?.paymentLink}</strong>`,
    "{{borrowerDetails}}": borrowerDetails,
  };

  // DOMPurify package is use to prevent Injection attacks.
  let formattedEmailHTML = filterHTML;

  // Loop through all placeholders and replace them globally
  for (const [placeholder, value] of Object.entries(placeholders)) {
    const regex = new RegExp(placeholder, "g"); // 'g' ensures global replacement
    formattedEmailHTML = formattedEmailHTML.replace(regex, value);
  }
  formattedEmailHTML = formattedEmailHTML.replace(
    /background-color: rgb\(255,255,255\);/g,
    ""
  );
  formattedEmailHTML = formattedEmailHTML.replace(
    /color: rgb(255,255,255);/g,
    ""
  );
  const sanitizedHTML = DOMPurify.sanitize(
    formattedEmailHTML.trim().replace(/^`|`$/g, "")
  );
  return <div dangerouslySetInnerHTML={{ __html: sanitizedHTML }} />;
};

export default EmailContent;
