// import React from "react";
import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { changeLang } from "../features/language/langSlice";

const LoginLang1 = ({ children }) => {
  const dispatch = useDispatch();
  const handleChangeLang = (language) => {
    dispatch(changeLang(language));
  };
  return (
    <div className="language-list">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <ul className="list-inline">
              <li
                className="list-inline-item"
                onClick={() => handleChangeLang("English(UK)")}
              >
                English (UK)
              </li>
              <li
                className="list-inline-item"
                id="button1"
                onClick={() => handleChangeLang("Hindi")}
              >
                हिन्दी
              </li>
              <li
                className="list-inline-item"
                id="button2"
                onClick={() => handleChangeLang("Bengali")}
              >
                বাংলা
              </li>
              <li
                className="list-inline-item"
                id="button3"
                onClick={() => handleChangeLang("Telugu")}
              >
                తెలుగు
              </li>
              <li
                className="list-inline-item"
                id="button4"
                onClick={() => handleChangeLang("Marathi")}
              >
                मराठी
              </li>
              <li
                className="list-inline-item"
                id="button5"
                onClick={() => handleChangeLang("Tamil")}
              >
                தமிழ்
              </li>
              <li
                className="list-inline-item"
                id="button6"
                onClick={() => handleChangeLang("Gujarati")}
              >
                ગુજરાતી
              </li>
              <li
                className="list-inline-item"
                id="button7"
                onClick={() => handleChangeLang("Kannada")}
              >
                ಕನ್ನಡ
              </li>
              <li
                className="list-inline-item"
                id="button8"
                onClick={() => handleChangeLang("Malayalam")}
              >
                മലയാളം
              </li>
              <li
                className="list-inline-item"
                id="button9"
                onClick={() => handleChangeLang("Odia")}
              >
                ওড়িয়া
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginLang1;
