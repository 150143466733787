/* eslint-disable no-undef */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable no-unused-vars */

import auth from "../../../services/authService";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import http from "../../../services/httpService";
import UseHandleModal from "../../../modal/UseHandleModal1";
import LoginHeader from "../../../components/loginHeader";
import Navbar from "../../../components/navbar";
import BackButton from "../../../components/backButton";
import { useSelector } from "react-redux";
import Loading from "../../../components/loading";

const DisputeUploadReportPage = (props) => {
  const navigate = useNavigate();
  const email = auth.getUser().UserLogin.email;
  const theme = useSelector((state) => state.theme.theme);

  const [usersData, setUsersData] = useState([]);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [perPage, setPerPage] = useState(10);
  const [cases, setCases] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [noticeTimeStamp, setNoticeTimeStamp] = useState("");
  const [noticeInfos, setNoticeInfo] = useState([]);
  const [levelFilter, setLevelFilter] = useState("");
  const [processFilter, setProcessFilter] = useState("");
  const [processStage, setprocessStage] = useState("");
  const [isDownloading, setIsDownloading] = useState(false);
  const { handleModal2, darkhandleModal2 } = UseHandleModal();

  const resetPage = () => {
    setPage(1);
  };

  const handleFilterReset = () => {
    setNoticeTimeStamp("");
    setLevelFilter("");
    setProcessFilter("");
    setSearch("");
    resetPage();
  };

  const fetchData = async () => {
    setIsLoading(true);

    try {
      let response = await http.post2("/getNoticeReports", {
        email,
        timeStamp: noticeTimeStamp,
        level: levelFilter,
        process: processFilter,
        page,
        perPage,
        search: search.trim(),
        processStage: processStage,
      });
      if (response.status === 200) {
        const transformedData = response.data.data.map((item) => ({
          ...item,
          payment: item?.payment || "",
          description: item?.description || "",
          manualCallingStatus: item?.manualCallingStatus || "",
        }));
        console.log(response.data.data);
        setUsersData(transformedData);
        setCases(response.data.totalCases);
      } else {
        throw new Error("Failed to fetch data");
      }
    } catch (err) {
      console.error("Error fetching report CSV data:", err);
      theme
        ? handleModal2("Error fetching data")
        : darkhandleModal2("Error fetching data");
    } finally {
      setIsLoading(false);
    }
  };

  const handleDocumentDownloadByLoanId = (loanId, timeStamp) => {
    if (loanId && timeStamp) {
      let url;
      if (timeStamp === 1732350327521) {
        url = `https://privatecourtdocs.s3.ap-south-1.amazonaws.com/CSBBank/${loanId}.pdf`;
      } else {
        url = `https://privatecourtdocs.s3.ap-south-1.amazonaws.com/${timeStamp}/${loanId}.pdf`;
      }
      window.open(url, "_blank");
    } else {
      console.error("Loan ID is missing.");
    }
  };

  const fetchData3 = async () => {
    setIsLoading(true);

    try {
      const response = await http.post2("/getNotices", {
        email,
      });
      if (response.status !== 400) {
        setNoticeInfo(response.data);
      } else {
        throw new Error("Failed to fetch notice info");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      theme
        ? handleModal2("Error fetching notice info")
        : darkhandleModal2("Error fetching notice info");
    } finally {
      setIsLoading(false);
    }
  };

  function formatTimestamp(timestamp) {
    const date = new Date(timestamp);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = month < 10 ? `0${month}` : month;
    return `${formattedDay}/${formattedMonth}/${year}`;
  }

  useEffect(() => {
    fetchData();
  }, [
    page,
    perPage,
    search,
    noticeTimeStamp,
    levelFilter,
    processFilter,
    processStage,
  ]);

  useEffect(() => {
    fetchData3();
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  const handleLoanClick = async (loanId, timestamp) => {
    navigate(`/DisputeUserProfile?loanId=${loanId}&timestamp=${timestamp}`);
  };

  useEffect(() => {
    const tooltipTriggerList = document.querySelectorAll(
      '[data-bs-toggle="tooltip"]'
    );
    const tooltipList = [...tooltipTriggerList].map(
      (tooltipTriggerEl) => new bootstrap.Tooltip(tooltipTriggerEl)
    );
  }, []);

  const downloadCSV = async () => {
    try {
      setIsDownloading(true);

      const response = await http.post2(
        "/getNoticeReportsForAdminSideForDownloadReport",
        {
          email: email,
          timeStamp: noticeTimeStamp,
          level: levelFilter,
          process: processFilter,
          search,
          processStage,
        }
      );

      if (response.status === 200) {
        const usersDataDownload = response.data.data.map((item) => ({
          ...item,
          payment: item?.payment || "",
          description: item?.description || "",
          manualCallingStatus: item?.manualCallingStatus || "",
        }));

        if (!usersDataDownload || usersDataDownload.length === 0) {
          alert("No data available for download");
          return;
        }

        const csvHeader = [
          "loanID",
          "borrowerName",
          "coborrowerName",
          "city",
          "state",
          "process",
          "stage",
          "level",
          "payment",
          "description",
          "mCallingStatus",
          "whatsappStatus",
          "emailStatus",
          "smsStatus",
          "postalID",
          "postalStatus",
          "callingStatus",
        ];

        const csvRows = [];
        csvRows.push(csvHeader.join(","));
        usersDataDownload.forEach((loan) => {
          const row = [
            loan?.loanId || "",
            loan?.borrowerName || "",
            loan?.coBorrowerName || "",
            loan?.city || "",
            loan?.state || "",
            loan?.noticeStage || "",
            loan?.stage || "",
            loan?.level || "",
            loan?.payment || "",
            loan?.description || "",
            loan?.manualCallingStatus || "",
            loan?.whatsAppStatus || "",
            loan?.prevBrevoInfo?.Status || "",
            loan?.borrowerSmsReport?.borrowerSmsStatus || "",
            loan?.trackingID || "",
            loan?.postalStatus || "",
            loan?.telecmiCallStatus?.status || "",
          ];
          csvRows.push(row.join(","));
        });
        const csvContent = csvRows.join("\n");
        const blob = new Blob([csvContent], {
          type: "text/csv;charset=utf-8;",
        });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = "report.csv";
        link.click();
        URL.revokeObjectURL(link.href);
      } else {
        throw new Error("Failed to fetch CSV report data");
      }
    } catch (error) {
      console.error("Error generating CSV:", error);
      alert("Failed to download the report. Please try again.");
    } finally {
      setIsDownloading(false);
    }
  };

  return (
    <div className="main-wrapper">
      <div className="container-fluid">
        <div className="row">
          {/* Left Side */}
          <div className="col-xl-3">
            <div className="left-wrapper">
              <Navbar></Navbar>
              <BackButton link="/bulkUpload"></BackButton>
              <div className="" id="collapseExample">
                <div className="data-filter">
                  {/* Search Bar */}
                  <div className="form-floating mb-3">
                    <input
                      className="form-control"
                      id="floatingSelect"
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                      aria-label="Floating label select example"
                    />
                    <label>Search By Borrower Name, Loan ID</label>
                  </div>
                  {/* Select Sheet Name */}
                  <div className="form-floating mb-3">
                    <select
                      className="form-select"
                      id="floatingSelect"
                      value={noticeTimeStamp}
                      onChange={(e) => setNoticeTimeStamp(e.target.value)}
                      aria-label="Floating label select example"
                    >
                      <option value="">All</option>
                      {noticeInfos.length > 0
                        ? noticeInfos.map((noticeItem, index) => (
                            <option
                              style={{ justifyContent: "flex-start" }}
                              key={index}
                              value={noticeItem.timeStamp}
                            >
                              {`${noticeItem.documentId}-${noticeItem.noticeType}`}
                            </option>
                          ))
                        : ""}
                    </select>
                    <label>Select Sheet Name</label>
                  </div>
                  {/* Level Filter */}
                  <div className="form-floating mb-3">
                    <select
                      className="form-select"
                      id="levelSelect"
                      value={levelFilter}
                      onChange={(e) => setLevelFilter(e.target.value)}
                      aria-label="Floating label select example"
                    >
                      <option value="">All</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                    </select>
                    <label>Select Level</label>
                  </div>
                  {/* Process Filter */}
                  <div className="form-floating mb-3">
                    <select
                      className="form-select"
                      id="processSelect"
                      value={processFilter}
                      onChange={(e) => setProcessFilter(e.target.value)}
                      aria-label="Floating label select example"
                    >
                      <option value="">All</option>
                      <option value="Arbitration">Arbitration</option>
                      <option value="Conciliation">Conciliation</option>
                    </select>
                    <label>Select Process</label>
                  </div>
                  {/* Process Stage F Filter */}
                  <div className="form-floating mb-3">
                    <select
                      className="form-select"
                      id="processSelect"
                      value={processStage}
                      onChange={(e) => setprocessStage(e.target.value)}
                      aria-label="Floating label select example"
                    >
                      <option value="">All</option>
                      <option value="LRN">LRN</option>
                      <option value="Invocation">Invocation</option>
                      <option value="Acceptance">
                        Acceptance / Nomination of Arbitrator
                      </option>
                      <option value="SOC">SOC</option>
                      <option value="section_17_order">
                        Section 17 Order / Interim Order
                      </option>
                      <option value="firstHearing">First Hearing</option>
                      <option value="secondHearing">Second Hearing</option>
                      <option value="thirdHearing">Third Hearing</option>
                      <option value="fourHearing">Four Hearing</option>
                      <option value="Award">Award</option>
                    </select>
                    <label>Select Stage</label>
                  </div>
                  {/* Reset Filter */}
                  <button
                    className="btn btn-primary btn-sm"
                    onClick={handleFilterReset}
                  >
                    Reset Filter
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-xl-9"
            style={{
              minHeight: "200px",
              overflowY: "auto",
            }}
          >
            <LoginHeader />
            {isLoading ? (
              <>
                <Loading />
              </>
            ) : (
              <div className="right-wrapper mt-3">
                <div className="conciliation-application-form">
                  <form id="regForm" action="#">
                    <div className="col-lg-12">
                      <div className="card card-new h-100">
                        {/* Above Table Details  */}
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <h2 className="mb-3">Reports</h2>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            {/* Cases Details */}
                            <div className="d-flex align-items-center gap-3">
                              {/* Cases Count */}
                              <div className="d-flex align-items-center">
                                <h2 className="mb-0">
                                  <span style={{ fontSize: "12px" }}>
                                    Cases{" "}
                                  </span>
                                  ({cases})
                                </h2>
                              </div>

                              {/* Download Data */}
                              <div className="d-flex align-items-center">
                                <i
                                  className="bi bi-filetype-csv fs-4"
                                  style={{ cursor: "pointer" }}
                                  onClick={downloadCSV}
                                  disabled={isDownloading}
                                ></i>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* Table Details */}
                        <div
                          className="col-lg-12"
                          style={{ minHeight: "auto", overflowY: "auto" }}
                        >
                          <table className="table table-bordered">
                            <thead
                              style={{
                                justifyContent: "center",
                                alignItems: "center",
                                textAlign: "center",
                              }}
                            >
                              <tr>
                                <th scope="col">Date</th>
                                <th scope="col">LoanID</th>
                                <th scope="col">Borrower</th>
                                <th scope="col">City & State</th>
                                <th scope="col">Process</th>
                                <th scope="col">Stage</th>
                                <th scope="col">Level</th>
                                <th scope="col">Payment</th>
                                <th scope="col">Description</th>
                                <th scope="col">Whatsapp</th>
                                <th scope="col">Email</th>
                                <th scope="col">Calling</th>
                                <th scope="col">MCalling</th>
                                <th scope="col">SMS</th>
                                <th scope="col">Postal</th>
                                <th scope="col">Postal Status</th>
                                <th scope="col">Download</th>
                              </tr>
                            </thead>
                            <tbody>
                              {usersData.length > 0 ? (
                                usersData?.map((item, index) => (
                                  <tr
                                    key={item._id}
                                    style={{ verticalAlign: "middle" }}
                                  >
                                    <td
                                      data-label="Date"
                                      style={{ textTransform: "capitalize" }}
                                    >
                                      {formatTimestamp(item?.timeStamp)}
                                    </td>
                                    <td
                                      data-label="Loan ID"
                                      onClick={() =>
                                        handleLoanClick(
                                          item?.loanId,
                                          item?.timeStamp
                                        )
                                      }
                                      style={{
                                        textDecoration: "underline",
                                        cursor: "pointer",
                                        color: "#007bff",
                                        fontWeight: "500",
                                        textTransform: "uppercase",
                                      }}
                                    >
                                      {item?.loanId}
                                    </td>
                                    <td
                                      data-label="Borrower/Company"
                                      data-bs-toggle="tooltip"
                                      title={`${item?.borrowerName} and ${item?.coBorrowerName}`}
                                    >
                                      {item?.displayName || "-"}
                                    </td>
                                    <td data-label="City & State">
                                      {item?.city ? `${item?.city}` : ""}
                                      {item?.city && item?.state ? `-` : ""}
                                      {item?.state ? `${item?.state}` : ""}
                                    </td>
                                    <td
                                      data-label="Process"
                                      style={{ textTransform: "capitalize" }}
                                    >
                                      {item?.type}
                                    </td>
                                    <td
                                      data-label="Stage"
                                      style={{ textTransform: "capitalize" }}
                                    >
                                      {item?.stage}
                                    </td>
                                    <td
                                      data-label="Level"
                                      style={{ textTransform: "capitalize" }}
                                    >
                                      {item?.level || "-"}
                                    </td>
                                    <td
                                      data-label="payment"
                                      style={{ textTransform: "capitalize" }}
                                    >
                                      {item?.payment || "-"}
                                    </td>
                                    <td
                                      data-label="description"
                                      style={{ textTransform: "capitalize" }}
                                    >
                                      {item?.description || "-"}
                                    </td>

                                    <td
                                      data-label="whatsapp"
                                      style={{ textTransform: "capitalize" }}
                                    >
                                      {item?.whatsAppStatus || " - "}
                                    </td>
                                    <td
                                      data-label="email"
                                      style={{ textTransform: "capitalize" }}
                                    >
                                      {item?.prevBrevoInfo?.Status || " - "}
                                    </td>
                                    <td
                                      data-label="calling"
                                      style={{ textTransform: "capitalize" }}
                                    >
                                      {item?.telecmiCallStatus?.status || " - "}
                                    </td>
                                    <td
                                      data-label="manualCalling"
                                      style={{ textTransform: "capitalize" }}
                                    >
                                      {item?.manualCallingStatus || " - "}
                                    </td>
                                    <td
                                      data-label="sms"
                                      style={{ textTransform: "capitalize" }}
                                    >
                                      {/* borrowerSmsReport:{borrowerSmsStatus: 'Delivered'} */}
                                      {item?.borrowerSmsReport?.borrowerSmsStatus || " - "}
                                    </td>
                                    <td
                                      data-label="postal"
                                      style={{ textTransform: "capitalize" }}
                                    >
                                      {item?.trackingID || " - "}
                                    </td>
                                    <td
                                      data-label="postalStatus"
                                      style={{ textTransform: "capitalize" }}
                                    >
                                      {item?.postalStatus || " - "}
                                    </td>
                                    <td
                                      data-label="Download"
                                      style={{ textAlign: "center" }}
                                    >
                                      <i
                                        className="bi bi-filetype-pdf fs-4"
                                        // onClick={() => setShowModal(true)}
                                        onClick={() =>
                                          handleDocumentDownloadByLoanId(
                                            item?.loanId,
                                            item?.timeStamp
                                          )
                                        }
                                        style={{
                                          cursor: "pointer",
                                        }}
                                        title="Download PDF"
                                      ></i>
                                    </td>
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td
                                    colSpan="20"
                                    className="text-center"
                                    style={{ fontStyle: "italic" }}
                                  >
                                    No records found.
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>

                        {/* Pagination Div */}
                        <div className="row mt-2 p-3">
                          <div className="col-lg-8">
                            <div className="data-pagi float-lg-end mt-lg-0 mt-2">
                              <select
                                className="form-control"
                                id="floatingSelect"
                                value={perPage ? perPage : 10}
                                onChange={(e) => {
                                  setPerPage(e.target.value);
                                }}
                                aria-label="Floating label select example"
                              >
                                <option value={10}>10</option>
                                <option value={50}>50</option>
                                <option value={100}>100</option>
                              </select>
                              <div
                                className="btn-toolbar"
                                role="toolbar"
                                aria-label="Toolbar with button groups"
                              >
                                <div
                                  className="btn-group me-1"
                                  role="group"
                                  aria-label="First group"
                                >
                                  <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={() => setPage(+page - 1)}
                                    disabled={page <= 1}
                                  >
                                    <i className="bi bi-chevron-left text-white"></i>
                                  </button>
                                </div>
                                <div
                                  className="btn-group me-1"
                                  role="group"
                                  aria-label="Second group"
                                >
                                  <button
                                    type="button"
                                    className="btn text-primary"
                                  >
                                    {page}
                                  </button>
                                </div>
                                <div
                                  className="btn-group"
                                  role="group"
                                  aria-label="Third group"
                                >
                                  <button
                                    type="button"
                                    className="btn btn-primary"
                                    disabled={usersData.length < +perPage}
                                    onClick={() => setPage(+page + 1)}
                                  >
                                    <i className="bi bi-chevron-right text-white"></i>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DisputeUploadReportPage;
