import React from "react";

const CommonOtpUse = (props) => {
  const { title, subtitle, handleOTPmodal, VerifyOTP,otp,setOtp } = props;
  const handleChange = (index, value) => {
    if (!/^\d*$/.test(value)) return; // Ensure only numeric values
    const updatedOtp = [...otp];
    updatedOtp[index] = value;
    setOtp(updatedOtp);

    // Automatically focus the next input
    if (value && index < otp.length - 1) {
      document.getElementById(`otp-input-${index + 1}`).focus();
    } 
  };

  return (
    <div
      className={`modal d-block`}
      id="acceptModal"
      tabindex="-1"
      aria-labelledby="acceptModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="acceptModalLabel">
              {title}
            </h5>
            <button
              type="button"
              class="btn-close"
              onClick={() => handleOTPmodal(false)}
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body pb-4">
            {/* <h5>{title}</h5> */}
            <p>{subtitle}</p>
            <div class="d-flex flex-row justify-content-center">
              {otp.map((digit, index) => (
                <input
                  key={index}
                  id={`otp-input-${index}`}
                  className="text-center form-control rounded mx-1"
                  type="text"
                  maxLength="1"
                  value={digit}
                  onChange={(e) => handleChange(index, e.target.value)}
                />
              ))}
            </div>
            <div class="text-center mt-2 cursor-pointer">
              <button
                type="button"
                class="btn btn-primary"
                onClick={() => VerifyOTP()} 
              >
                Verify OTP
              </button>
            </div>

            {/* <div class="alert alert-danger text-danger vfe-content" role="alert">
                    <div class="text-center">
                         <i class="bi bi-exclamation-triangle-fill text-danger"></i>
                    </div>
                    <h4 class="alert-heading">Error: Invalid OTP Number</h4>
                    <p class="text-center">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Odit quod soluta, harum error blanditiis explicabo!</p>
                </div>  */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommonOtpUse;
