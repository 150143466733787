/* eslint-disable react/prop-types */
import React from "react";

const Field = ({key1, value }) => {
  return (
        value?<div className="col-lg-4">
      <p>
        <strong>{key1}</strong> {value}
      </p>
    </div>:""
  );
};

export default Field;
