

import React from "react";
import arrow1 from "../images/arrow1.svg";
import arrow1Yellow from "../images/arrow1-yellow.svg";
import { useSelector } from "react-redux";
import useLanguage from "../local/saveLanguage"

const NextButton = ({ btnName, onSubmit, disabledBtn, type }) => {
  const translate = useLanguage.getLang();
  const theme = useSelector((state) => state.theme.theme);
  return (
    <button
      type="submit"
      className="butmim2 ms-auto"
      id="nextBtn"
      onClick={()=>onSubmit(type)}
      disabled={disabledBtn}
    >
      <h4 className="blue" data-translate="tran-9">
        {translate?.[btnName] ?? btnName}
      </h4>
      <div className="buttoncircle1 endh centrex">
        <img
          className="arrow1 btn-arrow-right"
          id="btn-arrow"
          src={theme ? arrow1 : arrow1Yellow}
          alt="Arrow"
        />
      </div>
    </button>
  );
};

export default NextButton;
