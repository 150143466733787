import React from "react";
import { useDispute } from "../../hooks/useDispute";
import { useEffect, useState } from "react";
import logo from "../../images/logo-bg.png";
import http from "../../services/httpService";
import CryptoJS from "crypto-js";
import axios from "axios";
import { PDFDocument, rgb } from "pdf-lib";
import PDFfile from "../../images/DRPAgreement.pdf";
import handleModal from "../../modal/useModal1";
export const Esign = () => {
  const { call, setCall, data, setData, toggleOtp, titleCase } = useDispute();
  const [otp, setOtp] = useState("");
  const [showotp, setShowOtp] = useState(false);
  const [ipAddress, setIpAddress] = useState(null);
  const { handleModal2, handleModal3 } = handleModal();
  const getUrlParameter = (name) => {
    name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    const regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
    const results = regex.exec(window.location.search);
    return results === null
      ? ""
      : decodeURIComponent(results[1].replace(/\+/g, " "));
  };
  // Extract encrypted email and DID from URL parameters
  const encryptedEmail = getUrlParameter("email");
  const encryptedDid = getUrlParameter("did");
  const emailValue = CryptoJS.AES.decrypt(
    encryptedEmail,
    process.env.REACT_APP_SECRET_ESIGN 
  ).toString(CryptoJS.enc.Utf8);
  const didValue = CryptoJS.AES.decrypt(
    encryptedDid,
    process.env.REACT_APP_SECRET_ESIGN
  ).toString(CryptoJS.enc.Utf8);
  // const emailValue = "ayush@privatecourt.in";
  // const didValue = "66ea91672c015475576a3ebc";
  const chec = data?.Parties?.filter((party) => {
    return party.email === emailValue;
  });
  // console.log(showModal)
  const submitOtp = async () => {
    // await axios.post("http://localhost:3004/api/esign", {
    const res = await http
      .post2("/esign", {
        otp,
        did: didValue,
        ip: ipAddress,
        email: emailValue,
      })
      .then((res) => {
        handleModal3("You have successfully signed!");
        setShowOtp(false);
        setCall(true);
      })
      .catch((err) => {
        handleModal2("Something went wrong. Please try again later.");
        console.log(err);
      });
  };
  const fetchDetails = async () => {
    await http
      .post2("/disputeData", {
        // await axios.post("http://localhost:3004/api/disputeData", {
        did: didValue,
      })
      .then((res) => {
        setData(res.data);
        if (didValue) {
          generateAndDownloadPDF(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const EsignSubmit = async () => {
    // await axios.post("http://localhost:3004/api/disputepaperEmail", {
    await http
      .post2("/disputepaperEmail", {
        receivers: [{ email: emailValue }],
        type: "otp",
        did: didValue,
      })
      .then((res) => {
        console.log(res);
        setShowOtp(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const generateAndDownloadPDF = async (formikValues) => {
    const existingPdfBytes = await fetch(PDFfile).then((res) =>
      res.arrayBuffer()
    );
    const pdfDoc = await PDFDocument.load(existingPdfBytes);
    const pages = pdfDoc.getPages();
    const firstPage = pages[0];
    const FormData1 = [
      { text: formikValues.Parties[0]?.name, y: 575 },
      {
        text: formikValues.Parties[0]?.organization,
        y: 535,
      },
      { text: formikValues.Parties[0]?.email, y: 495 },
      { text: formikValues.Parties[0]?.phone, y: 455 },
      { text: formikValues.Parties[0]?.govtId, y: 415 },
    ];

    const FormData2 = [
      { text: formikValues.Parties[1]?.name, y: 575 },
      {
        text: formikValues.Parties[1]?.organization,
        y: 535,
      },
      { text: formikValues.Parties[1]?.email, y: 495 },
      { text: formikValues.Parties[1]?.phone, y: 455 },
      { text: formikValues.Parties[1]?.govtId, y: 415 },
    ];

    // if(formikValues.Agreement.title!=='' && formikValues.Parties[0].name!=='' && formikValues.Parties[0].email!=='' && formikValues.Parties[1].name!=='' && formikValues.Parties[1].email!==''){
    try {
      FormData1.map(({ text, y }) => {
        const textString = typeof text === "number" ? text.toString() : text;
        firstPage.drawText(textString ? textString : "N/A", {
          x: 110,
          y,
          size: 10,
          color: rgb(0, 0, 0),
        });
      });
      if (formikValues.Parties[0]?.ip && formikValues.Parties[0]?.date) {
        firstPage.drawText(`${formikValues.Parties[0]?.name}`, {
          x: 110,
          y: 385,
          size: 6,
          color: rgb(0, 0, 0),
        });
        // Assuming formikValues.Parties[0].date is a valid date string
        var dateObj = new Date(formikValues.Parties[0].date);
        firstPage.drawText(`${dateObj}`, {
          x: 110,
          y: 375,
          size: 6,
          color: rgb(0, 0, 0),
        });
        firstPage.drawText(`${formikValues.Parties[0].ip}`, {
          x: 110,
          y: 365,
          size: 6,
          color: rgb(0, 0, 0),
        });
      }
      FormData2.map(({ text, y }) => {
        const textString = typeof text === "number" ? text.toString() : text;
        firstPage.drawText(textString ? textString : "N/A", {
          x: 360,
          y,
          size: 10,
          color: rgb(0, 0, 0),
          color: rgb(0, 0, 0),
        });
      });
      if (formikValues.Parties[1]?.ip && formikValues.Parties[1]?.date) {
        firstPage.drawText(`${formikValues.Parties[1]?.name}`, {
          x: 360,
          y: 385,
          size: 6,
          color: rgb(0, 0, 0),
        });
        // Assuming formikValues.Parties[0].date is a valid date string
        var dateObj = new Date(formikValues.Parties[1].date);
        firstPage.drawText(`${dateObj}`, {
          x: 360,
          y: 375,
          size: 6,
          color: rgb(0, 0, 0),
        });
        firstPage.drawText(`${formikValues.Parties[1].ip}`, {
          x: 360,
          y: 365,
          size: 6,
          color: rgb(0, 0, 0),
        });
      }
      firstPage.drawText(
        `${formikValues.AgreementData[0].referenceAgreementNo}`,
        {
          x: 100,
          y: 678,
          size: 12,
          color: rgb(0, 0, 0),
        }
      );
      firstPage.drawText(
        `${
          formikValues.AgreementData[0].seatofArbitration.country
            ? formikValues.AgreementData[0].seatofArbitration.country ===
              "India"
              ? "Rs"
              : "USD"
            : "USD"
        } ${formikValues.AgreementData[0].Fee}`,
        {
          x: 350,
          y: 678,
          size: 12,
          color: rgb(0, 0, 0),
        }
      );
      firstPage.drawText(`${formikValues.AgreementData[0].DateOfAgreement}`, {
        x: 470,
        y: 678,
        size: 12,
        color: rgb(0, 0, 0),
      });
      firstPage.drawText(
        `${
          formikValues.AgreementData[0].seatofArbitration.state
            ? formikValues.AgreementData[0].seatofArbitration.state
            : ""
        }`,
        {
          x: 450,
          y: 110,
          size: 12,
          color: rgb(0, 0, 0),
        }
      );
      firstPage.drawText(
        `Title : ${formikValues.AgreementData[0].title} & Value : ${
          formikValues.AgreementData[0]?.valueOfAgreement
            ? formikValues.AgreementData[0]?.valueOfAgreement
            : 0
        }`,
        {
          x: 50,
          y: 328,
          size: 12,
        }
      );

      if (formikValues.Parties.length > 2) {
        //new page added
        let thirdParty = pdfDoc.addPage([612, 792]);
        thirdParty.drawText("Other Parties Details:", {
          x: 30,
          y: 750,
          size: 20,
        });
        thirdParty.drawLine({
          start: { x: 30, y: 740 },
          end: { x: 570, y: 740 },
          thickness: 1,
        });
        let yOffset = 740;
        const addTextToPage = (page, text, x, y, fontSize, count, font) => {
          const lineHeight = fontSize + 2;
          if (yOffset <= 10) {
            // Create a new page if remaining space is not enough
            page = pdfDoc.addPage([612, 792]);
            yOffset = 740; // Start at the top of the new page
          }
          page.drawText(text ? text : "N/A", { x, y: yOffset, size: fontSize });
          if (count === 3) {
            yOffset -= lineHeight; // Move down for the next content, considering font size
          }
          return page;
        };
        formikValues.Parties.slice(2).forEach((Party, index) => {
          yOffset -= 30; // Add some space between parties
          thirdParty = addTextToPage(
            thirdParty,
            `Party ${index + 3} Detail`,
            50,
            yOffset,
            15
          );
          yOffset -= 10;
          for (const [key, value] of Object.entries(Party)) {
            if (
              key !== "slectionOption" &&
              key !== "_id" &&
              key !== "date" &&
              key !== "ip"
            ) {
              const keyText = key !== "govtId" ? titleCase(key) : "Govt ID";
              const valueText = `${value}`;
              // Set x-coordinates for key and value
              const keyX = 70;
              const separatorX = 130; // Adjust this value based on your layout requirements
              const valueX = 150; // Adjust this value based on your layout requirements
              yOffset -= 15;
              // Add key text to page
              thirdParty = addTextToPage(
                thirdParty,
                keyText,
                keyX,
                yOffset,
                10
              );
              // Add separator text to page
              thirdParty = addTextToPage(
                thirdParty,
                ":",
                separatorX,
                yOffset,
                10
              );
              // Add value text to page
              thirdParty = addTextToPage(
                thirdParty,
                valueText,
                valueX,
                yOffset,
                8,
                3
              );
            }
          }
          yOffset -= 20;
          thirdParty = addTextToPage(thirdParty, `Signature`, 70, yOffset, 10);
          thirdParty = addTextToPage(thirdParty, ` : `, 130, yOffset, 10);
          // thirdParty = add
          thirdParty = addTextToPage(
            thirdParty,
            `_______________`,
            150,
            yOffset,
            10,
            3
          );

          if (
            formikValues.Parties[index + 2]?.ip &&
            formikValues.Parties[index + 2]?.date
          ) {
            // console.log(index)
            yOffset += 30;
            thirdParty = addTextToPage(
              thirdParty,
              `${formikValues.Parties[index + 2].ip}`,
              160,
              yOffset,
              6
            );
            yOffset -= 8;
            var dateObj = new Date(formikValues.Parties[index + 2].date);
            thirdParty = addTextToPage(
              thirdParty,
              `${dateObj} `,
              160,
              yOffset,
              6
            );
            yOffset -= 8;
            thirdParty = addTextToPage(
              thirdParty,
              `${formikValues.Parties[index + 2].name} `,
              160,
              yOffset,
              6
            );
          }
        });
      }
      const pdfBytes = await pdfDoc.save();
      const blob = new Blob([pdfBytes], { type: "application/pdf" });
      const url = URL.createObjectURL(blob);
      // Set the PDF URL as the src of the iframe
      const iframe = document.getElementById("pdfIframe");
      console.log(iframe);
      iframe.src = url;
    } catch (err) {
      console.log(err);
    }
  };
  const fetchIpAddress = async () => {
    try {
      axios
        .get("https://api.ipify.org/?format=json")
        .then((res) => {
          setIpAddress(res.data.ip);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.error("Error fetching IP address:", error);
    }
  };

  useEffect(() => {
    fetchIpAddress();
  }, []);

  useEffect(() => {
    if (didValue || call) {
      fetchDetails();
    }
  }, [didValue, call]);
  return (
    <div className="new-login-wrapper">
      <div className="bg-logo-bottom bg-cover">
        <img className="bg-img-logo" src={logo} alt="logo" />
      </div>
      <div className="login-wrapper">
        <div className="login-header">
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="main-text-logo">
                  <h1>
                    PRIVATE<span>COURT</span>
                  </h1>
                  <p>
                    The International Court <span>of</span>{" "}
                    <strong>arbitration</strong>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="content">
          <div className="login-mid" style={{ paddingTop: 0 }}>
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="card pb-normal bank-verification p-4">
                    <div className="vf-content">
                      <p>
                        To proceed, we kindly request that you provide your
                        electronic signature below. Your signature indicates
                        your acknowledgment and acceptance of the terms outlined
                        in the Dispute Resolution Agreement.
                      </p>
                      <p className="mb-0">
                        Kindly review the document carefully before proceeding
                        to sign electronically. Your signature will be securely
                        encrypted to ensure the confidentiality and integrity of
                        your information.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="d-flex flex-column align-items-center pt-4">
                  <iframe
                    id="pdfIframe"
                    title="Generated PDF"
                    width="80%"
                    height="600px"
                  ></iframe>
                  <button
                    className="btn btn-primary mt-4"
                    disabled={
                      call ? true : false || (chec?.[0]?.date && chec?.[0]?.ip)
                    }
                    onClick={() => {
                      EsignSubmit();
                    }}
                  >
                    Esign
                  </button>
                  {chec?.[0]?.date && chec?.[0]?.ip ? (
                    <h3 className="mt-4">
                      You had already Signed the Document
                    </h3>
                  ) : (
                    <h3>Please Sign the Document</h3>
                  )}
                </div>
                <div
                  className={`modal ${showotp ? "show d-block" : "d-none"}`}
                  role="dialog"
                >
                  <div className="modal-dialog modal-md" role="document">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title">More Details Overview</h5>
                        <button
                          type="button"
                          onClick={toggleOtp}
                          className="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>
                      <div className="modal-body">
                        <div className="col-md-4 mb-3">
                          <label htmlFor="otp" className="form-label">
                            OTP:
                          </label>
                          <input
                            className="form-control form-control-new p-3"
                            type="number"
                            value={otp}
                            onChange={(e) => {
                              setOtp(e.target.value);
                            }}
                          />
                          <button
                            onClick={submitOtp}
                            className="btn btn-primary mx-2 mt-4"
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                      {/* <div className="modal-footer">
                        <button
                          type="button"
                          className="btn btn-secondary"
                          onClick={toggleOtp}
                        >
                          Close
                        </button>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
