/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import ask from "../../asset/ask-pic.png";
import logoutIcon from "../../asset/icons/logout.svg";
import logoutIconDark from "../../dark-images/icons/logout.svg";
import mobileMenu from "../../asset/mobile-menu.svg";
import mobileMenuDark from "../../dark-images/mobile-menu.svg";
import disputeIcon from "../../asset/icons/disputes.svg";
import disputeIconDark from "../../dark-images/icons/disputes.svg";
import agreementsIcon from "../../asset/icons/agreements.svg";
import agreementsIconDark from "../../dark-images/icons/agreements.svg";
import reportIcon from "../../asset/icons/report.svg";
import reportIconDark from "../../dark-images/icons/report.svg";
import scheduleIcon from "../../asset/icons/schedule.svg";
import scheduleIconDark from "../../dark-images/icons/schedule.svg";
import membersIcon from "../../asset/icons/members.svg";
import membersIconDark from "../../dark-images/icons/members.svg";
import accountIcon from "../../asset/icons/account.svg";
import accountIconDark from "../../dark-images/icons/account.svg";
import settingsIcon from "../../asset/icons/settings.svg";
import settingsIconDark from "../../dark-images/icons/settings.svg";
import supportIcon from "../../asset/icons/support.svg";
import supportIconDark from "../../dark-images/icons/support.svg";
import extensionsIcon from "../../asset/icons/extensions.svg";
import extensionsIconDark from "../../dark-images/icons/extensions.svg";
import searchIcon from "../../asset/search.svg";
import searchIconDark from "../../dark-images/search.svg";
import { Link } from "react-router-dom";
// import { withRouter } from "react-router-dom";logout-btn
import auth from "../../services/authService";
import { useEffect } from "react";

const FooterCompo = (props) => {
  const user = auth.getUser();
  const [search, setSearch] = useState("");
  let timer = null;
  const theme = useSelector((state) => state.theme);
  const openNav = () => {
    document.getElementById("myNav").style.display = "block";
  };
  const closeNav = () => {
    document.getElementById("myNav").style.display = "none";
  };

  const handleChange = (e) => {
    const value = e.target.value;
    setSearch(value);
    clearTimeout(timer);
    timer = setTimeout(() => {
      setSearch("");
      return props.history.push("/underconstruction");
    }, 1000);
  };

  // useEffect(()=>{console.log("theme change")
  // console.log(theme.theme);
  // }, [theme.theme])

  if(!user) return null

  return (
    <>
      <div className="main-wrapper">
        <div className="container-fluid">
          <footer className="dashboard-footer p-2">
            <div className="container-fluid">
              <div className="d-flex justify-content-xl-between">
                <div className="ask-person d-xl-inline d-none">
                  <div className="profile-pic">
                    <img src={ask} alt="" />
                  </div>
                  <button type="button">
                    Ask <span>Murthy</span>
                  </button>
                </div>
                <div className="logo-search align-self-baseline d-xl-flex d-none">
                  <div className="d-flex">
                    <div className="text-logo">
                      <Link to={"/dashboard"}>
                      <h1>
                        PRIVATE<span>COURT</span>
                      </h1>
                      </Link>
                      
                      <p>
                        The International Court{" "}
                        <span className="text-primary">of</span>{" "}
                        <strong>ARBITRATION</strong>
                      </p>
                    </div>
                    <form action="#" className="search">
                      <input
                        type="text"
                        className="form-control"
                        value={search}
                        onChange={handleChange}
                        placeholder="Search Documents / Knowledgebase"
                        // style={{ backgroundImage: `url(${searchIcon})` }}
                      />
                    </form>
                  </div>
                </div>
                <div className="d-flex justify-content-xl-between">
                  <Link to={"/logout"} className="logout-btn">
                    <img
                      src={theme.theme ? logoutIcon : logoutIconDark}
                      alt=""
                      style={{ display: "block", margin: "0 auto" }}
                    />
                  </Link>
                  <a
                    href="javascript:void(0)"
                    className="float-end menu-logo"
                    style={{ marginTop: "1px", marginBottom: "11px" }}
                    onClick={openNav}
                  >
                    <img
                      src={theme.theme ? mobileMenu : mobileMenuDark}
                      alt=""
                    />
                  </a>
                </div>
              </div>
              {/* <!-- /.d-flex --> */}
            </div>
            {/* <!-- /.container-fluid --> */}
          </footer>
          {/* <!-- /.dashboard-footer --> */}
        </div>
      </div>
      <div id="myNav" className="overlay myNav">
        <a href="javascript:void(0)" className="closebtn" onClick={closeNav}>
          &times;
        </a>
        <div className="overlay-content">
          <div className="container-sm">
            <div className="row row-cols-lg-3 justify-content-center row-cols-sm-3 row-cols-3">
              <div className="col">
                {/* <Link to={user.role!='user'?'/disputes':''} className={`menu-item me-sm-3 ${user.role=='user'?'bg-secondary':''}`} disabled={user.role=='user'} onClick={closeNav} >
                  <span className="text-white">File</span>
                  <div className="text-center">
                    {" "}
                    <img
                      src={disputeIcon}
                      alt=""
                      style={{ display: "block", margin: "0 auto" }}
                    />
                  </div>
                  <p className="text-white">Disputes</p>
                </Link> */}
                {user.role !== "admin" ||
                user.role === "privateCourtTeam" ||
                user.role === "NBFCBank" ? (
                  <Link
                    to="/bulkUpload"
                    className="menu-item me-sm-3"
                    onClick={closeNav}
                  >
                    <span className="text-white">File</span>
                    <div className="text-center">
                      <img
                        src={theme.theme ? disputeIcon : disputeIconDark}
                        alt=""
                        style={{ display: "block", margin: "0 auto" }}
                      />
                    </div>
                    <p className="text-white">Disputes</p>
                  </Link>
                ) : (
                  <div
                    className="menu-item me-sm-3 bg-secondary"
                    // onClick={closeNav}
                  >
                    <span className="text-white">File</span>
                    <div className="text-center">
                      <img
                        src={theme.theme ? disputeIcon : disputeIconDark}
                        alt=""
                        style={{ display: "block", margin: "0 auto" }}
                      />
                    </div>
                    <p className="text-white">Disputes</p>
                  </div>
                )}
              </div>
              <div className="col">
                {user.role === "admin" ||
                user.role === "privateCourtTeam" ||
                user.role === "user" ? (
                  <>
                    <Link
                      to={"/dispute-resolution-agreement"}
                      className="menu-item me-sm-3"
                      onClick={closeNav}
                    >
                      <span className="text-white">Create</span>
                      {/* <img src={agreementsIcon} alt="" /> */}
                      <div className="text-center">
                        {" "}
                        {/* Add Link container for centering */}
                        <img
                          src={
                            theme.theme ? agreementsIcon : agreementsIconDark
                          }
                          alt=""
                          style={{ display: "block", margin: "0 auto" }}
                        />
                      </div>
                      <p className="text-white">Agreements</p>
                    </Link>
                  </>
                ) : (
                  <>
                    <div
                      className="menu-item me-sm-3 bg-secondary"
                      // onClick={closeNav}
                    >
                      <span className="text-white">Create</span>
                      <div className="text-center">
                        <img
                          src={
                            theme.theme ? agreementsIcon : agreementsIconDark
                          }
                          alt=""
                          style={{ display: "block", margin: "0 auto" }}
                        />
                      </div>
                      <p className="text-white">Agreements</p>
                    </div>
                  </>
                )}
              </div>
              <div className="col">
                {/* <Link
                  to={"/dashboard"}
                  className={`menu-item me-sm-3 ${
                    user.role == "user" ? "bg-secondary" : ""
                  }`}
                  onClick={closeNav}
                >
                  <span className="text-white">Generate</span>
                  <div className="text-center">
                    {" "}
                    <img
                      src={reportIcon}
                      alt=""
                      style={{ display: "block", margin: "0 auto" }}
                    />
                  </div>
                  <p className="text-white">Reports</p>
                </Link> */}
                {user.role !== "admin" ||
                user.role === "privateCourtTeam" ||
                user.role === "NBFCBank" ? (
                  <Link
                    to="/disputeReporting"
                    className="menu-item me-sm-3"
                    onClick={closeNav}
                  >
                    <span className="text-white">Generate</span>
                    <div className="text-center">
                      <img
                        src={theme.theme ? reportIcon : reportIconDark}
                        alt=""
                        style={{ display: "block", margin: "0 auto" }}
                      />
                    </div>
                    <p className="text-white">Reports</p>
                  </Link>
                ) : (
                  <div
                    className="menu-item me-sm-3 bg-secondary"
                    // onClick={closeNav}
                  >
                    <span className="text-white">Generate</span>
                    <div className="text-center">
                      <img
                        src={theme.theme ? reportIcon : reportIconDark}
                        alt=""
                        style={{ display: "block", margin: "0 auto" }}
                      />
                    </div>
                    <p className="text-white">Reports</p>
                  </div>
                )}
              </div>
              <div className="col ">
                {user?.role == "admin" || user.role === "privateCourtTeam" ? (
                  <>
                    <Link
                      to={"/generateBill"}
                      className="menu-item me-sm-3"
                      onClick={closeNav}
                    >
                      <span className="text-white">Check</span>
                      {/* <img src={scheduleIcon} alt="" /> */}
                      <div className="text-center ">
                        {" "}
                        {/* Add Link container for centering */}
                        <img
                          src={theme.theme ? scheduleIcon : scheduleIconDark}
                          alt=""
                          style={{ display: "block", margin: "0 auto" }}
                        />
                      </div>
                      <p className="text-white">Billing</p>
                    </Link>
                  </>
                ) : (
                  <>
                    <div className={`menu-item me-sm-3 bg-secondary`}>
                      <span className="text-white">Check</span>
                      <div className="text-center">
                        <img
                          src={theme.theme ? scheduleIcon : scheduleIconDark}
                          alt=""
                          style={{ display: "block", margin: "0 auto" }}
                        />
                      </div>
                      <p className="text-white">Billing</p>
                    </div>
                  </>
                )}
              </div>
              <div className="col">
                {/* <a
                  href="#"
                  className={`menu-item me-sm-3 ${
                    user.role == "user" ? "bg-secondary" : ""
                  }`}
                >
                  <span className="text-white">View</span>
                  <div className="text-center">
                    {" "}
                    <img
                      src={membersIcon}
                      alt=""
                      style={{ display: "block", margin: "0 auto" }}
                    />
                  </div>
                  <p className="text-white">Members</p>
                </a> */}
                {user.role === "admin" || user.role === "privateCourtTeam" ? (
                  <Link
                    to={"/leadTable"}
                    className="menu-item me-sm-3"
                    onClick={closeNav}
                  >
                    <span className="text-white">View</span>
                    <div className="text-center">
                      <img
                        src={theme.theme ? membersIcon : membersIconDark}
                        alt=""
                        style={{ display: "block", margin: "0 auto" }}
                      />
                    </div>
                    <p className="text-white">Sales</p>
                  </Link>
                ) : (
                  //   <Link
                  //   to={"/addMembers"}
                  //   className="menu-item me-sm-3"
                  //   onClick={closeNav}
                  //   >
                  //     <span className="text-white">View</span>
                  //     <div className="text-center">
                  //       <img
                  //         src={theme.theme?membersIcon:membersIconDark}
                  //         alt=""
                  //         style={{ display: "block", margin: "0 auto" }}
                  //       />
                  //     </div>
                  //     <p className="text-white">Members</p>
                  // </Link>
                  <div className={`menu-item me-sm-3 bg-secondary`}>
                    <span className="text-white">View</span>
                    <div className="text-center">
                      <img
                        src={theme.theme ? membersIcon : membersIconDark}
                        alt=""
                        style={{ display: "block", margin: "0 auto" }}
                      />
                    </div>
                    <p className="text-white">Member</p>
                  </div>
                )}
              </div>
              <div className="col">
                {user.role !== "admin" || user.role === "privateCourtTeam" ? (
                  <>
                    <Link
                      to={"/userProfile"}
                      className="menu-item"
                      onClick={closeNav}
                    >
                      <span className="text-white">Manage</span>
                      {/* <img src={accountIcon} alt="" /> */}
                      <div className="text-center">
                        {" "}
                        {/* Add Link container for centering */}
                        <img
                          src={theme.theme ? accountIcon : accountIconDark}
                          alt=""
                          style={{ display: "block", margin: "0 auto" }}
                        />
                      </div>
                      <p className="text-white">Account</p>
                    </Link>
                  </>
                ) : (
                  <>
                    <div className={`menu-item me-sm-3 bg-secondary`}>
                      <span className="text-white">Manage</span>
                      <div className="text-center">
                        <img
                          src={theme.theme ? accountIcon : accountIconDark}
                          alt=""
                          style={{ display: "block", margin: "0 auto" }}
                        />
                      </div>
                      <p className="text-white">Account</p>
                    </div>
                  </>
                )}
              </div>
              <div className="col">
                {user.role === "admin" || user.role === "privateCourtTeam" ? (
                  <a href="#" className={`menu-item me-sm-3`}>
                    <span className="text-white">Manage</span>
                    <div className="text-center">
                      <img
                        src={theme.theme ? settingsIcon : settingsIconDark}
                        alt=""
                        style={{ display: "block", margin: "0 auto" }}
                      />
                    </div>
                    <p className="text-white">Settings</p>
                  </a>
                ) : (
                  <div className={`menu-item me-sm-3 bg-secondary`}>
                    <span className="text-white">Manage</span>
                    <div className="text-center">
                      <img
                        src={theme.theme ? settingsIcon : settingsIconDark}
                        alt=""
                        style={{ display: "block", margin: "0 auto" }}
                      />
                    </div>
                    <p className="text-white">Settings</p>
                  </div>
                )}
              </div>
              <div className="col">
                {/* <a
                  href="#"
                  className={`menu-item me-sm-3 ${
                    user.role == "user" ? "bg-secondary" : ""
                  }`}
                >
                  <span className="text-white">Get</span>
                  <div className="text-center">
                    {" "}
                    <img
                      src={supportIcon}
                      alt=""
                      style={{ display: "block", margin: "0 auto" }}
                    />
                  </div>
                  <p className="text-white">Support</p>
                </a> */}

                {user.role === "admin" || user.role === "privateCourtTeam" ? (
                  <a href="#" className={`menu-item me-sm-3`}>
                    <span className="text-white">Get</span>
                    <div className="text-center">
                      <img
                        src={theme.theme ? supportIcon : supportIconDark}
                        alt=""
                        style={{ display: "block", margin: "0 auto" }}
                      />
                    </div>
                    <p className="text-white">Support</p>
                  </a>
                ) : (
                  <div className={`menu-item me-sm-3 bg-secondary`}>
                    <span className="text-white">Get</span>
                    <div className="text-center">
                      <img
                        src={theme.theme ? supportIcon : supportIconDark}
                        alt=""
                        style={{ display: "block", margin: "0 auto" }}
                      />
                    </div>
                    <p className="text-white">Support</p>
                  </div>
                )}
              </div>
              <div className="col">
                {/* <a
                  href="#"
                  className={`menu-item me-sm-3 ${
                    user.role == "user" ? "bg-secondary" : ""
                  }`}
                >
                  <span className="text-white">Plugins</span>
                  <div className="text-center">
                    {" "}
                    <img
                      src={extensionsIcon}
                      alt=""
                      style={{ display: "block", margin: "0 auto" }}
                    />
                  </div>
                  <p className="text-white">Extensions</p>
                </a> */}
                {user.role === "admin" || user.role === "privateCourtTeam" ? (
                  <a href="#" className={`menu-item me-sm-3`}>
                    <span className="text-white">Plugins</span>
                    <div className="text-center">
                      <img
                        src={theme.theme ? extensionsIcon : extensionsIconDark}
                        alt=""
                        style={{ display: "block", margin: "0 auto" }}
                      />
                    </div>
                    <p className="text-white">Extensions</p>
                  </a>
                ) : (
                  <div className={`menu-item me-sm-3 bg-secondary`}>
                    <span className="text-white">Plugins</span>
                    <div className="text-center">
                      <img
                        src={theme.theme ? extensionsIcon : extensionsIconDark}
                        alt=""
                        style={{ display: "block", margin: "0 auto" }}
                      />
                    </div>
                    <p className="text-white">Extensions</p>
                  </div>
                )}
              </div>
              <div className="col">
                {/* <a
                  href="#"
                  className={`menu-item me-sm-3 ${
                    user.role == "user" ? "bg-secondary" : ""
                  }`}
                >
                  <span className="text-white">Search Box</span>
                  <div className="text-center">
                    {" "}
                    <img
                      src={searchIcon}
                      alt=""
                      style={{ display: "block", margin: "0 auto" }}
                    />
                  </div>
                  <p className="text-white">Search</p>
                </a> */}

                {user.role === "admin" || user.role === "privateCourtTeam" ? (
                  <a href="#" className={`menu-item me-sm-3`}>
                    <span className="text-white">Search Box</span>
                    <div className="text-center">
                      <img
                        src={theme.theme ? searchIcon : searchIconDark}
                        alt=""
                        style={{ display: "block", margin: "0 auto" }}
                      />
                    </div>
                    <p className="text-white">Search</p>
                  </a>
                ) : (
                  <div className={`menu-item me-sm-3 bg-secondary`}>
                    <span className="text-white">Search Box</span>
                    <div className="text-center">
                      <img
                        src={theme.theme ? searchIcon : searchIconDark}
                        alt=""
                        style={{ display: "block", margin: "0 auto" }}
                      />
                    </div>
                    <p className="text-white">Search</p>
                  </div>
                )}
              </div>
              <div className="col">
                <Link to="/logout" className="menu-item">
                  <span className="text-white">Logout</span>
                  {/* <img src={logoutIcon} alt="" /> */}
                  <div className="text-center">
                    {" "}
                    {/* Add Link container for centering */}
                    <img
                      src={theme.theme ? logoutIcon : logoutIconDark}
                      alt=""
                      style={{ display: "block", margin: "0 auto" }}
                    />
                  </div>
                  <p className="text-white">Logout</p>
                </Link>
              </div>
              <div className="col">
                {/* <a
                  href="#"
                  className={`menu-item me-sm-3 ${
                    user.role == "user" ? "bg-secondary" : ""
                  }`}
                >
                  <span className="text-white">Assistant</span>
                  <div className="text-center">
                    {" "}
                    <img
                      src={ask}
                      alt=""
                      style={{ display: "block", margin: "0 auto" }}
                    />
                  </div>
                  <p className="text-white">Ask Murthy</p>
                </a> */}
                {user.role === "admin" || user.role === "privateCourtTeam" ? (
                  <a
                    href="#"
                    className={`menu-item me-sm-3 ${
                      user.role === "user" ? "bg-secondary" : ""
                    }`}
                  >
                    <span className="text-white">Assistant</span>
                    <div className="text-center">
                      <img
                        src={ask}
                        alt=""
                        style={{ display: "block", margin: "0 auto" }}
                      />
                    </div>
                    <p className="text-white">Ask Murthy</p>
                  </a>
                ) : (
                  <div className={`menu-item me-sm-3 bg-secondary`}>
                    <span className="text-white">Assistant</span>
                    <div className="text-center">
                      <img
                        src={ask}
                        alt=""
                        style={{ display: "block", margin: "0 auto" }}
                      />
                    </div>
                    <p className="text-white">Ask Murthy</p>
                  </div>
                )}
              </div>
            </div>
          </div>
          {/* <!-- /.container --> */}
        </div>
        {/* <!-- /.overlay-content --> */}
      </div>
    </>
  );
};

// export default  withRouter(FooterCompo);
export default FooterCompo;
