import React from 'react'

const MapLocation = (props) => {
    const location = {
       Delhi:"https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d218.86116731671825!2d77.28529697333792!3d28.63639464634692!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1734336417099!5m2!1sen!2sin",
       Thane:"",
    }
  return (
    <div class="ratio ratio-16x9">
    <iframe
      src={location?.[props?.seat] ? location?.[props?.seat] : "https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d218.86116731671825!2d77.28529697333792!3d28.63639464634692!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1734336417099!5m2!1sen!2sin"}
      style={{ border: 0 }}
      allowfullscreen=""
      loading="lazy"
      referrerpolicy="no-referrer-when-downgrade"
    ></iframe>
  </div>
  )
}

export default MapLocation;