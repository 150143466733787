const BengaliTranslation = {
    "Email Security Check": "ইমেল নিরাপত্তা চেক",
    "Verify Your Email for Account Access":
        "অ্যাকাউন্ট অ্যাক্সেসের জন্য আপনার ইমেল যাচাই করুন",
    "To ensure the security of your account, please enter your valid Email ID in the specified format- username@domain.com. If your email address doesn't exist in our system":
        "আপনার অ্যাকাউন্টের নিরাপত্তা নিশ্চিত করতে, অনুগ্রহ করে নির্দিষ্ট ফরম্যাটে আপনার বৈধ ইমেল আইডি লিখুন- username@domain.com। যদি আপনার ইমেল ঠিকানা আমাদের সিস্টেমে বিদ্যমান না থাকে, ক",
    "NEW USER": "নতুন ব্যবহারকারী",
    "account will be created for you": "আপনার জন্য অ্যাকাউন্ট তৈরি করা হবে।",
    "Once submitted, you will receive a Verification OTP (One-Time Password) sent to your provided email address.":
        "একবার জমা দেওয়ার পরে, আপনি আপনার প্রদত্ত ইমেল ঠিকানায় পাঠানো একটি যাচাইকরণ ওটিপি (ওয়ান-টাইম পাসওয়ার্ড) পাবেন।",
    "For any queries or assistance, feel free to call us at +91-9699900111.":
        "যেকোনো প্রশ্ন বা সহায়তার জন্য, আমাদেরকে +91-9699900111 এ কল করুন।",
    "Thank you for entrusting PrivateCourt. Your security is our priority.":
        "প্রাইভেটকোর্টে দায়িত্ব দেওয়ার জন্য আপনাকে ধন্যবাদ। আপনার নিরাপত্তা আমাদের অগ্রাধিকার.",
    "Verify": "যাচাই করুন",
    "Invalid Email Address": "অকার্যকর ইমেইল ঠিকানা",
    "We regret to inform you that the email address entered is invalid. Please ensure that you have entered a correct and properly formatted email address.":
        "আমরা আপনাকে জানাতে দুঃখিত যে প্রবেশ করা ইমেল ঠিকানাটি অবৈধ৷ অনুগ্রহ করে নিশ্চিত করুন যে আপনি একটি সঠিক এবং সঠিকভাবে ফরম্যাট করা ইমেল ঠিকানা প্রবেশ করেছেন।",
    "If the issue persists, double-check for any typos or errors in the email format (e.g., username@example.com)":
        "সমস্যাটি অব্যাহত থাকলে, ইমেল বিন্যাসে (যেমন, username@example.com) কোনো টাইপ বা ত্রুটির জন্য দুবার চেক করুন।",
    "For further queries or assistance, feel free to call us at +91-9699900111":
        "আরও প্রশ্ন বা সহায়তার জন্য, আমাদেরকে +91-9699900111 এ কল করুন।",
    "Secure UDYAM Number Validation": "নিরাপদ উদ্যোগ নম্বর যাচাইকরণ",
    "Enter Your Udyam Number": "আপনার UDYAM নম্বর লিখুন",
    "Please enter your correct UDYAM Number.": "আপনার সঠিক UDYAM নম্বর লিখুন.",
    "Your UDYAM Number allows us to collect crucial details associated with your business. This includes your business address, current status, service details, and organization type.":
        "আপনার UDYAM নম্বর আমাদের আপনার ব্যবসার সাথে সম্পর্কিত গুরুত্বপূর্ণ বিবরণ সংগ্রহ করতে দেয়। এর মধ্যে আপনার ব্যবসার ঠিকানা, বর্তমান অবস্থা, পরিষেবার বিবরণ এবং প্রতিষ্ঠানের ধরন অন্তর্ভুক্ত রয়েছে।",
    "Ensuring the accuracy of your UDYAM Number is vital for a seamless verification process.":
        "আপনার UDYAM নম্বরের যথার্থতা নিশ্চিত করা একটি নির্বিঘ্ন যাচাইকরণ প্রক্রিয়ার জন্য অত্যাবশ্যক।",
    "UDYAM": "UDYAM",
    "Error: Invalid UDYAM Number": "ত্রুটি: অবৈধ UDYAM নম্বর",
    "We regret to inform you that the UDYAM number entered is invalid or incorrect. Please ensure you have entered the correct UDYAM details and double-check for any typos.":
        "আমরা আপনাকে জানাতে দুঃখিত যে প্রবেশ করা UDYAM নম্বরটি অবৈধ বা ভুল৷ অনুগ্রহ করে নিশ্চিত করুন যে আপনি সঠিক UDYAM বিশদ প্রবেশ করেছেন এবং কোনো টাইপ করার জন্য দুবার চেক করুন।",
    "If you continue to face issues, kindly verify your UDYAM certificate and re-enter the details.":
        "আপনি যদি সমস্যার সম্মুখীন হতে থাকেন, অনুগ্রহ করে আপনার UDYAM শংসাপত্র যাচাই করুন এবং বিশদ পুনরায় লিখুন।",
    "Secure PAN Validation Process": "নিরাপদ PAN বৈধকরণ প্রক্রিয়া",
    "This verification process is designed to uphold the integrity of our services, ensuring a reliable and secure experience.":
        "এই যাচাইকরণ প্রক্রিয়াটি আমাদের পরিষেবাগুলির অখণ্ডতা বজায় রাখার জন্য ডিজাইন করা হয়েছে, একটি নির্ভরযোগ্য এবং সুরক্ষিত অভিজ্ঞতা নিশ্চিত করে৷",
    "Enter Your PAN Number": "আপনার PAN নম্বর লিখুন",
    "Please enter your correct PAN number in the format XXXXX-0000-X.":
        "অনুগ্রহ করে আপনার সঠিক PAN নম্বরটি XXXXX-0000-X ফর্ম্যাটে লিখুন৷",
    "PrivateCourt will meticulously collect essential information associated with your PAN such as your full name, date of birth, residential address, and contact details. For Business Owners, we extend our data collection to encompass all pertinent business details tied to your PAN such as GST details, MCA specifics, and records of GST return filings.":
        "PrivateCourt সাবধানতার সাথে আপনার PAN এর সাথে সম্পর্কিত প্রয়োজনীয় তথ্য যেমন আপনার পুরো নাম, জন্ম তারিখ, আবাসিক ঠিকানা এবং যোগাযোগের বিশদ বিবরণ সংগ্রহ করবে। ব্যবসার মালিকদের জন্য, আমরা আপনার PANের সাথে সংযুক্ত সমস্ত প্রাসঙ্গিক ব্যবসার বিবরণ যেমন জিএসটি বিশদ বিবরণ, এমসিএ নির্দিষ্টকরণ এবং জিএসটি রিটার্ন ফাইলিংয়ের রেকর্ডগুলিকে অন্তর্ভুক্ত করতে আমাদের ডেটা সংগ্রহ প্রসারিত করি।",
    "Our system assesses your CIBIL score for financial insight and cross-verifies for any ongoing court cases associated with your PAN.":
        "আমাদের সিস্টেম আর্থিক অন্তর্দৃষ্টির জন্য আপনার CIBIL স্কোর মূল্যায়ন করে এবং আপনার PAN-এর সাথে সম্পর্কিত যেকোন চলমান আদালতের মামলাগুলির জন্য ক্রস-ভেরিফাই করে।",
    "Error: Invalid PAN Format": "ত্রুটি: অবৈধ PAN ফর্ম্যাট৷",
    "We regret to inform you that the PAN number entered does not match the required format (XXXXX-0000-X). Please ensure you have entered the correct PAN details and follow the specified format for accurate verification.":
        "আমরা আপনাকে জানাতে দুঃখিত যে PAN নম্বরটি প্রয়োজনীয় ফর্ম্যাটের (XXXXX-0000-X) সাথে মেলে না৷ অনুগ্রহ করে নিশ্চিত করুন যে আপনি সঠিক PAN বিবরণ প্রবেশ করেছেন এবং সঠিক যাচাইয়ের জন্য নির্দিষ্ট ফর্ম্যাট অনুসরণ করুন।",
    "If you continue to face issues, kindly double-check your PAN card and re-enter the details. For further queries or assistance, feel free to call us at +91-9699900111.":
        "আপনি যদি ক্রমাগত সমস্যার সম্মুখীন হন, অনুগ্রহ করে আপনার PAN কার্ড দুবার চেক করুন এবং বিশদটি পুনরায় লিখুন। আরও প্রশ্ন বা সহায়তার জন্য, আমাদেরকে +91-9699900111 এ কল করুন।",
    "Welcome XXXX": "স্বাগত XXXX",
    "Account security starts with verification":
        "অ্যাকাউন্টের নিরাপত্তা যাচাইকরণের মাধ্যমে শুরু হয়",
    "OTP Validation for Account Access": "অ্যাকাউন্ট অ্যাক্সেসের জন্য OTP বৈধতা",
    "Kindly check your inbox, and enter the OTP in the designated field to complete the verification process.":
        "অনুগ্রহ করে আপনার ইনবক্স চেক করুন, এবং যাচাইকরণ প্রক্রিয়াটি সম্পূর্ণ করতে মনোনীত ক্ষেত্রে OTP লিখুন।",
    "Didn't receive OTP?": "ওটিপি পাননি?",
    "Request a Resend": "একটি পুনরায় পাঠান অনুরোধ",
    "Verify OTP": "OTP যাচাই করুন",
    "Error: Incorrect OTP": "ত্রুটি: ভুল ওটিপি",
    "We apologize for the inconvenience. The One-Time Password (OTP) entered is incorrect. Please ensure that you have accurately entered the OTP received in your email.":
        "অসুবিধার জন্য আমরা ক্ষমাপ্রার্থী। প্রবেশ করা ওয়ান-টাইম পাসওয়ার্ড (OTP) ভুল। অনুগ্রহ করে নিশ্চিত করুন যে আপনি আপনার ইমেলে প্রাপ্ত OTP সঠিকভাবে প্রবেশ করেছেন।",
    "If the issue persists, you may request a new OTP by clicking on the resend link or contacting our support team at +91-9699900111.":
        "যদি সমস্যাটি থেকে যায়, আপনি পুনরায় পাঠান লিঙ্কে ক্লিক করে বা +91-9699900111 এ আমাদের সহায়তা টিমের সাথে যোগাযোগ করে একটি নতুন OTP অনুরোধ করতে পারেন।",
    "Double-check the email and try entering the OTP again.":
        "ইমেলটি দুবার চেক করুন এবং আবার ওটিপি প্রবেশ করার চেষ্টা করুন৷",
    "Choose Profile": "প্রোফাইল নির্বাচন করুন",
    "Setting Your Default Profile": "আপনার ডিফল্ট প্রোফাইল সেট করা",
    "Please choose the default profile for a seamless experience.":
        "একটি নির্বিঘ্ন অভিজ্ঞতার জন্য ডিফল্ট প্রোফাইল চয়ন করুন.",
    "If you manage multiple companies, select the profile you'd like to set as the default. This ensures that every time you log in, you'll have quick access to the information relevant to your preferred company.":
        "আপনি একাধিক কোম্পানি পরিচালনা করলে, আপনি ডিফল্ট হিসাবে সেট করতে চান এমন প্রোফাইল নির্বাচন করুন৷ এটি নিশ্চিত করে যে আপনি যতবার লগ ইন করবেন, আপনার পছন্দের কোম্পানির সাথে প্রাসঙ্গিক তথ্যে দ্রুত অ্যাক্সেস থাকবে।",
    "Making the right selection now simplifies your future logins and provides a tailored experience based on your primary business entity.":
        "এখন সঠিক নির্বাচন করা আপনার ভবিষ্যত লগইনগুলিকে সহজ করে এবং আপনার প্রাথমিক ব্যবসায়িক সত্তার উপর ভিত্তি করে একটি উপযোগী অভিজ্ঞতা প্রদান করে৷",
    "Go to Dashboard": "ড্যাশবোর্ড এ যা",
    "Data Compilation in Progress": "তথ্য সংকলন প্রক্রিয়াধীন: অনুগ্রহ",
    "Please Allow Processing Time": "করে প্রক্রিয়াকরণের সময় মঞ্জুর করুন",
    "Thank you for providing your information. Our system is currently in the process of analyzing and compiling the data you've entered, including details such as PAN, Aadhaar, GST, Email, and more, to establish your comprehensive profile.":
        "আপনার তথ্য প্রদানের জন্য আপনাকে ধন্যবাদ. আপনার বিস্তৃত প্রোফাইল স্থাপন করতে আমাদের সিস্টেম বর্তমানে PAN, আধার, জিএসটি, ইমেল এবং আরও অনেক কিছু সহ আপনার প্রবেশ করা ডেটা বিশ্লেষণ এবং সংকলনের প্রক্রিয়াধীন রয়েছে।",
    "This operation may take a few moments. During this time, your screen may show minimal activity. Please be assured that this is a routine part of our data compilation process.":
        "এই অপারেশন কয়েক মুহূর্ত লাগতে পারে. এই সময়ে, আপনার স্ক্রিন ন্যূনতম কার্যকলাপ দেখাতে পারে। অনুগ্রহ করে নিশ্চিত হন যে এটি আমাদের ডেটা সংকলন প্রক্রিয়ার একটি নিয়মিত অংশ।",
    "Your profile is being meticulously crafted to uphold accuracy and security. We sincerely appreciate your patience and understanding as we work to enhance your experience.":
        "নির্ভুলতা এবং নিরাপত্তা বজায় রাখার জন্য আপনার প্রোফাইলটি যত্ন সহকারে তৈরি করা হচ্ছে। আমরা আন্তরিকভাবে আপনার ধৈর্য এবং বোঝার প্রশংসা করি কারণ আমরা আপনার অভিজ্ঞতা বাড়াতে কাজ করি৷",
    "Start Your Bank Account Verification":
        "আপনার ব্যাঙ্ক অ্যাকাউন্ট যাচাইকরণ শুরু করুন",
    "Bank Verification": "ব্যাংক যাচাইকরণ",
    "Ensuring the accuracy of your bank account information is crucial for a seamless verification process. Kindly enter your valid Account Number, and input the correct IFSC code associated with your account.":
        "আপনার ব্যাঙ্ক অ্যাকাউন্টের তথ্যের যথার্থতা নিশ্চিত করা একটি নির্বিঘ্ন যাচাইকরণ প্রক্রিয়ার জন্য অত্যন্ত গুরুত্বপূর্ণ। অনুগ্রহ করে আপনার অ্যাকাউন্ট নম্বর লিখুন, এবং আপনার অ্যাকাউন্টের সাথে যুক্ত সঠিক IFSC কোড ইনপুট করুন।",
    "This verification step is designed to enhance the security of our services and provide you with a reliable experience.":
        "এই যাচাইকরণ পদক্ষেপটি আমাদের পরিষেবাগুলির নিরাপত্তা বাড়ানোর জন্য এবং আপনাকে একটি নির্ভরযোগ্য অভিজ্ঞতা প্রদানের জন্য ডিজাইন করা হয়েছে৷",
    "Account Number": "অ্যাকাউন্ট নম্বর",
    "IFSC code": "IFSC কোড",
    "Error: Invalid Bank Account or IFSC Code":
        "ত্রুটি: অবৈধ ব্যাঙ্ক অ্যাকাউন্ট বা IFSC কোড৷",
    "We regret to inform you that the Bank Account or IFSC Code entered is invalid or incorrect. Please ensure you have accurately entered both details and double-check for any typos.":
        "আমরা আপনাকে জানাতে দুঃখিত যে ব্যাঙ্ক অ্যাকাউন্ট বা IFSC কোডটি অবৈধ বা ভুল। অনুগ্রহ করে নিশ্চিত করুন যে আপনি বিশদ বিবরণ উভয়ই সঠিকভাবে প্রবেশ করেছেন এবং যেকোন টাইপোর জন্য দুবার চেক করুন।",
    "If you continue to face issues, kindly verify your bank statement or contact your bank to confirm the accuracy of the provided information.":
        "আপনি যদি ক্রমাগত সমস্যার সম্মুখীন হন, অনুগ্রহ করে আপনার ব্যাঙ্ক স্টেটমেন্ট যাচাই করুন বা প্রদত্ত তথ্যের যথার্থতা নিশ্চিত করতে আপনার ব্যাঙ্কের সাথে যোগাযোগ করুন।",
    "Aadhaar Details Verification": "ଆଧାର ବିବରଣୀ ଯାଞ୍ଚ |",
    "Enter Your Aadhaar Number": "আপনার আধার নম্বর লিখুন",
    "Please input your Aadhaar number. This process ensures accurate cross-checking for your security. Thank you for your cooperation.":
        "অনুগ্রহ করে আপনার আধার নম্বর ইনপুট করুন। এই প্রক্রিয়াটি আপনার নিরাপত্তার জন্য সঠিক ক্রস-চেকিং নিশ্চিত করে। আপনার সহযোগিতার জন্য ধন্যবাদ।",
    "Enter Your Aadhaar OTP Here": "এখানে আপনার আধার ওটিপি লিখুন",
    "Error: Incorrect Aadhaar OTP": "ত্রুটি: ভুল আধার ওটিপি",
    "We regret to inform you that the One-Time Password (OTP) entered for Aadhaar verification is incorrect. Please ensure that you have accurately entered the OTP received on your registered mobile number.":
        "আমরা আপনাকে জানাতে দুঃখিত যে আধার যাচাইকরণের জন্য দেওয়া ওয়ান-টাইম পাসওয়ার্ড (OTP) ভুল। অনুগ্রহ করে নিশ্চিত করুন যে আপনি আপনার নিবন্ধিত মোবাইল নম্বরে প্রাপ্ত OTP সঠিকভাবে প্রবেশ করেছেন।",
    "If the issue persists, you may request a new OTP by clicking on the resend link or contacting our support team at +91-9699900111.":
        "যদি সমস্যাটি থেকে যায়, আপনি পুনরায় পাঠান লিঙ্কে ক্লিক করে বা +91-9699900111 এ আমাদের সহায়তা টিমের সাথে যোগাযোগ করে একটি নতুন OTP অনুরোধ করতে পারেন।",
    "Double-check the email and try entering the OTP again.":
        "ଇମେଲକୁ ଦୁଇଥର ଯାଞ୍ଚ କରନ୍ତୁ ଏବଂ ପୁନର୍ବାର OTP ପ୍ରବେଶ କରିବାକୁ ଚେଷ୍ଟା କରନ୍ତୁ |",
    "Error: Invalid Aadhaar Number": "ত্রুটি: অবৈধ আধার নম্বর",
    "We apologize for the inconvenience. The Aadhaar number entered is invalid. Please double-check and ensure that you have entered the correct 12-digit Aadhaar number.":
        "অসুবিধার জন্য আমরা ক্ষমাপ্রার্থী। প্রবেশ করা আধার নম্বরটি অবৈধ৷ অনুগ্রহ করে দুবার চেক করুন এবং নিশ্চিত করুন যে আপনি সঠিক 12-সংখ্যার আধার নম্বরটি প্রবেশ করেছেন।",
    "If you continue to face issues, verify your Aadhaar card or contact our support team at +91-9699900111 for further assistance. We appreciate your cooperation.":
        "আপনি যদি ক্রমাগত সমস্যার সম্মুখীন হন, আপনার আধার কার্ড যাচাই করুন বা আরও সহায়তার জন্য +91-9699900111 এ আমাদের সহায়তা দলের সাথে যোগাযোগ করুন। আমরা আপনার সহযোগিতার প্রশংসা করি।",
    "Language Selection:": "ভাষা নির্বাচন: ",
    "Empowering Effective Communication": "কার্যকর যোগাযোগের ক্ষমতায়ন",
    "Good day! I'm Murthy, your dedicated assistant for today's proceedings. Lets start by selecting the language you're most comfortable with.":
        "শুভ দিন! আমি মূর্তি, আজকের কার্যধারার জন্য আপনার নিবেদিত সহকারী। আপনি যে ভাষাটির সাথে সবচেয়ে স্বাচ্ছন্দ্য বোধ করেন তা নির্বাচন করে শুরু করা যাক।",
    "Opting for your preferred language ensures clear and effective communication. Whether it's your native tongue or another language of your choice, selecting it will streamline our interaction and enhance your experience.":
        "আপনার পছন্দের ভাষা বেছে নেওয়া পরিষ্কার এবং কার্যকর যোগাযোগ নিশ্চিত করে। এটি আপনার মাতৃভাষা বা আপনার পছন্দের অন্য ভাষা হোক না কেন, এটি নির্বাচন করা আমাদের মিথস্ক্রিয়াকে প্রবাহিত করবে এবং আপনার অভিজ্ঞতা বাড়াবে।",
    "Select Your Preferred Language": "আপনার পছন্দের ভাষা নির্বাচন করুন",
    "Welcome to PrivateCourt, where Helping you is our priority.":
        "প্রাইভেটকোর্টে স্বাগতম, যেখানে আপনাকে সাহায্য করা আমাদের অগ্রাধিকার।",
    "Now, let me introduce you to PrivateCourt – your reliable partner in resolving lending disputes effectively and efficiently.":
        "এখন, আমি আপনাকে প্রাইভেটকোর্ট-এর সাথে পরিচয় করিয়ে দিই - কার্যকরভাবে এবং দক্ষতার সাথে ঋণ সংক্রান্ত বিরোধ নিষ্পত্তিতে আপনার নির্ভরযোগ্য অংশীদার।",
    "PrivateCourt:": "প্রাইভেটকোর্ট:",
    "Your trusted partner in resolving lending disputes with care and expertise.":
        "যত্ন এবং দক্ষতার সাথে ঋণ সংক্রান্ত বিরোধ সমাধানে আপনার বিশ্বস্ত অংশীদার।",
    "Welcome to PrivateCourt, where your case details are in good hands. With the lending company initiating arbitration or conciliation, it's essential for us to understand your situation fully. Please share all the details you can.":
        "প্রাইভেটকোর্টে স্বাগতম, যেখানে আপনার মামলার বিবরণ ভাল হাতে রয়েছে। ঋণদাতা কোম্পানি সালিশ বা সমঝোতা শুরু করার সাথে সাথে, আপনার পরিস্থিতি সম্পূর্ণরূপে বোঝা আমাদের জন্য অপরিহার্য। আপনি পারেন সব বিবরণ শেয়ার করুন.",
    "The more information we have, the better we can help. Your input is crucial in finding the right solution for you.":
        "আমাদের কাছে যত বেশি তথ্য আছে, আমরা তত ভালো সাহায্য করতে পারি। আপনার জন্য সঠিক সমাধান খুঁজে পেতে আপনার ইনপুট অত্যন্ত গুরুত্বপূর্ণ।",
    "Trust us to carefully examine your case and work together to find the best way forward.":
        "আপনার কেসটি সাবধানে পরীক্ষা করার জন্য আমাদের বিশ্বাস করুন এবং এগিয়ে যাওয়ার সর্বোত্তম উপায় খুঁজতে একসাথে কাজ করুন।",
    "Reviewing Your Dispute Information": "আপনার বিরোধ তথ্য পর্যালোচনা",
    "Let's take a closer look at the details surrounding your dispute. The information we're discussing today has been shared with us by LendingKart, regarding a personal loan.":
        "আসুন আপনার বিবাদের চারপাশের বিশদ বিবরণগুলি ঘনিষ্ঠভাবে দেখে নেওয়া যাক৷ আজ আমরা যে তথ্য নিয়ে আলোচনা করছি তা আমাদের সাথে শেয়ার করা হয়েছে LendingKart, একটি ব্যক্তিগত ঋণ সংক্রান্ত।",
    "By understanding these specifics, we can better assess the situation and formulate a resolution plan that aligns with your circumstances and goals.":
        "এই সুনির্দিষ্ট বিষয়গুলি বোঝার মাধ্যমে, আমরা পরিস্থিতিটি আরও ভালভাবে মূল্যায়ন করতে পারি এবং একটি রেজোলিউশন পরিকল্পনা তৈরি করতে পারি যা আপনার পরিস্থিতি এবং লক্ষ্যগুলির সাথে সামঞ্জস্য করে।",
    "Dispute Amount Information": "বিবাদ পরিমাণ তথ্য",
    "According to their records, you initially borrowed an amount of":
        "তাদের রেকর্ড অনুযায়ী, আপনি প্রাথমিকভাবে একটি পরিমাণ ধার নিয়েছেন ",
    "Presently, your outstanding due stands at Rs.":
        "বর্তমানে, আপনার বকেয়া টাকা দাঁড়িয়েছে ",
    "These details provide us with valuable insight into the history and current status of your loan.":
        "এই বিবরণগুলি আমাদের আপনার ঋণের ইতিহাস এবং বর্তমান অবস্থা সম্পর্কে মূল্যবান অন্তর্দৃষ্টি প্রদান করে।",
    "Spotting Financial Issues: Understanding Unpaid Obligations":
        "আর্থিক সমস্যাগুলি চিহ্নিত করা: অবৈতনিক বাধ্যবাধকতা বোঝা",
    "Now, let's gently explore the challenges you've faced due to financial constraints. Discussing this may be sensitive, but your openness will greatly assist us in finding the best path forward. Please take a moment to select any of the following options that resonate with your experience:":
        "এখন, আর্থিক সীমাবদ্ধতার কারণে আপনি যে চ্যালেঞ্জগুলির মুখোমুখি হয়েছেন তা আস্তে আস্তে অন্বেষণ করা যাক। এটি আলোচনা করা সংবেদনশীল হতে পারে, কিন্তু আপনার খোলামেলাতা আমাদের এগিয়ে যাওয়ার সেরা পথ খুঁজে পেতে ব্যাপকভাবে সহায়তা করবে। আপনার অভিজ্ঞতার সাথে অনুরণিত নিম্নলিখিত বিকল্পগুলির মধ্যে যেকোনো একটি নির্বাচন করতে অনুগ্রহ করে কিছুক্ষণ সময় নিন:",
    "Financial Loss": "আর্থিক ক্ষতি",
    "Business Loss": "ব্যবসায়িক ক্ষতি",
    "Partner Dispute": "অংশীদার বিবাদ",
    "Funds held with other vendors or clients":
        "অন্যান্য বিক্রেতা বা ক্লায়েন্টদের সাথে রাখা তহবিল",
    "Monetary loss through other means": "অন্যান্য উপায়ে আর্থিক ক্ষতি",
    "Job Loss": "চাকরির ক্ষতি",
    "Less than 2 months": "2 মাসেরও কম",
    "Less than 6 months": "৬ মাসের কম",
    "Less than 1 year": "১ বছরের কম",
    "More than 1 year": "১ বছরের বেশি",
    "Health Issue": "স্বাস্থ্য সংক্রান্ত",
    "Death of the earning member": "উপার্জনকারী সদস্যের মৃত্যু",
    "Missing payment details": "অনুপস্থিত পেমেন্ট বিবরণ",
    "Dispute due to bad behaviour": "খারাপ আচরণের কারণে বিবাদ",
    "No clear loan information": "কোন সুস্পষ্ট ঋণ তথ্য",
    "Death Case": "মৃত্যু মামলা",
    "We kindly request you to upload the death certificate at":
        "এ মৃত্যুর শংসাপত্র আপলোড করার জন্য অনুরোধ করছি",
    "Other reason/s": "অন্য কারণ/গুলি",
    "Your prompt response will greatly assist us in assessing the situation and finding the most suitable resolution. Rest assured, we are committed to working with you to resolve any outstanding issues and ensure a fair and satisfactory outcome.":
        "আপনার দ্রুত প্রতিক্রিয়া পরিস্থিতি মূল্যায়ন করতে এবং সবচেয়ে উপযুক্ত রেজোলিউশন খুঁজে পেতে আমাদের ব্যাপকভাবে সহায়তা করবে। নিশ্চিন্ত থাকুন, আমরা যেকোন অসামান্য সমস্যার সমাধান করতে এবং একটি ন্যায্য ও সন্তোষজনক ফলাফল নিশ্চিত করতে আপনার সাথে কাজ করতে প্রতিশ্রুতিবদ্ধ।",
    "Exploring Settlement Possibilities": "নিষ্পত্তি সম্ভাবনা অন্বেষণ",
    "As our discussion progresses, I appreciate your openness in explaining the reason for the default. It's crucial to address your outstanding dues promptly to prevent any legal complexities. We recommend exploring different avenues, such as seeking assistance from family or investigating alternative financing options, to fulfill your obligations without delay.":
        "আমাদের আলোচনার অগ্রগতির সাথে সাথে, আমি ডিফল্টের কারণ ব্যাখ্যা করার ক্ষেত্রে আপনার খোলামেলাতার প্রশংসা করি। কোনো আইনি জটিলতা এড়াতে অবিলম্বে আপনার বকেয়া পাওনা পরিশোধ করা অত্যন্ত গুরুত্বপূর্ণ। আমরা দেরি না করে আপনার বাধ্যবাধকতা পূরণের জন্য বিভিন্ন উপায় অন্বেষণ করার পরামর্শ দিই, যেমন পরিবারের কাছ থেকে সহায়তা চাওয়া বা বিকল্প অর্থায়নের বিকল্পগুলি অনুসন্ধান করা।",
    "Timely settlement not only mitigates legal risks but also strengthens trust and credibility in your financial interactions. Your cooperation in this matter is truly valued.":
        "সময়মত নিষ্পত্তি শুধুমাত্র আইনি ঝুঁকি প্রশমিত করে না বরং আপনার আর্থিক মিথস্ক্রিয়ায় বিশ্বাস এবং বিশ্বাসযোগ্যতাকে শক্তিশালী করে। এই বিষয়ে আপনার সহযোগিতা সত্যিই মূল্যবান.",
    "Settlement Options": "নিষ্পত্তি বিকল্প",
    "In our efforts to facilitate a swift resolution, we seek your cooperation.":
        "একটি দ্রুত রেজোলিউশনের সুবিধার্থে আমাদের প্রচেষ্টায়, আমরা আপনার সহযোগিতা চাই।",
    "Are you open to discussing the settlement of your outstanding dues?":
        "আপনি কি আপনার বকেয়া পাওনা নিষ্পত্তির বিষয়ে আলোচনার জন্য উন্মুক্ত?",
    "We appreciate your openness to resolving your outstanding dues. To better understand your financial situation and tailor our approach accordingly,":
        "আমরা আপনার বকেয়া বকেয়া সমাধানের জন্য আপনার খোলামেলাতার প্রশংসা করি। আপনার আর্থিক পরিস্থিতি আরও ভালভাবে বোঝার জন্য এবং সেই অনুযায়ী আমাদের দৃষ্টিভঙ্গি তৈরি করতে,",
    "could you kindly provide details on your current family income?":
        "আপনি কি দয়া করে আপনার বর্তমান পারিবারিক আয়ের বিবরণ দিতে পারেন?",
    "In our efforts to address your outstanding dues, we'd like to know if a":
        "আপনার বকেয়া পাওনা পরিশোধ করার জন্য আমাদের প্রচেষ্টায়, আমরা জানতে চাই",
    "one-time payment settlement is an option for you.":
        "যে আপনার জন্য এককালীন অর্থপ্রদান নিষ্পত্তি একটি বিকল্প কিনা।",
    "We appreciate your feedback. If a one-time payment is not viable,":
        "আমরা আপনার প্রতিক্রিয়া কৃতজ্ঞ। যদি এককালীন অর্থপ্রদান কার্যকর না হয়, ",
    "could you consider arranging payment in three equal instalments?":
        "আপনি কি তিনটি সমান কিস্তিতে অর্থপ্রদানের ব্যবস্থা করার কথা বিবেচনা করতে পারেন?",
    "This approach could help ease any immediate financial strain while still addressing the outstanding dues.":
        "এখনও বকেয়া বকেয়া মোকাবেলা করার সময় এই পদ্ধতিটি যে কোনও তাত্ক্ষণিক আর্থিক চাপ কমাতে সাহায্য করতে পারে।",
    "Yes": "হ্যাঁ",
    "Next": "",
    "I am unable to pay by any means": "আমি কোনোভাবেই টাকা দিতে পারছি না",
    "Amount with Yes": "হ্যাঁ সহ পরিমাণ",
    "No": "না",
    "Please Select": "অনুগ্রহ করে নির্বাচন করুন",
    "We Appreciate Your Honesty.": "আমরা আপনার সততার প্রশংসা করি।",
    "No Income": "কোন আয়",
    "less than Rs. 10000/- per month": "টাকার কম 10000/- প্রতি মাসে",
    "less than Rs. 20,000/- per month": "টাকার কম 20000/- প্রতি মাসে",
    "less than Rs. 40,000/- per month": "টাকার কম 40000/- প্রতি মাসে",
    "less than Rs. 60,000/- per month": "টাকার কম 60000/- প্রতি মাসে",
    "more than Rs. 1,00,000/- per month": "টাকার বেশি 1,00,000/- প্রতি মাসে",

    // newly added

    "Please ensure you have entered the correct GST details and follow the specified format for accurate verification.":
        "অনুগ্রহ করে নিশ্চিত করুন যে আপনি সঠিক GST বিবরণ প্রবেশ করেছেন এবং সঠিক যাচাইয়ের জন্য নির্দিষ্ট বিন্যাস অনুসরণ করুন।",
    "If you continue to face issues, kindly double-check your PAN card and re-enter the details.":
        "আপনি যদি ক্রমাগত সমস্যার সম্মুখীন হন, অনুগ্রহ করে আপনার PAN কার্ড দুবার চেক করুন এবং বিশদটি পুনরায় লিখুন।",
    "The GST provided already exists in our records. Please try using a different GST for verification.":
        "প্রদত্ত জিএসটি আমাদের রেকর্ডে ইতিমধ্যেই বিদ্যমান। যাচাইকরণের জন্য অনুগ্রহ করে একটি ভিন্ন GST ব্যবহার করার চেষ্টা করুন।",
    "It appears that the PAN details provided belong to a company. We require your personal PAN details for verification purposes.":
        "মনে হচ্ছে যে PANের বিবরণ দেওয়া হয়েছে তা একটি কোম্পানির। যাচাইকরণের উদ্দেশ্যে আমাদের আপনার ব্যক্তিগত PAN বিবরণ প্রয়োজন।",
    "If you continue to face issues, kindly double-check your PAN card and re-enter the details. For further queries or assistance, feel free to call us at +91-9699900111.":
        "আপনি যদি ক্রমাগত সমস্যার সম্মুখীন হন, অনুগ্রহ করে আপনার PAN কার্ড দুবার চেক করুন এবং বিশদটি পুনরায় লিখুন। আরও প্রশ্ন বা সহায়তার জন্য, আমাদেরকে +91-9699900111 এ কল করুন।",
    "The PAN provided already exists in our records. Please try using a different PAN for verification.":
        "প্রদত্ত PAN আমাদের রেকর্ডে ইতিমধ্যেই বিদ্যমান। যাচাইকরণের জন্য অনুগ্রহ করে একটি ভিন্ন PAN ব্যবহার করার চেষ্টা করুন।",
    "New User Registration": "নতুন ব্যবহারকারীর জন্য নিবন্ধন",
    "To begin your journey with us, we kindly request all new users to register by following the simple steps outlined below:":
        "আমাদের সাথে আপনার যাত্রা শুরু করার জন্য, আমরা অনুগ্রহ করে সমস্ত নতুন ব্যবহারকারীদের নীচে বর্ণিত সহজ পদক্ষেপগুলি অনুসরণ করে নিবন্ধন করার জন্য অনুরোধ করছি:",
    "Registration": "নিবন্ধন",
    "To register and gain access to our comprehensive verification services, please proceed by making a one-time payment of":
        "নিবন্ধন করতে এবং আমাদের ব্যাপক যাচাইকরণ পরিষেবাগুলিতে অ্যাক্সেস পেতে, অনুগ্রহ করে এককালীন অর্থপ্রদান করে এগিয়ে যান",
    "Rs. 500": "টাকা ৫০০",
    "GST": "জিএসটি",
    "done": "সম্পন্ন",
    "Please note that the registration fee is subject to 18% GST (Goods and Services Tax) as per government regulations.":
        "অনুগ্রহ করে মনে রাখবেন যে সরকারী প্রবিধান অনুযায়ী নিবন্ধন ফি 18% জিএসটি (পণ্য ও পরিষেবা কর) সাপেক্ষে।",
    "Total Amount Due:": "সর্বমোট বাকির পরিমাণ:",
    "Rs. 590": "টাকা ৫৯০",
    "Total Registration Fee + 18% GST": "মোট রেজিস্ট্রেশন ফি + 18% GST",
    "Secure Payment Gateway": "নিরাপদ পেমেন্ট গেটওয়ে",
    "Rest assured, your payment and personal information are handled with the utmost security through our encrypted payment gateway.":
        "নিশ্চিন্ত থাকুন, আপনার পেমেন্ট এবং ব্যক্তিগত তথ্য আমাদের এনক্রিপ্ট করা পেমেন্ট গেটওয়ের মাধ্যমে সর্বোচ্চ নিরাপত্তার সাথে পরিচালনা করা হয়।",
    "Registration Complete - Thank You!": "নিবন্ধন সম্পূর্ণ - আপনাকে ধন্যবাদ!",
    "Congratulations! You've completed the registration process. Your dedication to providing the necessary information has brought us one step closer to serving you effectively.":
        "অভিনন্দন! আপনি নিবন্ধন প্রক্রিয়া সম্পন্ন করেছেন. প্রয়োজনীয় তথ্য প্রদানের জন্য আপনার নিবেদন আমাদেরকে কার্যকরভাবে আপনাকে পরিবেশন করার এক ধাপ কাছাকাছি নিয়ে এসেছে।",
    "With your registration finalized, our team is fully equipped to handle your requirements efficiently. We assure you that your case will be handled with the utmost care and attention to detail.":
        "আপনার নিবন্ধন চূড়ান্ত হওয়ার সাথে সাথে, আমাদের দল আপনার প্রয়োজনীয়তাগুলি দক্ষতার সাথে পরিচালনা করতে সম্পূর্ণরূপে সজ্জিত। আমরা আপনাকে আশ্বাস দিচ্ছি যে আপনার কেসটি অত্যন্ত যত্ন সহকারে এবং বিস্তারিত মনোযোগ দিয়ে পরিচালনা করা হবে।",
    "Should you ever require assistance or have any queries, please feel free to contact us at any time. We are here to address your concerns and ensure a smooth journey throughout the process.":
        "আপনার যদি কখনও সহায়তার প্রয়োজন হয় বা কোন প্রশ্ন থাকে, অনুগ্রহ করে যেকোনো সময় আমাদের সাথে নির্দ্বিধায় যোগাযোগ করুন। আমরা আপনার উদ্বেগের সমাধান করতে এবং পুরো প্রক্রিয়া জুড়ে একটি মসৃণ যাত্রা নিশ্চিত করতে এখানে আছি।",
    "We eagerly anticipate the opportunity to assist you further.":
        "আমরা সাগ্রহে আপনাকে আরও সাহায্য করার সুযোগের প্রত্যাশা করছি।",
    "Warm regards,": "উষ্ণ শুভেচ্ছা,",
    "PrivateCourt Team": "প্রাইভেটকোর্ট টিম",
    "Secure UDYAM Number Validation": "নিরাপদ UDYAM নম্বর যাচাইকরণ",
    "Enter UDYAM for": "জজন্য UDYAM লিখুন",
    "having GST number": "জিএসটি নম্বর থাকা",
    "Enter Your Udyam Number": "আপনার UDYAM নম্বর লিখুন",
    "Error: Invalid UAM Number": "ত্রুটি: অবৈধ UAM নম্বর",
    "We regret to inform you that the UAM Number entered is invalid or incorrect. Please ensure you have accurately entered detail and double-check for any typos.":
        "আমরা আপনাকে জানাতে দুঃখিত যে প্রবেশ করা UAM নম্বরটি অবৈধ বা ভুল। অনুগ্রহ করে নিশ্চিত করুন যে আপনি সঠিকভাবে বিস্তারিত লিখেছেন এবং কোনো টাইপোর জন্য দুবার চেক করুন।",
    "Server Overload: Please Retry Later":
        "সার্ভার ওভারলোড: পরে আবার চেষ্টা করুন",
    "We apologize for the inconvenience and appreciate your patience. Currently, our server is experiencing a surge in requests, creating a temporary delay. Rest assured, we are diligently collecting your data to ensure accuracy and security.":
        "আমরা অসুবিধার জন্য দুঃখিত এবং আপনার ধৈর্যের প্রশংসা করছি। বর্তমানে, আমাদের সার্ভারটি অনুরোধের একটি বৃদ্ধির সম্মুখীন হচ্ছে, একটি অস্থায়ী বিলম্ব তৈরি করছে। নিশ্চিন্ত থাকুন, নির্ভুলতা এবং নিরাপত্তা নিশ্চিত করতে আমরা আন্তরিকভাবে আপনার ডেটা সংগ্রহ করছি।",
    "Please bear with us during this busy period, and we kindly request you to wait for a brief moment before attempting again. Your understanding is crucial in maintaining the quality of service we strive to provide.":
        "এই ব্যস্ত সময়ের মধ্যে আমাদের সাথে সহ্য করুন, এবং আমরা দয়া করে আপনাকে আবার চেষ্টা করার আগে একটি সংক্ষিপ্ত মুহূর্ত অপেক্ষা করার জন্য অনুরোধ করছি। আমরা যে পরিষেবা দেওয়ার চেষ্টা করি তার মান বজায় রাখতে আপনার বোঝাপড়া অত্যন্ত গুরুত্বপূর্ণ।",
    "For urgent matters or assistance, feel free to call us at +91-9699900111.":
        "জরুরী বিষয় বা সহায়তার জন্য, নির্দ্বিধায় আমাদের +91-9699900111 এ কল করুন।",
    "Thank you for your patience and entrusting PrivateCourt. Your security is our priority.":
        "আপনার ধৈর্য এবং প্রাইভেটকোর্টে দায়িত্ব দেওয়ার জন্য আপনাকে ধন্যবাদ। আপনার নিরাপত্তা আমাদের অগ্রাধিকার.",
    "If you continue to face issues, verify the":
        "আপনি যদি সমস্যার সম্মুখীন হতে থাকেন, তাহলে যাচাই করুন",
    "Applicant’s": "আবেদনকারীদের",
    "Error: Invalid OTP": "ত্রুটি: অবৈধ OTP",
    "We apologize for the inconvenience. The OTP entered is incorrect. Please double-check the OTP and try again.":
        "অসুবিধার জন্য আমরা ক্ষমাপ্রার্থী। প্রবেশ করা OTP ভুল. অনুগ্রহ করে OTP দুবার চেক করুন এবং আবার চেষ্টা করুন।",
    "Aadhaar card or contact our support team at +91-9699900111 for further assistance. We appreciate your cooperation.":
        "আরও সহায়তার জন্য আধার কার্ড বা +91-9699900111 এ আমাদের সহায়তা দলের সাথে যোগাযোগ করুন। আমরা আপনার সহযোগিতার প্রশংসা করি।",
    "Error: User already exists": "ত্রুটি: ব্যবহারকারী ইতিমধ্যেই বিদ্যমান৷",
    "We apologize for the inconvenience. The Aadhaar number provided already exists in our records. Please provide a different Aadhaar number for verification.":
        "অসুবিধার জন্য আমরা ক্ষমাপ্রার্থী। প্রদত্ত আধার নম্বরটি আমাদের রেকর্ডে ইতিমধ্যেই বিদ্যমান। যাচাইকরণের জন্য অনুগ্রহ করে একটি ভিন্ন আধার নম্বর প্রদান করুন।",
    "We apologize for the inconvenience. The name provided does not match the PAN records. Please ensure that the name entered matches the information on your PAN card and try again.":
        "অসুবিধার জন্য আমরা ক্ষমাপ্রার্থী। প্রদত্ত নাম PAN রেকর্ডের সাথে মেলে না। দয়া করে নিশ্চিত করুন যে প্রবেশ করা নামটি আপনার PAN কার্ডের তথ্যের সাথে মেলে এবং আবার চেষ্টা করুন।",
    "Enter Bank Details for": "এর জন্য ব্যাঙ্কের বিবরণ লিখুন",
    "Error: Already Exists": "ত্রুটি: ইতিমধ্যেই বিদ্যমান৷",
    "We regret to inform you that the ID you've provided already exists in our system. Please try another ID.":
        "আমরা আপনাকে জানাতে দুঃখিত যে আপনার দেওয়া আইডিটি আমাদের সিস্টেমে আগে থেকেই আছে। অনুগ্রহ করে অন্য একটি আইডি ব্যবহার করে দেখুন।",
    "If you continue to face issues, kindly verify your bank statement or contact your bank to confirm the accuracy of the provided information.":
        "আপনি যদি ক্রমাগত সমস্যার সম্মুখীন হন, অনুগ্রহ করে আপনার ব্যাঙ্ক স্টেটমেন্ট যাচাই করুন বা প্রদত্ত তথ্যের যথার্থতা নিশ্চিত করতে আপনার ব্যাঙ্কের সাথে যোগাযোগ করুন।",
    "Error: Name Not Matched": "ত্রুটি: নাম মিলছে না",
    "Good Afternoon": "শুভ অপরাহ্ন",
    "Account security starts with verification":
        "অ্যাকাউন্টের নিরাপত্তা যাচাইকরণের মাধ্যমে শুরু হয়",
    "OTP Validation for Account Access": "অ্যাকাউন্ট অ্যাক্সেসের জন্য OTP বৈধতা",
    "Kindly check your inbox, and enter the OTP in the designated field to complete the verification process.":
        "অনুগ্রহ করে আপনার ইনবক্স চেক করুন, এবং যাচাইকরণ প্রক্রিয়াটি সম্পূর্ণ করতে মনোনীত ক্ষেত্রে OTP লিখুন।",
    "Request a Resend": "একটি পুনরায় পাঠান অনুরোধ",
    "Error: Incorrect OTP": "ত্রুটি: ভুল OTP",
    "We apologize for the inconvenience. The One-Time Password (OTP) entered is incorrect. Please ensure that you have accurately entered the OTP received in your email.":
        "অসুবিধার জন্য আমরা ক্ষমাপ্রার্থী। প্রবেশ করা ওয়ান-টাইম পাসওয়ার্ড (OTP) ভুল। অনুগ্রহ করে নিশ্চিত করুন যে আপনি আপনার ইমেলে প্রাপ্ত OTP সঠিকভাবে প্রবেশ করেছেন।",
    "If the issue persists, you may request a new OTP by clicking on the resend link or contacting our support team  at +91-9699900111.":
        "যদি সমস্যাটি থেকে যায়, আপনি পুনরায় পাঠান লিঙ্কে ক্লিক করে বা +91-9699900111 এ আমাদের সহায়তা টিমের সাথে যোগাযোগ করে একটি নতুন OTP অনুরোধ করতে পারেন।",
    "Double-check the email and try entering the OTP again.":
        "ইমেলটি দুবার চেক করুন এবং আবার ওটিপি প্রবেশ করার চেষ্টা করুন৷",
    "At PrivateCourt, we prioritize the security and integrity of our services. Our GST verification process is meticulously designed to ensure a reliable and secure experience for all our users.":
        "প্রাইভেটকোর্ট-এ, আমরা আমাদের পরিষেবাগুলির নিরাপত্তা এবং অখণ্ডতাকে অগ্রাধিকার দিই৷ আমাদের GST যাচাইকরণ প্রক্রিয়াটি আমাদের সমস্ত ব্যবহারকারীদের জন্য একটি নির্ভরযোগ্য এবং সুরক্ষিত অভিজ্ঞতা নিশ্চিত করার জন্য সতর্কতার সাথে ডিজাইন করা হয়েছে।",
    "Enter Your GSTIN": "আপনার GSTIN লিখুন",
    "Please enter your correct GST Identification Number (GSTIN) in the format 00-XXXXX-0000-X-0-X-0.":
        "অনুগ্রহ করে আপনার সঠিক GST আইডেন্টিফিকেশন নম্বর (GSTIN) 00-XXXXX-0000-X-0-X-0 ফর্ম্যাটে লিখুন।",
    "Upon entering your GSTIN, PrivateCourt will diligently collect essential information associated with it. This includes your full name, date of birth, residential address, and contact details. For business owners, we extend our data collection to encompass all pertinent business details tied to your GSTIN, such as MCA specifics and records of GST return filings.":
        "আপনার GSTIN প্রবেশ করার পরে, প্রাইভেটকোর্ট অধ্যবসায়ের সাথে এর সাথে সম্পর্কিত প্রয়োজনীয় তথ্য সংগ্রহ করবে। এতে আপনার পুরো নাম, জন্ম তারিখ, আবাসিক ঠিকানা এবং যোগাযোগের বিশদ অন্তর্ভুক্ত রয়েছে। ব্যবসার মালিকদের জন্য, আমরা আপনার জিএসটিআইএন-এর সাথে সংযুক্ত সমস্ত প্রাসঙ্গিক ব্যবসার বিবরণ, যেমন এমসিএ স্পেসিফিকেশন এবং জিএসটি রিটার্ন ফাইলিংয়ের রেকর্ড অন্তর্ভুক্ত করার জন্য আমাদের ডেটা সংগ্রহ প্রসারিত করি।",
    "Our system assesses your CIBIL score for financial insight and cross-verifies for any ongoing court cases associated with your GSTIN.":
        "আমাদের সিস্টেম আর্থিক অন্তর্দৃষ্টির জন্য আপনার CIBIL স্কোর মূল্যায়ন করে এবং আপনার GSTIN-এর সাথে সম্পর্কিত যেকোন চলমান আদালতের মামলাগুলির জন্য ক্রস-ভেরিফাই করে।",
    "Error: Invalid GST Format": "ত্রুটি: অবৈধ GST ফর্ম্যাট৷",
    "Please enter your personal PAN": "অনুগ্রহ করে আপনার ব্যক্তিগত PAN লিখুন",
    "Already Exists": "আগে থেকেই আছে",
    "Invalid": "অবৈধ",
    "Secure PAN Validation Process": "নিরাপদ PAN বৈধকরণ প্রক্রিয়া",
    "Please enter your correct PAN number in the format XXXXX-0000-X.":
        "অনুগ্রহ করে আপনার সঠিক PAN নম্বরটি XXXXX-0000-X ফর্ম্যাটে লিখুন৷",
    "PrivateCourt will meticulously collect essential information associated with your PAN such as your full name, date of birth, residential address, and contact details. For Business Owners, we extend our data collection to encompass all pertinent business details tied to your PAN such as GST details, MCA specifics, and records of GST return filings.":
        "প্রাইভেটকোর্ট সাবধানতার সাথে আপনার PAN এর সাথে সম্পর্কিত প্রয়োজনীয় তথ্য যেমন আপনার পুরো নাম, জন্ম তারিখ, আবাসিক ঠিকানা এবং যোগাযোগের বিশদ বিবরণ সংগ্রহ করবে। ব্যবসার মালিকদের জন্য, আমরা আপনার PANের সাথে সংযুক্ত সমস্ত প্রাসঙ্গিক ব্যবসার বিবরণ যেমন জিএসটি বিশদ বিবরণ, এমসিএ নির্দিষ্টকরণ এবং জিএসটি রিটার্ন ফাইলিংয়ের রেকর্ডগুলিকে অন্তর্ভুক্ত করতে আমাদের ডেটা সংগ্রহ প্রসারিত করি।",
    "Our system assesses your CIBIL score for financial insight and cross-verifies for any ongoing court cases associated with your PAN.":
        "আমাদের সিস্টেম আর্থিক অন্তর্দৃষ্টির জন্য আপনার CIBIL স্কোর মূল্যায়ন করে এবং আপনার PAN-এর সাথে সম্পর্কিত যেকোন চলমান আদালতের মামলাগুলির জন্য ক্রস-ভেরিফাই করে।",
    "It appears that the PAN details provided belong to a company. We require your personal PAN details for verification purposes.":
        "মনে হচ্ছে যে PANের বিবরণ দেওয়া হয়েছে তা একটি কোম্পানির। যাচাইকরণের উদ্দেশ্যে আমাদের আপনার ব্যক্তিগত PAN বিবরণ প্রয়োজন।",

    // প্রাইভেটকোর্ট
    "Data Compilation in Progress: Please Allow Processing Time":
        "তথ্য সংকলন প্রক্রিয়াধীন: অনুগ্রহ করে প্রক্রিয়াকরণের সময় মঞ্জুর করুন",
    "Thank you for providing your information. Our system is currently in the process of analyzing and compiling the data you've entered, including details such as PAN, Aadhaar, GST, Email, and more, to establish your comprehensive profile.":
        "আপনার তথ্য প্রদানের জন্য আপনাকে ধন্যবাদ. আপনার বিস্তৃত প্রোফাইল স্থাপন করতে আমাদের সিস্টেম বর্তমানে PAN, আধার, জিএসটি, ইমেল এবং আরও অনেক কিছু সহ আপনার প্রবেশ করা ডেটা বিশ্লেষণ এবং সংকলনের প্রক্রিয়াধীন রয়েছে।",
    "This operation may take a few moments. During this time, your screen may show minimal activity. Please be assured that this is a routine part of our data compilation process.":
        "এই অপারেশন কয়েক মুহূর্ত লাগতে পারে. এই সময়ে, আপনার স্ক্রিন ন্যূনতম কার্যকলাপ দেখাতে পারে। অনুগ্রহ করে নিশ্চিত হন যে এটি আমাদের ডেটা সংকলন প্রক্রিয়ার একটি নিয়মিত অংশ।",
    "Your profile is being meticulously crafted to uphold accuracy and security. We sincerely appreciate your patience and understanding as we work to enhance your experience.":
        "নির্ভুলতা এবং নিরাপত্তা বজায় রাখার জন্য আপনার প্রোফাইলটি যত্ন সহকারে তৈরি করা হচ্ছে। আমরা আন্তরিকভাবে আপনার ধৈর্য এবং বোঝার প্রশংসা করি কারণ আমরা আপনার অভিজ্ঞতা বাড়াতে কাজ করি৷",
    "For urgent matters or assistance, feel free to call us at +91-9699900111.":
        "জরুরী বিষয় বা সহায়তার জন্য, নির্দ্বিধায় আমাদের +91-9699900111 এ কল করুন।",
    "Thank you for your patience and entrusting PrivateCourt. Your security is our priority.":
        "আপনার ধৈর্য এবং প্রাইভেটকোর্টে দায়িত্ব দেওয়ার জন্য আপনাকে ধন্যবাদ। আপনার নিরাপত্তা আমাদের অগ্রাধিকার.",
    "Please input your Aadhaar number. This process ensures accurate cross-checking for your security. Thank you for your cooperation.":
        "অনুগ্রহ করে আপনার আধার নম্বর ইনপুট করুন। এই প্রক্রিয়াটি আপনার নিরাপত্তার জন্য সঠিক ক্রস-চেকিং নিশ্চিত করে। আপনার সহযোগিতার জন্য ধন্যবাদ।",
    "We apologize for the inconvenience. The Aadhaar number entered is invalid. Please double-check and ensure that you have entered the":
        "অসুবিধার জন্য আমরা ক্ষমাপ্রার্থী। প্রবেশ করা আধার নম্বরটি অবৈধ৷ অনুগ্রহ করে দুবার চেক করুন এবং নিশ্চিত করুন যে আপনি প্রবেশ করেছেন",
    "Applicant’s correct 12-digit Aadhaar number.":
        "আবেদনকারীর সঠিক 12-সংখ্যার আধার নম্বর।",
    "Error: Invalid OTP": "ত্রুটি: অবৈধ OTP",
    "We apologize for the inconvenience. The OTP entered is incorrect. Please double-check the OTP and try again.":
        "অসুবিধার জন্য আমরা ক্ষমাপ্রার্থী। প্রবেশ করা OTP ভুল. অনুগ্রহ করে OTP দুবার চেক করুন এবং আবার চেষ্টা করুন।",
    "Welcome": "স্বাগত",
    "Ensuring the accuracy of your bank account information is crucial for a seamless verification process.Kindly enter your valid Account Number, and input the correct IFSC code associated with your account.":
        "একটি নির্বিঘ্ন যাচাইকরণ প্রক্রিয়ার জন্য আপনার ব্যাঙ্ক অ্যাকাউন্টের তথ্যের নির্ভুলতা নিশ্চিত করা অত্যন্ত গুরুত্বপূর্ণ৷ দয়া করে আপনার বৈধ অ্যাকাউন্ট নম্বর লিখুন এবং আপনার অ্যাকাউন্টের সাথে যুক্ত সঠিক IFSC কোডটি ইনপুট করুন৷",
    "This verification step is designed to enhance the security of our services and provide you with a reliable experience.":
        "এই যাচাইকরণ পদক্ষেপটি আমাদের পরিষেবাগুলির নিরাপত্তা বাড়ানোর জন্য এবং আপনাকে একটি নির্ভরযোগ্য অভিজ্ঞতা প্রদানের জন্য ডিজাইন করা হয়েছে৷",
    "We apologize for the inconvenience. The name provided does not match the GST records. Please ensure that the name entered matches the information on your GST card and try again.":
        "অসুবিধার জন্য আমরা ক্ষমাপ্রার্থী। প্রদত্ত নাম GST রেকর্ডের সাথে মেলে না। দয়া করে নিশ্চিত করুন যে প্রবেশ করা নামটি আপনার GST কার্ডের তথ্যের সাথে মেলে এবং আবার চেষ্টা করুন৷",
    "Business Verification Process": "ব্যবসা যাচাইকরণ প্রক্রিয়া",
    "Ensuring Secure Business Validation- This verification process is meticulously designed to uphold the integrity of our services, providing a reliable and secure experience.":
        "নিরাপদ ব্যবসার বৈধতা নিশ্চিত করা- এই যাচাইকরণ প্রক্রিয়াটি আমাদের পরিষেবার অখণ্ডতা বজায় রাখার জন্য, একটি নির্ভরযোগ্য এবং সুরক্ষিত অভিজ্ঞতা প্রদান করার জন্য সতর্কতার সাথে ডিজাইন করা হয়েছে।",
    "Please provide details for each of your businesses for accurate validation.":
        "সঠিক বৈধতার জন্য আপনার প্রতিটি ব্যবসার বিশদ বিবরণ প্রদান করুন।",
    "PrivateCourt will collect essential information associated with your PAN such as your full name, date of birth, residential address, and contact details. For Business Owners, we extend our data collection to encompass all pertinent business details tied to your PAN such as GST details, MCA specifics, and records of GST return filings.":
        "প্রাইভেটকোর্ট আপনার PAN-এর সাথে সম্পর্কিত প্রয়োজনীয় তথ্য যেমন আপনার পুরো নাম, জন্ম তারিখ, আবাসিক ঠিকানা এবং যোগাযোগের বিবরণ সংগ্রহ করবে। ব্যবসার মালিকদের জন্য, আমরা আপনার PANের সাথে সংযুক্ত সমস্ত প্রাসঙ্গিক ব্যবসার বিবরণ যেমন জিএসটি বিশদ বিবরণ, এমসিএ নির্দিষ্টকরণ এবং জিএসটি রিটার্ন ফাইলিংয়ের রেকর্ডগুলিকে অন্তর্ভুক্ত করতে আমাদের ডেটা সংগ্রহ প্রসারিত করি।",
    "Our system evaluates your CIBIL score for financial insight and cross-verifies for any ongoing court cases associated with your PAN.":
        "আমাদের সিস্টেম আর্থিক অন্তর্দৃষ্টির জন্য আপনার CIBIL স্কোর মূল্যায়ন করে এবং আপনার PAN-এর সাথে সম্পর্কিত যেকোন চলমান আদালতের মামলাগুলির জন্য ক্রস-ভেরিফাই করে।",
    "To ensure the security of your account, please enter your valid Email ID in the specified format- username@domain.com. If your email address doesn't exist in our system, a":
        "আপনার অ্যাকাউন্টের নিরাপত্তা নিশ্চিত করতে, অনুগ্রহ করে নির্দিষ্ট ফরম্যাটে আপনার বৈধ ইমেল আইডি লিখুন- username@domain.com। যদি আপনার ইমেল ঠিকানা আমাদের সিস্টেমে বিদ্যমান না থাকে,",
    "account will be created for you.": "আপনার জন্য অ্যাকাউন্ট তৈরি করা হবে।",
    "GST Verification Process": "জিএসটি যাচাইকরণ প্রক্রিয়া",
    "We regret to inform you that the GST number entered does not match the required format (00-XXXXX-0000-X-0-X-0).Please ensure you have entered the correct GST details and follow the specified format for accurate verification.":
        "আমরা আপনাকে জানাতে দুঃখিত যে লিখিত GST নম্বরটি প্রয়োজনীয় বিন্যাসের সাথে মেলে না (00-XXXXX-0000-X-0-X-0)। অনুগ্রহ করে নিশ্চিত করুন যে আপনি সঠিক GST বিবরণ প্রবেশ করেছেন এবং সঠিক যাচাইয়ের জন্য নির্দিষ্ট বিন্যাস অনুসরণ করুন।",
    "Verify GST": "জিএসটি যাচাই করুন",
    "Congratulations! You've completed the registration process. Your dedication to providing the necessary information has brought us one step closer to serving you effectively.":
        "অভিনন্দন! আপনি নিবন্ধন প্রক্রিয়া সম্পন্ন করেছেন. প্রয়োজনীয় তথ্য প্রদানের জন্য আপনার নিবেদন আমাদেরকে কার্যকরভাবে আপনাকে পরিবেশন করার এক ধাপ কাছাকাছি নিয়ে এসেছে।",
    "We apologize for the inconvenience. The name provided does not match the GST records. Please ensure that the name entered matches the information on your GST card and try again.":
        "অসুবিধার জন্য আমরা ক্ষমাপ্রার্থী। প্রদত্ত নাম GST রেকর্ডের সাথে মেলে না। দয়া করে নিশ্চিত করুন যে প্রবেশ করা নামটি আপনার GST কার্ডের তথ্যের সাথে মেলে এবং আবার চেষ্টা করুন৷",
    "Skip": "এড়িয়ে যান",
    "Server Overload: Please Retry Later":
        "সার্ভার ওভারলোড: পরে আবার চেষ্টা করুন",
    "Back To Home": "বাড়িতে ফিরে যাও",

    "Verify Aadhaar": "আধার যাচাই করুন",

    "having PAN number": "প্যান নম্বর আছে",
    "Verify Bank": "ব্যাংক যাচাই করুন",
    "Select Businesses": "ব্যবসা নির্বাচন করুন",
    "I am individual": "আমি স্বতন্ত্র",
    "Securely Verify Your Credit Score":
        "নিরাপদভাবে আপনার ক্রেডিট স্কোর যাচাই করুন",
    "Enter Your Mobile Number": "আপনার মোবাইল নম্বর লিখুন",
    "To access your credit score securely, please enter your mobile number below. We'll send you a one-time password (OTP) to verify your identity and ensure the confidentiality of your information.":
        "নিরাপদভাবে আপনার ক্রেডিট স্কোর অ্যাক্সেস করতে, দয়া করে নীচে আপনার মোবাইল নম্বর লিখুন। আপনার পরিচয় যাচাই করতে এবং আপনার তথ্যের গোপনীয়তা নিশ্চিত করতে আমরা আপনাকে একটি ওয়ান-টাইম পাসওয়ার্ড (OTP) পাঠাব।",
    "Verify without OTP": "ওটিপি ছাড়াই যাচাই করুন",
    "Upon submission, check your messages promptly for the OTP. This step ensures your financial information remains protected while you gain valuable insights into your credit profile.":
        "জমা দেওয়ার পরে, OTP-এর জন্য অবিলম্বে আপনার বার্তাগুলি পরীক্ষা করুন৷ এই পদক্ষেপটি নিশ্চিত করে যে আপনি আপনার ক্রেডিট প্রোফাইলে মূল্যবান অন্তর্দৃষ্টি লাভ করার সময় আপনার আর্থিক তথ্য সুরক্ষিত থাকবে৷",
    "Enter Your OTP Here": "এখানে আপনার ওটিপি লিখুন",
    "Error: Invalid Mobile Number": "ত্রুটি: অবৈধ মোবাইল নম্বর",
    "We apologize for the inconvenience. The Mobile number entered is invalid. Please double-check and ensure that you have entered the":
        "আমরা অসুবিধার জন্য দুঃখিত। প্রবেশ করা মোবাইল নম্বরটি অবৈধ। অনুগ্রহ করে দুবার চেক করুন এবং নিশ্চিত করুন যে আপনি প্রবেশ করেছেন",
    "Applicant’s correct 10-digit Mobile number.":
        "আবেদনকারীর সঠিক 10-সংখ্যার মোবাইল নম্বর।",
    "Mobile Number or contact our support team at +91-9699900111 for further assistance. We appreciate your cooperation.":
        "মোবাইল নম্বর বা আরও সহায়তার জন্য +91-9699900111 এ আমাদের সহায়তা দলের সাথে যোগাযোগ করুন। আমরা আপনার সহযোগিতার প্রশংসা করি।",
    "Good Morning": "শুভ সকাল",
    "Good Evening": "শুভ সকাল",
    "Good Night": "শুভ রাত্রি",
    "We regret to inform you that the GST number entered does not match the required format (00-XXXXX-0000-X-0-X-0). Please ensure you have entered the correct GST details and follow the specified format for accurate verification.":
        "আমরা আপনাকে জানাতে দুঃখিত যে লিখিত GST নম্বরটি প্রয়োজনীয় বিন্যাসের সাথে মেলে না (00-XXXXX-0000-X-0-X-0)। দয়া করে নিশ্চিত করুন যে আপনি সঠিক GST বিবরণ প্রবেশ করেছেন এবং সঠিক যাচাইকরণের জন্য নির্দিষ্ট বিন্যাস অনুসরণ করুন",
    "Rs.": "রুপি।",
    "Verification Complete – Thank You!": "যাচাই সম্পূর্ণ - আপনাকে ধন্যবাদ!",
    "Congratulations! You've completed the Verification process. Your dedication to providing the necessary information has brought us one step closer to serving you effectively.":
        "অভিনন্দন! আপনি যাচাইকরণ প্রক্রিয়া সম্পন্ন করেছেন। প্রয়োজনীয় তথ্য প্রদানের জন্য আপনার নিবেদন আমাদেরকে কার্যকরভাবে আপনাকে পরিবেশন করার এক ধাপ কাছাকাছি নিয়ে এসেছে।",
    "With your Verification finalized, our team is fully equipped to handle your requirements efficiently. We assure you that your case will be handled with the utmost care and attention to detail.":
        "আপনার যাচাইকরণ চূড়ান্ত হওয়ার সাথে সাথে, আমাদের টিম আপনার প্রয়োজনীয়তাগুলি দক্ষতার সাথে পরিচালনা করার জন্য সম্পূর্ণরূপে সজ্জিত। আমরা আপনাকে আশ্বাস দিচ্ছি যে আপনার কেসটি অত্যন্ত যত্ন সহকারে এবং বিস্তারিত মনোযোগ দিয়ে পরিচালনা করা হবে।",
    "having Gst number": "জিএসটি নম্বর থাকা",
    "Your UDYAM Number allows us to collect crucial details associated with your business. This includes your business address, current status, service details, and organization type.":
        "আপনার UDYAM নম্বর আমাদের আপনার ব্যবসার সাথে সম্পর্কিত গুরুত্বপূর্ণ বিবরণ সংগ্রহ করতে দেয়। এতে আপনার ব্যবসার ঠিকানা, বর্তমান অবস্থা, পরিষেবার বিবরণ এবং প্রতিষ্ঠানের ধরন অন্তর্ভুক্ত রয়েছে।",




    "Securely Verify Your Credit Score": "নিরাপদে আপনার ক্রেডিট স্কোর যাচাই করুন",
    "Enter Your Mobile Number": "আপনার মোবাইল নম্বর লিখুন",
    "To access your credit score securely, please enter your mobile number below. We'll send you a one-time password (OTP) to verify your identity and ensure the confidentiality of your information.": "নিরাপদে আপনার ক্রেডিট স্কোর অ্যাক্সেস করতে, দয়া করে নীচে আপনার মোবাইল নম্বর লিখুন। আপনার পরিচয় যাচাই করতে এবং আপনার তথ্যের গোপনীয়তা নিশ্চিত করতে আমরা আপনাকে একটি ওয়ান-টাইম পাসওয়ার্ড (OTP) পাঠাব।",
    "For any queries or assistance, feel free to call us at +91-9699900111.": "যেকোনো প্রশ্ন বা সহায়তার জন্য, আমাদেরকে +91-9699900111 এ কল করুন।",
    "Verify without OTP": "OTP ছাড়াই যাচাই করুন",
    "Upon submission, check your messages promptly for the OTP. This step ensures your financial information remains protected while you gain valuable insights into your credit profile.": "জমা দেওয়ার পরে, OTP-এর জন্য অবিলম্বে আপনার বার্তাগুলি পরীক্ষা করুন৷ এই পদক্ষেপটি নিশ্চিত করে যে আপনি আপনার ক্রেডিট প্রোফাইলে মূল্যবান অন্তর্দৃষ্টি লাভ করার সময় আপনার আর্থিক তথ্য সুরক্ষিত থাকবে।",
    "Enter Your OTP Here": "এখানে আপনার OTP লিখুন",
    "Error: Invalid Mobile Number": "ত্রুটি: অবৈধ মোবাইল নম্বর",
    "We apologize for the inconvenience. The Mobile number entered is invalid. Please double-check and ensure that you have entered the": "আমরা অসুবিধার জন্য দুঃখিত. প্রবেশ করা মোবাইল নম্বরটি অবৈধ৷ অনুগ্রহ করে দুবার চেক করুন এবং নিশ্চিত করুন যে আপনি প্রবেশ করেছেন",
    "Applicant’s correct 10-digit Mobile number.": "আবেদনকারীর সঠিক 10-সংখ্যার মোবাইল নম্বর।",
    "Mobile Number or contact our support team at +91-9699900111 for further assistance. We appreciate your cooperation.": "আরও সহায়তার জন্য মোবাইল নম্বর বা +91-9699900111 এ আমাদের সহায়তা দলের সাথে যোগাযোগ করুন। আমরা আপনার সহযোগিতার প্রশংসা করি।",
    "Error: Invalid OTP": "ত্রুটি: অবৈধ OTP",
    "We apologize for the inconvenience. The OTP entered is incorrect. Please double-check the OTP and try again.": "আমরা অসুবিধার জন্য ক্ষমাপ্রার্থী। প্রবেশ করানো OTP ভুল। অনুগ্রহ করে OTP দুবার চেক করুন এবং আবার চেষ্টা করুন।",
    "mobile Number or contact our support team at +91-9699900111 for further assistance. We appreciate your cooperation.": "আরও সহায়তার জন্য মোবাইল নম্বর বা +91-9699900111 এ আমাদের সহায়তা দলের সাথে যোগাযোগ করুন। আমরা আপনার সহযোগিতার প্রশংসা করি।",
    "Verify OTP": "OTP যাচাই করুন",
    "Verify": "যাচাই করুন",


    //Conciliation
    "Respected ,name":"শ্রদ্ধেয়, name",
    "CONCILIATION NOTICE": "সংশোধন বিজ্ঞপ্তি",
    "Warm Greetings from PRIVATECOURT. We are writing to formally inform you that PRIVATECOURT will oversee and resolve the dispute(s) between you and company .": "PRIVATECOURT থেকে উষ্ণ শুভেচ্ছা। আমরা আপনাকে আনুষ্ঠানিকভাবে জানাতে লিখছি যে PRIVATECOURT আপনার এবং কোম্পানির মধ্যে বিরোধগুলি তত্ত্বাবধান এবং সমাধান করবে।",
    "Please find attached the confirmation document with hearing details that outline our acceptance of our commitment to handling your disputes through Alternative Dispute Resolution (ADR).": "আমরা আপনার বিরোধগুলি বিকল্প বিরোধ সমাধান (ADR) মাধ্যমে পরিচালনার জন্য আমাদের প্রতিশ্রুতি গ্রহণ করার বিষয়টি বর্ণনা করে শুনানির বিবরণ সহ নিশ্চিতকরণ ডকুমেন্ট সংযুক্ত করেছি।",
    "To explore resolution options and engage in the process effectively, we provide the following avenues, from which you can select any one way:": "সমাধান অপশনগুলি অনুসন্ধান করতে এবং প্রক্রিয়ায় কার্যকরভাবে অংশগ্রহণ করতে, আমরা নিম্নলিখিত পথগুলি প্রদান করছি, যার মধ্যে আপনি যে কোনো একটি নির্বাচন করতে পারেন:",
    "Access Your Personalized Dispute Resolution Page: Click link to access your dedicated dispute resolution page. Here, you can provide detailed information about your issue. Our support team will review your submission promptly and guide you through the next steps of the resolution process.": "আপনার ব্যক্তিগত বিরোধ সমাধান পৃষ্ঠায় প্রবেশ করুন: আপনার নিবেদিত বিরোধ সমাধান পৃষ্ঠায় প্রবেশ করতে link ক্লিক করুন। এখানে, আপনি আপনার সমস্যার বিস্তারিত তথ্য প্রদান করতে পারেন। আমাদের সহায়তা দল আপনার সাবমিশনটি দ্রুত পর্যালোচনা করবে এবং সমাধান প্রক্রিয়ার পরবর্তী পদক্ষেপগুলিতে আপনাকে নির্দেশনা দেবে।",
    "OR": "অথবা",
    "You are hereby directed to attend the hearingType hearing scheduled on date, at place, between timing.": "আপনাকে date তারিখে place তে, timing সময়ের মধ্যে নির্ধারিত hearingType শুনানিতে উপস্থিত থাকার নির্দেশ দেওয়া হচ্ছে।",
    "This platform enables you to directly engage with our team, comprehensively discussing and sharing your dispute details.": "এই প্ল্যাটফর্ম আপনাকে আমাদের দলের সাথে সরাসরি যোগাযোগ করতে, আপনার বিরোধের বিস্তারিত আলোচনা এবং ভাগ করতে সক্ষম করে।",
    "If you have any additional information or documentation related to this matter, please feel free to reach out to our dedicated customer support team at +918035731376 or Email: legal@privatecourt.in": "এই বিষয়ে অতিরিক্ত কোনো তথ্য বা ডকুমেন্ট থাকলে, দয়া করে +918035731376 নম্বরে আমাদের নিবেদিত গ্রাহক সহায়তা দলের সাথে যোগাযোগ করুন অথবা legal@privatecourt.in এ ইমেইল করুন।",
    "We appreciate your trust in PRIVATECOURT for dispute resolution and are committed to delivering a fair and impartial resolution to the matter at hand.": "বিরোধ সমাধানের জন্য PRIVATECOURT এর প্রতি আপনার বিশ্বাসের প্রশংসা করি এবং বিষয়টির সঠিক এবং পক্ষপাতহীন সমাধান প্রদানের জন্য প্রতিশ্রুতিবদ্ধ।",
    "Thank You,": "ধন্যবাদ,",
    "Team PrivateCourt": "টিম প্রাইভেটকোর্ট",
    "Join anytime here and submit your response":"এখানে যে কোন সময় যোগদান করুন এবং আপনার প্রতিক্রিয়া জমা দিন",
    "It's time to join the video call.":"এবার ভিডিও কলে যোগ দেওয়ার পালা।",
    "You are hereby directed to attend the hearing scheduled on date, at place, between timing.":"আপনাকে এতদ্বারা নির্ধারিত date-এ, place-এ, timing-এর মধ্যে নির্ধারিত শুনানিতে অংশ নেওয়ার নির্দেশ দেওয়া হচ্ছে",
    "Please explain the reasons for your loan repayment difficulties below. Include any relevant circumstances such as financial hardship, medical issues, or disputes over loan terms. Your detailed response will help us assess and resolve your case effectively.":"আপনার ঋণ পরিশোধের অসুবিধার কারণগুলি নীচে ব্যাখ্যা করুন। আর্থিক কষ্ট, চিকিৎসা সংক্রান্ত সমস্যা বা ঋণের শর্তাদি নিয়ে বিরোধের মতো কোনও প্রাসঙ্গিক পরিস্থিতি অন্তর্ভুক্ত করুন। আপনার বিস্তারিত প্রতিক্রিয়া আমাদের আপনার কেসটি কার্যকরভাবে মূল্যায়ন ও সমাধান করতে সহায়তা করবে।",
    "It’s time for your scheduled online hearing. Join the session by clicking the button below, and ensure all necessary materials are ready for discussion.":"এটি আপনার নির্ধারিত অনলাইন শুনানির সময়। নীচের বোতামটি ক্লিক করে অধিবেশনে যোগ দিন এবং সমস্ত প্রয়োজনীয় উপকরণ আলোচনার জন্য প্রস্তুত রয়েছে তা নিশ্চিত করুন।",
    "Thank you for submitting the form!": "ফর্ম জমা দেওয়ার জন্য ধন্যবাদ!",
    "Your details have been successfully received and are currently under review. Should any additional information be required, our team will reach out to you directly. Please be informed that, as the form submission is complete, the \"Join Video Call\" option has been disabled, as your presence in the meeting is no longer necessary. We will keep you updated on the next steps in due course.": "আপনার বিস্তারিত সফলভাবে গ্রহণ করা হয়েছে এবং বর্তমানে পর্যালোচনার অধীনে রয়েছে। যদি কোনও অতিরিক্ত তথ্য প্রয়োজন হয়, আমাদের দল সরাসরি আপনার সাথে যোগাযোগ করবে। দয়া করে অবহিত থাকুন যে, ফর্ম জমা দেওয়া সম্পূর্ণ হওয়ার কারণে, \"জয়েন ভিডিও কল\" অপশন নিষ্ক্রিয় করা হয়েছে, কারণ আপনার উপস্থিতি এখন আর প্রয়োজনীয় নয়। আমরা যথাসময়ে আপনাকে পরবর্তী পদক্ষেপগুলি সম্পর্কে আপডেট রাখব।",
    "Join Video Call": "ভিডিও কলে যোগ দিন",
    "Join": "যোগ দিন",
    "To proceed further with the resolution process, kindly accept the conciliation notice.":"সমাধান প্রক্রিয়াকে আরও এগিয়ে নিতে, অনুগ্রহ করে মিলন নোটিশ গ্রহণ করুন।",
    "Accept":"গ্রহণ করুন",
    "Reject":"প্রত্যাখ্যান করুন",
    "Redefining Dispute Resolution":"বিরোধ নিষ্পত্তির পুনঃসংজ্ঞায়ন",
    "With PrivateCourt, experience faster, more effective dispute resolution powered by technology and guided by industry experts.":"PrivateCourt-এর সাথে, প্রযুক্তি দ্বারা চালিত এবং শিল্প বিশেষজ্ঞদের দ্বারা পরিচালিত, দ্রুত এবং আরও কার্যকর বিরোধ নিষ্পত্তির অভিজ্ঞতা নিন।",
    "Visit Website":"ওয়েবসাইট দেখুন"
};
export default BengaliTranslation;
