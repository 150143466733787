const TamilTranslation = {
    "Join": "சேரவும்",
    "Join Video Call": "வீடியோ கால் சேரவும்",
    "Your details have been successfully received and are currently under review. Should any additional information be required, our team will reach out to you directly. Please be informed that, as the form submission is complete, the \"Join Video Call\" option has been disabled, as your presence in the meeting is no longer necessary. We will keep you updated on the next steps in due course.": "உங்கள் விவரங்கள் வெற்றிகரமாக பெறப்பட்டு, தற்போது பரிசீலனையில் உள்ளன. கூடுதல் தகவல் தேவைப்பட்டால், எங்கள் அணி நேரடியாக உங்களுடன் தொடர்பு கொள்கின்றது. தங்களது கருத்தில், படிவம் சமர்ப்பிப்பு முடிந்துள்ளதால், \"Join Video Call\" விருப்பம் முடிக்கப்பட்டுள்ளது, ஏனெனில் கூட்டத்தில் உங்கள் கலந்து கொள்கை இப்போது தேவையில்லை. வருகிற படிநிலைகள் பற்றி எங்களிடம் பரிசு எப்போது எடுத்து தரப்படும் என்பது பற்றி உங்களுக்கு தகவல் அளிப்போம்.",
    "Thank you for submitting the form!": "படிவத்தை சமர்ப்பித்ததற்கு நன்றி!",
    'Email Security Check': 'மின்னஞ்சல் பாதுகாப்பு சோதனை',
    'Verify Your Email for Account Access': 'கணக்கு அணுகலுக்கான உங்கள் மின்னஞ்சலைச் சரிபார்க்கவும்',
    "To ensure the security of your account, please enter your valid Email ID in the specified format- username@domain.com. If your email address doesn't exist in our system": "உங்கள் கணக்கின் பாதுகாப்பை உறுதிசெய்ய, உங்கள் சரியான மின்னஞ்சல் ஐடியை குறிப்பிட்ட வடிவத்தில் உள்ளிடவும்- username@domain.com. உங்கள் மின்னஞ்சல் முகவரி எங்கள் அமைப்பில் இல்லை என்றால், ஏ",
    'NEW USER': 'புதிய பயனர்',
    'account will be created for you': 'உங்களுக்காக கணக்கு உருவாக்கப்படும்.',
    'Once submitted, you will receive a Verification OTP (One-Time Password) sent to your provided email address.': 'சமர்ப்பிக்கப்பட்டதும், நீங்கள் வழங்கிய மின்னஞ்சல் முகவரிக்கு அனுப்பப்பட்ட சரிபார்ப்பு OTP (ஒரு முறை கடவுச்சொல்) பெறுவீர்கள்.',
    'For any queries or assistance, feel free to call us at +91-9699900111.': 'ஏதேனும் கேள்விகள் அல்லது உதவிகளுக்கு, எங்களை +91-9699900111 என்ற எண்ணில் அழைக்கவும்.',
    'Thank you for entrusting PrivateCourt. Your security is our priority.': 'தனியார் நீதிமன்றத்தை ஒப்படைத்ததற்கு நன்றி. உங்கள் பாதுகாப்பு எங்கள் முன்னுரிமை.',
    'Verify': 'சரிபார்க்கவும்',
    'Invalid Email Address': 'தவறான மின்னஞ்சல் முகவரி',
    'We regret to inform you that the email address entered is invalid. Please ensure that you have entered a correct and properly formatted email address.': 'உள்ளிடப்பட்ட மின்னஞ்சல் முகவரி தவறானது என்பதை உங்களுக்குத் தெரிவிக்க வருந்துகிறோம். நீங்கள் சரியான மற்றும் சரியாக வடிவமைக்கப்பட்ட மின்னஞ்சல் முகவரியை உள்ளிட்டுள்ளீர்கள் என்பதை உறுதிப்படுத்தவும்.',
    'If the issue persists, double-check for any typos or errors in the email format (e.g., username@example.com).': 'சிக்கல் தொடர்ந்தால், மின்னஞ்சல் வடிவத்தில் ஏதேனும் எழுத்துப்பிழைகள் அல்லது பிழைகள் உள்ளதா என இருமுறை சரிபார்க்கவும் (எ.கா., username@example.com).',
    'For further queries or assistance, feel free to call us at +91-9699900111.': 'மேலும் கேள்விகள் அல்லது உதவிகளுக்கு, எங்களை +91-9699900111 என்ற எண்ணில் அழைக்கவும்.',
    'Thank you for entrusting PrivateCourt. Your security is our priority.': 'தனியார் நீதிமன்றத்தை ஒப்படைத்ததற்கு நன்றி. உங்கள் பாதுகாப்பு எங்கள் முன்னுரிமை.',
    'Secure UDYAM Number Validation': 'பாதுகாப்பான உத்யம் எண் சரிபார்ப்பு',
    'Enter Your Udyam Number': 'உங்கள் Udyam எண்ணை உள்ளிடவும்',
    'Please enter your correct UDYAM Number.': 'உங்களின் சரியான UDYAM எண்ணை உள்ளிடவும்.',
    'Your UDYAM Number allows us to collect crucial details associated with your business. This includes your business address, current status, service details, and organization type.': 'உங்கள் UDYAM எண் உங்கள் வணிகத்துடன் தொடர்புடைய முக்கியமான விவரங்களைச் சேகரிக்க அனுமதிக்கிறது. இதில் உங்கள் வணிக முகவரி, தற்போதைய நிலை, சேவை விவரங்கள் மற்றும் நிறுவன வகை ஆகியவை அடங்கும்.',
    'Ensuring the accuracy of your UDYAM Number is vital for a seamless verification process.': 'உங்கள் UDYAM எண்ணின் துல்லியத்தை உறுதி செய்வது தடையற்ற சரிபார்ப்பு செயல்முறைக்கு இன்றியமையாதது.',
    'UDYAM': 'உத்யம்',
    'Error: Invalid UDYAM Number': 'பிழை: உத்யம் எண் தவறானது',
    'We regret to inform you that the UDYAM number entered is invalid or incorrect. Please ensure you have entered the correct UDYAM details and double-check for any typos.': 'உள்ளிட்ட UDYAM எண் தவறானது அல்லது தவறானது என்பதை உங்களுக்குத் தெரிவிக்க வருந்துகிறோம். சரியான UDYAM விவரங்களை உள்ளிட்டுள்ளதை உறுதிசெய்து, ஏதேனும் எழுத்துப் பிழைகள் உள்ளதா என இருமுறை சரிபார்க்கவும்.',
    'If you continue to face issues, kindly verify your UDYAM certificate and re-enter the details.': 'நீங்கள் தொடர்ந்து சிக்கல்களைச் சந்தித்தால், தயவுசெய்து உங்களின் UDYAM சான்றிதழைச் சரிபார்த்து, விவரங்களை மீண்டும் உள்ளிடவும்.',
    'Secure PAN Validation Process': 'பாதுகாப்பான PAN சரிபார்ப்பு செயல்முறை',
    'This verification process is designed to uphold the integrity of our services, ensuring a reliable and secure experience.': 'இந்தச் சரிபார்ப்பு செயல்முறையானது, எங்கள் சேவைகளின் ஒருமைப்பாட்டை நிலைநிறுத்தவும், நம்பகமான மற்றும் பாதுகாப்பான அனுபவத்தை உறுதி செய்யவும் வடிவமைக்கப்பட்டுள்ளது.',
    'Enter Your PAN Number': 'உங்கள் PAN எண்ணை உள்ளிடவும்',
    'Please enter your correct PAN number in the format XXXXX-0000-X.': 'உங்கள் சரியான PAN எண்ணை XXXXX-0000-X வடிவத்தில் உள்ளிடவும்.',
    'PrivateCourt will meticulously collect essential information associated with your PAN such as your full name, date of birth, residential address, and contact details. For Business Owners, we extend our data collection to encompass all pertinent business details tied to your PAN such as GST details, MCA specifics, and records of GST return filings.': 'உங்களின் முழுப்பெயர், பிறந்த தேதி, வசிக்கும் முகவரி மற்றும் தொடர்பு விவரங்கள் போன்ற உங்களின் பான் எண்ணுடன் தொடர்புடைய அத்தியாவசியத் தகவல்களை PrivateCourt உன்னிப்பாகச் சேகரிக்கும். வணிக உரிமையாளர்களுக்காக, உங்கள் PAN உடன் இணைக்கப்பட்டுள்ள GST விவரங்கள், MCA விவரக்குறிப்புகள் மற்றும் GST ரிட்டர்ன் தாக்கல்களின் பதிவுகள் போன்ற அனைத்து தொடர்புடைய வணிக விவரங்களையும் உள்ளடக்கியதாக எங்கள் தரவு சேகரிப்பை விரிவுபடுத்துகிறோம்.',
    'Our system assesses your CIBIL score for financial insight and cross-verifies for any ongoing court cases associated with your PAN.': 'எங்கள் அமைப்பு உங்கள் CIBIL ஸ்கோரை நிதி நுண்ணறிவுக்காக மதிப்பிடுகிறது மற்றும் உங்கள் PAN உடன் தொடர்புடைய நீதிமன்ற வழக்குகளுக்கு குறுக்கு-சரிபார்க்கிறது.',
    'Error: Invalid PAN Format': 'பிழை: தவறான PAN வடிவமைப்பு',
    'We regret to inform you that the PAN number entered does not match the required format (XXXXX-0000-X). Please ensure you have entered the correct PAN details and follow the specified format for accurate verification.': 'உள்ளிடப்பட்ட PAN எண் தேவையான வடிவத்துடன் (XXXXX-0000-X) பொருந்தவில்லை என்பதைத் தெரிவித்துக் கொள்கிறோம். நீங்கள் சரியான PAN விவரங்களை உள்ளிட்டுள்ளீர்கள் என்பதை உறுதிசெய்து, துல்லியமான சரிபார்ப்புக்கு குறிப்பிட்ட வடிவமைப்பைப் பின்பற்றவும்.',
    'If you continue to face issues, kindly double-check your PAN card and re-enter the details. For further queries or assistance, feel free to call us at +91-9699900111.': 'நீங்கள் தொடர்ந்து சிக்கல்களை எதிர்கொண்டால், தயவுசெய்து உங்கள் பான் கார்டை இருமுறை சரிபார்த்து, விவரங்களை மீண்டும் உள்ளிடவும். மேலும் கேள்விகள் அல்லது உதவிகளுக்கு, எங்களை +91-9699900111 என்ற எண்ணில் அழைக்கவும்.',
    'Welcome XXXX': 'வரவேற்பு XXXX',
    'Account security starts with verification': 'கணக்கு பாதுகாப்பு சரிபார்ப்புடன் தொடங்குகிறது',
    'OTP Validation for Account Access': 'கணக்கு அணுகலுக்கான OTP சரிபார்ப்பு',
    'Kindly check your inbox, and enter the OTP in the designated field to complete the verification process.': 'உங்கள் இன்பாக்ஸைச் சரிபார்த்து, சரிபார்ப்பு செயல்முறையை முடிக்க நியமிக்கப்பட்ட புலத்தில் OTP ஐ உள்ளிடவும்.',
    "Didn't receive OTP?": 'OTP பெறவில்லையா?',
    'Request a Resend': 'மீண்டும் அனுப்பக் கோரவும்',
    'Verify OTP': 'OTP ஐ சரிபார்க்கவும்',
    'Error: Incorrect OTP': 'பிழை: தவறான OTP',
    'We apologize for the inconvenience. The One-Time Password (OTP) entered is incorrect. Please ensure that you have accurately entered the OTP received in your email.': 'சிரமத்திற்கு வருந்துகிறோம். உள்ளிடப்பட்ட ஒரு முறை கடவுச்சொல் (OTP) தவறானது. உங்கள் மின்னஞ்சலில் பெறப்பட்ட OTPயை நீங்கள் துல்லியமாக உள்ளிட்டுள்ளீர்கள் என்பதை உறுதிப்படுத்தவும்.',
    'If the issue persists, you may request a new OTP by clicking on the resend link or contacting our support team at +91-9699900111.': 'சிக்கல் தொடர்ந்தால், மீண்டும் அனுப்பும் இணைப்பைக் கிளிக் செய்வதன் மூலம் அல்லது எங்கள் ஆதரவுக் குழுவை +91-9699900111 என்ற எண்ணில் தொடர்புகொள்வதன் மூலம் நீங்கள் புதிய OTP-ஐக் கோரலாம்.',
    'Double-check the email and try entering the OTP again.': 'மின்னஞ்சலை இருமுறை சரிபார்த்து, மீண்டும் OTP ஐ உள்ளிட முயற்சிக்கவும்.',
    'Choose Profile': 'சுயவிவரத்தைத் தேர்ந்தெடுக்கவும்',
    'Setting Your Default Profile': 'உங்கள் இயல்புநிலை சுயவிவரத்தை அமைத்தல்',
    'Please choose the default profile for a seamless experience.': 'தடையற்ற அனுபவத்திற்கு இயல்புநிலை சுயவிவரத்தைத் தேர்வு செய்யவும்.',
    "If you manage multiple companies, select the profile you'd like to set as the default. This ensures that every time you log in, you'll have quick access to the information relevant to your preferred company.": 'நீங்கள் பல நிறுவனங்களை நிர்வகித்தால், இயல்புநிலையாக அமைக்க விரும்பும் சுயவிவரத்தைத் தேர்ந்தெடுக்கவும். ஒவ்வொரு முறையும் நீங்கள் உள்நுழையும்போது, நீங்கள் விரும்பும் நிறுவனத்துடன் தொடர்புடைய தகவல்களை விரைவாக அணுக முடியும் என்பதை இது உறுதி செய்கிறது.',
    'Making the right selection now simplifies your future logins and provides a tailored experience based on your primary business entity.': 'இப்போது சரியான தேர்வு செய்வது உங்கள் எதிர்கால உள்நுழைவுகளை எளிதாக்குகிறது மற்றும் உங்கள் முதன்மை வணிக நிறுவனத்தின் அடிப்படையில் வடிவமைக்கப்பட்ட அனுபவத்தை வழங்குகிறது.',
    'Go to Dashboard': 'டாஷ்போர்டுக்குச் செல்லவும்',
    'Data Compilation in Progress': 'தரவு தொகுத்தல் செயல்பாட்டில் உள்ளது:',
    'Please Allow Processing Time': 'செயலாக்க நேரத்தை அனுமதிக்கவும்',
    "Thank you for providing your information. Our system is currently in the process of analyzing and compiling the data you've entered, including details such as PAN, Aadhaar, GST, Email, and more, to establish your comprehensive profile.": 'உங்கள் தகவலை வழங்கியதற்கு நன்றி. உங்கள் விரிவான சுயவிவரத்தை உருவாக்க, PAN, ஆதார், GST, மின்னஞ்சல் மற்றும் பல போன்ற விவரங்கள் உட்பட, நீங்கள் உள்ளிட்ட தரவை பகுப்பாய்வு செய்து தொகுக்கும் பணியில் எங்கள் அமைப்பு தற்போது ஈடுபட்டுள்ளது.',
    "This operation may take a few moments. During this time, your screen may show minimal activity. Please be assured that this is a routine part of our data compilation process.": 'இந்தச் செயல்பாடு சில நொடிகள் ஆகலாம். இந்த நேரத்தில், உங்கள் திரை குறைந்தபட்ச செயல்பாட்டைக் காட்டலாம். இது எங்களின் தரவுத் தொகுத்தல் செயல்முறையின் வழக்கமான பகுதியாகும் என்பதை உறுதிப்படுத்திக் கொள்ளவும்.',
    'Your profile is being meticulously crafted to uphold accuracy and security. We sincerely appreciate your patience and understanding as we work to enhance your experience.': 'உங்கள் சுயவிவரம் துல்லியம் மற்றும் பாதுகாப்பை நிலைநிறுத்துவதற்கு உன்னிப்பாக வடிவமைக்கப்பட்டுள்ளது. உங்கள் அனுபவத்தை மேம்படுத்த நாங்கள் உழைக்கும்போது உங்கள் பொறுமை மற்றும் புரிதலை நாங்கள் மனதாரப் பாராட்டுகிறோம்.',
    'Start Your Bank Account Verification': 'உங்கள் வங்கி கணக்கு சரிபார்ப்பை தொடங்கவும்',
    'Bank Verification': 'வங்கி சரிபார்ப்பு',
    'Ensuring the accuracy of your bank account information is crucial for a seamless verification process. Kindly enter your valid Account Number, and input the correct IFSC code associated with your account.': 'உங்கள் வங்கிக் கணக்குத் தகவலின் துல்லியத்தை உறுதி செய்வது தடையற்ற சரிபார்ப்புச் செயல்முறைக்கு முக்கியமானது. தயவுசெய்து உங்கள் கணக்கு எண்ணை உள்ளிட்டு, உங்கள் கணக்குடன் தொடர்புடைய சரியான IFSC குறியீட்டை உள்ளிடவும்.',
    'This verification step is designed to enhance the security of our services and provide you with a reliable experience.': 'இந்த சரிபார்ப்பு படி எங்கள் சேவைகளின் பாதுகாப்பை மேம்படுத்தவும் நம்பகமான அனுபவத்தை உங்களுக்கு வழங்கவும் வடிவமைக்கப்பட்டுள்ளது.',
    'Account Number': 'கணக்கு எண்',
    'IFSC code': 'IFSC குறியீடு',
    'Error: Invalid Bank Account or IFSC Code': 'பிழை: தவறான வங்கிக் கணக்கு அல்லது IFSC குறியீடு',
    'We regret to inform you that the Bank Account or IFSC Code entered is invalid or incorrect. Please ensure you have accurately entered both details and double-check for any typos.': 'உள்ளிடப்பட்ட வங்கிக் கணக்கு அல்லது IFSC குறியீடு தவறானது அல்லது தவறானது என்பதை உங்களுக்குத் தெரிவிக்க வருந்துகிறோம். இரண்டு விவரங்களையும் துல்லியமாக உள்ளிட்டுள்ளதை உறுதிசெய்து, ஏதேனும் எழுத்துப் பிழைகள் உள்ளதா என இருமுறை சரிபார்க்கவும்.',
    'If you continue to face issues, kindly verify your bank statement or contact your bank to confirm the accuracy of the provided information.': 'நீங்கள் தொடர்ந்து சிக்கல்களைச் சந்தித்தால், உங்கள் வங்கி அறிக்கையைச் சரிபார்க்கவும் அல்லது வழங்கப்பட்ட தகவலின் துல்லியத்தை உறுதிப்படுத்த உங்கள் வங்கியைத் தொடர்பு கொள்ளவும்.',
    'Aadhaar Details Verification': 'ஆதார் விவரங்கள் சரிபார்ப்பு',
    'Enter Your Aadhaar Number': 'உங்கள் ஆதார் எண்ணை உள்ளிடவும்',
    'Please input your Aadhaar number. This process ensures accurate cross-checking for your security. Thank you for your cooperation.': 'உங்கள் ஆதார் எண்ணை உள்ளிடவும். இந்த செயல்முறை உங்கள் பாதுகாப்பிற்கான துல்லியமான குறுக்கு சோதனையை உறுதி செய்கிறது. உங்கள் ஒத்துழைப்பிற்கு நன்றி.',
    'Enter Your Aadhaar OTP Here': 'உங்கள் ஆதார் OTP ஐ இங்கே உள்ளிடவு',
    'Error: Incorrect Aadhaar OTP': 'பிழை: தவறான ஆதார் OTP',
    'We regret to inform you that the One-Time Password (OTP) entered for Aadhaar verification is incorrect. Please ensure that you have accurately entered the OTP received on your registered mobile number.': 'ஆதார் சரிபார்ப்புக்காக உள்ளிடப்பட்ட ஒரு முறை கடவுச்சொல் (OTP) தவறானது என்பதை தெரிவித்துக்கொள்கிறோம். உங்கள் பதிவு செய்யப்பட்ட மொபைல் எண்ணில் பெறப்பட்ட OTPயை நீங்கள் துல்லியமாக உள்ளிட்டுள்ளீர்கள் என்பதை உறுதிப்படுத்தவும்.',
    'If the issue persists, you may request a new OTP by clicking on the resend link or contacting our support team at +91-9699900111.': 'சிக்கல் தொடர்ந்தால், மீண்டும் அனுப்பும் இணைப்பைக் கிளிக் செய்வதன் மூலம் அல்லது எங்கள் ஆதரவுக் குழுவை +91-9699900111 என்ற எண்ணில் தொடர்புகொள்வதன் மூலம் நீங்கள் புதிய OTP-ஐக் கோரலாம்.',
    'Double-check the email and try entering the OTP again.': 'மின்னஞ்சலை இருமுறை சரிபார்த்து, மீண்டும் OTP ஐ உள்ளிட முயற்சிக்கவும்.',
    'Error: Invalid Aadhaar Number': 'பிழை: தவறான ஆதார் எண்',
    'We apologize for the inconvenience. The Aadhaar number entered is invalid. Please double-check and ensure that you have entered the correct 12-digit Aadhaar number.': 'சிரமத்திற்கு வருந்துகிறோம். உள்ளிட்ட ஆதார் எண் தவறானது. சரியான 12 இலக்க ஆதார் எண்ணை உள்ளிட்டுள்ளீர்களா என்பதை இருமுறை சரிபார்த்து உறுதிப்படுத்தவும்.',
    'If you continue to face issues, verify your Aadhaar card or contact our support team at +91-9699900111 for further assistance. We appreciate your cooperation.': 'நீங்கள் தொடர்ந்து சிக்கல்களைச் சந்தித்தால், உங்கள் ஆதார் அட்டையைச் சரிபார்க்கவும் அல்லது கூடுதல் உதவிக்கு எங்கள் ஆதரவுக் குழுவை +91-9699900111 என்ற எண்ணில் தொடர்பு கொள்ளவும். உங்கள் ஒத்துழைப்பை நாங்கள் பாராட்டுகிறோம்.',

    //newly added

    "Please ensure you have entered the correct GST details and follow the specified format for accurate verification.": "நீங்கள் சரியான ஜிஎஸ்டி விவரங்களை உள்ளிட்டுள்ளதை உறுதிசெய்து, துல்லியமான சரிபார்ப்புக்கு குறிப்பிட்ட வடிவமைப்பைப் பின்பற்றவும்.",
    "If you continue to face issues, kindly double-check your PAN card and re-enter the details.": "நீங்கள் தொடர்ந்து சிக்கல்களை எதிர்கொண்டால், தயவுசெய்து உங்கள் பான் கார்டை இருமுறை சரிபார்த்து, விவரங்களை மீண்டும் உள்ளிடவும்.",
    "The GST provided already exists in our records. Please try using a different GST for verification.": "வழங்கப்பட்ட ஜிஎஸ்டி ஏற்கனவே எங்கள் பதிவுகளில் உள்ளது. சரிபார்ப்பிற்கு வேறு GSTயைப் பயன்படுத்தவும்.",
    "It appears that the PAN details provided belong to a company. We require your personal PAN details for verification purposes.": "வழங்கப்பட்ட பான் விவரங்கள் ஒரு நிறுவனத்திற்கு சொந்தமானது என்று தெரிகிறது. சரிபார்ப்பு நோக்கங்களுக்காக உங்களின் தனிப்பட்ட PAN விவரங்கள் தேவை..",
    "If you continue to face issues, kindly double-check your PAN card and re- enter the details.For further queries or assistance, feel free to call us at +91-9699900111.": "நீங்கள் தொடர்ந்து சிக்கல்களை எதிர்கொண்டால், தயவுசெய்து உங்கள் பான் கார்டை இருமுறை சரிபார்த்து, விவரங்களை மீண்டும் உள்ளிடவும். மேலும் கேள்விகள் அல்லது உதவிக்கு, எங்களை +91-9699900111 என்ற எண்ணில் அழைக்கவும்.",
    "The PAN provided already exists in our records. Please try using a different PAN for verification.": "வழங்கப்பட்ட PAN ஏற்கனவே எங்கள் பதிவுகளில் உள்ளது. சரிபார்ப்பிற்கு வேறு PAN ஐப் பயன்படுத்த முயற்சிக்கவும்.",
    "New User Registration": "புதிய பயனர் பதிவு",
    "To begin your journey with us, we kindly request all new users to register by following the simple steps outlined below:": "எங்களுடன் உங்கள் பயணத்தைத் தொடங்க, கீழே உள்ள எளிய வழிமுறைகளைப் பின்பற்றி அனைத்து புதிய பயனர்களையும் பதிவு செய்யுமாறு கேட்டுக்கொள்கிறோம்:",
    "Registration": "பதிவு",
    "To register and gain access to our comprehensive verification services, please proceed by making a one-time payment of": "எங்கள் விரிவான சரிபார்ப்புச் சேவைகளைப் பதிவுசெய்து அணுகலைப் பெற, தயவு செய்து ஒரு முறை கட்டணம் செலுத்தி தொடரவும்",
    "Rs. 500": "ரூ. 500",
    "GST": "ஜிஎஸ்டி",
    "done": "முடிந்தது",
    "Please note that the registration fee is subject to 18% GST (Goods and Services Tax) as per government regulations.": "பதிவுக் கட்டணம் அரசாங்க விதிமுறைகளின்படி 18% GST (சரக்கு மற்றும் சேவை வரி)க்கு உட்பட்டது என்பதை நினைவில் கொள்ளவும்.",
    "Total Amount Due:": "செலுத்த வேண்டிய மொத்த தொகை:",
    "Rs. 590": "ரூ. 590",
    "Total Registration Fee + 18% GST": "மொத்தப் பதிவுக் கட்டணம் + 18% ஜிஎஸ்டி",
    "Secure Payment Gateway": "பாதுகாப்பான கட்டண நுழைவாயில்",
    "Rest assured, your payment and personal information are handled with the utmost security through our encrypted payment gateway.": "எங்களின் என்க்ரிப்ட் செய்யப்பட்ட பேமெண்ட் கேட்வே மூலம் உங்களது பேமெண்ட் மற்றும் தனிப்பட்ட தகவல்கள் மிகுந்த பாதுகாப்புடன் கையாளப்படுகின்றன.",
    "Registration Complete - Thank You!": "பதிவு முடிந்தது - நன்றி!",
    "Congratulations! You've completed the registration process. Your dedication to providing the necessary information has brought us one step closer to serving you effectively.": "வாழ்த்துகள்! பதிவு செயல்முறையை முடித்துவிட்டீர்கள். தேவையான தகவல்களை வழங்குவதற்கான உங்கள் அர்ப்பணிப்பு, உங்களுக்கு திறம்பட சேவை செய்வதற்கு எங்களை ஒரு படி மேலே கொண்டு வந்துள்ளது.",
    "With your registration finalized, our team is fully equipped to handle your requirements efficiently. We assure you that your case will be handled with the utmost care and attention to detail.": "உங்கள் பதிவு முடிவடைந்த நிலையில், உங்கள் தேவைகளை திறம்பட கையாள எங்கள் குழு முழுமையாக தயாராக உள்ளது. உங்கள் வழக்கு மிகவும் கவனமாகவும் விரிவாகவும் கையாளப்படும் என்று நாங்கள் உறுதியளிக்கிறோம்.",
    "Should you ever require assistance or have any queries, please feel free to contact us at any time. We are here to address your concerns and ensure a smooth journey throughout the process.": "உங்களுக்கு எப்போதாவது உதவி தேவைப்பட்டால் அல்லது ஏதேனும் கேள்விகள் இருந்தால், எந்த நேரத்திலும் எங்களைத் தொடர்புகொள்ள தயங்க வேண்டாம். உங்கள் கவலைகளை நிவர்த்தி செய்யவும், செயல்முறை முழுவதும் சுமூகமான பயணத்தை உறுதி செய்யவும் நாங்கள் இங்கு வந்துள்ளோம்.",
    "We eagerly anticipate the opportunity to assist you further.": "உங்களுக்கு மேலும் உதவுவதற்கான வாய்ப்பை நாங்கள் ஆவலுடன் எதிர்பார்க்கிறோம்.",
    "Warm regards,": "அன்பான வாழ்த்துக்கள்,",
    "PrivateCourt Team": "பிரைவேட் கோர்ட் குழு",
    "Secure UDYAM Number Validation": "பாதுகாப்பான UDYAM எண் சரிபார்ப்பு",
    "Enter UDYAM for": "UDYAM ஐ உள்ளிடவும்",
    "having GST number": "ஜிஎஸ்டி எண் உள்ளது",
    "Enter Your Udyam Number": "உங்கள் Udyam எண்ணை உள்ளிடவும்",
    "Error: Invalid UAM Number": "பிழை: தவறான UAM எண்",
    "We regret to inform you that the UAM Number entered isinvalid or incorrect. Please ensure you have accuratelyentered detail and double-check for any typos.": "உள்ளிட்ட UAM எண் தவறானது அல்லது தவறானது என்பதை உங்களுக்குத் தெரிவிக்க வருந்துகிறோம். நீங்கள் விவரங்களைத் துல்லியமாக உள்ளிட்டுள்ளதை உறுதிசெய்து, ஏதேனும் எழுத்துப் பிழைகள் உள்ளதா என இருமுறை சரிபார்க்கவும்.",
    "Server Overload: Please Retry Later": "சர்வர் ஓவர்லோட்: தயவுசெய்து பிறகு முயற்சிக்கவும்",
    "We apologize for the inconvenience and appreciate your patience. Currently, our server is experiencing a surge in requests, creating a temporary delay. Rest assured, we are diligently collecting your data to ensure accuracy and security.": "சிரமத்திற்கு வருந்துகிறோம், உங்கள் பொறுமையைப் பாராட்டுகிறோம். தற்போது, எங்கள் சேவையகம் கோரிக்கைகளின் அதிகரிப்பை எதிர்கொள்கிறது, தற்காலிக தாமதத்தை உருவாக்குகிறது. உறுதியாக இருங்கள், துல்லியம் மற்றும் பாதுகாப்பை உறுதி செய்வதற்காக உங்கள் தரவை நாங்கள் ஆர்வத்துடன் சேகரித்து வருகிறோம்.",
    "Please bear with us during this busy period, and we kindly request you to wait for a brief moment before attempting again. Your understanding is crucial in maintaining the quality of service we strive to provide.": "இந்த பரபரப்பான காலகட்டத்தில் தயவுசெய்து எங்களுடன் பொறுமையாக இருங்கள், மீண்டும் முயற்சிக்கும் முன் சிறிது நேரம் காத்திருக்குமாறு கேட்டுக்கொள்கிறோம். நாங்கள் வழங்க முயற்சிக்கும் சேவையின் தரத்தை பராமரிப்பதில் உங்கள் புரிதல் முக்கியமானது.",
    "For urgent matters or assistance, feel free to call us at +91-9699900111.": "அவசர விஷயங்கள் அல்லது உதவிக்கு, எங்களை +91-9699900111 என்ற எண்ணில் அழைக்கவும்.",
    "Thank you for your patience and entrusting PrivateCourt. Your security is our priority.": "உங்கள் பொறுமைக்கும், தனியார் நீதிமன்றத்தை நம்பி ஒப்படைத்ததற்கும் நன்றி. உங்கள் பாதுகாப்பு எங்கள் முன்னுரிமை.",
    "If you continue to face issues, verify the": "நீங்கள் தொடர்ந்து சிக்கல்களை எதிர்கொண்டால், சரிபார்க்கவும்",
    "Applicant’s": "விண்ணப்பதாரரின்",
    "Error: Invalid OTP": "பிழை: தவறான OTP",
    "We apologize for the inconvenience. The OTP entered is incorrect. Please double-check the OTP and try again.": "சிரமத்திற்கு வருந்துகிறோம். உள்ளிட்ட OTP தவறானது. OTP ஐ இருமுறை சரிபார்த்து மீண்டும் முயற்சிக்கவும்.",
    "Aadhaar card or contact our support team at +91-9699900111 for further assistance. We appreciate your cooperation.": "மேலும் உதவிக்கு ஆதார் அட்டை அல்லது எங்கள் ஆதரவு குழுவை +91-9699900111 என்ற எண்ணில் தொடர்பு கொள்ளவும். உங்கள் ஒத்துழைப்பை நாங்கள் பாராட்டுகிறோம்.",
    "Error: User already exists": "பிழை: பயனர் ஏற்கனவே இருக்கிறார்",
    "We apologize for the inconvenience. The Aadhaar number provided already exists in our records.Please provide a different Aadhaar number for verification.": "சிரமத்திற்கு வருந்துகிறோம். வழங்கப்பட்ட ஆதார் எண் ஏற்கனவே எங்கள் பதிவுகளில் உள்ளது. சரிபார்ப்பதற்காக வேறு ஆதார் எண்ணை வழங்கவும்.",
    "We apologize for the inconvenience. The name provided does not match the PAN records. Please ensure that the name entered matches the information on your PAN card and try again.": "சிரமத்திற்கு வருந்துகிறோம். வழங்கப்பட்ட பெயர் PAN பதிவுகளுடன் பொருந்தவில்லை. உள்ளிட்ட பெயர் உங்கள் பான் கார்டில் உள்ள தகவலுடன் பொருந்துகிறதா என்பதை உறுதிசெய்து, மீண்டும் முயற்சிக்கவும்.",
    "Enter Bank Details for": "வங்கி விவரங்களை உள்ளிடவும்",
    "Error: Already Exists": "பிழை: ஏற்கனவே உள்ளது",
    "We regret to inform you that the ID you've provided already exists in our system. Please try another ID.": "நீங்கள் வழங்கிய ஐடி ஏற்கனவே எங்கள் சிஸ்டத்தில் உள்ளது என்பதை தெரிவித்துக்கொள்கிறோம். வேறொரு ஐடியை முயற்சிக்கவும்.",
    "If you continue to face issues, kindly verify your bank statement or contact your bank to confirm the accuracy of the provided information.": "நீங்கள் தொடர்ந்து சிக்கல்களைச் சந்தித்தால், உங்கள் வங்கி அறிக்கையைச் சரிபார்க்கவும் அல்லது வழங்கப்பட்ட தகவலின் துல்லியத்தை உறுதிப்படுத்த உங்கள் வங்கியைத் தொடர்பு கொள்ளவும்.",
    "Error: Name Not Matched": "பிழை: பெயர் பொருந்தவில்லை",
    "Good Afternoon": "மதிய வணக்கம்",
    "Account security starts with verification": "கணக்கு பாதுகாப்பு சரிபார்ப்புடன் தொடங்குகிறது",
    "OTP Validation for Account Access": "கணக்கு அணுகலுக்கான OTP சரிபார்ப்பு",
    "Kindly check your inbox, and enter the OTP in the designated field to complete the verification process.": "உங்கள் இன்பாக்ஸைச் சரிபார்த்து, சரிபார்ப்பு செயல்முறையை முடிக்க நியமிக்கப்பட்ட புலத்தில் OTP ஐ உள்ளிடவும்.",
    "Request a Resend": "மீண்டும் அனுப்பக் கோரவும்",
    "Error: Incorrect OTP": "பிழை: தவறான OTP",
    "We apologize for the inconvenience. The One-Time Password (OTP) entered is incorrect. Please ensure that you have accurately entered the OTP received in your email.": "சிரமத்திற்கு வருந்துகிறோம். உள்ளிடப்பட்ட ஒரு முறை கடவுச்சொல் (OTP) தவறானது. உங்கள் மின்னஞ்சலில் பெறப்பட்ட OTPயை நீங்கள் துல்லியமாக உள்ளிட்டுள்ளீர்கள் என்பதை உறுதிப்படுத்தவும்.",
    "If the issue persists, you may request a new OTP by clicking on the resend link or contacting our support team at +91-9699900111.": "சிக்கல் தொடர்ந்தால், மீண்டும் அனுப்பும் இணைப்பைக் கிளிக் செய்வதன் மூலம் அல்லது எங்கள் ஆதரவுக் குழுவை +91-9699900111 என்ற எண்ணில் தொடர்புகொள்வதன் மூலம் நீங்கள் புதிய OTP-ஐக் கோரலாம்.",
    "Double-check the email and try entering the OTP again.": "மின்னஞ்சலை இருமுறை சரிபார்த்து, மீண்டும் OTP ஐ உள்ளிட முயற்சிக்கவும்.",
    "At PrivateCourt, we prioritize the security and integrity of our services. Our GST verification process is meticulously designed to ensure a reliable and secure experience for all our users.": "PrivateCourt இல், எங்கள் சேவைகளின் பாதுகாப்பு மற்றும் ஒருமைப்பாட்டுக்கு முன்னுரிமை அளிக்கிறோம். எங்கள் ஜிஎஸ்டி சரிபார்ப்பு செயல்முறை, எங்கள் பயனர்கள் அனைவருக்கும் நம்பகமான மற்றும் பாதுகாப்பான அனுபவத்தை உறுதிசெய்யும் வகையில் மிகவும் கவனமாக வடிவமைக்கப்பட்டுள்ளது.",
    "Enter Your GSTIN": "உங்கள் GSTIN ஐ உள்ளிடவும்",
    "Please enter your correct GST Identification Number (GSTIN) in the format 00-XXXXX-0000-X-0-X-0.": "உங்கள் சரியான GST அடையாள எண்ணை (GSTIN) 00-XXXXX-0000-X-0-X-0 வடிவத்தில் உள்ளிடவும்.",
    "Upon entering your GSTIN, PrivateCourt will diligently collect essential information associated with it. This includes your full name, date of birth, residential address, and contact details. For business owners, we extend our data collection to encompass all pertinent business details tied to your GSTIN, such a specifics and records of GST return filings.": "உங்கள் ஜிஎஸ்டிஐஎன் உள்ளிடும்போது, அதனுடன் தொடர்புடைய அத்தியாவசியத் தகவல்களை தனியார் நீதிமன்றம் ஆர்வத்துடன் சேகரிக்கும். இதில் உங்கள் முழுப்பெயர், பிறந்த தேதி, வசிக்கும் முகவரி மற்றும் தொடர்பு விவரங்கள் ஆகியவை அடங்கும். வணிக உரிமையாளர்களுக்காக, உங்கள் GSTIN உடன் இணைக்கப்பட்ட அனைத்து வணிக விவரங்களையும் உள்ளடக்கியதாக எங்கள் தரவு சேகரிப்பை விரிவுபடுத்துகிறோம்.",
    "Our system assesses your CIBIL score for financial insight and cross-verifies for any ongoing court cases associated with your GSTIN.": "எங்கள் அமைப்பு உங்கள் CIBIL ஸ்கோரை நிதி நுண்ணறிவுக்காக மதிப்பிடுகிறது மற்றும் உங்கள் GSTIN உடன் தொடர்புடைய நீதிமன்ற வழக்குகளுக்கு குறுக்கு-சரிபார்க்கிறது.",
    "Error: Invalid GST Format": "பிழை: தவறான GST வடிவம்",
    "Please enter your personal PAN": "உங்கள் தனிப்பட்ட PAN எண்ணை உள்ளிடவும்",
    "Already Exists": "ஏற்கனவே இருக்கிறது",
    "Invalid": "செல்லாதது",
    "Secure PAN Validation Process": "பாதுகாப்பான பான் சரிபார்ப்பு செயல்முறை",
    "This verification process is designed to uphold the integrity of our services, ensuring a reliable and secure experience.": "இந்தச் சரிபார்ப்புச் செயல்முறையானது, எங்கள் சேவைகளின் ஒருமைப்பாட்டை நிலைநிறுத்தி, நம்பகமான மற்றும் பாதுகாப்பான அனுபவத்தை உறுதிசெய்யும் வகையில் வடிவமைக்கப்பட்டுள்ளது.",
    "Enter Your PAN Number": "உங்கள் PAN எண்ணை உள்ளிடவும்",
    "Please enter your correct PAN number in the format XXXXX-0000-X.": "உங்கள் சரியான PAN எண்ணை XXXXX-0000-X வடிவத்தில் உள்ளிடவும்.",
    "PrivateCourt will meticulously collect essential information associated with your PAN such as your full name, date of birth, residential address, and contact details. For Business Owners, we extend our data collection to encompass all pertinent business details tied to your PAN such as GST details specifics, and records of GST return filings.": "உங்களின் முழுப்பெயர், பிறந்த தேதி, வசிக்கும் முகவரி மற்றும் தொடர்பு விவரங்கள் போன்ற உங்களின் பான் எண்ணுடன் தொடர்புடைய அத்தியாவசியத் தகவல்களை PrivateCourt உன்னிப்பாகச் சேகரிக்கும். வணிக உரிமையாளர்களுக்கு, ஜிஎஸ்டி விவரங்கள் விவரங்கள் மற்றும் ஜிஎஸ்டி ரிட்டர்ன் தாக்கல்களின் பதிவுகள் போன்ற உங்கள் PAN உடன் இணைக்கப்பட்ட அனைத்து வணிக விவரங்களையும் உள்ளடக்கியதாக எங்கள் தரவு சேகரிப்பை விரிவுபடுத்துகிறோம்.",
    "Our system assesses your CIBIL score for financial insight and cross-verifies for any ongoing court cases associated with your PAN.": "எங்கள் அமைப்பு உங்கள் CIBIL ஸ்கோரை நிதி நுண்ணறிவுக்காக மதிப்பிடுகிறது மற்றும் உங்கள் PAN உடன் தொடர்புடைய நீதிமன்ற வழக்குகளுக்கு குறுக்கு-சரிபார்க்கிறது.",
    "It appears that the PAN details provided belong to a company. We require your personal PAN details for verification purposes.": "வழங்கப்பட்ட பான் விவரங்கள் ஒரு நிறுவனத்திற்கு சொந்தமானது என்று தெரிகிறது. சரிபார்ப்பு நோக்கங்களுக்காக உங்களின் தனிப்பட்ட PAN விவரங்கள் தேவை.",



    "Data Compilation in Progress: Please Allow Processing Time": "தரவு தொகுத்தல் செயல்பாட்டில் உள்ளது: செயலாக்க நேரத்தை அனுமதிக்கவும்",
    "Thank you for providing your information. Our system is currently in the process of analyzing and compiling the data you've entered, including details such as PAN, Aadhaar, GST, Email, and more, to establish your comprehensive profile.": "உங்கள் தகவலை வழங்கியதற்கு நன்றி. உங்கள் விரிவான சுயவிவரத்தை உருவாக்க, PAN, ஆதார், GST, மின்னஞ்சல் மற்றும் பல போன்ற விவரங்கள் உட்பட, நீங்கள் உள்ளிட்ட தரவை பகுப்பாய்வு செய்து தொகுக்கும் பணியில் எங்கள் அமைப்பு தற்போது ஈடுபட்டுள்ளது.",
    "This operation may take a few moments. During this time, your screen may show minimal activity. Please be assured that this is a routine part of our data compilation process.": "இந்தச் செயல்பாடு சில நொடிகள் ஆகலாம். இந்த நேரத்தில், உங்கள் திரை குறைந்தபட்ச செயல்பாட்டைக் காட்டலாம். இது எங்கள் தரவுத் தொகுத்தல் செயல்முறையின் வழக்கமான பகுதியாகும் என்பதை உறுதிப்படுத்திக் கொள்ளவும்.",
    "Your profile is being meticulously crafted to uphold accuracy and security. We sincerely appreciate your patience and understanding as we work to enhance your experience.": "உங்கள் சுயவிவரம் துல்லியம் மற்றும் பாதுகாப்பை நிலைநிறுத்துவதற்கு உன்னிப்பாக வடிவமைக்கப்பட்டுள்ளது. உங்கள் அனுபவத்தை மேம்படுத்த நாங்கள் உழைக்கும்போது உங்கள் பொறுமை மற்றும் புரிதலை நாங்கள் மனதாரப் பாராட்டுகிறோம்.",
    "For urgent matters or assistance, feel free to call us at +91-9699900111.": "அவசர விஷயங்கள் அல்லது உதவிகளுக்கு, எங்களை +91-9699900111 என்ற எண்ணில் அழைக்கவும்.",
    "Thank you for your patience and entrusting PrivateCourt. Your security is our priority.": "உங்கள் பொறுமைக்கும், தனியார் நீதிமன்றத்தை நம்பி ஒப்படைத்ததற்கும் நன்றி. உங்கள் பாதுகாப்பு எங்கள் முன்னுரிமை.",
    "Please input your Aadhaar number. This process ensures accurate cross-checking for your security. Thank you for your cooperation.": "உங்கள் ஆதார் எண்ணை உள்ளிடவும். இந்த செயல்முறை உங்கள் பாதுகாப்பிற்கான துல்லியமான குறுக்கு சோதனையை உறுதி செய்கிறது. உங்கள் ஒத்துழைப்பிற்கு நன்றி.",
    "We apologize for the inconvenience. The Aadhaar number entered is invalid. Please double-check and ensure that you have entered the": "சிரமத்திற்கு வருந்துகிறோம். உள்ளிட்ட ஆதார் எண் தவறானது. இருமுறை சரிபார்த்து, நீங்கள் உள்ளிட்டுள்ளீர்கள் என்பதை உறுதிப்படுத்தவும்",
    "Applicant’s correct 12-digit Aadhaar number.": "விண்ணப்பதாரரின் சரியான 12 இலக்க ஆதார் எண்.",
    "Error: Invalid OTP": "பிழை: தவறான OTP",
    "We apologize for the inconvenience. The OTP entered is incorrect. Please double-check the OTP and try again.": "சிரமத்திற்கு வருந்துகிறோம். உள்ளிட்ட OTP தவறானது. OTP ஐ இருமுறை சரிபார்த்து மீண்டும் முயற்சிக்கவும்.",
    "Welcome": "வரவேற்பு",
    "Ensuring the accuracy of your bank account information is crucial for a seamless verification process.Kindly enter your valid Account Number, and input the correct IFSC code associated with your account.": "தடையற்ற சரிபார்ப்புச் செயல்முறைக்கு உங்கள் வங்கிக் கணக்குத் தகவலின் துல்லியத்தை உறுதிசெய்வது மிகவும் முக்கியமானது. உங்கள் செல்லுபடியாகும் கணக்கு எண்ணை உள்ளிட்டு, உங்கள் கணக்குடன் தொடர்புடைய சரியான IFSC குறியீட்டை உள்ளிடவும்.",
    "This verification step is designed to enhance the security of our services and provide you with a reliable experience.": "இந்த சரிபார்ப்பு படி எங்கள் சேவைகளின் பாதுகாப்பை மேம்படுத்தவும் நம்பகமான அனுபவத்தை உங்களுக்கு வழங்கவும் வடிவமைக்கப்பட்டுள்ளது.",
    "We apologize for the inconvenience. The name provided does not match the GST records. Please ensure that the name entered matches the information on your GST card and try again.": "சிரமத்திற்கு வருந்துகிறோம். வழங்கப்பட்ட பெயர் GST பதிவுகளுடன் பொருந்தவில்லை. உள்ளிட்ட பெயர் உங்கள் ஜிஎஸ்டி கார்டில் உள்ள தகவலுடன் பொருந்துகிறதா என்பதை உறுதிசெய்து, மீண்டும் முயற்சிக்கவும்.",
    "Business Verification Process": "வணிக சரிபார்ப்பு செயல்முறை",
    "Ensuring Secure Business Validation- This verification process is meticulously designed to uphold the integrity of our services, providing a reliable and secure experience.": "பாதுகாப்பான வணிகச் சரிபார்ப்பை உறுதி செய்தல்- இந்தச் சரிபார்ப்புச் செயல்முறையானது, நம்பகமான மற்றும் பாதுகாப்பான அனுபவத்தை வழங்கும், எங்கள் சேவைகளின் ஒருமைப்பாட்டை நிலைநிறுத்துவதற்காக உன்னிப்பாக வடிவமைக்கப்பட்டுள்ளது.",
    "Please provide details for each of your businesses for accurate validation.": "துல்லியமான சரிபார்ப்புக்கு உங்கள் ஒவ்வொரு வணிகத்திற்கும் விவரங்களை வழங்கவும்.",
    "PrivateCourt will collect essential information associated with your PAN such as your full name, date of birth, residential address, and contact details. For Business Owners, we extend our data collection to encompass all pertinent business details tied to your PAN such as GST details, MCA specifics, and records of GST return filings.": "உங்கள் முழுப்பெயர், பிறந்த தேதி, குடியிருப்பு முகவரி மற்றும் தொடர்பு விவரங்கள் போன்ற உங்கள் PAN உடன் தொடர்புடைய அத்தியாவசியத் தகவல்களை PrivateCourt சேகரிக்கும். வணிக உரிமையாளர்களுக்கு, GST விவரங்கள், MCA விவரக்குறிப்புகள் மற்றும் GST ரிட்டர்ன் தாக்கல்களின் பதிவுகள் போன்ற உங்கள் PAN உடன் இணைக்கப்பட்டுள்ள அனைத்து தொடர்புடைய வணிக விவரங்களையும் உள்ளடக்கும் வகையில் எங்கள் தரவு சேகரிப்பை விரிவுபடுத்துகிறோம்.",
    "Our system evaluates your CIBIL score for financial insight and cross-verifies for any ongoing court cases associated with your PAN.": "நிதி நுண்ணறிவுக்காக உங்கள் CIBIL ஸ்கோரை எங்கள் அமைப்பு மதிப்பிடுகிறது மற்றும் உங்கள் PAN உடன் தொடர்புடைய நீதிமன்ற வழக்குகளுக்கு குறுக்கு சரிபார்ப்பு செய்கிறது.",
    "To ensure the security of your account, please enter your valid Email ID in the specified format- username@domain.com. If your email address doesn't exist in our system, a": "உங்கள் கணக்கின் பாதுகாப்பை உறுதிசெய்ய, உங்கள் சரியான மின்னஞ்சல் ஐடியை குறிப்பிட்ட வடிவத்தில் உள்ளிடவும்- username@domain.com. உங்கள் மின்னஞ்சல் முகவரி எங்கள் அமைப்பில் இல்லை என்றால், ஏ",
    "account will be created for you.": "உங்களுக்காக கணக்கு உருவாக்கப்படும்.",
    "GST Verification Process": "ஜிஎஸ்டி சரிபார்ப்பு செயல்முறை",
    "We regret to inform you that the GST number entered does not match the required format (00-XXXXX-0000-X-0-X-0).Please ensure you have entered the correct GST details and follow the specified format for accurate verification.": "உள்ளிடப்பட்ட ஜிஎஸ்டி எண் தேவையான வடிவத்துடன் (00-XXXXX-0000-X-0-X-0) பொருந்தவில்லை என்பதைத் தெரிவித்துக் கொள்கிறோம். சரியான ஜிஎஸ்டி விவரங்களை உள்ளிட்டுள்ளதை உறுதிசெய்து, துல்லியமான சரிபார்ப்புக்கு குறிப்பிட்ட வடிவமைப்பைப் பின்பற்றவும்.",
    "Verify GST": "ஜிஎஸ்டியைச் சரிபார்க்கவும்",
    "Congratulations! You've completed the registration process. Your dedication to providing the necessary information has brought us one step closer to serving you effectively.": "வாழ்த்துகள்! பதிவு செயல்முறையை முடித்துவிட்டீர்கள். தேவையான தகவல்களை வழங்குவதற்கான உங்கள் அர்ப்பணிப்பு, உங்களுக்கு திறம்பட சேவை செய்வதற்கு எங்களை ஒரு படி மேலே கொண்டு வந்துள்ளது.",
    "We apologize for the inconvenience. The name provided does not match the GST records. Please ensure that the name entered matches the information on your GST card and try again.": "சிரமத்திற்கு வருந்துகிறோம். வழங்கப்பட்ட பெயர் GST பதிவுகளுடன் பொருந்தவில்லை. உள்ளிட்ட பெயர் உங்கள் ஜிஎஸ்டி கார்டில் உள்ள தகவலுடன் பொருந்துகிறதா என்பதை உறுதிசெய்து, மீண்டும் முயற்சிக்கவும்.",
    "Skip": "தவிர்க்கவும்",
    "Server Overload: Please Retry Later": "சர்வர் ஓவர்லோட்: தயவுசெய்து பிறகு முயற்சிக்கவும்",
    "Back To Home": "வீட்டிற்குத் திரும்பு",

    'Language Selection:': '',
    'Empowering Effective Communication': '',
    "Good day! I'm Murthy, your dedicated assistant for today's proceedings. Lets start by selecting the language you're most comfortable with.": '',
    "Opting for your preferred language ensures clear and effective communication. Whether it's your native tongue or another language of your choice, selecting it will streamline our interaction and enhance your experience.": "",
    "Select Your Preferred Language": "",
    "Welcome to PrivateCourt, where Helping you is our priority.": "",
    "Now, let me introduce you to PrivateCourt – your reliable partner in resolving lending disputes effectively and efficiently.": '',
    "PrivateCourt: Your trusted partner in resolving lending disputes with care and expertise.": "",
    "Welcome to PrivateCourt, where your case details are in good hands. With the lending company initiating arbitration or conciliation, it's essential for us to understand your situation fully. Please share all the details you can.": "",
    "The more information we have, the better we can help. Your input is crucial in finding the right solution for you.": "",
    "Trust us to carefully examine your case and work together to find the best way forward.": "",
    "Reviewing Your Dispute Information": 'आपकी विवाद जानकारी की समीक्षा करना',
    "Let's take a closer look at the details surrounding your dispute. The information we're discussing today has been shared with us by LendingKart, regarding a personal loan.": "",
    "By understanding these specifics, we can better assess the situation and formulate a resolution plan that aligns with your circumstances and goals.": "",
    "Dispute Amount Information": "",
    "According to their records, you initially borrowed an amount of": "",
    "Presently, your outstanding due stands at Rs.": "",
    "These details provide us with valuable insight into the history and current status of your loan.": "",
    "Spotting Financial Issues: Understanding Unpaid Obligations": "",
    "Now, let's gently explore the challenges you've faced due to financial constraints. Discussing this may be sensitive, but your openness will greatly assist us in finding the best path forward. Please take a moment to select any of the following options that resonate with your experience:": "",
    "Financial Loss": "",
    "Business Loss": "",
    "Partner Dispute": "",
    "Funds held with other vendors or clients": "",
    "Monetary loss through other means": "",
    "Job Loss": "",
    "Less than 2 months": "",
    "Less than 6 months": "",
    "Less than 1 year": "",
    "More than 1 year": "",
    "Health Issue": "",
    "Death of the earning member": "",
    "Missing payment details": "",
    "Dispute due to bad behaviour": "",
    "No clear loan information": "",
    "Death Case": "",
    "We kindly request you to upload the death certificate at": "",
    "Other reason/s": "",
    "Your prompt response will greatly assist us in assessing the situation and finding the most suitable resolution. Rest assured, we are committed to working with you to resolve any outstanding issues and ensure a fair and satisfactory outcome.": "",
    "Exploring Settlement Possibilities": "",
    "As our discussion progresses, I appreciate your openness in explaining the reason for the default. It's crucial to address your outstanding dues promptly to prevent any legal complexities. We recommend exploring different avenues, such as seeking assistance from family or investigating alternative financing options, to fulfill your obligations without delay.": "",
    "Timely settlement not only mitigates legal risks but also strengthens trust and credibility in your financial interactions. Your cooperation in this matter is truly valued.": "",
    "Settlement Options": "",
    "In our efforts to facilitate a swift resolution, we seek your cooperation.": "",
    "Are you open to discussing the settlement of your outstanding dues?": "",
    "We appreciate your openness to resolving your outstanding dues. To better understand your financial situation and tailor our approach accordingly, could you kindly provide details on your current family income?": "",
    "In our efforts to address your outstanding dues, we'd like to know if a one-time payment settlement is an option for you.": "",



    "Verify Aadhaar": "ஆதாரை சரிபார்க்கவும்",
    "having PAN number": "பான் எண் உள்ளது",
    "Verify Bank": "வங்கியைச் சரிபார்க்கவும்",
    "Select Businesses": "வணிகங்களைத் தேர்ந்தெடு",
    "I am individual": "நான் தனிப்பட்டவன்",
    "Securely Verify Your Credit Score": "உங்கள் கிரெடிட் ஸ்கோரைப் பாதுகாப்பாகச் சரிபார்க்கவும்",
    "Enter Your Mobile Number": "உங்கள் மொபைல் எண்ணை உள்ளிடவும்",
    "To access your credit score securely, please enter your mobile number below. We'll send you a one-time password (OTP) to verify your identity and ensure the confidentiality of your information.": "உங்கள் கிரெடிட் ஸ்கோரைப் பாதுகாப்பாக அணுக, கீழே உங்கள் மொபைல் எண்ணை உள்ளிடவும். உங்கள் அடையாளத்தைச் சரிபார்க்கவும், உங்கள் தகவலின் ரகசியத்தன்மையை உறுதிப்படுத்தவும் ஒரு முறை கடவுச்சொல்லை (OTP) அனுப்புவோம்.",
    "Verify without OTP": "OTP இல்லாமல் சரிபார்க்கவும்",
    "Upon submission, check your messages promptly for the OTP. This step ensures your financial information remains protected while you gain valuable insights into your credit profile.": "சமர்ப்பித்ததும், OTPக்காக உங்கள் செய்திகளை உடனடியாகச் சரிபார்க்கவும். உங்கள் கிரெடிட் சுயவிவரத்தில் மதிப்புமிக்க நுண்ணறிவுகளைப் பெறும்போது, ​​உங்கள் நிதித் தகவல் பாதுகாக்கப்படுவதை இந்தப் படி உறுதி செய்கிறது.",
    "Enter Your OTP Here": "உங்கள் OTP ஐ இங்கே உள்ளிடவும்",
    "Error: Invalid Mobile Number": "பிழை: தவறான மொபைல் எண்",
    "We apologize for the inconvenience. The Mobile number entered is invalid. Please double-check and ensure that you have entered the": "சங்கடத்திற்கு வருந்துகிறோம். உள்ளிட்ட மொபைல் எண் தவறானது. இருமுறை சரிபார்த்து, நீங்கள் உள்ளிட்டுள்ளீர்கள் என்பதை உறுதிப்படுத்தவும்",
    "Applicant’s correct 10-digit Mobile number.": "விண்ணப்பதாரரின் சரியான 10 இலக்க மொபைல் எண்.",
    "Mobile Number or contact our support team at +91-9699900111 for further assistance. We appreciate your cooperation.": "கூடுதல் உதவிக்கு மொபைல் எண்ணை அல்லது எங்கள் ஆதரவுக் குழுவை +91-9699900111 இல் தொடர்பு கொள்ளவும். உங்கள் ஒத்துழைப்பை நாங்கள் பாராட்டுகிறோம்.",
    "Good Morning": "காலை வணக்கம்",
    "Good Evening": "நல்ல மாலை",
    "Good Night": "குட் நைட்",
    "We regret to inform you that the GST number entered does not match the required format (00-XXXXX-0000-X-0-X-0). Please ensure you have entered the correct GST details and follow the specified format for accurate verification.": "உள்ளிடப்பட்ட ஜிஎஸ்டி எண் தேவையான வடிவத்துடன் (00-XXXXX-0000-X-0-X-0) பொருந்தவில்லை என்பதைத் தெரிவித்துக்கொள்கிறோம். சரியான ஜிஎஸ்டி விவரங்களை உள்ளிட்டுள்ளதை உறுதிசெய்து, துல்லியமான சரிபார்ப்புக்கு குறிப்பிட்ட வடிவமைப்பைப் பின்பற்றவும். .",
    "Error: Invalid PAN category": "பிழை: தவறான PAN வகை",
    "Rs.": "ரூ.",
    "Verification Complete – Thank You!": "சரிபார்ப்பு முடிந்தது - நன்றி!",
    "Congratulations! You've completed the Verification process. Your dedication to providing the necessary information has brought us one step closer to serving you effectively.": "வாழ்த்துக்கள், சரிபார்ப்பு செயல்முறையை முடித்துவிட்டீர்கள். தேவையான தகவல்களை வழங்குவதற்கான உங்கள் அர்ப்பணிப்பு, உங்களுக்கு திறம்பட சேவை செய்வதற்கு எங்களை ஒரு படி மேலே கொண்டு வந்துள்ளது.",
    "With your Verification finalized, our team is fully equipped to handle your requirements efficiently. We assure you that your case will be handled with the utmost care and attention to detail.": "உங்கள் சரிபார்ப்பு முடிவடைந்தவுடன், உங்கள் தேவைகளை திறம்பட கையாள எங்கள் குழு முழுமையாகத் தயாராக உள்ளது. உங்கள் வழக்கு மிகவும் கவனமாகவும் விரிவாகவும் கையாளப்படும் என்று நாங்கள் உறுதியளிக்கிறோம்.",
    "having Gst number": "ஜிஎஸ்டி எண் உள்ளது",
    "Your UDYAM Number allows us to collect crucial details associated with your business. This includes your business address, current status, service details, and organization type.": "உங்கள் UDYAM எண், உங்கள் வணிகத்துடன் தொடர்புடைய முக்கியமான விவரங்களைச் சேகரிக்க எங்களை அனுமதிக்கிறது. இதில் உங்கள் வணிக முகவரி, தற்போதைய நிலை, சேவை விவரங்கள் மற்றும் நிறுவன வகை ஆகியவை அடங்கும்.",


    //Newly added

    "We regret to inform you that the UAM Number entered is invalid or incorrect. Please ensure you have accurately entered detail and double-check for any typos.": "உள்ளிட்ட UAM எண் தவறானது அல்லது தவறானது என்பதை உங்களுக்குத் தெரிவிக்க வருந்துகிறோம். நீங்கள் விவரங்களைத் துல்லியமாக உள்ளிட்டுள்ளதை உறுதிசெய்து, ஏதேனும் எழுத்துப் பிழைகள் உள்ளதா என இருமுறை சரிபார்க்கவும்.",
    "We apologize for the inconvenience. The Aadhaar number provided already exists in our records. Please provide a different Aadhaar number for verification.": "சிரமத்திற்கு வருந்துகிறோம். வழங்கப்பட்ட ஆதார் எண் ஏற்கனவே எங்கள் பதிவுகளில் உள்ளது. சரிபார்ப்புக்கு வேறு ஆதார் எண்ணை வழங்கவும்.",
    "If the issue persists, you may request a new OTP by clicking on the resend link or contacting our support team  at +91-9699900111.": "சிக்கல் தொடர்ந்தால், மீண்டும் அனுப்பும் இணைப்பைக் கிளிக் செய்வதன் மூலம் அல்லது எங்கள் ஆதரவுக் குழுவை +91-9699900111 என்ற எண்ணில் தொடர்புகொள்வதன் மூலம் நீங்கள் புதிய OTP-ஐக் கோரலாம்.",
    "Upon entering your GSTIN, PrivateCourt will diligently collect essential information associated with it. This includes your full name, date of birth, residential address, and contact details. For business owners, we extend our data collection to encompass all pertinent business details tied to your GSTIN, such as MCA specifics and records of GST return filings.": "உங்கள் ஜிஎஸ்டிஐஎன் உள்ளிடும்போது, ​​அதனுடன் தொடர்புடைய அத்தியாவசியத் தகவல்களை தனியார் நீதிமன்றம் ஆர்வத்துடன் சேகரிக்கும். இதில் உங்கள் முழுப்பெயர், பிறந்த தேதி, வசிக்கும் முகவரி மற்றும் தொடர்பு விவரங்கள் ஆகியவை அடங்கும். வணிக உரிமையாளர்களுக்கு, MCA விவரக்குறிப்புகள் மற்றும் GST ரிட்டர்ன் தாக்கல்களின் பதிவுகள் போன்ற உங்கள் GSTIN உடன் இணைக்கப்பட்டுள்ள அனைத்து தொடர்புடைய வணிக விவரங்களையும் உள்ளடக்கும் வகையில் எங்கள் தரவு சேகரிப்பை விரிவுபடுத்துகிறோம்.",
    "Our system assesses your CIBIL score for financial insight and cross-verifies for any ongoing court cases associated with your GSTIN.": "Our system assesses your CIBIL score for financial insight and cross-verifies for any ongoing court cases associated with your GSTIN.",

    "Securely Verify Your Credit Score": "உங்கள் கிரெடிட் ஸ்கோரைப் பாதுகாப்பாகச் சரிபார்க்கவும்"
    ,
    "Enter Your Mobile Number": "உங்கள் மொபைல் எண்ணை உள்ளிடவும்"
    ,
    "To access your credit score securely, please enter your mobile number below. We'll send you a one-time password (OTP) to verify your identity and ensure the confidentiality of your information.": "உங்கள் கிரெடிட் ஸ்கோரைப் பாதுகாப்பாக அணுக, கீழே உங்கள் மொபைல் எண்ணை உள்ளிடவும். உங்கள் அடையாளத்தைச் சரிபார்க்கவும், உங்கள் தகவலின் ரகசியத்தன்மையை உறுதிப்படுத்தவும் ஒரு முறை கடவுச்சொல்லை (OTP) அனுப்புவோம்."
    ,
    "For any queries or assistance, feel free to call us at +91-9699900111.": "ஏதேனும் கேள்விகள் அல்லது உதவிகளுக்கு, எங்களை +91-9699900111 என்ற எண்ணில் அழைக்கவும்."
    ,
    "Verify without OTP": "OTP இல்லாமல் சரிபார்க்கவும்"
    ,
    "Upon submission, check your messages promptly for the OTP. This step ensures your financial information remains protected while you gain valuable insights into your credit profile.": "சமர்ப்பித்ததும், OTPக்காக உங்கள் செய்திகளை உடனடியாகச் சரிபார்க்கவும். உங்கள் கிரெடிட் சுயவிவரத்தில் மதிப்புமிக்க நுண்ணறிவுகளைப் பெறும்போது, ​​உங்கள் நிதித் தகவல் பாதுகாக்கப்படுவதை இந்தப் படி உறுதி செய்கிறது."
    ,
    "Enter Your OTP Here": "உங்கள் OTP ஐ இங்கே உள்ளிடவும்"
    ,
    "Error: Invalid Mobile Number": "பிழை: தவறான மொபைல் எண்"
    ,
    "We apologize for the inconvenience. The Mobile number entered is invalid. Please double-check and ensure that you have entered the": "சங்கடத்திற்கு வருந்துகிறோம். உள்ளிட்ட மொபைல் எண் தவறானது. இருமுறை சரிபார்த்து, நீங்கள் உள்ளிட்டுள்ளீர்கள் என்பதை உறுதிப்படுத்தவும்"
    ,
    "Applicant’s correct 10-digit Mobile number.": "விண்ணப்பதாரரின் சரியான 10 இலக்க மொபைல் எண்."
    ,
    "Mobile Number or contact our support team at +91-9699900111 for further assistance. We appreciate your cooperation.": "கூடுதல் உதவிக்கு மொபைல் எண்ணை அல்லது எங்கள் ஆதரவுக் குழுவை +91-9699900111 இல் தொடர்பு கொள்ளவும். உங்கள் ஒத்துழைப்பை நாங்கள் பாராட்டுகிறோம்."
    ,
    "Error: Invalid OTP": "பிழை: தவறான OTP"
    ,
    "We apologize for the inconvenience. The OTP entered is incorrect. Please double-check the OTP and try again.": "பிழை: தவறான OTP"
    ,
    "mobile Number or contact our support team at +91-9699900111 for further assistance. We appreciate your cooperation.": "மொபைல் எண்ணை அழைக்கவும் அல்லது மேலும் உதவிக்கு எங்கள் ஆதரவுக் குழுவை +91-9699900111 இல் தொடர்பு கொள்ளவும். உங்கள் ஒத்துழைப்பை நாங்கள் பாராட்டுகிறோம்."
    ,
    "Verify OTP": "OTP ஐ சரிபார்க்கவும்"
    ,
    "Verify": "சரிபார்",

    //Conciliation 

    "Respected ,name":"மதிப்பிற்குரியவர் ,name",
    "CONCILIATION NOTICE": "சூழல் அறிவிப்பு",
    "Warm Greetings from PRIVATECOURT. We are writing to formally inform you that PRIVATECOURT will oversee and resolve the dispute(s) between you and company .": "PRIVATECOURT இல் இருந்து மண் வணக்கங்கள். PRIVATECOURT உங்கள் மற்றும் நிறுவனத்திற்கிடையிலான மோதல்களைப் கண்காணித்து, தீர்க்கப்படும் என்பதை நாங்கள் உங்களைப் அதிகாரப்பூர்வமாகத் தகவல் அளிக்கிறோம்.",
    "Please find attached the confirmation document with hearing details that outline our acceptance of our commitment to handling your disputes through Alternative Dispute Resolution (ADR).": "நாங்கள் உங்கள் மோதல்களை மாற்று மோதலுக்கான தீர்வின் (ADR) மூலம் கையாளும் நம்பிக்கையை ஒப்புக்கொள்வதை விளக்கும் கேள்வி விவரங்களுடன் உறுதிப்பத்திரத்தை இணைத்துள்ளோம்.",
    "To explore resolution options and engage in the process effectively, we provide the following avenues, from which you can select any one way:": "தீர்வு விருப்பங்களை ஆராய்ந்து, செயல்முறை நன்கு ஈடுபடுவதற்காக, நாங்கள் கீழ்க்காணும் வழிகளை வழங்குகிறோம், இவற்றில் எதுவும் நீங்கள் தேர்ந்தெடுக்கலாம்:",
    "Access Your Personalized Dispute Resolution Page: Click link to access your dedicated dispute resolution page. Here, you can provide detailed information about your issue. Our support team will review your submission promptly and guide you through the next steps of the resolution process.": "உங்கள் தனிப்பட்ட மோதல் தீர்வு பக்கம் அணுகவும்: உங்கள் மவுணை குறிக்கோள் தீர்வு பக்கம் அணுக link என்பதைக் கிளிக் செய்யவும். இங்கு, நீங்கள் உங்கள் பிரச்சினையைக் குறித்த விரிவான தகவல்களை வழங்கலாம். எங்கள் ஆதரவு குழு உங்கள் சமர்ப்பணத்தை உடனடியாகப் பரிசீலித்து, தீர்வுப் செயல்முறையின் அடுத்த படிகள் மூலம் உங்களை வழிகாட்டும்.",
    "OR": "அல்லது",
    "You are hereby directed to attend the hearingType hearing scheduled on date, at place, between timing.": "நீங்கள் date அன்று place இல், timing இடையில் திட்டமிடப்பட்ட hearingType கேள்வியைச் சேர்க்குமாறு இங்கு உங்களை வழிமொழிகிறோம்.",
    "This platform enables you to directly engage with our team, comprehensively discussing and sharing your dispute details.": "இந்த தளம் உங்களை நேரடியாக எங்கள் குழுவுடன் இணைந்து, உங்கள் மோதல் விவரங்களை விரிவாகப் பேச மற்றும் பகிர்ந்து கொள்ள உதவுகிறது.",
    "If you have any additional information or documentation related to this matter, please feel free to reach out to our dedicated customer support team at +918035731376 or Email: legal@privatecourt.in": "இந்த விஷயத்திற்கு சம்பந்தப்பட்ட கூடுதல் தகவல்களோ அல்லது ஆவணங்களோ இருந்தால், தயவுசெய்து +918035731376 என்ற எண்ணுக்கு அல்லது legal@privatecourt.in என்ற மின்னஞ்சலுக்கு எங்கள் நன்கு அய்யா ஆதரவு குழுவுடன் தொடர்புகொள்ள தயங்க வேண்டாம்.",
    "We appreciate your trust in PRIVATECOURT for dispute resolution and are committed to delivering a fair and impartial resolution to the matter at hand.": "மோதல் தீர்விற்காக PRIVATECOURT மீது உங்கள் நம்பிக்கையை நாங்கள் மதிக்கிறோம் மற்றும் தற்போதைய விவகாரத்திற்கு நேர்மையான மற்றும் அங்கீகாரமான தீர்வுகளை வழங்குவதில் நாங்கள் உறுதியாக உள்ளோம்.",
    "Thank You,": "நன்றி,",
    "Team PrivateCourt": "பிரைவேட்‌கார்ட் குழு",
     "Join anytime here and submit your response":"எப்போது வேண்டுமானாலும் இங்கே வந்து உங்கள் பதிலை சமர்ப்பிக்கவும்",
      "It's time to join the video call.":"வீடியோ அழைப்பில் சேர வேண்டிய நேரம் இது.",
      "You are hereby directed to attend the hearing scheduled on date, at place, between timing.":"தேதி date-ல், இடம் place-ல், நேரம் timing-க்கு இடையில் திட்டமிடப்பட்ட விசாரணையில் கலந்து கொள்ளுமாறு இதன்மூலம் நீங்கள் அறிவுறுத்தப்படுகிறீர்கள்.",
      "Please explain the reasons for your loan repayment difficulties below. Include any relevant circumstances such as financial hardship, medical issues, or disputes over loan terms. Your detailed response will help us assess and resolve your case effectively.":"உங்கள் கடன் திருப்பிச் செலுத்துவதில் உள்ள சிரமங்களுக்கான காரணங்களை கீழே விளக்கவும். நிதி கஷ்டம், மருத்துவ சிக்கல்கள் அல்லது கடன் விதிமுறைகள் தொடர்பான சர்ச்சைகள் போன்ற எந்தவொரு தொடர்புடைய சூழ்நிலைகளையும் சேர்க்கவும். உங்கள் விரிவான பதில், உங்கள் வழக்கை திறம்பட மதிப்பிட்டு தீர்க்க எங்களுக்கு உதவும்.",
      "It’s time for your scheduled online hearing. Join the session by clicking the button below, and ensure all necessary materials are ready for discussion.":"உங்கள் திட்டமிடப்பட்ட ஆன்லைன் விசாரணைக்கான நேரம் இது. கீழே உள்ள பட்டனை கிளிக் செய்வதன் மூலம் அமர்வில் சேரவும், தேவையான அனைத்து பொருட்களும் விவாதத்திற்கு தயாராக இருப்பதை உறுதிசெய்யவும்.",
      "To proceed further with the resolution process, kindly accept the conciliation notice.":"தீர்வு செயல்முறையை மேற்கொள்வதற்கு, தயவுசெய்து சமரச அறிவிப்பை ஏற்றுக்கொள்ளவும்.",
      "Accept":"ஏற்றுக்கொள்",
      "Reject":"நிராகரி",
      "Redefining Dispute Resolution":"மீண்டும் மோதல் தீர்வை வரையறுக்கிறது",
      "With PrivateCourt, experience faster, more effective dispute resolution powered by technology and guided by industry experts.":"PrivateCourt உடன், தொழில்நுட்பத்தால் இயக்கப்பட்ட மற்றும் தொழில் நிபுணர்களால் வழிநடத்தப்படும், விரைவாகவும் மேலும் செயல்படக்கூடிய மோதல் தீர்வை அனுபவிக்கவும்.",
      "Visit Website": "வலைத்தளத்தை பார்வையிடவும்",
}

export default TamilTranslation;