const MalayalamTranslation = {
    "Join": "ചേരുക",
    "Join Video Call": "വീഡിയോ കോൾ ചേർക്കുക",
    "Your details have been successfully received and are currently under review. Should any additional information be required, our team will reach out to you directly. Please be informed that, as the form submission is complete, the \"Join Video Call\" option has been disabled, as your presence in the meeting is no longer necessary. We will keep you updated on the next steps in due course.": "നിങ്ങളുടെ വിശദാംശങ്ങൾ വിജയകരമായി ലഭിച്ചിരിക്കുന്നു, നിലവിൽ അവ പരിശോധിക്കുന്നുണ്ട്. അധിക വിവരങ്ങൾ ആവശ്യമായാൽ, ഞങ്ങളുടെ ടീം നേരിട്ട് നിങ്ങളെ ബന്ധപ്പെടും. ദയവായി അറിയിക്കുക, ഫോം സമർപ്പിതമായിരിക്കുന്നു, \"ജോയിൻ വീഡിയോ കോൾ\" ഓപ്ഷൻ നിരസിച്ചിരിക്കുന്നു, കാരണം നിങ്ങളുടെ സംഗമത്തിൽ હાજരാവുന്നത് ഇനി ആവശ്യമല്ല. നാം ഉപഭോക്താവിനെ അടുത്ത പ്രവൃത്തികളിൽ അപ്ഡേറ്റ് ചെയ്യാം.",
    "Thank you for submitting the form!": "ഫോം സമർപ്പിച്ചതിന് നന്ദി!",
    'Email Security Check': 'ഇമെയിൽ സുരക്ഷാ പരിശോധന',
    'Verify Your Email for Account Access': 'അക്കൗണ്ട് ആക്സസിനായി നിങ്ങളുടെ ഇമെയിൽ പരിശോധിക്കുക',
    "To ensure the security of your account, please enter your valid Email ID in the specified format- username@domain.com. If your email address doesn't exist in our system": 'നിങ്ങളുടെ അക്കൗണ്ടിൻ്റെ സുരക്ഷ ഉറപ്പാക്കാൻ, നിർദ്ദിഷ്ട ഫോർമാറ്റിൽ നിങ്ങളുടെ സാധുവായ ഇമെയിൽ ഐഡി നൽകുക- username@domain.com. നിങ്ങളുടെ ഇമെയിൽ വിലാസം ഞങ്ങളുടെ സിസ്റ്റത്തിൽ നിലവിലില്ലെങ്കിൽ, എ',
    'NEW USER': 'പുതിയ ഉപയോക്താവ്',
    'account will be created for you': 'നിങ്ങൾക്കായി അക്കൗണ്ട് സൃഷ്ടിക്കും.',
    'Once submitted, you will receive a Verification OTP (One-Time Password) sent to your provided email address.': 'സമർപ്പിച്ചുകഴിഞ്ഞാൽ, നിങ്ങൾ നൽകിയ ഇമെയിൽ വിലാസത്തിലേക്ക് അയച്ച ഒരു സ്ഥിരീകരണ OTP (ഒറ്റത്തവണ പാസ്‌വേഡ്) നിങ്ങൾക്ക് ലഭിക്കും.',
    'For any queries or assistance, feel free to call us at +91-9699900111.': 'എന്തെങ്കിലും ചോദ്യങ്ങൾക്കോ സഹായങ്ങൾക്കോ, ഞങ്ങളെ +91-9699900111 എന്ന നമ്പറിൽ വിളിക്കാൻ മടിക്കേണ്ടതില്ല.',
    'Thank you for entrusting PrivateCourt. Your security is our priority.': 'പ്രൈവറ്റ് കോടതിയെ ഏൽപ്പിച്ചതിന് നന്ദി. നിങ്ങളുടെ സുരക്ഷയാണ് ഞങ്ങളുടെ മുൻഗണന.',
    'Verify': 'സ്ഥിരീകരിക്കുക',
    'Invalid Email Address': 'ഇമെയിൽ വിലാസം അസാധുവാണ്',
    'We regret to inform you that the email address entered is invalid. Please ensure that you have entered a correct and properly formatted email address.': 'നൽകിയ ഇമെയിൽ വിലാസം അസാധുവാണെന്ന് നിങ്ങളെ അറിയിക്കുന്നതിൽ ഞങ്ങൾ ഖേദിക്കുന്നു. നിങ്ങൾ കൃത്യവും ശരിയായി ഫോർമാറ്റ് ചെയ്തതുമായ ഇമെയിൽ വിലാസമാണ് നൽകിയതെന്ന് ഉറപ്പാക്കുക.',
    'If the issue persists, double-check for any typos or errors in the email format (e.g., username@example.com).': 'പ്രശ്നം നിലനിൽക്കുകയാണെങ്കിൽ, ഇമെയിൽ ഫോർമാറ്റിലെ അക്ഷരത്തെറ്റുകളോ പിശകുകളോ ഉണ്ടോ എന്ന് രണ്ടുതവണ പരിശോധിക്കുക (ഉദാ. username@example.com).',
    'For further queries or assistance, feel free to call us at +91-9699900111.': 'കൂടുതൽ ചോദ്യങ്ങൾക്കോ സഹായത്തിനോ ഞങ്ങളെ +91-9699900111 എന്ന നമ്പറിൽ വിളിക്കുക.',
    'Thank you for entrusting PrivateCourt. Your security is our priority.': 'പ്രൈവറ്റ് കോടതിയെ ഏൽപ്പിച്ചതിന് നന്ദി. നിങ്ങളുടെ സുരക്ഷയാണ് ഞങ്ങളുടെ മുൻഗണന.',
    'Secure UDYAM Number Validation': 'സുരക്ഷിത ഉദയം നമ്പർ മൂല്യനിർണ്ണയം',
    'Enter Your Udyam Number': 'നിങ്ങളുടെ ഉദ്യം നമ്പർ നൽകുക',
    'Please enter your correct UDYAM Number.': 'ദയവായി നിങ്ങളുടെ ശരിയായ ഉദയം നമ്പർ നൽകുക.',
    'Your UDYAM Number allows us to collect crucial details associated with your business. This includes your business address, current status, service details, and organization type.': 'നിങ്ങളുടെ ബിസിനസ്സുമായി ബന്ധപ്പെട്ട നിർണായക വിശദാംശങ്ങൾ ശേഖരിക്കാൻ നിങ്ങളുടെ ഉദയം നമ്പർ ഞങ്ങളെ അനുവദിക്കുന്നു. ഇതിൽ നിങ്ങളുടെ ബിസിനസ്സ് വിലാസം, നിലവിലെ നില, സേവന വിശദാംശങ്ങൾ, ഓർഗനൈസേഷൻ തരം എന്നിവ ഉൾപ്പെടുന്നു.',
    'Ensuring the accuracy of your UDYAM Number is vital for a seamless verification process.': 'തടസ്സങ്ങളില്ലാത്ത സ്ഥിരീകരണ പ്രക്രിയയ്ക്ക് നിങ്ങളുടെ ഉദയം നമ്പറിൻ്റെ കൃത്യത ഉറപ്പാക്കുന്നത് അത്യന്താപേക്ഷിതമാണ്.',
    'UDYAM': 'ഉദയം',
    'Error: Invalid UDYAM Number': 'പിശക്: സ്ഥിരീകരിക്കുക നമ്പർ അസാധുവാണ്',
    'We regret to inform you that the UDYAM number entered is invalid or incorrect. Please ensure you have entered the correct UDYAM details and double-check for any typos.': 'നൽകിയ UDYAM നമ്പർ അസാധുവോ തെറ്റോ ആണെന്ന് നിങ്ങളെ അറിയിക്കുന്നതിൽ ഞങ്ങൾ ഖേദിക്കുന്നു. നിങ്ങൾ ശരിയായ UDYAM വിശദാംശങ്ങൾ നൽകിയിട്ടുണ്ടെന്ന് ഉറപ്പാക്കുകയും ഏതെങ്കിലും അക്ഷരത്തെറ്റുകൾ ഉണ്ടോയെന്ന് രണ്ടുതവണ പരിശോധിക്കുക.',
    'If you continue to face issues, kindly verify your UDYAM certificate and re-enter the details.': 'നിങ്ങൾക്ക് പ്രശ്നങ്ങൾ അഭിമുഖീകരിക്കുന്നത് തുടരുകയാണെങ്കിൽ, ദയവായി നിങ്ങളുടെ UDYAM സർട്ടിഫിക്കറ്റ് പരിശോധിച്ച് വിശദാംശങ്ങൾ വീണ്ടും നൽകുക.',
    'Secure PAN Validation Process': 'സുരക്ഷിത പാൻ മൂല്യനിർണ്ണയ പ്രക്രിയ',
    'This verification process is designed to uphold the integrity of our services, ensuring a reliable and secure experience.': 'വിശ്വസനീയവും സുരക്ഷിതവുമായ അനുഭവം ഉറപ്പാക്കിക്കൊണ്ട് ഞങ്ങളുടെ സേവനങ്ങളുടെ സമഗ്രത ഉയർത്തിപ്പിടിക്കുന്നതിനാണ് ഈ സ്ഥിരീകരണ പ്രക്രിയ രൂപകൽപ്പന ചെയ്തിരിക്കുന്നത്.',
    'Enter Your PAN Number': 'നിങ്ങളുടെ പാൻ നമ്പർ നൽകുക',
    'Please enter your correct PAN number in the format XXXXX-0000-X.': 'XXXXX-0000-X ഫോർമാറ്റിൽ നിങ്ങളുടെ ശരിയായ പാൻ നമ്പർ നൽകുക.',
    'PrivateCourt will meticulously collect essential information associated with your PAN such as your full name, date of birth, residential address, and contact details. For Business Owners, we extend our data collection to encompass all pertinent business details tied to your PAN such as GST details, MCA specifics, and records of GST return filings.': 'നിങ്ങളുടെ പൂർണ്ണമായ പേര്, ജനനത്തീയതി, താമസ വിലാസം, ബന്ധപ്പെടാനുള്ള വിശദാംശങ്ങൾ എന്നിവ പോലുള്ള നിങ്ങളുടെ പാൻ നമ്പറുമായി ബന്ധപ്പെട്ട അവശ്യ വിവരങ്ങൾ സ്വകാര്യകോടതി സൂക്ഷ്മമായി ശേഖരിക്കും. ബിസിനസ്സ് ഉടമകൾക്കായി, GST വിശദാംശങ്ങൾ, MCA സവിശേഷതകൾ, GST റിട്ടേൺ ഫയലിംഗുകളുടെ രേഖകൾ എന്നിവ പോലെ നിങ്ങളുടെ പാൻ ബന്ധിപ്പിച്ചിട്ടുള്ള എല്ലാ പ്രസക്തമായ ബിസിനസ്സ് വിശദാംശങ്ങളും ഉൾക്കൊള്ളുന്നതിനായി ഞങ്ങളുടെ ഡാറ്റ ശേഖരണം ഞങ്ങൾ വിപുലീകരിക്കുന്നു.',
    'Our system assesses your CIBIL score for financial insight and cross-verifies for any ongoing court cases associated with your PAN.': 'സാമ്പത്തിക ഉൾക്കാഴ്‌ചയ്‌ക്കായി ഞങ്ങളുടെ സിസ്റ്റം നിങ്ങളുടെ CIBIL സ്‌കോർ വിലയിരുത്തുകയും നിങ്ങളുടെ PAN-മായി ബന്ധപ്പെട്ട നിലവിലുള്ള ഏതെങ്കിലും കോടതി കേസുകൾ ക്രോസ്-വെരിഫൈ ചെയ്യുകയും ചെയ്യുന്നു.',
    'Error: Invalid PAN Format': 'പിശക്: അസാധുവായ പാൻ ഫോർമാറ്റ്',
    'We regret to inform you that the PAN number entered does not match the required format (XXXXX-0000-X). Please ensure you have entered the correct PAN details and follow the specified format for accurate verification.': 'നൽകിയ പാൻ നമ്പർ ആവശ്യമായ ഫോർമാറ്റുമായി (XXXXX-0000-X) പൊരുത്തപ്പെടുന്നില്ലെന്ന് നിങ്ങളെ അറിയിക്കുന്നതിൽ ഞങ്ങൾ ഖേദിക്കുന്നു. നിങ്ങൾ ശരിയായ പാൻ വിശദാംശങ്ങൾ നൽകിയിട്ടുണ്ടെന്ന് ഉറപ്പാക്കുകയും കൃത്യമായ സ്ഥിരീകരണത്തിനായി നിർദ്ദിഷ്ട ഫോർമാറ്റ് പിന്തുടരുകയും ചെയ്യുക.',
    'If you continue to face issues, kindly double-check your PAN card and re-enter the details. For further queries or assistance, feel free to call us at +91-9699900111.': 'നിങ്ങൾക്ക് പ്രശ്നങ്ങൾ അഭിമുഖീകരിക്കുന്നത് തുടരുകയാണെങ്കിൽ, ദയവായി നിങ്ങളുടെ പാൻ കാർഡ് രണ്ടുതവണ പരിശോധിച്ച് വിശദാംശങ്ങൾ വീണ്ടും നൽകുക. കൂടുതൽ ചോദ്യങ്ങൾക്കോ സഹായത്തിനോ ഞങ്ങളെ +91-9699900111 എന്ന നമ്പറിൽ വിളിക്കുക.',
    'Welcome XXXX': 'സ്വാഗതം XXXX',
    'Account security starts with verification': 'അക്കൗണ്ട് സുരക്ഷ സ്ഥിരീകരണത്തോടെ ആരംഭിക്കുന്നു',
    'OTP Validation for Account Access': 'അക്കൗണ്ട് സുരക്ഷ സ്ഥിരീകരണത്തോടെ ആരംഭിക്കുന്നു',
    'Kindly check your inbox, and enter the OTP in the designated field to complete the verification process.': 'നിങ്ങളുടെ ഇൻബോക്സ് പരിശോധിക്കുക, സ്ഥിരീകരണ പ്രക്രിയ പൂർത്തിയാക്കാൻ നിയുക്ത ഫീൽഡിൽ OTP നൽകുക.',
    "Didn't receive OTP?": 'OTP ലഭിച്ചില്ലേ?',
    'Request a Resend': 'വീണ്ടും അയയ്ക്കാൻ അഭ്യർത്ഥിക്കുക',
    'Verify OTP': 'OTP പരിശോധിക്കുക',
    'Error: Incorrect OTP': 'പിശക്: തെറ്റായ OTP',
    'We apologize for the inconvenience. The One-Time Password (OTP) entered is incorrect. Please ensure that you have accurately entered the OTP received in your email.': 'അസൗകര്യത്തിൽ ഞങ്ങൾ ക്ഷമ ചോദിക്കുന്നു. നൽകിയ ഒറ്റത്തവണ പാസ്‌വേഡ് (OTP) തെറ്റാണ്. നിങ്ങളുടെ ഇമെയിലിൽ ലഭിച്ച OTP നിങ്ങൾ കൃത്യമായി നൽകിയിട്ടുണ്ടെന്ന് ഉറപ്പാക്കുക.',
    'If the issue persists, you may request a new OTP by clicking on the resend link or contacting our support team at +91-9699900111.': 'പ്രശ്നം നിലനിൽക്കുകയാണെങ്കിൽ, വീണ്ടും അയയ്‌ക്കുന്ന ലിങ്കിൽ ക്ലിക്കുചെയ്‌ത് അല്ലെങ്കിൽ +91-9699900111 എന്ന നമ്പറിൽ ഞങ്ങളുടെ പിന്തുണാ ടീമിനെ ബന്ധപ്പെടുന്നതിലൂടെ നിങ്ങൾക്ക് ഒരു പുതിയ OTP അഭ്യർത്ഥിക്കാം.',
    'Double-check the email and try entering the OTP again.': 'ഇമെയിൽ രണ്ടുതവണ പരിശോധിച്ച് OTP വീണ്ടും നൽകാൻ ശ്രമിക്കുക.',
    'Choose Profile': 'പ്രൊഫൈൽ തിരഞ്ഞെടുക്കുക',
    'Setting Your Default Profile': 'നിങ്ങളുടെ ഡിഫോൾട്ട് പ്രൊഫൈൽ സജ്ജീകരിക്കുന്നു',
    'Please choose the default profile for a seamless experience.': 'തടസ്സമില്ലാത്ത അനുഭവത്തിനായി ഡിഫോൾട്ട് പ്രൊഫൈൽ തിരഞ്ഞെടുക്കുക.',
    "If you manage multiple companies, select the profile you'd like to set as the default. This ensures that every time you log in, you'll have quick access to the information relevant to your preferred company.": 'നിങ്ങൾ ഒന്നിലധികം കമ്പനികൾ മാനേജുചെയ്യുകയാണെങ്കിൽ, സ്ഥിരസ്ഥിതിയായി സജ്ജീകരിക്കാൻ നിങ്ങൾ ആഗ്രഹിക്കുന്ന പ്രൊഫൈൽ തിരഞ്ഞെടുക്കുക. നിങ്ങൾ ലോഗിൻ ചെയ്യുന്ന ഓരോ തവണയും, നിങ്ങൾ തിരഞ്ഞെടുത്ത കമ്പനിയുമായി ബന്ധപ്പെട്ട വിവരങ്ങളിലേക്ക് പെട്ടെന്ന് ആക്സസ് ലഭിക്കുമെന്ന് ഇത് ഉറപ്പാക്കുന്നു.',
    'Making the right selection now simplifies your future logins and provides a tailored experience based on your primary business entity.': 'ഇപ്പോൾ ശരിയായ തിരഞ്ഞെടുപ്പ് നടത്തുന്നത് നിങ്ങളുടെ ഭാവി ലോഗിനുകളെ ലളിതമാക്കുകയും നിങ്ങളുടെ പ്രാഥമിക ബിസിനസ്സ് സ്ഥാപനത്തെ അടിസ്ഥാനമാക്കി അനുയോജ്യമായ അനുഭവം നൽകുകയും ചെയ്യുന്നു.',
    'Go to Dashboard': 'ഡാഷ്‌ബോർഡിലേക്ക് പോകുക',
    'Data Compilation in Progress': 'ഡാറ്റ സമാഹരണം പുരോഗമിക്കുന്നു:',
    'Please Allow Processing Time': 'ദയവായി പ്രോസസ്സിംഗ് സമയം അനുവദിക്കുക',
    "Thank you for providing your information. Our system is currently in the process of analyzing and compiling the data you've entered, including details such as PAN, Aadhaar, GST, Email, and more, to establish your comprehensive profile.": 'നിങ്ങളുടെ വിവരങ്ങൾ നൽകിയതിന് നന്ദി. നിങ്ങളുടെ സമഗ്രമായ പ്രൊഫൈൽ സ്ഥാപിക്കുന്നതിന് പാൻ, ആധാർ, ജിഎസ്ടി, ഇമെയിൽ എന്നിവയും അതിലേറെയും പോലുള്ള വിശദാംശങ്ങൾ ഉൾപ്പെടെ നിങ്ങൾ നൽകിയ ഡാറ്റ വിശകലനം ചെയ്യുകയും സമാഹരിക്കുകയും ചെയ്യുന്ന പ്രക്രിയയിലാണ് ഞങ്ങളുടെ സിസ്റ്റം.',
    "This operation may take a few moments. During this time, your screen may show minimal activity. Please be assured that this is a routine part of our data compilation process.": 'ഈ പ്രവർത്തനത്തിന് കുറച്ച് നിമിഷങ്ങൾ എടുത്തേക്കാം. ഈ സമയത്ത്, നിങ്ങളുടെ സ്‌ക്രീൻ കുറഞ്ഞ പ്രവർത്തനം കാണിച്ചേക്കാം. ഇത് ഞങ്ങളുടെ ഡാറ്റ കംപൈലേഷൻ പ്രക്രിയയുടെ ഒരു പതിവ് ഭാഗമാണെന്ന് ദയവായി ഉറപ്പാക്കുക.',
    'Your profile is being meticulously crafted to uphold accuracy and security. We sincerely appreciate your patience and understanding as we work to enhance your experience.': 'കൃത്യതയും സുരക്ഷയും ഉയർത്തിപ്പിടിക്കാൻ നിങ്ങളുടെ പ്രൊഫൈൽ സൂക്ഷ്മമായി രൂപപ്പെടുത്തിയിരിക്കുന്നു. നിങ്ങളുടെ അനുഭവം മെച്ചപ്പെടുത്തുന്നതിനായി ഞങ്ങൾ പ്രവർത്തിക്കുമ്പോൾ നിങ്ങളുടെ ക്ഷമയെയും ധാരണയെയും ഞങ്ങൾ ആത്മാർത്ഥമായി അഭിനന്ദിക്കുന്നു.',
    'Start Your Bank Account Verification': 'നിങ്ങളുടെ ബാങ്ക് അക്കൗണ്ട് സ്ഥിരീകരണം ആരംഭിക്കുക',
    'Bank Verification': 'ബാങ്ക് സ്ഥിരീകരണം',
    'Ensuring the accuracy of your bank account information is crucial for a seamless verification process. Kindly enter your valid Account Number, and input the correct IFSC code associated with your account.': 'തടസ്സമില്ലാത്ത സ്ഥിരീകരണ പ്രക്രിയയ്ക്ക് നിങ്ങളുടെ ബാങ്ക് അക്കൗണ്ട് വിവരങ്ങളുടെ കൃത്യത ഉറപ്പാക്കുന്നത് നിർണായകമാണ്. ദയവായി നിങ്ങളുടെ അക്കൗണ്ട് നമ്പർ നൽകുക, നിങ്ങളുടെ അക്കൗണ്ടുമായി ബന്ധപ്പെട്ട ശരിയായ IFSC കോഡ് നൽകുക.',
    'This verification step is designed to enhance the security of our services and provide you with a reliable experience.': 'ഞങ്ങളുടെ സേവനങ്ങളുടെ സുരക്ഷ വർധിപ്പിക്കുന്നതിനും നിങ്ങൾക്ക് വിശ്വസനീയമായ അനുഭവം നൽകുന്നതിനുമായാണ് ഈ സ്ഥിരീകരണ ഘട്ടം രൂപകൽപ്പന ചെയ്തിരിക്കുന്നത്.',
    'Account Number': 'അക്കൗണ്ട് നമ്പർ',
    'IFSC code': 'IFSC കോഡ്',
    'Error: Invalid Bank Account or IFSC Code': 'പിശക്: അസാധുവായ ബാങ്ക് അക്കൗണ്ട് അല്ലെങ്കിൽ IFSC കോഡ്',
    'We regret to inform you that the Bank Account or IFSC Code entered is invalid or incorrect. Please ensure you have accurately entered both details and double-check for any typos.': 'നൽകിയ ബാങ്ക് അക്കൗണ്ട് അല്ലെങ്കിൽ IFSC കോഡ് അസാധുവാണ് അല്ലെങ്കിൽ തെറ്റാണെന്ന് നിങ്ങളെ അറിയിക്കുന്നതിൽ ഞങ്ങൾ ഖേദിക്കുന്നു. നിങ്ങൾ രണ്ട് വിശദാംശങ്ങളും കൃത്യമായി നൽകിയിട്ടുണ്ടെന്ന് ഉറപ്പുവരുത്തുക കൂടാതെ ഏതെങ്കിലും അക്ഷരത്തെറ്റുകൾ ഉണ്ടോയെന്ന് രണ്ടുതവണ പരിശോധിക്കുക.',
    'If you continue to face issues, kindly verify your bank statement or contact your bank to confirm the accuracy of the provided information.': 'നിങ്ങൾ തുടർന്നും പ്രശ്‌നങ്ങൾ നേരിടുന്നുണ്ടെങ്കിൽ, നിങ്ങളുടെ ബാങ്ക് സ്റ്റേറ്റ്‌മെൻ്റ് ദയവായി പരിശോധിക്കുക അല്ലെങ്കിൽ നൽകിയ വിവരങ്ങളുടെ കൃത്യത സ്ഥിരീകരിക്കാൻ നിങ്ങളുടെ ബാങ്കുമായി ബന്ധപ്പെടുക.',
    'Aadhaar Details Verification': 'ആധാർ വിശദാംശങ്ങളുടെ പരിശോധന',
    'Enter Your Aadhaar Number': 'നിങ്ങളുടെ ആധാർ നമ്പർ നൽകുക',
    'Please input your Aadhaar number. This process ensures accurate cross-checking for your security. Thank you for your cooperation.': 'ദയവായി നിങ്ങളുടെ ആധാർ നമ്പർ നൽകുക. ഈ പ്രക്രിയ നിങ്ങളുടെ സുരക്ഷയ്ക്കായി കൃത്യമായ ക്രോസ് ചെക്കിംഗ് ഉറപ്പാക്കുന്നു. നിങ്ങളുടെ സഹകരണത്തിന് നന്ദി.',
    'Enter Your Aadhaar OTP Here': 'നിങ്ങളുടെ ആധാർ OTP ഇവിടെ നൽകുക',
    'Error: Incorrect Aadhaar OTP': 'പിശക്: തെറ്റായ ആധാർ OTP',
    'We regret to inform you that the One-Time Password (OTP) entered for Aadhaar verification is incorrect. Please ensure that you have accurately entered the OTP received on your registered mobile number.': 'ആധാർ സ്ഥിരീകരണത്തിനായി നൽകിയ ഒറ്റത്തവണ പാസ്‌വേഡ് (OTP) തെറ്റാണെന്ന് നിങ്ങളെ അറിയിക്കുന്നതിൽ ഞങ്ങൾ ഖേദിക്കുന്നു. നിങ്ങളുടെ രജിസ്റ്റർ ചെയ്ത മൊബൈൽ നമ്പറിൽ ലഭിച്ച OTP നിങ്ങൾ കൃത്യമായി നൽകിയിട്ടുണ്ടെന്ന് ഉറപ്പാക്കുക.',
    'If the issue persists, you may request a new OTP by clicking on the resend link or contacting our support team at +91-9699900111.': 'പ്രശ്നം നിലനിൽക്കുകയാണെങ്കിൽ, വീണ്ടും അയയ്‌ക്കുന്ന ലിങ്കിൽ ക്ലിക്കുചെയ്‌ത് അല്ലെങ്കിൽ +91-9699900111 എന്ന നമ്പറിൽ ഞങ്ങളുടെ പിന്തുണാ ടീമിനെ ബന്ധപ്പെടുന്നതിലൂടെ നിങ്ങൾക്ക് ഒരു പുതിയ OTP അഭ്യർത്ഥിക്കാം.',
    'Double-check the email and try entering the OTP again.': 'ഇമെയിൽ രണ്ടുതവണ പരിശോധിച്ച് OTP വീണ്ടും നൽകാൻ ശ്രമിക്കുക.',
    'Error: Invalid Aadhaar Number': 'പിശക്: അസാധുവായ ആധാർ നമ്പർ',
    'We apologize for the inconvenience. The Aadhaar number entered is invalid. Please double-check and ensure that you have entered the correct 12-digit Aadhaar number.': 'അസൗകര്യത്തിൽ ഞങ്ങൾ ക്ഷമ ചോദിക്കുന്നു. നൽകിയ ആധാർ നമ്പർ അസാധുവാണ്. നിങ്ങൾ ശരിയായ 12 അക്ക ആധാർ നമ്പർ നൽകിയിട്ടുണ്ടോയെന്ന് രണ്ടുതവണ പരിശോധിച്ച് ഉറപ്പാക്കുക.',
    'If you continue to face issues, verify your Aadhaar card or contact our support team at +91-9699900111 for further assistance. We appreciate your cooperation.': 'നിങ്ങൾക്ക് പ്രശ്നങ്ങൾ അഭിമുഖീകരിക്കുന്നത് തുടരുകയാണെങ്കിൽ, നിങ്ങളുടെ ആധാർ കാർഡ് പരിശോധിച്ചുറപ്പിക്കുക അല്ലെങ്കിൽ കൂടുതൽ സഹായത്തിനായി +91-9699900111 എന്ന നമ്പറിൽ ഞങ്ങളുടെ പിന്തുണാ ടീമിനെ ബന്ധപ്പെടുക. നിങ്ങളുടെ സഹകരണത്തെ ഞങ്ങൾ അഭിനന്ദിക്കുന്നു.',

    //newly added 


    "Please ensure you have entered the correct GST details and follow the specified format for accurate verification.": "നിങ്ങൾ ശരിയായ GST വിശദാംശങ്ങൾ നൽകിയിട്ടുണ്ടെന്ന് ഉറപ്പാക്കുകയും കൃത്യമായ സ്ഥിരീകരണത്തിനായി നിർദ്ദിഷ്ട ഫോർമാറ്റ് പിന്തുടരുകയും ചെയ്യുക.",
    "If you continue to face issues, kindly double-check your PAN card and re-enter the details.": "നിങ്ങൾക്ക് പ്രശ്നങ്ങൾ അഭിമുഖീകരിക്കുന്നത് തുടരുകയാണെങ്കിൽ, ദയവായി നിങ്ങളുടെ പാൻ കാർഡ് രണ്ടുതവണ പരിശോധിച്ച് വിശദാംശങ്ങൾ വീണ്ടും നൽകുക.",
    "The GST provided already exists in our records. Please try using a different GST for verification.": "നൽകിയിരിക്കുന്ന ജിഎസ്ടി ഞങ്ങളുടെ രേഖകളിൽ നിലവിലുണ്ട്. സ്ഥിരീകരണത്തിനായി മറ്റൊരു GST ഉപയോഗിച്ച് ശ്രമിക്കുക.",
    "It appears that the PAN details provided belong to a company. We require your personal PAN details for verification purposes.": "നൽകിയിരിക്കുന്ന പാൻ വിശദാംശങ്ങൾ ഒരു കമ്പനിയുടേതാണെന്ന് തോന്നുന്നു. സ്ഥിരീകരണ ആവശ്യങ്ങൾക്കായി ഞങ്ങൾക്ക് നിങ്ങളുടെ സ്വകാര്യ പാൻ വിശദാംശങ്ങൾ ആവശ്യമാണ്.",
    "If you continue to face issues, kindly double-check your PAN card and re- enter the details. For further queries or assistance, feel free to call us at +91-9699900111.": "നിങ്ങൾക്ക് പ്രശ്നങ്ങൾ അഭിമുഖീകരിക്കുന്നത് തുടരുകയാണെങ്കിൽ, ദയവായി നിങ്ങളുടെ പാൻ കാർഡ് രണ്ടുതവണ പരിശോധിച്ച് വിശദാംശങ്ങൾ വീണ്ടും നൽകുക. കൂടുതൽ ചോദ്യങ്ങൾക്കോ സഹായത്തിനോ ഞങ്ങളെ +91-9699900111 എന്ന നമ്പറിൽ വിളിക്കുക.",
    "The PAN provided already exists in our records. Please try using a different PAN for verification.": "നൽകിയിരിക്കുന്ന പാൻ ഇതിനകം തന്നെ ഞങ്ങളുടെ രേഖകളിൽ നിലവിലുണ്ട്. സ്ഥിരീകരണത്തിനായി മറ്റൊരു പാൻ ഉപയോഗിച്ച് ശ്രമിക്കുക.",
    "New User Registration": "പുതിയ ഉപയോക്തൃ രജിസ്ട്രേഷൻ",
    "To begin your journey with us, we kindly request all new users to register by following the simple steps outlined below:": "ഞങ്ങളോടൊപ്പം നിങ്ങളുടെ യാത്ര ആരംഭിക്കുന്നതിന്, ചുവടെ വിവരിച്ചിരിക്കുന്ന ലളിതമായ ഘട്ടങ്ങൾ പാലിച്ചുകൊണ്ട് എല്ലാ പുതിയ ഉപയോക്താക്കളും രജിസ്റ്റർ ചെയ്യാൻ ഞങ്ങൾ അഭ്യർത്ഥിക്കുന്നു:",
    "Registration": "രജിസ്ട്രേഷൻ",
    "To register and gain access to our comprehensive verification services, please proceed by making a one-time payment of": "രജിസ്റ്റർ ചെയ്യുന്നതിനും ഞങ്ങളുടെ സമഗ്ര പരിശോധനാ സേവനങ്ങളിലേക്ക് പ്രവേശനം നേടുന്നതിനും, ദയവായി ഒറ്റത്തവണ പേയ്‌മെൻ്റ് നടത്തി തുടരുക",
    "Rs. 500": "രൂപ. 500",
    "GST": "GST",
    "done": "ചെയ്തു",
    "Please note that the registration fee is subject to 18% GST (Goods and Services Tax) as per government regulations.": "ഗവൺമെൻ്റ് ചട്ടങ്ങൾ അനുസരിച്ച് രജിസ്ട്രേഷൻ ഫീസ് 18% GST (ചരക്ക് സേവന നികുതി)ക്ക് വിധേയമാണെന്ന് ദയവായി ശ്രദ്ധിക്കുക.",
    "Total Amount Due:": "ആകെ കുടിശ്ശിക തുക:",
    "Rs. 590": "രൂപ. 590",
    "Total Registration Fee + 18% GST": "മൊത്തം രജിസ്ട്രേഷൻ ഫീസ് + 18% GST",
    "Secure Payment Gateway": "സുരക്ഷിത പേയ്‌മെൻ്റ് ഗേറ്റ്‌വേ",
    "Rest assured, your payment and personal information are handled with the utmost security through our encrypted payment gateway.": "ഞങ്ങളുടെ എൻക്രിപ്റ്റ് ചെയ്‌ത പേയ്‌മെൻ്റ് ഗേറ്റ്‌വേയിലൂടെ നിങ്ങളുടെ പേയ്‌മെൻ്റും വ്യക്തിഗത വിവരങ്ങളും അതീവ സുരക്ഷയോടെയാണ് കൈകാര്യം ചെയ്യുന്നത്.",
    "Congratulations! You've completed the registration process. Your dedication to providing the necessary information has brought us one step closer to serving you effectively.": "അഭിനന്ദനങ്ങൾ! നിങ്ങൾ രജിസ്ട്രേഷൻ പ്രക്രിയ പൂർത്തിയാക്കി. ആവശ്യമായ വിവരങ്ങൾ നൽകാനുള്ള നിങ്ങളുടെ സമർപ്പണം നിങ്ങളെ ഫലപ്രദമായി സേവിക്കുന്നതിന് ഞങ്ങളെ ഒരു പടി കൂടി അടുപ്പിച്ചു.",
    "With your registration finalized, our team is fully equipped to handle your requirements efficiently. We assure you that your case will be handled with the utmost care and attention to detail.": "നിങ്ങളുടെ രജിസ്ട്രേഷൻ പൂർത്തിയായതോടെ, നിങ്ങളുടെ ആവശ്യകതകൾ കാര്യക്ഷമമായി കൈകാര്യം ചെയ്യാൻ ഞങ്ങളുടെ ടീം പൂർണ്ണമായും സജ്ജമാണ്. നിങ്ങളുടെ കേസ് വളരെ ശ്രദ്ധയോടെയും വിശദമായി ശ്രദ്ധയോടെയും കൈകാര്യം ചെയ്യുമെന്ന് ഞങ്ങൾ നിങ്ങൾക്ക് ഉറപ്പ് നൽകുന്നു.",
    "Should you ever require assistance or have any queries, please feel free to contact us at any time. We are here to address your concerns and ensure a smooth journey throughout the process.": "നിങ്ങൾക്ക് എപ്പോഴെങ്കിലും സഹായം ആവശ്യമുണ്ടെങ്കിൽ അല്ലെങ്കിൽ എന്തെങ്കിലും ചോദ്യങ്ങൾ ഉണ്ടെങ്കിൽ, എപ്പോൾ വേണമെങ്കിലും ഞങ്ങളെ ബന്ധപ്പെടാൻ മടിക്കേണ്ടതില്ല. നിങ്ങളുടെ ആശങ്കകൾ പരിഹരിക്കാനും പ്രക്രിയയിലുടനീളം സുഗമമായ യാത്ര ഉറപ്പാക്കാനും ഞങ്ങൾ ഇവിടെയുണ്ട്.",
    "We eagerly anticipate the opportunity to assist you further.": "നിങ്ങളെ കൂടുതൽ സഹായിക്കാനുള്ള അവസരം ഞങ്ങൾ ആകാംക്ഷയോടെ പ്രതീക്ഷിക്കുന്നു.",
    "Warm regards,": "ആശംസകൾ,",
    "PrivateCourt Team": "പ്രൈവറ്റ് കോടതി ടീം",
    "Secure UDYAM Number Validation": "സുരക്ഷിത UDYAM നമ്പർ മൂല്യനിർണ്ണയം",
    "Enter UDYAM for": "ഇതിനായി UDYAM നൽകുക",
    "having GST number": "GST നമ്പർ ഉള്ളത്",
    "Enter Your Udyam Number": "നിങ്ങളുടെ ഉദ്യം നമ്പർ നൽകുക",
    "Error: Invalid UAM Number": "പിശക്: UAM നമ്പർ അസാധുവാണ്",
    "We regret to inform you that the UAM Number entered is invalid or incorrect. Please ensure you have accurately entered detail and double-check for any typos.": "നൽകിയ UAM നമ്പർ അസാധുവോ തെറ്റോ ആണെന്ന് നിങ്ങളെ അറിയിക്കുന്നതിൽ ഞങ്ങൾ ഖേദിക്കുന്നു. നിങ്ങൾ വിശദാംശങ്ങൾ കൃത്യമായി നൽകിയിട്ടുണ്ടെന്ന് ഉറപ്പാക്കുകയും അക്ഷരത്തെറ്റുകൾ ഉണ്ടോയെന്ന് രണ്ടുതവണ പരിശോധിക്കുകയും ചെയ്യുക.",
    "Server Overload: Please Retry Later": "സെർവർ ഓവർലോഡ്: ദയവായി പിന്നീട് വീണ്ടും ശ്രമിക്കുക",
    "We apologize for the inconvenience and appreciate your patience. Currently, our server is experiencing a surge in requests, creating a temporary delay. Rest assured, we are diligently collecting your data to ensure accuracy and security.": "അസൗകര്യത്തിൽ ഞങ്ങൾ ക്ഷമ ചോദിക്കുകയും നിങ്ങളുടെ ക്ഷമയെ അഭിനന്ദിക്കുകയും ചെയ്യുന്നു. നിലവിൽ, ഞങ്ങളുടെ സെർവർ അഭ്യർത്ഥനകളിൽ കുതിച്ചുചാട്ടം നേരിടുന്നു, ഇത് താൽക്കാലിക കാലതാമസം സൃഷ്ടിക്കുന്നു. ഉറപ്പാക്കുക, കൃത്യതയും സുരക്ഷയും ഉറപ്പാക്കാൻ ഞങ്ങൾ നിങ്ങളുടെ ഡാറ്റ ഉത്സാഹത്തോടെ ശേഖരിക്കുകയാണ്.",
    "Please bear with us during this busy period, and we kindly request you to wait for a brief moment before attempting again. Your understanding is crucial in maintaining the quality of service we strive to provide.": "ഈ തിരക്കിനിടയിൽ ദയവായി ഞങ്ങളോട് സഹിഷ്ണുത പുലർത്തുക, വീണ്ടും ശ്രമിക്കുന്നതിന് മുമ്പ് ഒരു ചെറിയ നിമിഷം കാത്തിരിക്കാൻ ഞങ്ങൾ നിങ്ങളോട് അഭ്യർത്ഥിക്കുന്നു. ഞങ്ങൾ നൽകാൻ ശ്രമിക്കുന്ന സേവനത്തിൻ്റെ ഗുണനിലവാരം നിലനിർത്തുന്നതിൽ നിങ്ങളുടെ ധാരണ നിർണായകമാണ്.",
    "For urgent matters or assistance, feel free to call us at +91-9699900111.": "അടിയന്തര കാര്യങ്ങൾക്കോ സഹായത്തിനോ ഞങ്ങളെ +91-9699900111 എന്ന നമ്പറിൽ വിളിക്കുക.",
    "Thank you for your patience and entrusting PrivateCourt. Your security is our priority.": "നിങ്ങളുടെ ക്ഷമയ്ക്കും സ്വകാര്യ കോടതിയെ ഭരമേൽപ്പിച്ചതിനും നന്ദി. നിങ്ങളുടെ സുരക്ഷയാണ് ഞങ്ങളുടെ മുൻഗണന.",
    "If you continue to face issues, verify the": "നിങ്ങൾ പ്രശ്നങ്ങൾ അഭിമുഖീകരിക്കുന്നത് തുടരുകയാണെങ്കിൽ, പരിശോധിച്ചുറപ്പിക്കുക",
    "Applicant’s": "അപേക്ഷകർ",
    "Error: Invalid OTP": "പിശക്: OTP അസാധുവാണ്",
    "We apologize for the inconvenience. The OTP entered is incorrect. Please double-check the OTP and try again.": "അസൗകര്യത്തിൽ ഞങ്ങൾ ക്ഷമ ചോദിക്കുന്നു. നൽകിയ OTP തെറ്റാണ്. OTP രണ്ടുതവണ പരിശോധിച്ച് വീണ്ടും ശ്രമിക്കുക.",
    "Aadhaar card or contact our support team at +91-9699900111 for further assistance. We appreciate your cooperation.": "കൂടുതൽ സഹായത്തിന് ആധാർ കാർഡ് അല്ലെങ്കിൽ ഞങ്ങളുടെ സപ്പോർട്ട് ടീമിനെ +91-9699900111 എന്ന നമ്പറിൽ ബന്ധപ്പെടുക. നിങ്ങളുടെ സഹകരണത്തെ ഞങ്ങൾ അഭിനന്ദിക്കുന്നു.",
    "Error: User already exists": "പിശക്: ഉപയോക്താവ് ഇതിനകം നിലവിലുണ്ട്",
    "We apologize for the inconvenience. The Aadhaar number provided already exists in our records.Please provide a different Aadhaar number for verification.": "അസൗകര്യത്തിൽ ഞങ്ങൾ ക്ഷമ ചോദിക്കുന്നു. നൽകിയിരിക്കുന്ന ആധാർ നമ്പർ ഇതിനകം തന്നെ ഞങ്ങളുടെ രേഖകളിൽ നിലവിലുണ്ട്. സ്ഥിരീകരണത്തിനായി മറ്റൊരു ആധാർ നമ്പർ നൽകുക.",
    "We apologize for the inconvenience. The name provided does not match the PAN records. Please ensure that the name entered matches the information on your PAN card and try again.": "അസൗകര്യത്തിൽ ഞങ്ങൾ ക്ഷമ ചോദിക്കുന്നു. നൽകിയിരിക്കുന്ന പേര് പാൻ രേഖകളുമായി പൊരുത്തപ്പെടുന്നില്ല. നൽകിയ പേര് നിങ്ങളുടെ പാൻ കാർഡിലെ വിവരങ്ങളുമായി പൊരുത്തപ്പെടുന്നുണ്ടെന്ന് ഉറപ്പുവരുത്തി വീണ്ടും ശ്രമിക്കുക.",
    "Enter Bank Details for": "ഇതിനായി ബാങ്ക് വിശദാംശങ്ങൾ നൽകുക",
    "Error: Already Exists": "പിശക്: ഇതിനകം നിലവിലുണ്ട്",
    "We regret to inform you that the ID you've provided already exists in our system. Please try another ID.": "നിങ്ങൾ നൽകിയ ഐഡി ഞങ്ങളുടെ സിസ്റ്റത്തിൽ നിലവിലുണ്ടെന്ന് നിങ്ങളെ അറിയിക്കുന്നതിൽ ഞങ്ങൾ ഖേദിക്കുന്നു. ദയവായി മറ്റൊരു ഐഡി പരീക്ഷിക്കുക.",
    "If you continue to face issues, kindly verify your bank statement or contact your bank to confirm the accuracy of the provided information.": "നിങ്ങൾ തുടർന്നും പ്രശ്‌നങ്ങൾ നേരിടുന്നുണ്ടെങ്കിൽ, നിങ്ങളുടെ ബാങ്ക് സ്റ്റേറ്റ്‌മെൻ്റ് ദയവായി പരിശോധിക്കുക അല്ലെങ്കിൽ നൽകിയ വിവരങ്ങളുടെ കൃത്യത സ്ഥിരീകരിക്കാൻ നിങ്ങളുടെ ബാങ്കുമായി ബന്ധപ്പെടുക.",
    "Error: Name Not Matched": "പിശക്: പേര് പൊരുത്തപ്പെടുന്നില്ല",
    "Good Afternoon": "ഗുഡ് ആഫ്റ്റർനൂൺ",
    "Account security starts with verification": "അക്കൗണ്ട് സുരക്ഷ സ്ഥിരീകരണത്തോടെ ആരംഭിക്കുന്നു",
    "OTP Validation for Account Access": "അക്കൗണ്ട് ആക്‌സസിനായുള്ള OTP മൂല്യനിർണ്ണയം",
    "Kindly check your inbox, and enter the OTP in the designated field to complete the verification process.": "നിങ്ങളുടെ ഇൻബോക്സ് പരിശോധിക്കുക, സ്ഥിരീകരണ പ്രക്രിയ പൂർത്തിയാക്കാൻ നിയുക്ത ഫീൽഡിൽ OTP നൽകുക.",
    "Request a Resend": "വീണ്ടും അയയ്ക്കാൻ അഭ്യർത്ഥിക്കുക",
    "Error: Incorrect OTP": "പിശക്: തെറ്റായ OTP",
    "We apologize for the inconvenience. The One-Time Password (OTP) entered is incorrect. Please ensure that you have accurately entered the OTP received in your email.": "അസൗകര്യത്തിൽ ഞങ്ങൾ ക്ഷമ ചോദിക്കുന്നു. നൽകിയ ഒറ്റത്തവണ പാസ്‌വേഡ് (OTP) തെറ്റാണ്. നിങ്ങളുടെ ഇമെയിലിൽ ലഭിച്ച OTP നിങ്ങൾ കൃത്യമായി നൽകിയിട്ടുണ്ടെന്ന് ഉറപ്പാക്കുക.",
    "If the issue persists, you may request a new OTP by clicking on the resend link or contacting our support team  at +91-9699900111.": "പ്രശ്നം നിലനിൽക്കുകയാണെങ്കിൽ, വീണ്ടും അയയ്‌ക്കുന്ന ലിങ്കിൽ ക്ലിക്കുചെയ്‌ത് അല്ലെങ്കിൽ +91-9699900111 എന്ന നമ്പറിൽ ഞങ്ങളുടെ പിന്തുണാ ടീമിനെ ബന്ധപ്പെടുന്നതിലൂടെ നിങ്ങൾക്ക് ഒരു പുതിയ OTP അഭ്യർത്ഥിക്കാം.",
    "Double-check the email and try entering the OTP again.": "ഇമെയിൽ രണ്ടുതവണ പരിശോധിച്ച് OTP വീണ്ടും നൽകാൻ ശ്രമിക്കുക.",
    "At PrivateCourt, we prioritize the security and integrity of our services. Our GST verification process is meticulously designed to ensure a reliable and secure experience for all our users.": "പ്രൈവറ്റ് കോർട്ടിൽ, ഞങ്ങളുടെ സേവനങ്ങളുടെ സുരക്ഷയ്ക്കും സമഗ്രതയ്ക്കും ഞങ്ങൾ മുൻഗണന നൽകുന്നു. ഞങ്ങളുടെ എല്ലാ ഉപയോക്താക്കൾക്കും വിശ്വസനീയവും സുരക്ഷിതവുമായ അനുഭവം ഉറപ്പാക്കുന്നതിനാണ് ഞങ്ങളുടെ ജിഎസ്ടി സ്ഥിരീകരണ പ്രക്രിയ രൂപകൽപ്പന ചെയ്തിരിക്കുന്നത്.",
    "Enter Your GSTIN": "നിങ്ങളുടെ GSTIN നൽകുക",
    "Please enter your correct GST Identification Number (GSTIN) in the format 00-XXXXX-0000-X-0-X-0.": "ദയവായി നിങ്ങളുടെ ശരിയായ GST ഐഡൻ്റിഫിക്കേഷൻ നമ്പർ (GSTIN) 00-XXXXX-0000-X-0-X-0 ഫോർമാറ്റിൽ നൽകുക.",
    "Upon entering your GSTIN, PrivateCourt will diligently collect essential information associated with it. This includes your full name, date of birth, residential address, and contact details. For business owners, we extend our data collection to encompass all pertinent business details tied to your GSTIN, such as MCA specifics and records of GST return filings.": "നിങ്ങളുടെ GSTIN നൽകുമ്പോൾ, അതുമായി ബന്ധപ്പെട്ട അവശ്യ വിവരങ്ങൾ പ്രൈവറ്റ്കോർട്ട് ഉത്സാഹത്തോടെ ശേഖരിക്കും. ഇതിൽ നിങ്ങളുടെ മുഴുവൻ പേര്, ജനനത്തീയതി, താമസ വിലാസം, ബന്ധപ്പെടാനുള്ള വിശദാംശങ്ങൾ എന്നിവ ഉൾപ്പെടുന്നു. ബിസിനസ്സ് ഉടമകൾക്കായി, MCA സ്പെസിഫിക്കുകളും GST റിട്ടേൺ ഫയലിംഗുകളുടെ രേഖകളും പോലെ, നിങ്ങളുടെ GSTIN-മായി ബന്ധിപ്പിച്ചിട്ടുള്ള എല്ലാ പ്രസക്തമായ ബിസിനസ്സ് വിശദാംശങ്ങളും ഉൾക്കൊള്ളുന്നതിനായി ഞങ്ങളുടെ ഡാറ്റ ശേഖരണം ഞങ്ങൾ വിപുലീകരിക്കുന്നു.",
    "Our system assesses your CIBIL score for financial insight and cross-verifies for any ongoing court cases associated with your GSTIN.": "സാമ്പത്തിക ഉൾക്കാഴ്‌ചയ്‌ക്കായി ഞങ്ങളുടെ സിസ്റ്റം നിങ്ങളുടെ CIBIL സ്‌കോർ വിലയിരുത്തുകയും നിങ്ങളുടെ GSTIN-മായി ബന്ധപ്പെട്ട നിലവിലുള്ള ഏതെങ്കിലും കോടതി കേസുകൾ ക്രോസ്-വെരിഫൈ ചെയ്യുകയും ചെയ്യുന്നു.",
    "Error: Invalid GST Format": "പിശക്: അസാധുവായ GST ഫോർമാറ്റ്",
    "Please enter your personal PAN": "നിങ്ങളുടെ സ്വകാര്യ പാൻ നൽകുക",
    "Already Exists": "ഇതിനകം നിലവിലുണ്ട്",
    "Invalid": "അസാധുവാണ്",
    "Secure PAN Validation Process": "സുരക്ഷിത പാൻ മൂല്യനിർണ്ണയ പ്രക്രിയ",
    "This verification process is designed to uphold the integrity of our services, ensuring a reliable and secure experience.": "വിശ്വസനീയവും സുരക്ഷിതവുമായ അനുഭവം ഉറപ്പാക്കിക്കൊണ്ട് ഞങ്ങളുടെ സേവനങ്ങളുടെ സമഗ്രത ഉയർത്തിപ്പിടിക്കുന്നതിനാണ് ഈ സ്ഥിരീകരണ പ്രക്രിയ രൂപകൽപ്പന ചെയ്തിരിക്കുന്നത്.",
    "Enter Your PAN Number": "നിങ്ങളുടെ പാൻ നമ്പർ നൽകുക",
    "Please enter your correct PAN number in the format XXXXX-0000-X.": "XXXXX-0000-X ഫോർമാറ്റിൽ നിങ്ങളുടെ ശരിയായ പാൻ നമ്പർ നൽകുക.",
    "PrivateCourt will meticulously collect essential information associated with your PAN such as your full name, date of birth, residential address, and contact details. For Business Owners, we extend our data collection to encompass all pertinent business details tied to your PAN such as GST details, MCA specifics, and records of GST return filings.": "നിങ്ങളുടെ പൂർണ്ണമായ പേര്, ജനനത്തീയതി, താമസ വിലാസം, ബന്ധപ്പെടാനുള്ള വിശദാംശങ്ങൾ എന്നിവ പോലുള്ള നിങ്ങളുടെ പാൻ നമ്പറുമായി ബന്ധപ്പെട്ട അവശ്യ വിവരങ്ങൾ സ്വകാര്യകോടതി സൂക്ഷ്മമായി ശേഖരിക്കും. ബിസിനസ്സ് ഉടമകൾക്കായി, GST വിശദാംശങ്ങൾ, MCA സവിശേഷതകൾ, GST റിട്ടേൺ ഫയലിംഗുകളുടെ രേഖകൾ എന്നിവ പോലെ നിങ്ങളുടെ പാൻ ബന്ധിപ്പിച്ചിട്ടുള്ള എല്ലാ പ്രസക്തമായ ബിസിനസ്സ് വിശദാംശങ്ങളും ഉൾക്കൊള്ളുന്നതിനായി ഞങ്ങളുടെ ഡാറ്റ ശേഖരണം ഞങ്ങൾ വിപുലീകരിക്കുന്നു.",
    "Our system assesses your CIBIL score for financial insight and cross-verifies for any ongoing court cases associated with your PAN.": "സാമ്പത്തിക ഉൾക്കാഴ്‌ചയ്‌ക്കായി ഞങ്ങളുടെ സിസ്റ്റം നിങ്ങളുടെ CIBIL സ്‌കോർ വിലയിരുത്തുകയും നിങ്ങളുടെ PAN-മായി ബന്ധപ്പെട്ട നിലവിലുള്ള ഏതെങ്കിലും കോടതി കേസുകൾ ക്രോസ്-വെരിഫൈ ചെയ്യുകയും ചെയ്യുന്നു.",
    "It appears that the PAN details provided belong to a company. We require your personal PAN details for verification purposes.": "നൽകിയിരിക്കുന്ന പാൻ വിശദാംശങ്ങൾ ഒരു കമ്പനിയുടേതാണെന്ന് തോന്നുന്നു. സ്ഥിരീകരണ ആവശ്യങ്ങൾക്കായി ഞങ്ങൾക്ക് നിങ്ങളുടെ സ്വകാര്യ പാൻ വിശദാംശങ്ങൾ ആവശ്യമാണ്.",




    "Data Compilation in Progress: Please Allow Processing Time": "ഡാറ്റ സമാഹരണം പുരോഗമിക്കുന്നു: ദയവായി പ്രോസസ്സിംഗ് സമയം അനുവദിക്കുക",
    "Thank you for providing your information. Our system is currently in the process of analyzing and compiling the data you've entered, including details such as PAN, Aadhaar, GST, Email, and more, to establish your comprehensive profile.": "നിങ്ങളുടെ വിവരങ്ങൾ നൽകിയതിന് നന്ദി. നിങ്ങളുടെ സമഗ്രമായ പ്രൊഫൈൽ സ്ഥാപിക്കുന്നതിന് പാൻ, ആധാർ, ജിഎസ്ടി, ഇമെയിൽ എന്നിവയും അതിലേറെയും പോലുള്ള വിശദാംശങ്ങൾ ഉൾപ്പെടെ നിങ്ങൾ നൽകിയ ഡാറ്റ വിശകലനം ചെയ്യുകയും സമാഹരിക്കുകയും ചെയ്യുന്ന പ്രക്രിയയിലാണ് ഞങ്ങളുടെ സിസ്റ്റം.",
    "This operation may take a few moments. During this time, your screen may show minimal activity. Please be assured that this is a routine part of our data compilation process.": "ഈ പ്രവർത്തനത്തിന് കുറച്ച് നിമിഷങ്ങൾ എടുത്തേക്കാം. ഈ സമയത്ത്, നിങ്ങളുടെ സ്‌ക്രീൻ കുറഞ്ഞ പ്രവർത്തനം കാണിച്ചേക്കാം. ഇത് ഞങ്ങളുടെ ഡാറ്റ കംപൈലേഷൻ പ്രക്രിയയുടെ ഒരു പതിവ് ഭാഗമാണെന്ന് ദയവായി ഉറപ്പാക്കുക.",
    "Your profile is being meticulously crafted to uphold accuracy and security. We sincerely appreciate your patience and understanding as we work to enhance your experience.": "കൃത്യതയും സുരക്ഷയും ഉയർത്തിപ്പിടിക്കാൻ നിങ്ങളുടെ പ്രൊഫൈൽ സൂക്ഷ്മമായി രൂപപ്പെടുത്തിയിരിക്കുന്നു. നിങ്ങളുടെ അനുഭവം മെച്ചപ്പെടുത്തുന്നതിനായി ഞങ്ങൾ പ്രവർത്തിക്കുമ്പോൾ നിങ്ങളുടെ ക്ഷമയെയും ധാരണയെയും ഞങ്ങൾ ആത്മാർത്ഥമായി അഭിനന്ദിക്കുന്നു.",
    "For urgent matters or assistance, feel free to call us at +91-9699900111.": "അടിയന്തര കാര്യങ്ങൾക്കോ സഹായത്തിനോ ഞങ്ങളെ +91-9699900111 എന്ന നമ്പറിൽ വിളിക്കുക.",
    "Thank you for your patience and entrusting PrivateCourt. Your security is our priority.": "നിങ്ങളുടെ ക്ഷമയ്ക്കും സ്വകാര്യ കോടതിയെ ഭരമേൽപ്പിച്ചതിനും നന്ദി. നിങ്ങളുടെ സുരക്ഷയാണ് ഞങ്ങളുടെ മുൻഗണന.",
    "Please input your Aadhaar number. This process ensures accurate cross-checking for your security. Thank you for your cooperation.": "ദയവായി നിങ്ങളുടെ ആധാർ നമ്പർ നൽകുക. ഈ പ്രക്രിയ നിങ്ങളുടെ സുരക്ഷയ്ക്കായി കൃത്യമായ ക്രോസ് ചെക്കിംഗ് ഉറപ്പാക്കുന്നു. നിങ്ങളുടെ സഹകരണത്തിന് നന്ദി.",
    "We apologize for the inconvenience. The Aadhaar number entered is invalid. Please double-check and ensure that you have entered the": "അസൗകര്യത്തിൽ ഞങ്ങൾ ക്ഷമ ചോദിക്കുന്നു. നൽകിയ ആധാർ നമ്പർ അസാധുവാണ്. ദയവായി രണ്ടുതവണ പരിശോധിച്ച് നിങ്ങൾ നൽകിയിട്ടുണ്ടെന്ന് ഉറപ്പാക്കുക",
    "Applicant’s correct 12-digit Aadhaar number.": "അപേക്ഷകൻ്റെ ശരിയായ 12 അക്ക ആധാർ നമ്പർ.",
    "Error: Invalid OTP": "പിശക്: OTP അസാധുവാണ്",
    "We apologize for the inconvenience. The OTP entered is incorrect. Please double-check the OTP and try again.": "അസൗകര്യത്തിൽ ഞങ്ങൾ ക്ഷമ ചോദിക്കുന്നു. നൽകിയ OTP തെറ്റാണ്. OTP രണ്ടുതവണ പരിശോധിച്ച് വീണ്ടും ശ്രമിക്കുക.",
    "Welcome": "സ്വാഗതം",
    "Ensuring the accuracy of your bank account information is crucial for a seamless verification process.Kindly enter your valid Account Number, and input the correct IFSC code associated with your account.": "തടസ്സമില്ലാത്ത സ്ഥിരീകരണ പ്രക്രിയയ്ക്ക് നിങ്ങളുടെ ബാങ്ക് അക്കൗണ്ട് വിവരങ്ങളുടെ കൃത്യത ഉറപ്പാക്കുന്നത് നിർണായകമാണ്. ദയവായി നിങ്ങളുടെ സാധുവായ അക്കൗണ്ട് നമ്പർ നൽകുക, നിങ്ങളുടെ അക്കൗണ്ടുമായി ബന്ധപ്പെട്ട ശരിയായ IFSC കോഡ് നൽകുക.",
    "This verification step is designed to enhance the security of our services and provide you with a reliable experience.": "ഞങ്ങളുടെ സേവനങ്ങളുടെ സുരക്ഷ വർധിപ്പിക്കുന്നതിനും നിങ്ങൾക്ക് വിശ്വസനീയമായ അനുഭവം നൽകുന്നതിനുമായാണ് ഈ സ്ഥിരീകരണ ഘട്ടം രൂപകൽപ്പന ചെയ്തിരിക്കുന്നത്.",
    "We apologize for the inconvenience. The name provided does not match the GST records. Please ensure that the name entered matches the information on your GST card and try again.": "അസൗകര്യത്തിൽ ഞങ്ങൾ ക്ഷമ ചോദിക്കുന്നു. നൽകിയിരിക്കുന്ന പേര് GST രേഖകളുമായി പൊരുത്തപ്പെടുന്നില്ല. നൽകിയ പേര് നിങ്ങളുടെ GST കാർഡിലെ വിവരങ്ങളുമായി പൊരുത്തപ്പെടുന്നുണ്ടെന്ന് ഉറപ്പുവരുത്തി വീണ്ടും ശ്രമിക്കുക.",
    "Business Verification Process": "ബിസിനസ്സ് സ്ഥിരീകരണ പ്രക്രിയ",
    "Ensuring Secure Business Validation- This verification process is meticulously designed to uphold the integrity of our services, providing a reliable and secure experience.": "സുരക്ഷിതമായ ബിസിനസ് മൂല്യനിർണ്ണയം ഉറപ്പാക്കുന്നു- ഈ സ്ഥിരീകരണ പ്രക്രിയ, ഞങ്ങളുടെ സേവനങ്ങളുടെ സമഗ്രത ഉയർത്തിപ്പിടിച്ച്, വിശ്വസനീയവും സുരക്ഷിതവുമായ അനുഭവം പ്രദാനം ചെയ്യുന്നതിനാണ്.",
    "Please provide details for each of your businesses for accurate validation.": "കൃത്യമായ മൂല്യനിർണ്ണയത്തിനായി നിങ്ങളുടെ ഓരോ ബിസിനസിൻ്റെയും വിശദാംശങ്ങൾ നൽകുക.",
    "PrivateCourt will collect essential information associated with your PAN such as your full name, date of birth, residential address, and contact details. For Business Owners, we extend our data collection to encompass all pertinent business details tied to your PAN such as GST details, MCA specifics, and records of GST return filings.": "നിങ്ങളുടെ പൂർണ്ണമായ പേര്, ജനനത്തീയതി, താമസ വിലാസം, ബന്ധപ്പെടാനുള്ള വിശദാംശങ്ങൾ എന്നിവ പോലുള്ള നിങ്ങളുടെ പാൻ നമ്പറുമായി ബന്ധപ്പെട്ട അവശ്യ വിവരങ്ങൾ സ്വകാര്യകോടതി ശേഖരിക്കും. ബിസിനസ്സ് ഉടമകൾക്കായി, GST വിശദാംശങ്ങൾ, MCA സവിശേഷതകൾ, GST റിട്ടേൺ ഫയലിംഗുകളുടെ രേഖകൾ എന്നിവ പോലെ നിങ്ങളുടെ പാൻ ബന്ധിപ്പിച്ചിട്ടുള്ള എല്ലാ പ്രസക്തമായ ബിസിനസ്സ് വിശദാംശങ്ങളും ഉൾക്കൊള്ളുന്നതിനായി ഞങ്ങളുടെ ഡാറ്റ ശേഖരണം ഞങ്ങൾ വിപുലീകരിക്കുന്നു.",
    "Our system evaluates your CIBIL score for financial insight and cross-verifies for any ongoing court cases associated with your PAN.": "സാമ്പത്തിക ഉൾക്കാഴ്‌ചയ്‌ക്കായി ഞങ്ങളുടെ സിസ്റ്റം നിങ്ങളുടെ CIBIL സ്‌കോർ വിലയിരുത്തുകയും നിങ്ങളുടെ PAN-മായി ബന്ധപ്പെട്ട നിലവിലുള്ള ഏതെങ്കിലും കോടതി കേസുകൾ ക്രോസ്-വെരിഫൈ ചെയ്യുകയും ചെയ്യുന്നു.",
    "To ensure the security of your account, please enter your valid Email ID in the specified format- username@domain.com. If your email address doesn't exist in our system, a": "നിങ്ങളുടെ അക്കൗണ്ടിൻ്റെ സുരക്ഷ ഉറപ്പാക്കാൻ, നിർദ്ദിഷ്ട ഫോർമാറ്റിൽ നിങ്ങളുടെ സാധുവായ ഇമെയിൽ ഐഡി നൽകുക- username@domain.com. നിങ്ങളുടെ ഇമെയിൽ വിലാസം ഞങ്ങളുടെ സിസ്റ്റത്തിൽ നിലവിലില്ലെങ്കിൽ, എ",
    "account will be created for you.": "നിങ്ങൾക്കായി അക്കൗണ്ട് സൃഷ്ടിക്കും.",
    "GST Verification Process": "ജിഎസ്ടി സ്ഥിരീകരണ പ്രക്രിയ",
    "We regret to inform you that the GST number entered does not match the required format (00-XXXXX-0000-X-0-X-0).Please ensure you have entered the correct GST details and follow the specified format for accurate verification.": "നൽകിയ GST നമ്പർ ആവശ്യമായ ഫോർമാറ്റുമായി (00-XXXXX-0000-X-0-X-0) പൊരുത്തപ്പെടുന്നില്ലെന്ന് നിങ്ങളെ അറിയിക്കുന്നതിൽ ഞങ്ങൾ ഖേദിക്കുന്നു. നിങ്ങൾ ശരിയായ GST വിശദാംശങ്ങൾ നൽകിയിട്ടുണ്ടെന്ന് ഉറപ്പുവരുത്തുകയും കൃത്യമായ സ്ഥിരീകരണത്തിനായി നിർദ്ദിഷ്ട ഫോർമാറ്റ് പിന്തുടരുകയും ചെയ്യുക.",
    "Verify GST": "GST പരിശോധിക്കുക",
    "Congratulations! You've completed the registration process. Your dedication to providing the necessary information has brought us one step closer to serving you effectively.": "അഭിനന്ദനങ്ങൾ! നിങ്ങൾ രജിസ്ട്രേഷൻ പ്രക്രിയ പൂർത്തിയാക്കി. ആവശ്യമായ വിവരങ്ങൾ നൽകാനുള്ള നിങ്ങളുടെ സമർപ്പണം നിങ്ങളെ ഫലപ്രദമായി സേവിക്കുന്നതിന് ഞങ്ങളെ ഒരു പടി കൂടി അടുപ്പിച്ചു.",
    "We apologize for the inconvenience. The name provided does not match the GST records. Please ensure that the name entered matches the information on your GST card and try again.": "അസൗകര്യത്തിൽ ഞങ്ങൾ ക്ഷമ ചോദിക്കുന്നു. നൽകിയിരിക്കുന്ന പേര് GST രേഖകളുമായി പൊരുത്തപ്പെടുന്നില്ല. നൽകിയ പേര് നിങ്ങളുടെ GST കാർഡിലെ വിവരങ്ങളുമായി പൊരുത്തപ്പെടുന്നുണ്ടെന്ന് ഉറപ്പുവരുത്തി വീണ്ടും ശ്രമിക്കുക.",
    "Skip": "ഒഴിവാക്കുക",
    "Server Overload: Please Retry Later": "സെർവർ ഓവർലോഡ്: ദയവായി പിന്നീട് വീണ്ടും ശ്രമിക്കുക",
    "Back To Home": "തിരികെ വീട്ടിലേക്ക്",

    'Language Selection:': '',
    'Empowering Effective Communication': '',
    "Good day! I'm Murthy, your dedicated assistant for today's proceedings. Lets start by selecting the language you're most comfortable with.": '',
    "Opting for your preferred language ensures clear and effective communication. Whether it's your native tongue or another language of your choice, selecting it will streamline our interaction and enhance your experience.": "",
    "Select Your Preferred Language": "",
    "Welcome to PrivateCourt, where Helping you is our priority.": "",
    "Now, let me introduce you to PrivateCourt – your reliable partner in resolving lending disputes effectively and efficiently.": '',
    "PrivateCourt: Your trusted partner in resolving lending disputes with care and expertise.": "",
    "Welcome to PrivateCourt, where your case details are in good hands. With the lending company initiating arbitration or conciliation, it's essential for us to understand your situation fully. Please share all the details you can.": "",
    "The more information we have, the better we can help. Your input is crucial in finding the right solution for you.": "",
    "Trust us to carefully examine your case and work together to find the best way forward.": "",
    "Reviewing Your Dispute Information": 'आपकी विवाद जानकारी की समीक्षा करना',
    "Let's take a closer look at the details surrounding your dispute. The information we're discussing today has been shared with us by LendingKart, regarding a personal loan.": "",
    "By understanding these specifics, we can better assess the situation and formulate a resolution plan that aligns with your circumstances and goals.": "",
    "Dispute Amount Information": "",
    "According to their records, you initially borrowed an amount of": "",
    "Presently, your outstanding due stands at Rs.": "",
    "These details provide us with valuable insight into the history and current status of your loan.": "",
    "Spotting Financial Issues: Understanding Unpaid Obligations": "",
    "Now, let's gently explore the challenges you've faced due to financial constraints. Discussing this may be sensitive, but your openness will greatly assist us in finding the best path forward. Please take a moment to select any of the following options that resonate with your experience:": "",
    "Financial Loss": "",
    "Business Loss": "",
    "Partner Dispute": "",
    "Funds held with other vendors or clients": "",
    "Monetary loss through other means": "",
    "Job Loss": "",
    "Less than 2 months": "",
    "Less than 6 months": "",
    "Less than 1 year": "",
    "More than 1 year": "",
    "Health Issue": "",
    "Death of the earning member": "",
    "Missing payment details": "",
    "Dispute due to bad behaviour": "",
    "No clear loan information": "",
    "Death Case": "",
    "We kindly request you to upload the death certificate at": "",
    "Other reason/s": "",
    "Your prompt response will greatly assist us in assessing the situation and finding the most suitable resolution. Rest assured, we are committed to working with you to resolve any outstanding issues and ensure a fair and satisfactory outcome.": "",
    "Exploring Settlement Possibilities": "",
    "As our discussion progresses, I appreciate your openness in explaining the reason for the default. It's crucial to address your outstanding dues promptly to prevent any legal complexities. We recommend exploring different avenues, such as seeking assistance from family or investigating alternative financing options, to fulfill your obligations without delay.": "",
    "Timely settlement not only mitigates legal risks but also strengthens trust and credibility in your financial interactions. Your cooperation in this matter is truly valued.": "",
    "Settlement Options": "",
    "In our efforts to facilitate a swift resolution, we seek your cooperation.": "",
    "Are you open to discussing the settlement of your outstanding dues?": "",
    "We appreciate your openness to resolving your outstanding dues. To better understand your financial situation and tailor our approach accordingly, could you kindly provide details on your current family income?": "",
    "In our efforts to address your outstanding dues, we'd like to know if a one-time payment settlement is an option for you.": "",



    "Verify Aadhaar": "ആധാർ പരിശോധിക്കുക",
    "having PAN number": "പാൻ നമ്പർ ഉണ്ട്",
    "Verify Bank": "ബാങ്ക് സ്ഥിരീകരിക്കുക",
    "Select Businesses": "ബിസിനസുകൾ തിരഞ്ഞെടുക്കുക",
    "I am individual": "ഞാൻ ഒരു വ്യക്തിയാണ്",
    "Securely Verify Your Credit Score": "നിങ്ങളുടെ ക്രെഡിറ്റ് സ്കോർ സുരക്ഷിതമായി പരിശോധിക്കുക",
    "Enter Your Mobile Number": "നിങ്ങളുടെ മൊബൈൽ നമ്പർ നൽകുക",
    "To access your credit score securely, please enter your mobile number below. We'll send you a one-time password (OTP) to verify your identity and ensure the confidentiality of your information.": "നിങ്ങളുടെ ക്രെഡിറ്റ് സ്‌കോർ സുരക്ഷിതമായി ആക്‌സസ് ചെയ്യുന്നതിന്, ദയവായി നിങ്ങളുടെ മൊബൈൽ നമ്പർ ചുവടെ നൽകുക. നിങ്ങളുടെ ഐഡൻ്റിറ്റി സ്ഥിരീകരിക്കുന്നതിനും നിങ്ങളുടെ വിവരങ്ങളുടെ രഹസ്യാത്മകത ഉറപ്പാക്കുന്നതിനും ഞങ്ങൾ നിങ്ങൾക്ക് ഒറ്റത്തവണ പാസ്‌വേഡ് (OTP) അയയ്ക്കും.",
    "Verify without OTP": "ഒടിപി ഇല്ലാതെ പരിശോധിക്കുക",
    "Upon submission, check your messages promptly for the OTP. This step ensures your financial information remains protected while you gain valuable insights into your credit profile.": "സമർപ്പിക്കുമ്പോൾ, ഒടിപിക്കായി നിങ്ങളുടെ സന്ദേശങ്ങൾ ഉടനടി പരിശോധിക്കുക. നിങ്ങളുടെ ക്രെഡിറ്റ് പ്രൊഫൈലിലേക്ക് മൂല്യവത്തായ ഉൾക്കാഴ്ചകൾ ലഭിക്കുമ്പോൾ നിങ്ങളുടെ സാമ്പത്തിക വിവരങ്ങൾ സംരക്ഷിക്കപ്പെട്ടതായി ഈ ഘട്ടം ഉറപ്പാക്കുന്നു.",
    "Enter Your OTP Here": "നിങ്ങളുടെ OTP ഇവിടെ നൽകുക",
    "Error: Invalid Mobile Number": "പിശക്: അസാധുവായ മൊബൈൽ നമ്പർ",
    "We apologize for the inconvenience. The Mobile number entered is invalid. Please double-check and ensure that you have entered the": "അസൗകര്യത്തിൽ ഞങ്ങൾ ക്ഷമ ചോദിക്കുന്നു. നൽകിയ മൊബൈൽ നമ്പർ അസാധുവാണ്. ദയവായി രണ്ടുതവണ പരിശോധിച്ച് നിങ്ങൾ നൽകിയിട്ടുണ്ടെന്ന് ഉറപ്പാക്കുക",
    "Applicant’s correct 10-digit Mobile number.": "അപേക്ഷകൻ്റെ ശരിയായ 10 അക്ക മൊബൈൽ നമ്പർ.",
    "Mobile Number or contact our support team at +91-9699900111 for further assistance. We appreciate your cooperation.": "കൂടുതൽ സഹായത്തിനായി മൊബൈൽ നമ്പർ അല്ലെങ്കിൽ ഞങ്ങളുടെ പിന്തുണാ ടീമിനെ +91-9699900111 എന്ന നമ്പറിൽ ബന്ധപ്പെടുക. നിങ്ങളുടെ സഹകരണത്തെ ഞങ്ങൾ അഭിനന്ദിക്കുന്നു.",
    "Good Morning": "സുപ്രഭാതം",
    "Good Evening": "ഗുഡ് ഈവനിംഗ്",
    "Good Night": "ശുഭ രാത്രി",
    "We regret to inform you that the GST number entered does not match the required format (00-XXXXX-0000-X-0-X-0). Please ensure you have entered the correct GST details and follow the specified format for accurate verification.": "നൽകിയ GST നമ്പർ ആവശ്യമായ ഫോർമാറ്റുമായി (00-XXXXX-0000-X-0-X-0) പൊരുത്തപ്പെടുന്നില്ലെന്ന് നിങ്ങളെ അറിയിക്കുന്നതിൽ ഞങ്ങൾ ഖേദിക്കുന്നു. നിങ്ങൾ ശരിയായ GST വിശദാംശങ്ങൾ നൽകിയിട്ടുണ്ടെന്ന് ഉറപ്പുവരുത്തി കൃത്യമായ സ്ഥിരീകരണത്തിനായി നിർദ്ദിഷ്ട ഫോർമാറ്റ് പിന്തുടരുക .",
    "Error: Invalid PAN category": "പിശക്: അസാധുവായ പാൻ വിഭാഗം",
    "Rs.": "രൂപ.",
    "Verification Complete – Thank You!": "സ്ഥിരീകരണം പൂർത്തിയായി - നന്ദി!",
    "Congratulations! You've completed the Verification process. Your dedication to providing the necessary information has brought us one step closer to serving you effectively.": "അഭിനന്ദനങ്ങൾ! നിങ്ങൾ സ്ഥിരീകരണ പ്രക്രിയ പൂർത്തിയാക്കി. ആവശ്യമായ വിവരങ്ങൾ നൽകാനുള്ള നിങ്ങളുടെ സമർപ്പണം നിങ്ങളെ ഫലപ്രദമായി സേവിക്കുന്നതിന് ഞങ്ങളെ ഒരു പടി കൂടി അടുപ്പിച്ചു.",
    "With your Verification finalized, our team is fully equipped to handle your requirements efficiently. We assure you that your case will be handled with the utmost care and attention to detail.": "നിങ്ങളുടെ പരിശോധിച്ചുറപ്പിക്കൽ പൂർത്തിയായതോടെ, നിങ്ങളുടെ ആവശ്യകതകൾ കാര്യക്ഷമമായി കൈകാര്യം ചെയ്യാൻ ഞങ്ങളുടെ ടീം പൂർണ്ണമായും സജ്ജമാണ്. നിങ്ങളുടെ കേസ് വളരെ ശ്രദ്ധയോടെയും വിശദമായി ശ്രദ്ധയോടെയും കൈകാര്യം ചെയ്യുമെന്ന് ഞങ്ങൾ ഉറപ്പ് നൽകുന്നു.",
    "having Gst number": "ജിഎസ്ടി നമ്പർ ഉണ്ട്",
    "Your UDYAM Number allows us to collect crucial details associated with your business. This includes your business address, current status, service details, and organization type.": "നിങ്ങളുടെ ബിസിനസ്സുമായി ബന്ധപ്പെട്ട നിർണായക വിശദാംശങ്ങൾ ശേഖരിക്കാൻ നിങ്ങളുടെ UDYAM നമ്പർ ഞങ്ങളെ അനുവദിക്കുന്നു. ഇതിൽ നിങ്ങളുടെ ബിസിനസ്സ് വിലാസം, നിലവിലെ നില, സേവന വിശദാംശങ്ങൾ, ഓർഗനൈസേഷൻ തരം എന്നിവ ഉൾപ്പെടുന്നു.",

    "Registration Complete - Thank You!": "രജിസ്ട്രേഷൻ പൂർത്തിയായി - നന്ദി!",
    "We apologize for the inconvenience. The Aadhaar number provided already exists in our records. Please provide a different Aadhaar number for verification.": "അസൗകര്യത്തിൽ ഞങ്ങൾ ക്ഷമ ചോദിക്കുന്നു. നൽകിയിരിക്കുന്ന ആധാർ നമ്പർ ഞങ്ങളുടെ രേഖകളിൽ നിലവിലുണ്ട്. സ്ഥിരീകരണത്തിനായി മറ്റൊരു ആധാർ നമ്പർ നൽകുക.",



    "Securely Verify Your Credit Score": "നിങ്ങളുടെ ക്രെഡിറ്റ് സ്കോർ സുരക്ഷിതമായി പരിശോധിക്കുക",
    "Enter Your Mobile Number": "നിങ്ങളുടെ മൊബൈൽ നമ്പർ നൽകുക",
    "To access your credit score securely, please enter your mobile number below. We'll send you a one-time password (OTP) to verify your identity and ensure the confidentiality of your information.": "നിങ്ങളുടെ ക്രെഡിറ്റ് സ്‌കോർ സുരക്ഷിതമായി ആക്‌സസ് ചെയ്യുന്നതിന്, ദയവായി നിങ്ങളുടെ മൊബൈൽ നമ്പർ ചുവടെ നൽകുക. നിങ്ങളുടെ ഐഡൻ്റിറ്റി സ്ഥിരീകരിക്കുന്നതിനും നിങ്ങളുടെ വിവരങ്ങളുടെ രഹസ്യാത്മകത ഉറപ്പാക്കുന്നതിനും ഞങ്ങൾ നിങ്ങൾക്ക് ഒറ്റത്തവണ പാസ്‌വേഡ് (OTP) അയയ്ക്കും.",
    "For any queries or assistance, feel free to call us at +91-9699900111.": "ഏത് ചോദ്യങ്ങൾക്കും സഹായത്തിനും ഞങ്ങളെ +91-9699900111 എന്ന നമ്പറിൽ വിളിക്കാൻ മടിക്കേണ്ടതില്ല.",
    "Verify without OTP": "ഒടിപി ഇല്ലാതെ പരിശോധിക്കുക",
    "Upon submission, check your messages promptly for the OTP. This step ensures your financial information remains protected while you gain valuable insights into your credit profile.": "സമർപ്പിക്കുമ്പോൾ, ഒടിപിക്കായി നിങ്ങളുടെ സന്ദേശങ്ങൾ ഉടനടി പരിശോധിക്കുക. നിങ്ങളുടെ ക്രെഡിറ്റ് പ്രൊഫൈലിലേക്ക് മൂല്യവത്തായ ഉൾക്കാഴ്‌ചകൾ നേടുമ്പോൾ നിങ്ങളുടെ സാമ്പത്തിക വിവരങ്ങൾ സംരക്ഷിക്കപ്പെടുന്നതായി ഈ ഘട്ടം ഉറപ്പാക്കുന്നു.",
    "Enter Your OTP Here": "നിങ്ങളുടെ OTP ഇവിടെ നൽകുക",
    "Error: Invalid Mobile Number": "പിശക്: അസാധുവായ മൊബൈൽ നമ്പർ",
    "We apologize for the inconvenience. The Mobile number entered is invalid. Please double-check and ensure that you have entered the": "അസൗകര്യത്തിൽ ഞങ്ങൾ ക്ഷമ ചോദിക്കുന്നു. നൽകിയ മൊബൈൽ നമ്പർ അസാധുവാണ്. ദയവായി രണ്ടുതവണ പരിശോധിച്ച് നിങ്ങൾ നൽകിയിട്ടുണ്ടെന്ന് ഉറപ്പാക്കുക",
    "Applicant’s correct 10-digit Mobile number.": "അപേക്ഷകൻ്റെ ശരിയായ 10 അക്ക മൊബൈൽ നമ്പർ.",
    "Mobile Number or contact our support team at +91-9699900111 for further assistance. We appreciate your cooperation.": "കൂടുതൽ സഹായത്തിനായി മൊബൈൽ നമ്പർ അല്ലെങ്കിൽ ഞങ്ങളുടെ പിന്തുണാ ടീമിനെ +91-9699900111 എന്ന നമ്പറിൽ ബന്ധപ്പെടുക. നിങ്ങളുടെ സഹകരണത്തെ ഞങ്ങൾ അഭിനന്ദിക്കുന്നു.",
    "Error: Invalid OTP": "പിശക്: OTP അസാധുവാണ്",
    "We apologize for the inconvenience. The OTP entered is incorrect. Please double-check the OTP and try again.": "അസൗകര്യത്തിൽ ഞങ്ങൾ ക്ഷമ ചോദിക്കുന്നു. നൽകിയ OTP തെറ്റാണ്. OTP രണ്ടുതവണ പരിശോധിച്ച് വീണ്ടും ശ്രമിക്കുക.",
    "mobile Number or contact our support team at +91-9699900111 for further assistance. We appreciate your cooperation.": "കൂടുതൽ സഹായത്തിനായി മൊബൈൽ നമ്പർ അല്ലെങ്കിൽ ഞങ്ങളുടെ പിന്തുണാ ടീമിനെ +91-9699900111 എന്ന നമ്പറിൽ ബന്ധപ്പെടുക. നിങ്ങളുടെ സഹകരണത്തെ ഞങ്ങൾ അഭിനന്ദിക്കുന്നു.",
    "Verify OTP": "ഒടിപി പരിശോധിക്കുക",
    "Verify": "സ്ഥിരീകരിക്കുക",


    //Conciliation

    "Respected ,name":"ആദരണീയന് ,name",
    "CONCILIATION NOTICE": "സാധുവാക്കല്‍ അറിയിപ്പ്",
    "Warm Greetings from PRIVATECOURT. We are writing to formally inform you that PRIVATECOURT will oversee and resolve the dispute(s) between you and company .": "PRIVATECOURT-നു നിന്ന് ഹൃദ്യമായ Salam. PRIVATECOURT നിങ്ങളുടെ കമ്പനിയുമായുള്ള തര്‍ക്കങ്ങളെ മേല്‍നോട്ടം വഹിച്ച് പരിഹരിക്കുമെന്ന് ഔദ്യോഗികമായി നിങ്ങളെ അറിയിക്കുകയാണ്.",
    "Please find attached the confirmation document with hearing details that outline our acceptance of our commitment to handling your disputes through Alternative Dispute Resolution (ADR).": "വിവാദങ്ങളെ സാന്ത്വനപരമായ പരിഹാരത്തിനായി (ADR) കൈകാര്യം ചെയ്യുന്നതിനുള്ള പ്രതിജ്ഞയുമായി ഞങ്ങളുടെ അംഗീകരണത്തെ വിശദീകരിക്കുന്ന ഉപദേശം വിശദീകരണമായുള്ള സ്ഥിരീകരണ ഡോക്യുമെന്റ് സംയോജിപ്പിച്ചിരിക്കുന്നു.",
     "To explore resolution options and engage in the process effectively, we provide the following avenues, from which you can select any one way:": "പരിഹാര ഓപ്ഷനുകൾ പരിശോധിക്കുകയും പ്രക്രിയയിൽ ഫലപ്രദമായി ചേരുന്നതിനായി, നിങ്ങൾക്ക് ഏതെങ്കിലും ഒരു മാർഗം തിരഞ്ഞെടുക്കാവുന്ന താഴെപ്പറയുന്ന മാർഗ്ഗങ്ങൾ നാമിൽ നൽകുന്നു:",
     "Access Your Personalized Dispute Resolution Page: Click link to access your dedicated dispute resolution page. Here, you can provide detailed information about your issue. Our support team will review your submission promptly and guide you through the next steps of the resolution process.": "നിങ്ങളുടെ വ്യക്തിഗത विवाद പരിഹാര പേജ് ആക്സസ് ചെയ്യുക: നിങ്ങളുടെ സമർപ്പിത വിഷയം പരിഹാര പേജിലേക്ക് പ്രവേശിക്കാൻ link ക്ലിക്ക് ചെയ്യുക. ഇവിടെ, നിങ്ങളുടെ പ്രശ്നത്തെ കുറിച്ച് വിശദമായ വിവരങ്ങൾ നൽകാവുന്നതാണ്. ഞങ്ങളുടെ പിന്തുണ ടീമിന്റെ സമർപ്പണത്തെ ഉടൻ പരിശോധിച്ച്, പരിഹാര പ്രക്രിയയുടെ അടുത്ത പടികളിലൂടെ നിങ്ങളെ വഴികാട്ടും.",
     "OR": "അഥവാ",
     "You are hereby directed to attend the hearingType hearing scheduled on date, at place, between timing.": "date-ന് place-ൽ hearingType കേട്ടുനിൽക്കാൻ നിങ്ങൾക്ക് ഈ സന്ദേശം വഴി നിർദ്ദേശം ചെയ്യുന്നു, timing-ൽ.",
    "This platform enables you to directly engage with our team, comprehensively discussing and sharing your dispute details.": "ഈ പ്ലാറ്റ്ഫോം നിങ്ങൾക്ക് നേരിട്ട് ഞങ്ങളുടെ ടീമുമായി ബന്ധപ്പെടാൻ, നിങ്ങളുടെ വിവാദ വിശദാംശങ്ങൾ വിശദമായി ചർച്ച ചെയ്യാനും പങ്കുവെക്കാനും സഹായിക്കുന്നു.",
     "If you have any additional information or documentation related to this matter, please feel free to reach out to our dedicated customer support team at +918035731376 or Email: legal@privatecourt.in": "ഈ വിഷയവുമായി ബന്ധപ്പെട്ട കൂടുതൽ വിവരങ്ങൾ അല്ലെങ്കിൽ പ്രമാണങ്ങൾ നിങ്ങൾക്ക് ഉണ്ടെങ്കിൽ, ദയവായി +918035731376-ന് വിളിക്കാനും അല്ലെങ്കിൽ legal@privatecourt.in എന്ന ഇമെയിലിൽ ബന്ധപ്പെടാനും മടിക്കരുത്.",
     "We appreciate your trust in PRIVATECOURT for dispute resolution and are committed to delivering a fair and impartial resolution to the matter at hand.": "വിവാദ പരിഹാരത്തിനായി PRIVATECOURT-ൽ നിങ്ങൾക്കുള്ള വിശ്വാസം ഞങ്ങൾ ആദരിക്കുന്നു, നിങ്ങളുടെ കാര്യത്തിന് ന്യായമായും നീതിയും ചേർന്ന പരിഹാരമൊരുക്കാൻ ഞങ്ങൾ പ്രതിജ്ഞാബദ്ധരാണ്.",
     "Thank You,": "നന്ദി,",
     "Team PrivateCourt": "ടീം പ്രൈവറ്റ്‌കോർട്ട്",
     "Join anytime here and submit your response":"എപ്പോൾ വേണമെങ്കിലും ഇവിടെ ചേരുക, നിങ്ങളുടെ പ്രതികരണം സമർപ്പിക്കുക",
      "It's time to join the video call.":"വീഡിയോ കോളിൽ ചേരാൻ സമയമായി.",
      "You are hereby directed to attend the hearing scheduled on date, at place, between timing.":"തീയതി date-ൽ, സ്ഥലം place-ൽ, സമയം timing ന്റെ ഇടയിൽ ഷെഡ്യൂൾ ചെയ്തിട്ടുള്ള ഹിയറിംഗിൽ പങ്കെടുക്കാൻ നിങ്ങളോട് ഇതിനാൽ നിർദ്ദേശിക്കുന്നു.",
      "Please explain the reasons for your loan repayment difficulties below. Include any relevant circumstances such as financial hardship, medical issues, or disputes over loan terms. Your detailed response will help us assess and resolve your case effectively.":"നിങ്ങളുടെ ലോൺ തിരിച്ചടവ് ബുദ്ധിമുട്ടുകളുടെ കാരണങ്ങൾ ചുവടെ വിശദീകരിക്കുക. സാമ്പത്തിക ബുദ്ധിമുട്ട്, മെഡിക്കൽ പ്രശ്നങ്ങൾ അല്ലെങ്കിൽ ലോൺ നിബന്ധനകളെക്കുറിച്ചുള്ള തർക്കങ്ങൾ പോലുള്ള പ്രസക്തമായ സാഹചര്യങ്ങൾ ഉൾപ്പെടുത്തുക. നിങ്ങളുടെ വിശദമായ പ്രതികരണം നിങ്ങളുടെ കേസ് ഫലപ്രദമായി വിലയിരുത്താനും പരിഹരിക്കാനും ഞങ്ങളെ സഹായിക്കും.",
      "It’s time for your scheduled online hearing. Join the session by clicking the button below, and ensure all necessary materials are ready for discussion.":"നിങ്ങളുടെ ഷെഡ്യൂൾ ചെയ്ത ഓൺലൈൻ ഹിയറിംഗിനുള്ള സമയമാണിത്. ചുവടെയുള്ള ബട്ടൺ ക്ലിക്കുചെയ്ത് സെഷനിൽ ചേരുക, ആവശ്യമായ എല്ലാ മെറ്റീരിയലുകളും ചർച്ചയ്ക്ക് തയ്യാറാണെന്ന് ഉറപ്പാക്കുക.",
      "To proceed further with the resolution process, kindly accept the conciliation notice.":"പരിഹാര നടപടികൾക്ക് മുന്നോട്ട് പോകാൻ, ദയവായി ഒത്തുതീർപ്പ് അറിയിപ്പ് അംഗീകരിക്കുക.",
      "Accept":"അംഗീകരിക്കുക",
      "Reject":"നിരസിക്കുക",
      "Redefining Dispute Resolution":"വിവാദ പരിഹാരത്തിന് പുനർവ്യാഖ്യാനം നൽകുന്നു",
      "With PrivateCourt, experience faster, more effective dispute resolution powered by technology and guided by industry experts.":"PrivateCourtയോടൊപ്പം, സാങ്കേതിക വിദ്യയാൽ ശക്തിപ്പെടുത്തപ്പെട്ടതും വ്യവസായ വിദഗ്ദ്ധരാൽ മാർഗനിർദ്ദേശം ലഭിച്ചതുമായ വേഗതയേറിയതും കൂടുതൽ ഫലപ്രദവുമായ വിവാദ പരിഹാരത്തെ അനുഭവപ്പെടുത്തുക.",
       "Visit Website": "വെബ്സൈറ്റ് സന്ദർശിക്കുക"
}

export default MalayalamTranslation;