/* eslint-disable react/prop-types */
import React from "react";
import Field from "./Field";

const FourthCard = ({ loan }) => {
  return (
    <div>
      {" "}
      {[
        loan?.disputeResolutionPaperID,
        loan?.associateName,
        loan?.caseID,
        loan?.refNo,
        loan?.pinCode,
        loan?.state,
        loan?.zone,
        loan?.dpd,
        loan?.meetingDate,
        loan?.noticeDate,
      ].some((field) => field && field !== "N/A") ? (
        <div className="card card-new">
          {/* Additional Detail Section */}
          <>
            <h2 className="mb-3 mt-3">Additional Details</h2>
            <div className="row">

                <Field key1="Dispute Resolution Paper ID:" value={loan?.disputeResolutionPaperID || ""}/>
                <Field key1="Associate Name:" value={loan?.associateName || ""}/>
                <Field key1="Case ID:" value= {loan?.caseID || ""}/>
                <Field key1="Reference Number:" value={loan?.refNo || ""}/>
                <Field key1="State:" value={loan?.state || ""}/>
                <Field key1="Zone:" value={loan?.zone || ""}/>
                <Field key1="DPD:" value={loan?.dpd || ""}/>
                <Field key1="Meeting Date:" value={loan?.meetingDate || ""}/>
                <Field key1="Notice Date" value={loan?.noticeDate || ""}/>
                <Field key1="Pin Code:" value={loan?.pinCode || ""}/>
            </div>
          </>
        </div>
      ) : null}
    </div>
  );
};

export default FourthCard;
