import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeTheme } from "../features/theme/themeSlice";
import { fontChange } from "../features/font/fontSlice";
import auth from "../services/authService";
// import bgLogo from "./../../../images/logo-bg.png";
// import bgLogoWhite from "./../../../images/logo-white-bg.png";


const LoginHeader = ({ children }) => {
  const theme = useSelector((state) => state.theme.theme);
  const fontSize =useSelector((state)=>state.font.fontSize);
  console.log(fontSize,"font");
  
  const dispatch = useDispatch();
  const handleThemeChange = () => {
    dispatch(changeTheme());
  };

  const handleDecrease=()=>{
    dispatch(fontChange(fontSize.curr-2));
  }
  const handleIncrease=()=>{
    dispatch(fontChange(fontSize.curr+2));
  }
  const handleReset=()=>{
    dispatch(fontChange(16));
  }

  const changeFontSizeAndLineHeight = (changeAmount) => {
    const elementsToChange = document.querySelectorAll('.main-wrapper .right-wrapper');
    // console.log(elementsToChange);
    elementsToChange.forEach((element) => {
      const currentSize = window.getComputedStyle(element).getPropertyValue('font-size');
      let changeAmount=0
      if(fontSize.curr>fontSize.prev){
        changeAmount=2;
      }
      else if(fontSize.curr<fontSize.prev){
        changeAmount=-2;
      }
      else changeAmount=0;
      // console.log(currentSize,'currSize')
      const currentLineHeight = window.getComputedStyle(element).getPropertyValue('line-height');
      const newSize = parseFloat(currentSize) + changeAmount;
      const newLineHeight = parseFloat(currentLineHeight) + changeAmount;
      if(changeAmount!=0){
        element.style.fontSize = `${newSize}px`;
        element.style.lineHeight = `${newLineHeight}px`;
      }
      else{
        var elementsToReset = document.querySelectorAll('.main-wrapper *');
        elementsToReset.forEach(function(element) {
            element.style.fontSize = ''; // Reset font size to default
            element.style.lineHeight = ''; // Reset line height to default
        });
      }
    });
  };

  const handleLogout=()=>{
    auth.logout();
    window.location.reload();
  }

  useEffect(()=>{
    changeFontSizeAndLineHeight(fontSize);
  },[fontSize])

  return (
    <div className="login-header">
      <div className="me-2">
        <div className="row">
          <div className="col">
            {children}
          </div>
          <div className="col">
            <ul className="list-inline fs-ld text-end mb-0">
              <li className="list-inline-item font-size-fun" id="increase" onClick={handleIncrease}>
                <span>A+</span>
              </li>
              <li className="list-inline-item font-size-fun" id="decrease" onClick={handleDecrease}>
                <span>A-</span>
              </li>
              <li className="list-inline-item font-size-fun" id="reset" onClick={handleReset}>
                <span>A</span>
              </li>
              <li
                className={`list-inline-item ${theme ? "d-none-dark" : "me-1 d-none-light"}`}
              >
                <span id={theme ? "style2Btn" : "style1Btn"} onClick={handleThemeChange} className={`color-switcher ${ theme ? "dark-style" : "light-style"}`}
                ></span>
              </li>
              {auth.getUser()?.UserLogin?.verificationStage?<li className="list-inline-item font-size-fun">
                <button
                  type="button"
                  className="btn btn-outline-primary"
                  style={{ padding: '4px 8px', borderRadius: '7.333px' }}
                  onClick={handleLogout}
                >
                  <i
                    className="bi bi-person"
                    style={{ fontSize: '15px', position: 'relative', top: '2px' }}
                  ></i>
                </button>
              </li>:""}
              
            </ul>
          </div>
        </div>
        {/* <!-- /.row --> */}
      </div>
      {/* <!-- /.container --> */}
    </div>
    //   <!-- /.login-header -->
  );
};

export default LoginHeader;
