import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    fontSize: {
        curr: 16,
        prev: 16
    },
}

export const fontSlice = createSlice({
    name: "fontSize",
    initialState,
    reducers: {
        fontChange: (state, action) => {
            state.fontSize.prev = state.fontSize.curr;
            state.fontSize.curr = +action.payload
        }
    }
});

export const { fontChange } = fontSlice.actions;

export default fontSlice.reducer;