/* eslint-disable no-unused-vars */

import React, { useEffect, useState } from "react";
import { Suspense, lazy } from "react";
import { useSelector } from "react-redux";
import { Routes, Route } from "react-router-dom";
import Login from "./pages/loginPage/login";
import auth from "./services/authService";
import loadingIcon from "./asset/loading3.gif";
import AuthRoute from "./components/authRoute";
import AdBlockerWarning from "./components/adBlockerWarning";
import FooterComponent from "../src/pages/footerPage/footerComponent";
import Logout from "./components/logout";
import { useLocation, useNavigate } from "react-router-dom";
import UserProfile from "./pages/userProfile/UserProfile";
import UploadAndTableIntegartionPage from "./pages/bulkDisputeUploadPage/UploadAndTableIntegrationPage";
import DisputeUploadReportPage from "./pages/bulkDisputeUploadPage/disputeUploadReportPage/DisputeUploadReportPage";
import DisputeDataProfilePage from "./pages/bulkDisputeUploadPage/disputeDataProfilePage/DisputeDataProfilePage";
import { Esign } from "./pages/EsignCode/Esign";
import CompleteTranslation from "./pages/multilingual/completeNotice";
const NewUserDashboard = lazy(() => import('./pages/dashboardPage/dashboard'));
const Dispute = lazy(() => import('./pages/disputePage/Dispute'));
const NBFCVerify = lazy(() => import('./pages/NbfcVerifyPage/nbfcVerify'));
const NBFCCompanyClientVerify = lazy(() => import("./pages/NbfcCompanyVerifyPage/nbfcCompanyVerify"));
function Loading() {
  return (
    <div className="main-wrapper d-flex justify-content-center align-items-center">
      <div className="container-fluid">
        <div className="row">
          <div
            className="col-lg d-flex justify-content-center align-items-center"
            style={{ backgroundColor: "rgba(255, 255, 255, 0.2)" }}
          >
            <img
              src={loadingIcon}
              alt="loading image"
              style={{
                marginTop: "40vh",
                backgroundColor: "rgba(255, 255, 255, 0.2)",
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
function App() {
  const theme = useSelector((state) => state.theme.theme);
  const [user, setUser] = useState(auth.getUser().UserLogin);
  const [did, setDid] = useState("")
  const location = useLocation();
  const navigate = useNavigate();

  function getQueryParameter(parameterName) {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(parameterName);
  }

  useEffect(async () => {
    const queryParams = new URLSearchParams(location.search);
    const tokenValue = queryParams.get("find");
    const email = queryParams.get("email");
    const did = queryParams.get("did");
    setDid(did);
    if (tokenValue) {
      auth.login(tokenValue);
      // const timer = setTimeout(() => {
      // }, 2000);
      // return () => clearTimeout(timer);
      // await delay(8000);
      // navigate("/dashboard");
      window.location.href = "https://dashboard.pvtcourt.com/dashboard";
    }
    else if (email && did) {
      console.log(email);
    }
  }, []);

  useEffect(() => {
    const importThemeStyle = async () => {
      const styleSheetId = "themeStyleSheet";
      const existingStyleSheet = document.getElementById(styleSheetId);
      if (existingStyleSheet) {
        existingStyleSheet.remove();
      }
      const link = document.createElement("link");
      link.id = styleSheetId;
      link.rel = "stylesheet";
      link.href = theme ? "./style.css" : "./style2.css";
      document.head.appendChild(link);
    };

    importThemeStyle();
  }, [theme]);

  return (
    <>
    <AdBlockerWarning />
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/esign" element={<Esign />} />
        <Route path="/logout" element={<Logout />} />
        <Route path='/multiLang'element={<CompleteTranslation />}/>
        <Route
          path="/"
          element={
            <Suspense fallback={Loading}><AuthRoute>
              <Suspense fallback={Loading}><NewUserDashboard /></Suspense>
            </AuthRoute></Suspense>
          }
        />
        {/* Protect the following routes */}
        <Route
          path="/dashboard"
          element={
            <Suspense fallback={Loading}><AuthRoute>
            <Suspense fallback={Loading}><NewUserDashboard /></Suspense>
          </AuthRoute></Suspense>
          }
        />
        <Route
          path="/verifyClient"
          element={
            <Suspense fallback={Loading}><AuthRoute>
              <Suspense fallback={Loading}><NBFCVerify /></Suspense>
            </AuthRoute></Suspense>
          }
        />
        <Route
          path="/DisputeReporting"
          element={
            <Suspense fallback={Loading}><AuthRoute>
              <Suspense fallback={Loading}><DisputeUploadReportPage /></Suspense>
            </AuthRoute></Suspense>
          }
        />
                <Route
          path="/DisputeUserProfile"
          element={
            <Suspense fallback={Loading}><AuthRoute>
              <Suspense fallback={Loading}><DisputeDataProfilePage /></Suspense>
            </AuthRoute></Suspense>
          }
        />
        <Route
          path="/verifyCompanyClient"
          element={
            <AuthRoute>
              <NBFCCompanyClientVerify />
            </AuthRoute>
          }
        />
        <Route
          path="/dispute"
          element={
            <Suspense fallback={Loading}><AuthRoute>
              <Suspense fallback={Loading}><Dispute /></Suspense>
            </AuthRoute></Suspense>
          }
        />
        <Route
          path="/userProfile"
          element={
            <Suspense fallback={Loading}><AuthRoute>
              <Suspense fallback={Loading}><UserProfile /></Suspense>
            </AuthRoute></Suspense>
          }
        />
          <Route
          path="/bulkUpload"
          element={
            <Suspense fallback={Loading}><AuthRoute>
              <Suspense fallback={Loading}><UploadAndTableIntegartionPage /></Suspense>
            </AuthRoute>
            </Suspense>
          }
        />
        </Routes>
        {
          <FooterComponent />
        }
    </>
  );
}

export default App;