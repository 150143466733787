// /* eslint-disable react/prop-types */
// /* eslint-disable no-unused-vars */
// import React, { useState, useEffect, useRef } from "react";
// import { Tooltip } from "bootstrap";
// import UseHandleModal from "../../Modal/UseHandleModal";

// const HeaderMapping = ({
//   show,
//   onHide,
//   csvHeaders,
//   requiredHeaders,
//   onMappingComplete,
//   tooltipTexts,
//   initialMapping,
// }) => {
//   const [mapping, setMapping] = useState({});
//   const [selectedHeaders, setSelectedHeaders] = useState(
//     Object.values(initialMapping || {})
//   );
//   const [customDropdowns, setCustomDropdowns] = useState([]);
//   const [showCustomHeaderModal, setShowCustomHeaderModal] = useState(false);
//   const completeButtonRef = useRef(null);
//   const { handleModal2 } = UseHandleModal();

//   const [availableHeaders, setAvailableHeaders] = useState([]);

//   useEffect(() => {
//     setAvailableHeaders(
//       [
//         "Last EMI Paid Date",
//         "Borrower Email",
//         "CoBorrower Email",
//         "Borrower PAN Number",
//         "Name of CoBorrower",
//         "CoBorrower Address",
//         "CoBorrower Phone",
//         "CoBorrower PAN Number",
//         "EMI Start Date",
//         "EMI End Date",
//         "Interest",
//         "RC Number",
//         "CIN Number",
//         "GST Number",
//         "Vehicle Number",
//         "Driving Licence Number",
//         "Loan Type",
//         "EMI",
//         "Total Loan",
//         "EMI Start",
//         "EMI End",
//         "Seat",
//         "Bank Account Number",
//         "IFSC Code",
//         "Dispute Resolution Paper ID",
//         "Associate Name",
//         "Ref Number",
//         "Case ID",
//         "Principle Outstanding",
//         "Maximum Settlement Amount",
//         "Minimum Settlement Amount",
//         "ClaimantSide For Coordination",
//         "ClaimantSide For Coordination Contact",
//         "Zone",
//         "DPD",
//         "Notice Stage",
//         "City",
//         "State",
//       ].sort()
//     );
//   }, []);

//   useEffect(() => {
//     if (show) {
//       const tooltipTriggerList = document.querySelectorAll(
//         '[data-bs-toggle="tooltip"]'
//       );
//       tooltipTriggerList.forEach((tooltipTriggerEl) => {
//         new Tooltip(tooltipTriggerEl);
//       });

//       if (completeButtonRef.current) {
//         new Tooltip(completeButtonRef.current);
//       }
//     }
//   }, [show]);

//   useEffect(() => {
//     setMapping(initialMapping);
//   }, [initialMapping]);

//   const getAllSelectedHeaders = () => {
//     const requiredMappedHeaders = requiredHeaders
//       .map(header => mapping[header])
//       .filter(Boolean);

//     const customMappedHeaders = customDropdowns
//       .map(dropdown => mapping[dropdown.name])
//       .filter(Boolean);

//     return [...requiredMappedHeaders, ...customMappedHeaders];
//   };

//   const handleSelectChange = (e, requiredHeader) => {
//     const selectedValue = e.target.value;
//     const currentSelectedHeaders = getAllSelectedHeaders();

//     if (currentSelectedHeaders.includes(selectedValue) && selectedValue !== '') {
//       handleModal2(
//         `The header "${selectedValue}" is already mapped. Please choose a different header.`
//       );
//       return;
//     }

//     setMapping((prevMapping) => ({
//       ...prevMapping,
//       [requiredHeader]: e.target.value,
//     }));
//   };

//   const handleAddCustomDropdown = (headerName) => {
//     const isHeaderAlreadySelected =
//       requiredHeaders.includes(headerName) ||
//       customDropdowns.some((d) => d.name === headerName);

//     if (isHeaderAlreadySelected) {
//       handleModal2(
//         `The header "${headerName}" is already selected. Please choose a different header.`
//       );
//       return;
//     }

//     setCustomDropdowns((prevCustomDropdowns) => [
//       ...prevCustomDropdowns,
//       { id: prevCustomDropdowns.length + 1, name: headerName },
//     ]);

//     setAvailableHeaders((prevHeaders) =>
//       prevHeaders.filter((header) => header !== headerName)
//     );
//   };

//   const handleRemoveCustomDropdown = (dropdownId) => {
//     const dropdownToRemove = customDropdowns.find((d) => d.id === dropdownId);

//     if (dropdownToRemove) {
//       setAvailableHeaders((prevHeaders) =>
//         [...prevHeaders, dropdownToRemove.name].sort()
//       );

//       setCustomDropdowns((prevCustomDropdowns) =>
//         prevCustomDropdowns.filter((d) => d.id !== dropdownId)
//       );

//       setMapping((prevMapping) => {
//         const updatedMapping = { ...prevMapping };
//         delete updatedMapping[dropdownToRemove.name];
//         return updatedMapping;
//       });
//     }
//   };

//   const handleCompleteMapping = () => {
//     onMappingComplete(mapping, customDropdowns);
//     onHide();
//   };

//   const isMappingComplete =
//     requiredHeaders.every(
//       (header) => mapping[header] && mapping[header] !== ""
//     ) &&
//     customDropdowns.every(
//       (dropdown) => mapping[dropdown.name] && mapping[dropdown.name] !== ""
//     );

//   return (
//     <>
//       {/* Main Modal */}
//       <div
//         className={`modal fade ${show ? "show d-block" : ""}`}
//         tabIndex="-1"
//         role="dialog"
//         style={{ display: show ? "block" : "none" }}
//       >
//         <div
//           className="modal-dialog modal-lg modal-dialog-centered"
//           role="document"
//         >
//           <div className="modal-content fixed-modal">
//             <div className="modal-header">
//               <h5 className="modal-title">Match Headers </h5>
//               <button
//                 type="button"
//                 className="btn-close"
//                 onClick={onHide}
//               ></button>
//             </div>
//             <div className="modal-body">
//               <form>
//                 <div className="row">
//                   {requiredHeaders.map((requiredHeader) => (
//                     <div className="col-md-6 mb-3" key={requiredHeader}>
//                       <label
//                         className="form-label"
//                         data-bs-toggle="tooltip"
//                         title={
//                           tooltipTexts[requiredHeader] ||
//                           `Tooltip for ${requiredHeader}`
//                         }
//                       >
//                         {requiredHeader}
//                       </label>
//                       <select
//                         className="form-select"
//                         value={mapping[requiredHeader] || ""}
//                         onChange={(e) => handleSelectChange(e, requiredHeader)}
//                       >
//                         <option value="">Select a header</option>
//                         {csvHeaders.map((csvHeader) => (
//                           <option key={csvHeader} value={csvHeader}>
//                             {csvHeader}
//                           </option>
//                         ))}
//                       </select>
//                       {!mapping[requiredHeader] && (
//                         <div className="text-danger mt-1">
//                           This field is required.
//                         </div>
//                       )}
//                     </div>
//                   ))}
//                   {customDropdowns.map((dropdown) => (
//                     <div className="col-md-6 mb-3" key={dropdown.id}>
//                       <label className="form-label">
//                         {dropdown.name}{" "}
//                         <i
//                           className="bi bi-dash-circle ms-2"
//                           onClick={() =>
//                             handleRemoveCustomDropdown(dropdown.id)
//                           }
//                         ></i>
//                       </label>
//                       <select
//                         className="form-select"
//                         value={mapping[dropdown.name] || ""}
//                         onChange={(e) => handleSelectChange(e, dropdown.name)}
//                       >
//                         <option value="">Select a header</option>
//                         {csvHeaders.map((csvHeader) => (
//                           <option key={csvHeader} value={csvHeader}>
//                             {csvHeader}
//                           </option>
//                         ))}
//                       </select>
//                       {!mapping[dropdown.name] && (
//                         <div className="text-danger mt-1">
//                           This field is required.
//                         </div>
//                       )}
//                     </div>
//                   ))}
//                 </div>
//               </form>
//             </div>
//             <div className="modal-footer">
//               <button
//                 type="button"
//                 className="btn btn-secondary"
//                 onClick={onHide}
//               >
//                 Close
//               </button>
//               <button
//                 type="button"
//                 className="btn btn-secondary"
//                 onClick={() => setShowCustomHeaderModal(true)}
//               >
//                 Add
//               </button>
//               <button
//                 type="button"
//                 className="btn btn-primary"
//                 onClick={handleCompleteMapping}
//                 disabled={!isMappingComplete}
//                 ref={completeButtonRef}
//                 data-bs-toggle="tooltip"
//                 data-bs-placement="top"
//                 title={
//                   !isMappingComplete
//                     ? "Please select a value for all required headers."
//                     : ""
//                 }
//               >
//                 Done
//               </button>
//             </div>
//           </div>
//         </div>
//       </div>

//       {/* Custom Header Modal */}
//       <div
//         className={`modal fade ${showCustomHeaderModal ? "show d-block" : ""}`}
//         tabIndex="-1"
//         role="dialog"
//         style={{ display: showCustomHeaderModal ? "block" : "none" }}
//       >
//         <div
//           className="modal-dialog modal-sm modal-dialog-centered"
//           role="document"
//         >
//           <div className="modal-content">
//             <div className="modal-header">
//               <h5 className="modal-title">Select Header</h5>
//               <button
//                 type="button"
//                 className="btn-close"
//                 onClick={() => setShowCustomHeaderModal(false)}
//               ></button>
//             </div>
//             <div
//               className="modal-body"
//               style={{ maxHeight: "400px", overflowY: "auto" }}
//             >
//               {availableHeaders.length > 0 ? (
//                 <ul className="list-group">
//                   {availableHeaders.map((header) => (
//                     <li
//                       key={header}
//                       className="d-flex justify-content-between align-items-center border-bottom mb-3 pb-2"
//                     >
//                       {header}
//                       <button
//                         className="btn btn-primary btn-sm custom-btn-click-effect"
//                         onClick={() => handleAddCustomDropdown(header)}
//                       >
//                         +
//                       </button>
//                     </li>
//                   ))}
//                 </ul>
//               ) : (
//                 <p className="text-center text-muted">
//                   No options left to add.
//                 </p>
//               )}
//             </div>
//             <div className="modal-footer">
//               <button
//                 type="button"
//                 className="btn btn-secondary"
//                 onClick={() => setShowCustomHeaderModal(false)}
//               >
//                 Close
//               </button>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default HeaderMapping;

/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from "react";
import { Tooltip } from "bootstrap";
import UseHandleModal from "../../Modal/UseHandleModal";

const HeaderMapping = ({
  show,
  onHide,
  csvHeaders,
  requiredHeaders,
  onMappingComplete,
  tooltipTexts,
  initialMapping,
}) => {
  const [mapping, setMapping] = useState({});
  const [selectedHeaders, setSelectedHeaders] = useState(
    Object.values(initialMapping || {})
  );
  const [customDropdowns, setCustomDropdowns] = useState([]);
  const [showCustomHeaderModal, setShowCustomHeaderModal] = useState(false);
  const completeButtonRef = useRef(null);
  const { handleModal2 } = UseHandleModal();

  const [availableHeaders, setAvailableHeaders] = useState([]);

  useEffect(() => {
    setAvailableHeaders(
      [
        "Last EMI Paid Date",
        "Stage",
        "Borrower Email",
        "CoBorrower Email",
        "Borrower PAN Number",
        "Name of CoBorrower",
        "CoBorrower Address",
        "CoBorrower Phone",
        "CoBorrower PAN Number",
        "EMI Start Date",
        "EMI End Date",
        "Interest",
        "RC Number",
        "CIN Number",
        "GST Number",
        "Vehicle Number",
        "Driving Licence Number",
        "Loan Type",
        "EMI",
        "Total Loan",
        "EMI Start",
        "EMI End",
        "Seat",
        "Bank Account Number",
        "IFSC Code",
        "Dispute Resolution Paper ID",
        "Associate Name",
        "Ref Number",
        "Case ID",
        "Principle Outstanding",
        "Maximum Settlement Amount",
        "Minimum Settlement Amount",
        "ClaimantSide For Coordination",
        "ClaimantSide For Coordination Contact",
        "Zone",
        "DPD",
        "Notice Stage",
        "City",
        "State",
        "TrackingID",
        "Whatsapp Status",
        "Call Status",
        "Postal Status",
        "Sms Status",
        "Email Status",
        "Level"
      ].sort()
    );
  }, []);

  useEffect(() => {
    if (show) {
      const tooltipTriggerList = document.querySelectorAll(
        '[data-bs-toggle="tooltip"]'
      );
      tooltipTriggerList.forEach((tooltipTriggerEl) => {
        new Tooltip(tooltipTriggerEl);
      });

      if (completeButtonRef.current) {
        new Tooltip(completeButtonRef.current);
      }
    }
  }, [show]);

  useEffect(() => {
    setMapping(initialMapping);
  }, [initialMapping]);

  const getAllSelectedHeaders = () => {
    const requiredMappedHeaders = requiredHeaders
      .map((header) => mapping[header])
      .filter(Boolean);

    const customMappedHeaders = customDropdowns
      .map((dropdown) => mapping[dropdown.name])
      .filter(Boolean);

    return [...requiredMappedHeaders, ...customMappedHeaders];
  };

  const handleSelectChange = (e, requiredHeader) => {
    const selectedValue = e.target.value;
    const currentSelectedHeaders = getAllSelectedHeaders();

    // Check if the selected header is already used
    if (
      currentSelectedHeaders.includes(selectedValue) &&
      selectedValue !== ""
    ) {
      handleModal2(
        `The header "${selectedValue}" is already mapped. Please choose a different header.`
      );
      return;
    }

    setMapping((prevMapping) => ({
      ...prevMapping,
      [requiredHeader]: selectedValue,
    }));
  };

  const handleAddCustomDropdown = (headerName) => {
    const currentSelectedHeaders = getAllSelectedHeaders();

    // Check if header is already selected
    if (currentSelectedHeaders.includes(headerName)) {
      handleModal2(
        `The header "${headerName}" is already selected. Please choose a different header.`
      );
      return;
    }

    setCustomDropdowns((prevCustomDropdowns) => [
      ...prevCustomDropdowns,
      { id: prevCustomDropdowns.length + 1, name: headerName },
    ]);

    setAvailableHeaders((prevHeaders) =>
      prevHeaders.filter((header) => header !== headerName)
    );
  };

  const handleRemoveCustomDropdown = (dropdownId) => {
    const dropdownToRemove = customDropdowns.find((d) => d.id === dropdownId);

    if (dropdownToRemove) {
      setAvailableHeaders((prevHeaders) =>
        [...prevHeaders, dropdownToRemove.name].sort()
      );

      setCustomDropdowns((prevCustomDropdowns) =>
        prevCustomDropdowns.filter((d) => d.id !== dropdownId)
      );

      setMapping((prevMapping) => {
        const updatedMapping = { ...prevMapping };
        delete updatedMapping[dropdownToRemove.name];
        return updatedMapping;
      });
    }
  };

  const handleCompleteMapping = () => {
    onMappingComplete(mapping, customDropdowns);
    onHide();
  };

  const isMappingComplete =
    requiredHeaders.every(
      (header) => mapping[header] && mapping[header] !== ""
    ) &&
    customDropdowns.every(
      (dropdown) => mapping[dropdown.name] && mapping[dropdown.name] !== ""
    );

  return (
    <>
      {/* Main Modal */}
      <div
        className={`modal fade ${show ? "show d-block" : ""}`}
        tabIndex="-1"
        role="dialog"
        style={{ display: show ? "block" : "none" }}
      >
        <div
          className="modal-dialog modal-lg modal-dialog-centered"
          role="document"
        >
          <div className="modal-content fixed-modal">
            <div className="modal-header">
              <h5 className="modal-title">Match Headers </h5>
              <button
                type="button"
                className="btn-close"
                onClick={onHide}
              ></button>
            </div>
            <div className="modal-body">
              <form>
                <div className="row">
                  {requiredHeaders.map((requiredHeader) => {
                    const currentSelectedHeaders = getAllSelectedHeaders();
                    return (
                      <div className="col-md-6 mb-3" key={requiredHeader}>
                        <label
                          className="form-label"
                          data-bs-toggle="tooltip"
                          title={
                            tooltipTexts[requiredHeader] ||
                            `Tooltip for ${requiredHeader}`
                          }
                        >
                          {requiredHeader}
                        </label>
                        <select
                          className="form-select"
                          value={mapping[requiredHeader] || ""}
                          onChange={(e) =>
                            handleSelectChange(e, requiredHeader)
                          }
                        >
                          <option value="">Select a header</option>
                          {csvHeaders.map((csvHeader) => (
                            <option
                              key={csvHeader}
                              value={csvHeader}
                              disabled={
                                currentSelectedHeaders.includes(csvHeader) &&
                                mapping[requiredHeader] !== csvHeader
                              }
                            >
                              {csvHeader}
                            </option>
                          ))}
                        </select>
                        {!mapping[requiredHeader] && (
                          <div className="text-danger mt-1">
                            This field is required.
                          </div>
                        )}
                      </div>
                    );
                  })}
                  {customDropdowns.map((dropdown) => {
                    const currentSelectedHeaders = getAllSelectedHeaders();
                    return (
                      <div className="col-md-6 mb-3" key={dropdown.id}>
                        <label className="form-label">
                          {dropdown.name}{" "}
                          <i
                            className="bi bi-dash-circle ms-2"
                            onClick={() =>
                              handleRemoveCustomDropdown(dropdown.id)
                            }
                          ></i>
                        </label>
                        <select
                          className="form-select"
                          value={mapping[dropdown.name] || ""}
                          onChange={(e) => handleSelectChange(e, dropdown.name)}
                        >
                          <option value="">Select a header</option>
                          {csvHeaders.map((csvHeader) => (
                            <option
                              key={csvHeader}
                              value={csvHeader}
                              disabled={
                                currentSelectedHeaders.includes(csvHeader) &&
                                mapping[dropdown.name] !== csvHeader
                              }
                            >
                              {csvHeader}
                            </option>
                          ))}
                        </select>
                        {!mapping[dropdown.name] && (
                          <div className="text-danger mt-1">
                            This field is required.
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={onHide}
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => setShowCustomHeaderModal(true)}
              >
                Add
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleCompleteMapping}
                disabled={!isMappingComplete}
                ref={completeButtonRef}
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title={
                  !isMappingComplete
                    ? "Please select a value for all required headers."
                    : ""
                }
              >
                Done
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Custom Header Modal */}
      <div
        className={`modal fade ${showCustomHeaderModal ? "show d-block" : ""}`}
        tabIndex="-1"
        role="dialog"
        style={{ display: showCustomHeaderModal ? "block" : "none" }}
      >
        <div
          className="modal-dialog modal-sm modal-dialog-centered"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Select Header</h5>
              <button
                type="button"
                className="btn-close"
                onClick={() => setShowCustomHeaderModal(false)}
              ></button>
            </div>
            <div
              className="modal-body"
              style={{ maxHeight: "400px", overflowY: "auto" }}
            >
              {availableHeaders.length > 0 ? (
                <ul className="list-group">
                  {availableHeaders.map((header) => {
                    const currentSelectedHeaders = getAllSelectedHeaders();
                    return (
                      <li
                        key={header}
                        className="d-flex justify-content-between align-items-center border-bottom mb-3 pb-2"
                      >
                        {header}
                        <button
                          className="btn btn-primary btn-sm custom-btn-click-effect"
                          onClick={() => handleAddCustomDropdown(header)}
                          disabled={currentSelectedHeaders.includes(header)}
                        >
                          +
                        </button>
                      </li>
                    );
                  })}
                </ul>
              ) : (
                <p className="text-center text-muted">
                  No options left to add.
                </p>
              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => setShowCustomHeaderModal(false)}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeaderMapping;
