import { createSlice } from "@reduxjs/toolkit";
import getLanguage from "../../local/importTranslate";
import saveLang from "../../local/saveLanguage";

const initialState = {
    lang: "English(UK)",
}

export const langSlice = createSlice({
    name: "lang",
    initialState,
    reducers: {
        changeLang: (state, action) => {
            state.lang = action.payload;
            let obj = getLanguage(action.payload);
            saveLang.saveLang(obj);
        }
    }
});

export const { changeLang } = langSlice.actions;
export default langSlice.reducer;