/* eslint-disable react/prop-types */
import React from "react";

const CustomField = ({ label, value }) => {
  return (
    <div className="seat-of-arbitration text-center p-2">
      <h2 style={{ textAlign: "start" }}>
        {label}: {value ? value : " "}
      </h2>
    </div>
  );
};

export default CustomField;
