/* eslint-disable react/jsx-key */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/no-unknown-property */
import Profileblue from "../../images/icons/profileblue.svg";
import Shareblue from "../../images/icons/shareblue.svg";
import Profilewhite from "../../images/icons/profileWhite.svg";
import Sharewhite from "../../images/icons/shareWhite.svg";
import ProfileUser from "./components/ProfileUser";
import MainGraph from "./components/MainGraph";
import { useEffect, useMemo, useState } from "react";
import PaymentModal from "./components/PaymentModal";
import AccountModal from "./components/AccountsModal";
import AgeModal from "./components/AgeModal";
import ProfileModal from "./components/ProfileModal";
import GSTUser from "./components/GSTUser";
import GSTPaymentModal from "./components/GSTPaymentModal";
import BarChart from "./components/CompanyGraph";
import CompanyProfileModal from "./components/CompanyProfile";
import CompanyAgeModal from "./components/CompanyAgeModal";
import CompanyAccounts from "./components/CompanyAccounts";
import { useDispatch, useSelector } from "react-redux";
import { setData } from "../../features/dashboardSlice/dashboardSlice";
import ShareModal from "./components/shareModal";
import http from "../../services/httpService";
import { formatDate } from "../../utils/formatDate";
import LoginHeader from "../../components/loginHeader";
import Navbar from "../../components/navbar";
import BackButton from "../../components/backButton";
// import { useParams } from 'react-router-dom';/
import { useLocation } from "react-router-dom";
import auth from "../../services/authService";

const UserProfile = () => {
  const theme = useSelector((state) => state.theme.theme);
  const location = useLocation();
  const [id, setId] = useState("");
  const [active, setActive] = useState("");
  const [filingData, setFilingData] = useState([]);
  const [date, setDate] = useState("");
  const [companyData, setCompanyData] = useState([]);
  const [shareClick, setShareClick] = useState(false);
  const dispatch = useDispatch();
  const data = useSelector((state) => state.dashboardSlice.data);

  const calculateDateDifference = (dateString) => {
    console.log(dateString);
    const regex = /(\d{2}-\d{2}-\d{4})/;
    const match = dateString.match(regex);

    if (match) {
      const dateParts = match[0].split("-");
      const date = new Date(`${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`);

      const now = new Date();
      let years = now.getFullYear() - date.getFullYear();
      let months = now.getMonth() - date.getMonth();
      let days = now.getDate() - date.getDate();

      if (days < 0) {
        months -= 1;
        days += new Date(now.getFullYear(), now.getMonth(), 0).getDate();
      }

      if (months < 0) {
        years -= 1;
        months += 12;
      }

      return { years, months, days };
    }

    return { years: 0, months: 0, days: 0 };
  };
  const callData = async () => {
    try {
      const searchParams = new URLSearchParams(location.search);
      const id = searchParams.get("id");
      setId(id);
      const response = await http.post2("/readJson", {
        UID: id || auth.getUser().UserLogin._id,
      });
      dispatch(setData(response.data)); // Update state with fetched data
      const filterAccounts =
        response.data?.CIBILDetails.credit_report.CCRResponse.CIRReportDataLst[0].CIRReportData.RetailAccountDetails.map(
          (item) => ({
            history: item.History48Months,
          })
        );
      setFilingData(filterAccounts);
      const filterAccounts1 =
        response.data?.CIBILDetails.credit_report.CCRResponse
          .CIRReportDataLst[0].CIRReportData.RetailAccountsSummary
          .OldestAccount;
      setActive(
        response.data?.CIBILDetails.credit_report.CCRResponse
          .CIRReportDataLst[0].CIRReportData.RetailAccountsSummary
          .NoOfActiveAccounts
      );
      setDate(filterAccounts1);
      const data = response.data?.companies_list.filter(
        (item) => item.companyStatus == "Active"
      );
      setCompanyData(data);
    } catch (error) {
      alert("Something went wrong"); // Handle error
    }
  };
  // useEffect to call callData() when component mounts
  const BasicDetailsVerified = () => {
    const { aadhaar, address, bank, phone, pan_number } = data;
    console.log(aadhaar, address, bank, phone, pan_number);
    const missingFields = [];
    if (!aadhaar) missingFields.push("aadhaar");
    if (!address) missingFields.push("address");
    if (!bank) missingFields.push("bank");
    if (!phone) missingFields.push("phone");
    if (!pan_number) missingFields.push("pan_number");

    if (missingFields.length > 0) {
      return {
        status: `The following fields are missing: ${missingFields.join(", ")}`,
        allFieldsPresent: false,
        missingFields: missingFields,
      };
    } else {
      return {
        status: "All fields are present.",
        allFieldsPresent: true,
        missingFields: [],
      };
    }
  };
  const VerifiedStatus = BasicDetailsVerified();

  // use useMemo for not recalculating it
  const lastUpdateDate = useMemo(() => {
    const formDates = formatDate(data?.ProfileCreationDate);
    return formDates;
  }, []);

  useEffect(() => {
    callData();
  }, []);
  const combinedFilingData = filingData?.flatMap((item) =>
    Object.values(item).flat()
  );
  console.log(combinedFilingData);
  const calculatePayment = () => {
    let totalcount = 0;
    let paidcount = 0;
    combinedFilingData.map((item) => {
      if (
        item.PaymentStatus == "STD" ||
        item.PaymentStatus == "000" ||
        item.PaymentStatus == "CLSD" ||
        item.PaymentStatus == "NEW" ||
        item.PaymentStatus == "*"
      ) {
        paidcount++;
      }
      totalcount++;
    });
    const percent = (paidcount / totalcount) * 100;
    return { percent, paidcount, totalcount };
  };
  const dateDifference = calculateDateDifference(date);
  const handleShare = (event) => {
    event.preventDefault();

    setShareClick(!shareClick);
  };
  return (
    <div class="main-wrapper">
      <div class="container-fluid">
        <div class="row">
          <div class="col-xl-3">
            <div class="left-wrapper">
              <Navbar></Navbar>
              <BackButton
                link={id ? "/verifyClient" : "/dashboard"}
              ></BackButton>
              {/* <nav class="navbar top-menu p-0">
                <div class="container-fluid">
                  <h1 class="logo">
                    PRIVATE<span>COURT</span>
                  </h1>
                </div>
              </nav> */}
              {/* <h2 class="admin-name">uxnishu@gmail.com</h2> */}
              {/* <button type="button" class="btn btn-light btn-sm back-btn mb-3">
                <i class="bi bi-arrow-left"></i> Back
              </button> */}
              {/* <button
                class="btn btn-primary d-block w-100 btn-lg mt-3 d-lg-none mb-3"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseExample"
                aria-expanded="false"
                aria-controls="collapseExample"
              >
                Menu
              </button> */}
              <div class="collapse d-lg-block" id="collapseExample">
                <div class="dot-bg">
                  <div class="row row-cols-2">
                    <div class="col">
                      <a
                        href="#"
                        class="white-circle"
                        style={{ background: theme ? "#fff" : "#212529" }}
                      >
                        <img src={theme ? Profileblue : Profilewhite} alt="" />
                        <h2>Update Your Profile</h2>
                      </a>
                    </div>
                    {!id ? (
                      <>
                        <div class="col">
                          <button
                            class="white-circle"
                            style={{ background: theme ? "#fff" : "#212529" }}
                            onClick={handleShare}
                          >
                            <img src={theme ? Shareblue : Sharewhite} alt="" />
                            <h2>Share Your Profile</h2>
                          </button>
                        </div>
                      </>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-9">
            <LoginHeader></LoginHeader>
            <div class="right-wrapper">
              <div class="profile-business-info profile-business-info-2">
                <div class="row">
                  <div class="col-lg-12">
                    <p class="last-updated-sec text-end">
                      <i class="fas fa-wrench"></i> Last Update on
                      <strong>{lastUpdateDate}</strong>
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-12">
                    <ProfileUser user={data} />
                  </div>
                  <div class="col-lg-12">
                    <div class="accordion" id="accordionExample">
                      <div class="accordion-item">
                        <h2 class="accordion-header details-analysis-header">
                          <button
                            class={`accordion-button ${
                              theme ? "text-blue bg-blue" : "text-white"
                            }`}
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseOne"
                            aria-expanded="false"
                            aria-controls="collapseOne"
                          >
                            Details Analysis
                          </button>
                        </h2>
                        <div
                          id="collapseOne"
                          class="accordion-collapse collapse show"
                          data-bs-parent="#accordionExample"
                        >
                          <div class="accordion-body">
                            <div class="row">
                              <div class="col-lg-6">
                                <ul class="list-unstyled">
                                  <li class="list-inline-item">
                                    {VerifiedStatus.allFieldsPresent ? (
                                      <i class="bi bi-patch-check-fill"></i>
                                    ) : (
                                      <i class="bi bi-patch-check text-muted"></i>
                                    )}
                                    &nbsp; Basic Details Verified
                                  </li>
                                  <li class="list-inline-item">
                                    <i
                                      class={`bi ${
                                        VerifiedStatus.allFieldsPresent
                                          ? "bi-patch-check-fill"
                                          : "bi-patch-check text-muted"
                                      }`}
                                    ></i>
                                    &nbsp;{VerifiedStatus.status}
                                  </li>
                                </ul>
                                <MainGraph
                                  cibil={data?.CIBILDetails?.credit_score}
                                />
                              </div>
                              <div class="col-lg-6">
                                <div class="pt-5">
                                  <div class="row">
                                    <div class="col-md-6">
                                      <div
                                        class={`card border-0 shadow details-analysis-card ${
                                          !data?.CIBILDetails?.credit_report ||
                                          Object.values(
                                            data?.CIBILDetails?.credit_report
                                          ).length === 0
                                            ? "disabled-card"
                                            : ""
                                        }`}
                                        style={{
                                          pointerEvents:
                                            !data?.CIBILDetails
                                              ?.credit_report ||
                                            Object.values(
                                              data?.CIBILDetails?.credit_report
                                            ).length === 0
                                              ? "none"
                                              : "auto",
                                        }}
                                      >
                                        <div class="card-body">
                                          <h5 class="card-title mb-0">
                                            Payment
                                          </h5>
                                          <p class="card-text">Highly Impact</p>
                                          <a
                                            href="javascript: void(0)"
                                            data-bs-toggle="modal"
                                            data-bs-target="#paymentmodal"
                                            class="stretched-link card-payment"
                                          ></a>
                                        </div>
                                        <div class="card-footer">
                                          <p className="text-white">
                                            Timely payments
                                          </p>
                                          <h3 className="text-white">
                                            {calculatePayment().percent.toFixed(
                                              2
                                            )}
                                            %
                                          </h3>
                                          <i class="bi bi-chevron-right"></i>
                                          <a
                                            href="javascript: void(0)"
                                            data-bs-toggle="modal"
                                            data-bs-target="#paymentmodal"
                                            class="stretched-link card-payment"
                                          ></a>
                                          {data ? <PaymentModal /> : ""}
                                        </div>
                                      </div>
                                    </div>
                                    <div class="col-md-6">
                                      <div
                                        class={`card border-0 shadow details-analysis-card ${
                                          !data?.CIBILDetails?.credit_report ||
                                          Object.values(
                                            data?.CIBILDetails?.credit_report
                                          ).length === 0
                                            ? "disabled-card"
                                            : ""
                                        }`}
                                        style={{
                                          pointerEvents:
                                            !data?.CIBILDetails
                                              ?.credit_report ||
                                            Object.values(
                                              data?.CIBILDetails?.credit_report
                                            ).length === 0
                                              ? "none"
                                              : "auto",
                                        }}
                                      >
                                        <div class="card-body">
                                          <h5 class="card-title mb-0">Age</h5>
                                          <p class="card-text">Highly Impact</p>
                                          <a
                                            href="javascript: void(0)"
                                            data-bs-toggle="modal"
                                            data-bs-target="#ageModel"
                                            class="stretched-link card-payment"
                                          ></a>
                                        </div>
                                        <div class="card-footer">
                                          <p className="text-white">
                                            Timely payments
                                          </p>
                                          <h3 className="text-white">
                                            {dateDifference.years > 15
                                              ? "90%"
                                              : dateDifference.years > 10
                                              ? "80%"
                                              : dateDifference.years > 5
                                              ? "60%"
                                              : "less than 60%"}
                                          </h3>
                                          <i class="bi bi-chevron-right"></i>
                                          <a
                                            href="javascript: void(0)"
                                            data-bs-toggle="modal"
                                            data-bs-target="#ageModel"
                                            class="stretched-link card-payment"
                                          ></a>
                                          <AgeModal />
                                        </div>
                                      </div>
                                    </div>
                                    <div class="col-md-6">
                                      <div
                                        class={`card border-0 shadow details-analysis-card ${
                                          !data?.CIBILDetails?.credit_report ||
                                          Object.values(
                                            data?.CIBILDetails?.credit_report
                                          ).length === 0
                                            ? "disabled-card"
                                            : ""
                                        }`}
                                        style={{
                                          pointerEvents:
                                            !data?.CIBILDetails
                                              ?.credit_report ||
                                            Object.values(
                                              data?.CIBILDetails?.credit_report
                                            ).length === 0
                                              ? "none"
                                              : "auto",
                                        }}
                                      >
                                        <div class="card-body">
                                          <h5 class="card-title mb-0">
                                            Accounts
                                          </h5>
                                          <p class="card-text">
                                            {active > 2
                                              ? "High"
                                              : active > 1
                                              ? "Medium"
                                              : "Low"}{" "}
                                            Impact
                                          </p>
                                        </div>
                                        <div class="card-footer">
                                          <p className="text-white">
                                            Timely payments
                                          </p>
                                          <h3 className="text-white">
                                            {active} Active
                                          </h3>
                                          <i class="bi bi-chevron-right"></i>
                                          <a
                                            href="javascript: void(0)"
                                            data-bs-toggle="modal"
                                            data-bs-target="#accountsmodal"
                                            class="stretched-link card-accounts"
                                          ></a>
                                          <AccountModal
                                            PaymentStatus={calculatePayment()}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div class="col-md-6">
                                      <div
                                        class={`card border-0 shadow details-analysis-card ${
                                          !data?.CIBILDetails?.credit_report ||
                                          Object.values(
                                            data?.CIBILDetails?.credit_report
                                          ).length === 0
                                            ? "disabled-card"
                                            : ""
                                        }`}
                                        style={{
                                          pointerEvents:
                                            !data?.CIBILDetails
                                              ?.credit_report ||
                                            Object.values(
                                              data?.CIBILDetails?.credit_report
                                            ).length === 0
                                              ? "none"
                                              : "auto",
                                        }}
                                      >
                                        <div class="card-body">
                                          <h5 class="card-title mb-0">
                                            Profile
                                          </h5>
                                          <p class="card-text">Low Impact</p>
                                        </div>
                                        <div class="card-footer">
                                          <p className="text-white">
                                            Timely payments
                                          </p>
                                          <h3 className="text-white">
                                            {active}
                                          </h3>
                                          <i class="bi bi-chevron-right"></i>
                                          <a
                                            href="javascript: void(0)"
                                            data-bs-toggle="modal"
                                            data-bs-target="#profileModal"
                                            class="stretched-link card-accounts"
                                          ></a>
                                          <ProfileModal active={active} />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div></div>
                      </div>
                    </div>
                  </div>
                </div>
                {companyData?.map((item, index) => (
                  <div class="row">
                    <div class="col-lg-12">
                      <GSTUser user={item} />
                    </div>
                    <div class="col-lg-12">
                      <div class="accordion" id="accordionExample">
                        <div class="accordion-item">
                          <h2 class="accordion-header details-analysis-header">
                            <button
                              class="accordion-button"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target={`#collapseOne${index}`}
                              aria-expanded="true"
                              aria-controls="collapseOne"
                            >
                              Details Analysis
                            </button>
                          </h2>
                          <div
                            id={`collapseOne${index}`}
                            class="accordion-collapse collapse show"
                            data-bs-parent="#accordionExample"
                          >
                            <div class="accordion-body">
                              <div class="row">
                                <div class="col-lg-6">
                                  <ul class="list-unstyled">
                                    <li class="list-inline-item">
                                      <i class="bi bi-patch-check-fill"></i>{" "}
                                      Basic Details Verified
                                    </li>
                                    <li class="list-inline-item">
                                      <i class="bi bi-patch-check text-muted"></i>{" "}
                                      Need to Verify Address
                                    </li>
                                  </ul>
                                  <BarChart user={item} />
                                </div>
                                <div class="col-lg-6">
                                  <div class="pt-5">
                                    <div class="row">
                                      <div class="col-md-6">
                                        <div class="card border-0 shadow details-analysis-card">
                                          <div class="card-body">
                                            <h5 class="card-title mb-0">
                                              Payment
                                            </h5>
                                            <p class="card-text">
                                              Highly Impact
                                            </p>
                                            <a
                                              href="javascript: void(0)"
                                              data-bs-toggle="modal"
                                              data-bs-target="#paymentmodal"
                                              class="stretched-link card-payment"
                                            ></a>
                                          </div>
                                          <div class="card-footer">
                                            {/* <p style={{color:"#fff"}}>Timely payments</p> */}
                                            <h6 className="text-white">
                                              Check Status..
                                            </h6>
                                            <i class="bi bi-chevron-right"></i>
                                            <a
                                              href="javascript: void(0)"
                                              data-bs-toggle="modal"
                                              data-bs-target="#gstpaymentmodal"
                                              class="stretched-link card-payment"
                                            ></a>
                                            <GSTPaymentModal
                                              companyData={companyData}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div class="col-md-6">
                                        <div class="card border-0 shadow details-analysis-card">
                                          <div class="card-body">
                                            <h5 class="card-title mb-0">Age</h5>
                                            <p class="card-text">
                                              Highly Impact
                                            </p>
                                            <a
                                              href="javascript: void(0)"
                                              data-bs-toggle="modal"
                                              data-bs-target="#companyAgeModel"
                                              class="stretched-link card-payment"
                                            ></a>
                                          </div>
                                          <div class="card-footer">
                                            {/* <p style={{color:"#fff"}}>Timely payments</p> */}
                                            <h6 className="text-white">
                                              Check Age..
                                            </h6>
                                            <i class="bi bi-chevron-right"></i>
                                            <a
                                              href="javascript: void(0)"
                                              data-bs-toggle="modal"
                                              data-bs-target="#companyAgeModel"
                                              class="stretched-link card-payment"
                                            ></a>
                                            <CompanyAgeModal user={item} />
                                          </div>
                                        </div>
                                      </div>
                                      <div class="col-md-6">
                                        <div class="card border-0 shadow details-analysis-card">
                                          <div class="card-body">
                                            <h5 class="card-title mb-0">
                                              Accounts
                                            </h5>
                                            <p class="card-text">Low Impact</p>
                                          </div>
                                          <div class="card-footer">
                                            {/* <p style={{color:"#fff"}}>Timely payments</p> */}
                                            <h6 className="text-white">
                                              Check Accounts..
                                            </h6>
                                            <i class="bi bi-chevron-right"></i>
                                            <a
                                              href="javascript: void(0)"
                                              data-bs-toggle="modal"
                                              data-bs-target="#companyAccount"
                                              class="stretched-link card-accounts"
                                            ></a>
                                            <CompanyAccounts user={item} />
                                          </div>
                                        </div>
                                      </div>
                                      <div class="col-md-6">
                                        <div class="card border-0 shadow details-analysis-card">
                                          <div class="card-body">
                                            <h5 class="card-title mb-0">
                                              Profile
                                            </h5>
                                            <p class="card-text">Low Impact</p>
                                          </div>
                                          <div class="card-footer">
                                            {/* <p style={{color:"#fff"}}>Timely payments</p> */}
                                            <h6 className="text-white">
                                              Check Profile..
                                            </h6>
                                            <i class="bi bi-chevron-right"></i>
                                            <a
                                              href="javascript: void(0)"
                                              data-bs-toggle="modal"
                                              data-bs-target="#companyprofile"
                                              class="stretched-link card-accounts"
                                            ></a>
                                            <CompanyProfileModal user={item} />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div></div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            {shareClick ? (
              <ShareModal
                shareClick={shareClick}
                setShareClick={setShareClick}
              />
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserProfile;
