/* eslint-disable react/prop-types */
/* eslint-disable react/react-in-jsx-scope */
import chatSvg from "../../../../../assets2/chat.svg";
import mailbox from "../../../../../assets2/mailbox.svg";
import envelope from "../../../../../assets2/envelope.svg";
import telephone from "../../../../../assets2/telephone.svg";
import phone from "../../../../../assets2/phone.svg";
import communicationInfo from "../../../../../assets2/communicationInfo.svg";
import whatsapp from "../../../../../assets2/whatsapp.svg";

const ToggleCommBtn = ({ loan }) => {
  console.log(loan, "loans");
  const renderModal = (id, title, details, headers) => (
    <div
      className="modal"
      id={id}
      tabIndex="-1"
      aria-labelledby={`${id}Label`}
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id={`${id}Label`}>
              {title}
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <table className="table table-bordered">
              <thead>
                <tr>
                  {headers.map((header, index) => (
                    <th scope="col" key={index}>
                      {header}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {console.log(details, "details")}
                <td>{loan.stage || "-"}</td>
                <td> {details || "-"}</td>
              </tbody>
            </table>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <div className="d-flex">
        <div className="icon-group">
          <div className="dropdown">
            <button
              type="button"
              className="btn btn-primary dropdown-toggle"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              data-bs-auto-close="true"
            >
              Communication
            </button>
            <ul className="dropdown-menu">
              <li>
                <button
                  type="button"
                  className="dropdown-item"
                  data-bs-toggle="modal"
                  data-bs-target="#smsModal"
                >
                  <img
                    className="me-2"
                    src={chatSvg}
                    alt="SMS"
                    style={{ width: "25px", height: "25px" }}
                  />
                  SMS Details
                </button>
              </li>
              <li>
                <button
                  type="button"
                  className="dropdown-item"
                  data-bs-toggle="modal"
                  data-bs-target="#whatsappModal"
                >
                  <img
                    className="me-2"
                    src={whatsapp}
                    alt="WhatsApp"
                    style={{ width: "25px", height: "25px" }}
                  />
                  WhatsApp Details
                </button>
              </li>
              <li>
                <button
                  type="button"
                  className="dropdown-item"
                  data-bs-toggle="modal"
                  data-bs-target="#postalModal"
                >
                  <img
                    className="me-2"
                    src={mailbox}
                    alt="Postal"
                    style={{ width: "25px", height: "25px" }}
                  />
                  Postal Details
                </button>
              </li>
              <li>
                <button
                  type="button"
                  className="dropdown-item"
                  data-bs-toggle="modal"
                  data-bs-target="#emailModal"
                >
                  <img
                    className="me-2"
                    src={envelope}
                    alt="Email"
                    style={{ width: "25px", height: "25px" }}
                  />
                  Email Details
                </button>
              </li>
              <li>
                <button
                  type="button"
                  className="dropdown-item"
                  data-bs-toggle="modal"
                  data-bs-target="#ivrModal"
                >
                  <img
                    className="me-2"
                    src={phone}
                    alt="IVR Calling"
                    style={{ width: "25px", height: "25px" }}
                  />
                  IVR Calling Details
                </button>
              </li>
              <li>
                <button
                  type="button"
                  className="dropdown-item"
                  data-bs-toggle="modal"
                  data-bs-target="#manualCallingModal"
                >
                  <img
                    className="me-2"
                    src={telephone}
                    alt="Manual Calling"
                    style={{ width: "25px", height: "25px" }}
                  />
                  Manual Calling Details
                </button>
              </li>
              <li>
                <button
                  type="button"
                  className="dropdown-item"
                  data-bs-toggle="modal"
                  data-bs-target="#communicationInfoModal"
                >
                  <img
                    className="me-2"
                    src={communicationInfo}
                    alt="CommunicationInfo"
                    style={{ width: "25px", height: "25px" }}
                  />
                  Communication Info
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>

      {/* {console.log(loan, "    loan?.connection?.sms,")} */}
      {renderModal("smsModal", "SMS Communication Details", loan.smsStatus, [
        "Process",
        "Status",
      ])}
      {renderModal(
        "whatsappModal",
        "WhatsApp Communication Details",
        loan?.whatsappStatus,
        ["Process", "Status"]
      )}
      {renderModal(
        "postalModal",
        "Postal Communication Details",
        loan?.postalStatus,
        ["Process", "BorrowerTrackingStatus"]
      )}
      {renderModal(
        "emailModal",
        "Email Communication Details",
        loan?.emailStatus,
        ["Process", "Status"]
      )}
      {renderModal(
        "ivrModal",
        "IVR Calling Communication Details",
        loan?.callingStatus,
        ["Process", "Status"]
      )}
      {renderModal(
        "manualCallingModal",
        "Manual Calling Communication Details",
        loan?.manualCalling,
        ["Process", "Status"]
      )}

      {/* Communciation Info Modal */}
      <div
        className="modal"
        id="communicationInfoModal"
        tabIndex="-1"
        aria-labelledby="communicationinfoLabel"
        aria-hidden="true"
        data-bs-backdrop="static"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="communicationinfoLabel">
                Communication Info
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="table-responsive">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th colSpan="3">
                        Dated: {true?.process || "21/11/2024"}
                      </th>
                      <th colSpan="2">
                        Process: {true?.process || "Arbitration"}
                      </th>
                      <th colSpan="1">Stage: {true?.stage || "Invocation"}</th>
                    </tr>
                    <tr>
                      <th>Date</th>
                      <th>Process</th>
                      <th>Stage</th>
                      <th>Communication</th>
                      <th>Response</th>
                      <th>Additional Information</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>21/11/2024</td>
                      <td>Arbitration</td>
                      <td>Invocation</td>
                      <td>Postal</td>
                      <td>{loan.postalStatus || ""}</td>
                      <td>-</td>
                    </tr>
                    <tr>
                      <td>22/11/2024</td>
                      <td>Arbritration</td>
                      <td>Invocation</td>
                      <td>Whatsapp</td>
                      <td>{loan?.whatsappStatus || ""}</td>
                      <td>-</td>
                    </tr>
                    <tr>
                      <td>22/11/2024</td>
                      <td>Arbitartion</td>
                      <td>Invocation</td>
                      <td>Email</td>
                      <td>{loan?.emailStatus || ""}</td>
                      <td>-</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ToggleCommBtn;
