import { useSelector } from "react-redux";
import LoginInnerWrapper from "../loginPage/components/loginInnerWrapper";
import LoginWrapper from "../loginPage/components/loginWrapper";
import { TranslationNotice } from "./TranslationNotice";
import LoginLang1 from "../../components/LoginLang1";

const CompleteTranslation = () => {
    const lang = useSelector((state) => state.lang.lang)
    console.log(lang)
    return(
        <>
        <LoginWrapper>
        <LoginInnerWrapper>
             <TranslationNotice />
             <LoginLang1/>  
        </LoginInnerWrapper>
        </LoginWrapper>
    </>
    )
}

export default CompleteTranslation;