/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from "react";
import Papa from "papaparse";

export const handleDownload = () => {
  const headers = [
    "loanId",
    "Name of Borrower",
    "Borrower Address",
    "Borrower Email",
    "Borrower Phone",
    "Borrower PAN Number",
    "Name of CoBorrower",
    "CoBorrower Address",
    "CoBorrower Email",
    "CoBorrower Phone",
    "CoBorrower PAN Number",
    "Claim Amount",
    "Claimant Company",
    "Claimant Company Address",
    "Meeting Date",
    "Notice Date",
    "Last EMI Paid Date",
    "Name of Second CoBorrower",
    "EMI Start Date",
    "EMI End Date",
    "Interest",
    "RC Number",
    "CIN Number",
    "GST Number",
    "Vehicle Number",
    "Driving Licence Number",
    "Loan Type",
    "EMI",
    "Total Loan",
    "EMI Start",
    "EMI End",
    "Seat",

    "Bank Account Number",
    "IFSC Code",
    "Dispute Resolution Paper ID",
    "Associate Name",
    "Ref Number",
    "Case ID",
    "Principle Outstanding",
    "Maximum Settlement Amount",
    "Minimum Settlement Amount",
    "ClaimantSide For Coordination",
    "ClaimantSide For Coordination Contact",
    "Zone",
    "DPD",
    "Notice Stage",
    "City",
    "State",
    "Stage",
  ];
  const data = [
    [
      "A123",
      "Ayush Tiwari",
      "NetaJi Nagar, North Delhi, 330211",
      "ayush@example.com",
      "6245789685",
      "CEPPT7676M",
      "Manoj Tiwari",
      "NetaJi Nagar, North Delhi, 330211",
      "manoj@example.com",
      "6245789685",
      "CEPPT7676M",
      "21000",
      "ClaimantPVT",
      "North Street Park Chennai, 989803, TamilNadu",
      "08/08/2024",
      "23/12/2024",
      "05/05/2024",
      "Seena Sharma",
      "05/12/2023",
      "05/12/2026",
      "6%",
      "DL12AG8956",
      "L17110MH1973PLC019786",
      "22AAAAA0000A1Z5",
      "DL12AM2112",
      "MH03 200800222135",
      "Personal",
      "4545",
      "1000000",
      "05/12/2021",
      "05/12/2206",
      "First",
      "8080000008854",
      "SBIN0006245",
      "DRP12456",
      "Lalit Singh",
      "54789654",
      "A78954A",
      "124578",
      "12457",
      "1235",
      "Nitesh Singh",
      "6598785421",
      "zone",
      "dpd",
      "Conciliation",
      "Raipur",
      "Chhattisgarh",
      "Hearing",
    ],
  ];
  const csvContent = [headers, ...data];
  const csvString = Papa.unparse(csvContent);

  const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.setAttribute("download", "privatecourt.csv");
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
const InfoModal = ({ show, onHide, tooltipTexts }) => {
  return (
    <div
      className={`modal fade ${show ? "show d-block" : ""}`}
      tabIndex="-1"
      style={{ backgroundColor: show ? "rgba(0,0,0,0.5)" : "transparent" }}
      role="dialog"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">CSV Upload Instructions</h5>
            <button
              type="button"
              className="btn-close"
              onClick={onHide}
            ></button>
          </div>
          <div
            className="modal-body"
            style={{ maxHeight: "550px", overflowY: "auto" }}
          >
            <p>
              Please make sure your CSV file contains the following headers:
            </p>
            <ul>
              {Object.keys(tooltipTexts).map((header) => (
                <li key={header}>
                  <strong>{header}:</strong> {tooltipTexts[header]}
                </li>
              ))}
            </ul>

            <p>You can download a sample CSV file with the required headers:</p>
            <div className="d-flex align-items-center mb-3">
              <i
                className="bi bi-cloud-arrow-down-fill"
                style={{
                  fontSize: "1.5rem",
                  color: "#007bff",
                  cursor: "pointer",
                }}
                onClick={handleDownload}
              ></i>
              <span
                style={{ marginLeft: "0.5rem", cursor: "pointer" }}
                onClick={handleDownload}
              >
                Download CSV
              </span>
            </div>
            <b className="mt-2 mb-2">
              Note : If a person contains more than one piece of data, use a
              comma to separate them in the cell before uploading.
            </b>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={onHide}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfoModal;
