/* eslint-disable react/react-in-jsx-scope */
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
const ProfileModal = (props) => {
  const theme = useSelector((state) => state.theme.theme);
  const [detail, setDetail] = useState({});
  const [addresses, setAddresses] = useState([]);
  const [phones, setPhones] = useState([]);
  const [emails, setEmails] = useState([]);
  const [activeTab, setActiveTab] = useState("address-details");
  const handleTabChange = (tabKey) => {
    setActiveTab(tabKey);
  };
  const data = useSelector((state) => state.dashboardSlice.data);
  useEffect(() => {
    if (data) {
      const filterAccounts1 =
        data?.CIBILDetails?.credit_report.CCRResponse.CIRReportDataLst[0]
          .CIRReportData.IDAndContactInfo.AddressInfo;
      setAddresses(filterAccounts1);
      const Phone =
        data?.CIBILDetails?.credit_report.CCRResponse.CIRReportDataLst[0]
          .CIRReportData.IDAndContactInfo.PhoneInfo;
      setPhones(Phone);
      const Email =
        data?.CIBILDetails?.credit_report.CCRResponse.CIRReportDataLst[0]
          .CIRReportData.IDAndContactInfo.EmailAddressInfo;
      setEmails(Email);
      const filterAccounts =
        data?.CIBILDetails?.credit_report.CCRResponse.CIRReportDataLst[0]
          .CIRReportData.RetailAccountsSummary;
      setDetail(filterAccounts);
    }
  }, [data]);
  return (
    <>
      <div
        className="modal fade"
        id="profileModal"
        tabIndex="-1"
        aria-labelledby="profileModal"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Profile Details</h4>
              <button
                type="button"
                className={`btn-close ${
                  theme ? "btn-close-black" : "btn-close-white"
                }`}
                data-bs-dismiss="modal"
              ></button>
            </div>

            <div className="modal-body">
              <div className="card card-new">
                <div className="d-flex payment-header">
                  <div className="payment-header-left w-50">
                    <h2 className="mb-0">
                      {props?.active > 2 ? "High" : "Low"} Impact
                    </h2>
                    <p>{/* Last updated on <strong>13 Jun 2024</strong> */}</p>
                  </div>
                  <div className="payment-header-right w-50 text-end  align-items-center">
                    <span className="badge text-bg-warning mt-3">Fair</span>
                  </div>
                </div>
                <div className="d-flex mt-2">
                  <div className="">
                    <h2 className="mb-0">{detail?.NoOfAccounts}</h2>
                    <p className="mb-0">Total Accounts</p>
                  </div>
                  <div className="ps-5">
                    <h2 className="mb-0">{detail?.NoOfActiveAccounts}</h2>
                    <p className="mb-0">Active Accounts</p>
                  </div>
                </div>
              </div>
              {addresses?.length > 2 ? (
                <div className="alert alert-warning">
                  <div className="d-flex payment-header">
                    <div style={{ marginRight: "8px", marginTop: "10px" }}>
                      <i
                        className="fas fa-exclamation-triangle"
                        style={{ "font-size": "40px", color: "#dc3545" }}
                      ></i>
                    </div>
                    <div className="payment-header-left">
                      <h2 className="mb-0 fs-5 text-danger">
                        Inconsistent Address
                      </h2>
                      <p className="mb-0 mt-2">
                        Your address is different across various accounts.
                        Please contact your bank to update it for a more
                        accurate score.
                      </p>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
              {/* <h4>Basic Information : </h4> */}
              <ul
                className="nav nav-pills profile-modal-tab mb-3"
                id="pills-tab"
                role="tablist"
              >
                <li className="nav-item" role="presentation">
                  <button
                    className={`nav-link ${
                      activeTab === "address-details" ? "active" : ""
                    }`}
                    id="pills-address-details-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-address-details"
                    type="button"
                    style={{
                      background:
                        activeTab === "address-details"
                          ? ""
                          : theme
                          ? "#fff"
                          : "#000",
                    }}
                    role="tab"
                    aria-controls="pills-address-details"
                    aria-selected={activeTab === "address-details"}
                    onClick={() => handleTabChange("address-details")}
                  >
                    Address Details
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className={`nav-link ${
                      activeTab === "phone-details" ? "active" : ""
                    }`}
                    id="pills-phone-details-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-phone-details"
                    type="button"
                    role="tab"
                    style={{
                      background:
                        activeTab === "phone-details"
                          ? ""
                          : theme
                          ? "#fff"
                          : "#000",
                    }}
                    aria-controls="pills-phone-details"
                    aria-selected={activeTab === "phone-details"}
                    onClick={() => handleTabChange("phone-details")}
                  >
                    Phone Details
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className={`nav-link ${
                      activeTab === "email-details" ? "active" : ""
                    }`}
                    id="pills-email-details-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-email-details"
                    type="button"
                    style={{
                      background:
                        activeTab === "email-details"
                          ? ""
                          : theme
                          ? "#fff"
                          : "#000",
                    }}
                    role="tab"
                    aria-controls="pills-email-details"
                    aria-selected={activeTab === "email-details"}
                    onClick={() => handleTabChange("email-details")}
                  >
                    Email Details
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className={`nav-link ${
                      activeTab === "employment-details" ? "active" : ""
                    }`}
                    id="pills-employment-details-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-employment-details"
                    type="button"
                    style={{
                      background:
                        activeTab === "employment-details"
                          ? ""
                          : theme
                          ? "#fff"
                          : "#000",
                    }}
                    role="tab"
                    aria-controls="pills-employment-details"
                    aria-selected={activeTab === "employment-details"}
                    onClick={() => handleTabChange("employment-details")}
                  >
                    Employment Details
                  </button>
                </li>
              </ul>
              <div className="tab-content" id="pills-tabContent">
                <div
                  className={`tab-pane fade ${
                    activeTab === "address-details" ? "show active" : ""
                  }`}
                  id="pills-address-details"
                  role="tabpanel"
                  aria-labelledby="pills-address-details-tab"
                >
                  {addresses?.map((address, index) => (
                    <div
                      key={index}
                      className="d-flex flex-row align-items-start box-style-1 p-3 border border-grey border-r-8 mb-3"
                    >
                      <div className="flex-grow-1 ms-3">
                        <p className="mb-0">
                          <strong>Address</strong>:{" "}
                          {address.Address?.toLowerCase()}
                        </p>
                        <p className="mb-0">
                          <strong>State</strong>: {address.State}
                        </p>
                        <p className="mb-0">
                          <strong>Postal ID</strong>:{" "}
                          {address.Postal?.toLowerCase()}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
                <div
                  className={`tab-pane fade ${
                    activeTab === "phone-details" ? "show active" : ""
                  }`}
                  id="pills-phone-details"
                  role="tabpanel"
                  aria-labelledby="pills-phone-details-tab"
                >
                  {phones?.map((phone, index) => (
                    <div
                      key={index}
                      className="d-flex flex-row align-items-center box-style-1 p-3 border border-grey border-r-8 mb-3"
                    >
                      <div className="flex-grow-1 ms-3">
                        <p className="mb-0">
                          <strong>Contact {index + 1}</strong> : {phone.Number}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
                <div
                  className={`tab-pane fade ${
                    activeTab === "email-details" ? "show active" : ""
                  }`}
                  id="pills-email-details"
                  role="tabpanel"
                  aria-labelledby="pills-email-details-tab"
                >
                  {emails?.map((email, index) => (
                    <div
                      key={index}
                      className="d-flex flex-row align-items-center box-style-1 p-3 border border-grey border-r-8 mb-3"
                    >
                      <div className="flex-grow-1 ms-3">
                        <p className="mb-0">
                          <strong>Emails {index + 1}</strong> :{" "}
                          {email.EmailAddress?.toLowerCase()}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
                <div
                  className={`tab-pane fade ${
                    activeTab === "employment-details" ? "show active" : ""
                  }`}
                  id="pills-employment-details"
                  role="tabpanel"
                  aria-labelledby="pills-employment-details-tab"
                >
                  <div className="d-flex flex-row align-items-center box-style-1 p-3 border border-grey border-r-8 mb-3">
                    <div className="flex-grow-1 ms-3">
                      <p className="mb-0">
                        <strong>Company Name :</strong>{" "}
                        {
                          data?.EPFODetails?.result?.summary
                            ?.recent_employer_data?.establishment_name
                        }
                      </p>
                      <p className="mb-0">
                        <strong>Date of Joining :</strong>{" "}
                        {
                          data?.EPFODetails?.result?.summary
                            ?.recent_employer_data?.date_of_joining
                        }
                      </p>
                      <p className="mb-0">
                        <strong>Date of exit :</strong>{" "}
                        {data?.EPFODetails?.result?.summary
                          ?.recent_employer_data?.date_of_exit || "Present"}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileModal;
