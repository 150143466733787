/* eslint-disable react/prop-types */
import React from "react";
import Field from "./Field";

const SecondCard = ({ loan }) => {
  // console.log(loan)
  return (
    <div className="card card-new">
      {/* loanId and Agremeent Detail Section */}
      <>
        <div className="row mt-2">
          <Field key1="Loan ID:" value={loan?.loanId || ""} />
          <Field key1="Agreement No:" value={loan?.agreementNumber || ""} />
        </div>
      </>

      {/* Borrower Section */}
      <>
        <hr className="mt-0 mb-1" />
        <h2 className="mb-3 mt-3">Borrower</h2>
        <div className="row">
          <Field key1="Name:" value={loan?.borrowerName || " "} />
          <Field key1="Email:" value={loan?.borrowerEmail || " "} />
          <Field key1="Phone:" value={loan?.borrowerPhone || " "} />
          <Field key1="Pan:" value={loan?.borrowerPANNumber || " "} />
          <Field key1="Address:" value={loan?.borrowerAddress || " "} />
        </div>
      </>

      {/* Co-Borrower Section */}
      {[
        loan?.coBorrowerName,
        loan?.coBorrowerPhone,
        loan?.coBorrowerAddress,
      ].some((field) => field && field !== "N/A") ? (
        <>
          <hr className="mt-0 mb-1" />
          <h2 className="mb-3 mt-3">Co Borrower</h2>
          <div className="row">
            <Field key1="Name:" value={loan?.coBorrowerName || " "} />
            <Field key1="Phone:" value={loan?.coBorrowerPhone || " "} />
            <Field key1="Address:" value={loan?.coBorrowerAddress || " "} />
          </div>
        </>
      ) : null}

      {/* GST-Number and CIN Section */}
      {[loan?.gstNumber, loan?.cin].some(
        (field) => field && field !== "N/A"
      ) ? (
        <div className="gst-cin-number">
          <>
            <hr className="mt-0" />
            <div className="row">
              <Field key1="GST Number:" value={loan?.gstNumber || " "} />
              <Field key1="CIN:" value={loan?.cin || " "} />
            </div>{" "}
          </>
        </div>
      ) : null}

      {/* Loan Details Section */}
      {[
        loan?.loanType,
        loan?.interest,
        // loan?.emi,
        loan?.totalLoan,
        loan?.principleOutstanding,
        loan?.minimumSettlementAmount,
        loan?.maximumSettlementAmount,
      ].some((field) => field && field !== "N/A") ? (
        <div className="loan-Details">
          <>
            <hr className="mt-0 mb-1" />
            <h2 className="mb-3 mt-3">Loan Details</h2>
            <div className="row">
              <Field key1="Loan Type:" value={loan?.loanType || " "} />
              <Field key1="Interest:" value={loan?.interest || " "} />
              <Field key1="Total Loan:" value={loan?.totalLoan || " "} />
              <Field
                key1="Principle Outstanding:"
                value={loan?.principleOutstanding || " "}
              />
              <Field
                key1="Min Settlement:"
                value={loan?.minimumSettlementAmount || " "}
              />
              <Field
                key1="Max Settlement:"
                value={loan?.maximumSettlementAmount || " "}
              />
            </div>
          </>
        </div>
      ) : null}

      {/* Vehicle Details Section */}
      {(loan?.vehicleNo && loan?.vehicleNo !== "N/A") ||
      (loan?.rcNo && loan?.rcNo !== "N/A") ||
      (loan?.drivingLicenceNo && loan?.drivingLicenceNo !== "N/A") ? (
        <div className="vehicle-details">
          <>
            <hr className="mt-0 mb-1" />
            <h2 className="mb-3 mt-3">Vehicle Details</h2>
            <div className="row">
              {/* Vehicle Number */}
              <Field key1="Vehicle Number:" value={loan?.vehicleNo || " "} />
              <Field key1="RC Number:" value={loan?.rcNo || " "} />
              <Field
                key1="Driving License:"
                value={loan?.drivingLicenceNo || " "}
              />
            </div>
          </>
        </div>
      ) : null}
    </div>
  );
};

export default SecondCard;
